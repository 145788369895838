import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../components/context/Auth";
import {
  HomeImg,
  LogInBtnIcon,
  LogInImg,
  Spinner,
  WalliconLogo,
} from "../components/icons/Icon";
import styles from "./Home.module.css";
import CryptoJS from "crypto-js";
import InputTag2 from "../components/updatedComponents/FormComponents/InputTag2";
import Request from "../components/updatedComponents/Login/Request";
import PinSetup from "../components/updatedComponents/Login/PinSetup";
import Pinlogin from "../components/updatedComponents/Login/Pinlogin";
import RequestPending from "../components/updatedComponents/Login/RequestPending";
import useDeviceInfo from "../components/updatedComponents/Hooks/GetdeviceId";
import fetchData from "../APIsControll/apiControll";
import { useToast } from "../components/updatedComponents/Toaster/Toaster";
import { io } from "socket.io-client";
import DesableLogin from "../components/updatedComponents/Login/DesableLogin";
import AdminOtpverify from "../components/updatedComponents/Login/AdminOtpverify";

const Home = () => {
  const navigate = useNavigate();
  const { setDesignation, setManagerId, setManagerName, setLoggedIn, mode, designations, setDesignations, } = useAuth();
  const [devicename, setdevicename] = useState();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    error: "",
  });
  const [pin, setPin] = useState(['', '', '', '', '', '']);
  const [confirmPin, setConfirmPin] = useState(['', '', '', '', '', '']);
  const [loginPin, setLoginpin] = useState(['', '', '', '', '', '']);

  const [confirmOtp, setConfirmOtp] = useState(['', '', '', '', '', '']);
  const [loginOtp, setLoginOtp] = useState(['', '', '', '', '', '']);
  // const [msg, setMsg] = useState("");
  const [loading, setLoading] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
    loading4: false,
    loading5: false,
  });
  const [error, setError] = useState(false);
  const [screen, setScreen] = useState("default");
  const { show } = useToast();
  const { deviceId, osName } = useDeviceInfo();
  const getdeviceIdStatus = async () => {
    try {
      setLoading({ ...loading, loading2: true });
      const payload = {
        deviceId: deviceId,
        loginId: localStorage.getItem("loginID") || credentials.username,
      }
      const res = await fetchData(`managers/getdeviceIdStatus`, payload);
      if (res?.status === 'success') {
        setScreen(res?.deviceActive);
        setLoading({ ...loading, loading2: false });
        return
      }
      if (res?.status === 'failed') {
        setLoading({ ...loading, loading2: false });
        return
      }
      else {
        show("something wrong ", "error");
        setLoading({ ...loading, loading2: false });
        return
      }
    } catch (error) {
      show("something wrong ", "error");
      setLoading({ ...loading, loading2: false });
    }
  }
  // request For Device
  const requestForDevice = async () => {
    try {
      setLoading({ ...loading, loading5: true });
      setLoading({ ...loading, loading2: true });
      const payload = {
        deviceId: deviceId,
        loginId: localStorage.getItem("loginID") || credentials.username,
        deviceName: devicename
      }
      const res = await fetchData(`managers/requestForDevice`, payload);
      if (res?.status === 'success') {
        setLoading({ ...loading, loading5: false });
        getdeviceIdStatus();
        return
      }
      if (res?.status === 'failed') {
        setLoading({ ...loading, loading5: false });
        show(res?.msg);
        return
      }
      else {
        show("something wrong ", "error");
        return
      }

    } catch (error) {
      show("something wrong ", "error");
      setLoading({ ...loading, loading5: false });
    }
  }
  const logoutDevice = async () => {
    try {
      const payload = {
        deviceId: deviceId,
        loginId: localStorage.getItem("loginID") || credentials.username,
      }
      setLoading({ ...loading, loading4: true });
      setLoading({ ...loading, loading2: true });
      const res = await fetchData(`managers/logoutDevice`, payload);
      if (res?.status === 'success') {
        setScreen("default")
        getdeviceIdStatus();
        setLoading({ ...loading, loading4: false });
        return
      }
      if (res?.status === 'failed') {
        setLoading({ ...loading, loading4: false });
        show(res?.msg);
        return
      }
      else {
        show("something wrong ", "error");
        setLoading({ ...loading, loading4: false });
        return
      }

    } catch (error) {
      show("something wrong ", "error");
      setLoading({ ...loading, loading4: false });
    }
  }

  // pinSetup 
  const pinSetup = async () => {
    try {
      setLoading({ ...loading, loading2: true });
      const pinString = pin.join('');
      const payload = {
        deviceId: deviceId,
        loginId: localStorage.getItem("loginID") || credentials.username,
        pin: pinString,
      }
      const res = await fetchData(`managers/pinSetup`, payload);
      if (res?.status === 'success') {
        getdeviceIdStatus();
        return
      }
      if (res?.status === 'failed') {
        show(res?.msg);
        return
      }
      else {
        show("something wrong ", "error");
        return
      }
    } catch (error) {
      show("something wrong ", "error");
    }
  }
  const loginWithotp = async () => {
    try {
      const pinString = loginOtp.join('');
      const payload = {
        loginId: localStorage.getItem("loginID") || credentials.username,
        otp: pinString,
      }
      const res = await fetchData(`managers/adminotpverify`, payload);
      if (res?.status === 'success') {

        sessionStorage.setItem("managerId", res?.user?.managerId);
        const encryptedName = CryptoJS.AES.encrypt(
          res?.user?.name,
          "secret key"
        ).toString();
        const encryptedDesignation = CryptoJS.AES.encrypt(
          res?.user?.designation[0],
          "secret key"
        ).toString();
        const encryptedDesignations = CryptoJS.AES.encrypt(
          JSON.stringify(res?.user?.designation),
          "secret key"
        ).toString();
        const encryptedLoginId = CryptoJS.AES.encrypt(
          JSON.stringify(res?.user?.loginId),
          "secret key"
        ).toString();

        sessionStorage.setItem("name", encryptedName);
        sessionStorage.setItem("designation", encryptedDesignation);
        sessionStorage.setItem("designations", encryptedDesignations);
        sessionStorage.setItem("loginID", encryptedLoginId);

        setDesignation(res?.user?.designation[0]);
        setManagerId(res?.user?.managerId);
        setManagerName(res?.user?.name);
        setLoggedIn(true);
        navigate("/dashboard");
        return
      }
      if (res?.status === 'failed') {
        show(res?.msg);
        return
      }
      else {
        show("something wrong ", "error");
        return
      }
    } catch (error) {
      show("something wrong ", "error");
    }
  }







  //  login with pin
  const loginWithPin = async () => {
    try {
      setLoading({ ...loading, loading2: true });
      const pinString = loginPin.join('');
      const payload = {
        deviceId: deviceId,
        loginId: localStorage.getItem("loginID") || credentials.username,
        pin: pinString,
      }
      const res = await fetchData(`managers/pinLogin`, payload);
      if (res?.status === 'success') {
        if (res.user) {
          sessionStorage.setItem("managerId", res?.user?.managerId);
          const encryptedName = CryptoJS.AES.encrypt(
            res?.user?.name,
            "secret key"
          ).toString();
          const encryptedDesignation = CryptoJS.AES.encrypt(
            res?.user?.designation[0],
            "secret key"
          ).toString();
          const encryptedDesignations = CryptoJS.AES.encrypt(
            JSON.stringify(res?.user?.designation),
            "secret key"
          ).toString();
          const encryptedLoginId = CryptoJS.AES.encrypt(
            JSON.stringify(res?.user?.loginId),
            "secret key"
          ).toString();

          sessionStorage.setItem("name", encryptedName);
          sessionStorage.setItem("designation", encryptedDesignation);
          sessionStorage.setItem("designations", encryptedDesignations);
          sessionStorage.setItem("loginID", encryptedLoginId);

          setDesignation(res?.user?.designation[0]);
          setManagerId(res?.user?.managerId);
          setManagerName(res?.user?.name);
          setLoggedIn(true);
          navigate("/dashboard");
        } else {
          getdeviceIdStatus();
          show(`${res.msg}`, "success");
        }
        return
      }
      if (res?.status === 'failed') {
        setLoading({ ...loading, loading2: false });
        return
      }
      else {
        show("something wrong ", "error");
        setLoading({ ...loading, loading2: false });
        return
      }
    } catch (error) {
      show("something wrong ", "error");
      setLoading({ ...loading, loading2: false });
    }
  }

  useEffect(() => {
    if (deviceId) {
      getdeviceIdStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);
  const handleClick = async () => {
    setError(false);
    setLoading({ ...loading, loading1: true });
    const payload = {
      loginId: credentials.username,
      password: credentials.password,
      deviceId: deviceId,
      devicePlatform: "web",
      deviceOS: osName
    }
    try {
      const result = await fetchData(`managers/login`, payload);
      if (result?.status === "success") {
        localStorage.setItem("loginID", result?.loginId);
        localStorage.setItem("loginName", result?.loginId);
        setLoading(false);
        getdeviceIdStatus();
        setLoading({ ...loading, loading1: false });
        show(`${result?.msg}`, "success");
      }
      else {
        show(`${result?.msg}`, "error");
        setCredentials({ ...credentials, error: result.msg });
      }
      // setMsg(result.msg);
      setLoading(false);
    } catch (error) {
    }
  };



  // var designations = sessionStorage.getItem("designation");
  const getDesignation = sessionStorage.getItem("designation")
    ? sessionStorage.getItem("designation")
    : "";
  const decryptedDesignationBytes = CryptoJS.AES.decrypt(
    getDesignation,
    "secret key"
  );
  const designationss = decryptedDesignationBytes.toString(CryptoJS.enc.Utf8);
  useEffect(() => {
    if (designationss) {
      setDesignation(designationss.split(",")[0]);
    }
  }, [setDesignation, designationss]);
  const handleSignOut = () => {
    sessionStorage.clear();
    window.location.href = "/";
    // setDropDownOpen(false);
  };

  useEffect(() => {
    const socket = io(process.env.REACT_APP_URL);
    socket.on('getdeviceIdStatus', (getdeviceIdStatus) => {
      // console.log(getdeviceIdStatus);
      if (getdeviceIdStatus?.deviceActive === "disabled") {
        handleSignOut();
      }
      if (getdeviceIdStatus?.deviceActive === "pinsetup") {
        setScreen(getdeviceIdStatus?.deviceActive);
      }
      return
    });
    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  return (
    <React.Fragment>
      <div
        className={styles.home}
        style={mode ? { backgroundColor: "#232529" } : {}}
      >
        <div
          className={styles.frame}
          style={mode ? { backgroundColor: "#2C2E33" } : {}}
        >
          <div className={styles.sub_Frame}>
            <div className={styles.home_Logo}>
              <WalliconLogo />
            </div>
            <HomeImg />
            <h1 className={styles.home_wallicon_heading}>
              Wallicon Admin Suite
            </h1>
          </div>
        </div>
        {
          loading.loading2 ? <div
            className={styles.login_container}
            style={mode ? { backgroundColor: "#2C2E33" } : {}}
          >
            <div
              className={styles.login1}
              style={mode ? { backgroundColor: "#232529" } : {}}
            >
              <div >
                <div className={styles.sub_Login}>
                  {/* <div className={styles.login_Header}>
                   
                    <div>

                      <Skeleton shape="square" />
                    </div>
                  </div> */}
                  <form
                    className={styles.login_Form}
                  >
                    <Spinner size={"100"} />
                  </form>
                  <div style={{ marginTop: "40px" }}>   </div>

                </div>
              </div>
            </div>
          </div> : <>

            {
              screen === "default" &&
              <div
                className={styles.login_container}
                style={mode ? { backgroundColor: "#2C2E33" } : {}}
              >
                <div
                  className={styles.login}
                  style={mode ? { backgroundColor: "#232529" } : {}}
                >
                  <div className={styles.sub_Login}>
                    <div className={styles.login_Header}>
                      <div className={styles.login_Header_Text}>
                        <h2 style={mode ? { color: "#ffffff" } : {}}>Login</h2>
                        <p style={mode ? { color: "#9C9797" } : {}}>
                          Thank you for get back to Wallicon
                        </p>
                      </div>
                      <LogInImg />
                    </div>
                    <form
                      className={styles.login_Form}
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleClick();
                      }}
                    >
                      <InputTag2
                        labelText={"Username"}
                        placeholderType={"text"}
                        placeholderText={"Enter Username"}
                        required={true}
                        value={credentials.username}
                        maxLength={36}
                        onChange={(e) =>
                          setCredentials({ ...credentials, username: e.target.value })
                        }
                        padding={"16px 12px 16px 12px"}
                      />
                      <InputTag2
                        labelText={"Password"}
                        placeholderType={"password"}
                        placeholderText={"Enter Password"}
                        required={true}
                        value={credentials.password}
                        maxLength={30}
                        onChange={(e) =>
                          setCredentials({ ...credentials, password: e.target.value })
                        }
                        padding={"16px 12px 16px 12px"}
                      />
                      <button
                        type="submit"
                        disabled={loading.loading1}
                        className={styles.login_button}
                      >
                        {loading.loading1 ? (
                          <>
                            <span className={styles.spinner}></span>
                            <p>Loading...</p>
                          </>
                        ) : (
                          <>
                            <LogInBtnIcon />
                            <p>Login</p>
                          </>
                        )}
                      </button>
                    </form>

                    <Link to={"#"} style={mode ? { color: "#749AFF" } : {}}>
                      Need Help?
                    </Link>
                    {/* {msg && <div className={styles.err}>{msg}</div>} */}
                    {error && (
                      <div className={styles.err}>
                        Oops! Something went wrong. Please try again later.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
            {
              screen === "new" &&
              <Request
                handleClick={requestForDevice}
                loading={loading.loading5}
                setDeviceName={setdevicename}
              />
            }
            {
              screen === "pending" &&
              <RequestPending handleClick={logoutDevice} loading={loading.loading4} />
            }
            {
              screen === "pinsetup" &&
              <PinSetup
                confirmPin={confirmPin}
                setConfirmPin={setConfirmPin}
                setPin={setPin}
                pin={pin}
                handleClick={pinSetup}
                loading={loading.loading2}
              />
            }
            {
              screen === "adminOtp" &&
              <AdminOtpverify
                confirmPin={confirmOtp}
                setConfirmPin={setConfirmOtp}
                setPin={setLoginOtp}
                pin={loginOtp}
                handleClick={loginWithotp}
                loading={loading.loading2}
              />
            }
            {
              screen === "active" &&
              <Pinlogin
                confirmPin={loginPin}
                setConfirmPin={setLoginpin}
                handleClick={loginWithPin}
                loading={loading.loading2}
              />
            }
            {
              screen === "disabled" &&
              <DesableLogin />
            }
          </>
        }



      </div>
    </React.Fragment>
  );
};

export default Home;
