import React, { useState, useRef } from "react";
import styles from "../../../pages/kyc/Kyc.module.css";
import { useAuth } from "../../context/Auth";

const VerticalSearchDropDown = ({ options, onOptionSelect }) => {
  const [searchText, setSearchText] = useState("");
  const [filteredItems, setFilteredItems] = useState(options);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const dropdownRef = useRef(null);
  const itemRefs = useRef([]);
  const { mode } = useAuth();

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    setFilteredItems(
      options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      )
    );
    setSelectedIndex(-1);
    setIsOpen(true);
  };

  const handleKeyDown = (e) => {
    if (!filteredItems.length) return;

    if (e.key === "ArrowDown") {
      setSelectedIndex((prevIndex) => {
        const nextIndex =
          prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex;
        scrollToItem(nextIndex);
        return nextIndex;
      });
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prevIndex) => {
        const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
        scrollToItem(nextIndex);
        return nextIndex;
      });
    } else if (e.key === "Enter") {
      if (selectedIndex >= 0) {
        const selectedItem = filteredItems[selectedIndex];
        setSearchText(selectedItem.label);
        setIsOpen(false);
        onOptionSelect?.(selectedItem); 
      } else {
        onOptionSelect?.({ label: searchText, value: null }); 
        setIsOpen(false);
      }
    }
  };

  const scrollToItem = (index) => {
    if (itemRefs.current[index]) {
      itemRefs.current[index].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  const handleItemClick = (item) => {
    setSearchText(item.label);
    setIsOpen(false);
    onOptionSelect?.(item); 
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (
        searchText &&
        !filteredItems.find((item) => item.label === searchText)
      ) {
        onOptionSelect?.({ label: searchText, value: null }); 
      }
      setIsOpen(false);
    }, 100);
  };

  return (
    <div className={styles.dropdownContainer} onBlur={handleBlur}>
      <div className={styles.searchInput}>
        <input
          type="text"
          placeholder="Search..."
          value={searchText}
          onChange={handleSearchChange}
          onKeyDown={handleKeyDown}
          style={{
            backgroundColor: mode ? "#1B1D21" : "#fff",
            color: mode ? "#fff" : "#000",
            border: mode ? "none" : "1px solid #D9D9D9",
          }}
        />
      </div>
      {isOpen && (
        <div
          className={styles.dropdownList}
          ref={dropdownRef}
          style={{
            border: mode ? "none" : "1px solid #D9D9D9",
            backgroundColor: mode ? "#2C2E33" : "#fff",
            color: mode ? "#fff" : "#000",
          }}
        >
          {filteredItems.length ? (
            filteredItems.map((item, index) => (
              <div
                key={item.value}
                ref={(el) => (itemRefs.current[index] = el)}
                className={`${styles.dropdownItem} ${
                  index === selectedIndex ? styles.selectedItem : ""
                }`}
                style={{
                  backgroundColor:
                    index === selectedIndex
                      ? mode
                        ? "#333333"
                        : "#E4E7EB"
                      : "transparent",
                  color: mode ? "#fff" : "#000",
                  borderBottom: mode ? "1px solid #000" : "1px solid #ccc",
                }}
                onMouseDown={() => handleItemClick(item)}
              >
                {item.label}
              </div>
            ))
          ) : (
            <div className={styles.noItems}>
              <p style={{ color: mode === "dark" ? "#ccc" : "#555" }}>
                No items found
              </p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VerticalSearchDropDown;

// import React, { useState, useRef } from "react";
// import styles from "../../../pages/kyc/Kyc.module.css";
// import { useAuth } from "../../context/Auth";

// const VerticalSearchDropDown = ({ options, onOptionSelect }) => {
//   const [searchText, setSearchText] = useState("");
//   const [filteredItems, setFilteredItems] = useState(options);
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedIndex, setSelectedIndex] = useState(-1);
//   const dropdownRef = useRef(null);
//   const itemRefs = useRef([]);
//   const { mode } = useAuth();

//   const handleSearchChange = (e) => {
//     const value = e.target.value.toLowerCase();
//     setSearchText(value);
//     setFilteredItems(
//       options.filter((option) => option.label.toLowerCase().includes(value))
//     );
//     setSelectedIndex(-1);
//     setIsOpen(true);
//   };

//   const handleKeyDown = (e) => {
//     if (!filteredItems.length) return;

//     if (e.key === "ArrowDown") {
//       setSelectedIndex((prevIndex) => {
//         const nextIndex =
//           prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex;
//         scrollToItem(nextIndex);
//         return nextIndex;
//       });
//     } else if (e.key === "ArrowUp") {
//       setSelectedIndex((prevIndex) => {
//         const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
//         scrollToItem(nextIndex);
//         return nextIndex;
//       });
//     } else if (e.key === "Enter" && selectedIndex >= 0) {
//       const selectedItem = filteredItems[selectedIndex];
//       setSearchText(selectedItem.label);
//       setIsOpen(false);
//       onOptionSelect?.(selectedItem); // Notify parent of selection
//     }
//   };

//   const scrollToItem = (index) => {
//     if (itemRefs.current[index]) {
//       itemRefs.current[index].scrollIntoView({
//         behavior: "smooth",
//         block: "nearest",
//       });
//     }
//   };

//   const handleItemClick = (item) => {
//     setSearchText(item.label);
//     setIsOpen(false);
//     onOptionSelect?.(item); // Notify parent of selection
//   };

//   const handleFocus = () => {
//     setIsOpen(true);
//   };

//   const handleBlur = () => {
//     setTimeout(() => setIsOpen(false), 100);
//   };

//   return (
//     <div className={styles.dropdownContainer} onBlur={handleBlur}>
//       <div className={styles.searchInput}>
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchText}
//           onChange={handleSearchChange}
//           onFocus={handleFocus}
//           onKeyDown={handleKeyDown}
//           style={{
//             backgroundColor: mode ? "#1B1D21" : "#fff",
//             color: mode ? "#fff" : "#000",
//             border: mode ? "none" : "1px solid #D9D9D9",
//           }}
//         />
//       </div>
//       {isOpen && (
//         <div
//           className={styles.dropdownList}
//           ref={dropdownRef}
//           style={{
//             border: mode ? "none" : "1px solid #D9D9D9",
//             backgroundColor: mode ? "#2C2E33" : "#fff",
//             color: mode ? "#fff" : "#000",
//           }}
//         >
//           {filteredItems.length ? (
//             filteredItems.map((item, index) => (
//               <div
//                 key={item.value}
//                 ref={(el) => (itemRefs.current[index] = el)}
//                 className={`${styles.dropdownItem} ${
//                   index === selectedIndex ? styles.selectedItem : ""
//                 }`}
//                 style={{
//                   backgroundColor:
//                     index === selectedIndex
//                       ? mode
//                         ? "#333333"
//                         : "#E4E7EB"
//                       : "transparent",
//                   color: mode ? "#fff" : "#000",
//                   borderBottom: mode ? "1px solid #000" : "1px solid #ccc",
//                 }}
//                 onMouseDown={() => handleItemClick(item)}
//               >
//                 {item.label}
//               </div>
//             ))
//           ) : (
//             <div className={styles.noItems}>
//               <p style={{ color: mode === "dark" ? "#ccc" : "#555" }}>
//                 No items found
//               </p>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VerticalSearchDropDown;

// import React, { useState, useRef } from "react";
// import styles from "../../../pages/kyc/Kyc.module.css";
// import { useAuth } from "../../context/Auth";

// const VerticalSearchDropDown = ({ options }) => {
//   const items = [
//     "Apple",
//     "Banana",
//     "Cherry",
//     "Date",
//     "Elderberry",
//     "Fig",
//     "Grape",
//   ];
//   const [searchText, setSearchText] = useState("");
//   const [filteredItems, setFilteredItems] = useState(items);
//   const [isOpen, setIsOpen] = useState(false);
//   const [selectedIndex, setSelectedIndex] = useState(-1);
//   const dropdownRef = useRef(null);
//   const itemRefs = useRef([]);
//   const { mode } = useAuth();
//   console.log("options ", options);
//   const handleSearchChange = (e) => {
//     const value = e.target.value.toLowerCase();
//     setSearchText(value);
//     setFilteredItems(
//       items.filter((item) => item.toLowerCase().includes(value))
//     );
//     setSelectedIndex(-1);
//     setIsOpen(true);
//   };

//   const handleKeyDown = (e) => {
//     if (!filteredItems.length) return;

//     if (e.key === "ArrowDown") {
//       setSelectedIndex((prevIndex) => {
//         const nextIndex =
//           prevIndex < filteredItems.length - 1 ? prevIndex + 1 : prevIndex;
//         scrollToItem(nextIndex);
//         return nextIndex;
//       });
//     } else if (e.key === "ArrowUp") {
//       setSelectedIndex((prevIndex) => {
//         const nextIndex = prevIndex > 0 ? prevIndex - 1 : prevIndex;
//         scrollToItem(nextIndex);
//         return nextIndex;
//       });
//     } else if (e.key === "Enter" && selectedIndex >= 0) {
//       setSearchText(filteredItems[selectedIndex]);
//       setIsOpen(false);
//     }
//   };

//   const scrollToItem = (index) => {
//     if (itemRefs.current[index]) {
//       itemRefs.current[index].scrollIntoView({
//         behavior: "smooth",
//         block: "nearest",
//       });
//     }
//   };

//   const handleItemClick = (item) => {
//     setSearchText(item);
//     setIsOpen(false);
//   };

//   const handleFocus = () => {
//     setIsOpen(true);
//   };

//   const handleBlur = () => {
//     setTimeout(() => setIsOpen(false), 100);
//   };

//   return (
//     <div className={styles.dropdownContainer} onBlur={handleBlur}>
//       <div className={styles.searchInput}>
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchText}
//           onChange={handleSearchChange}
//           onFocus={handleFocus}
//           onKeyDown={handleKeyDown}
//           style={{
//             backgroundColor: mode ? "#1B1D21" : "#fff",
//             color: mode ? "#fff" : "#000",
//             border: mode ? "none" : "1px solid #D9D9D9",
//           }}
//         />
//       </div>
//       {isOpen && (
//         <div
//           className={styles.dropdownList}
//           ref={dropdownRef}
//           style={{
//             border: mode ? "none" : "1px solid #D9D9D9",
//             backgroundColor: mode ? "#2C2E33" : "#fff",
//             color: mode ? "#fff" : "#000",
//           }}
//         >
//           {filteredItems.length ? (
//             filteredItems.map((item, index) => (
//               <div
//                 key={index}
//                 ref={(el) => (itemRefs.current[index] = el)}
//                 className={`${styles.dropdownItem} ${
//                   index === selectedIndex ? styles.selectedItem : ""
//                 }`}
//                 style={{
//                   backgroundColor:
//                     index === selectedIndex
//                       ? mode
//                         ? "#333333"
//                         : "#E4E7EB"
//                       : "transparent",
//                   color: mode ? "#fff" : "#000",
//                   borderBottom: mode ? "1px solid #000" : "1px solid #ccc",
//                 }}
//                 onMouseDown={() => handleItemClick(item)}
//               >
//                 {item}
//               </div>
//             ))
//           ) : (
//             <div className={styles.noItems}>
//               <p style={{ color: mode === "dark" ? "#ccc" : "#555" }}>
//                 No items found
//               </p>
//             </div>
//           )}
//         </div>
//       )}
//     </div>
//   );
// };

// export default VerticalSearchDropDown;
