import React, { useEffect, useRef, useState } from "react";
import styles from "./Manager.module.css";
import { ManagerDevices3Dots } from "../../icons/Icon";
import { useAuth } from "../../context/Auth";
import fetchData from "../../../APIsControll/apiControll";
import Skeleton from "../Skeleton/Skeleton";
import { truncateText } from "../../../Utils/TextUtils";
import { useToast } from "../Toaster/Toaster";
import NoDataFound from "../NoDataFound/NoDataFound";
import { TemplateNotFound } from "../../icons/Icon2";

const SingleManagerDevicesTable = ({ data }) => {
  const { mode, managerId } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const dropdownRefs = useRef([]);
  const { show } = useToast()

  const [loading, setloading] = useState({
    loading1: false,
    loading2: false,
    loading3: false,
  });


  const toggleDropdown = (index) => {
    setDropdownVisible((prev) => (prev === index ? null : index));
  };

  const handleOptionClick = async (option, data) => {
    if (option === "Active") {
      try {
        const paylod = {
          managerId: managerId,
          deviceIds: [data?.deviceId]
        }
        setloading({ ...loading, loading2: true });
        const res = await fetchData(`managers/accessDeviceRequest`, paylod);
        if (res?.status === "success") {
          show(`${res?.msg}`, "success");
          setloading({ ...loading, loading2: false });
          return;
        }
        if (res?.status === "failed") {
          show(`${res?.msg}`, "error");
          setloading({ ...loading, loading2: false });
          return;
        } else {
          show("something went wrong", "error");
        }

      } catch (error) {
        show("something went wrong", "error");
      }
      return
    }
    if (option === "Disabled") {
      try {
        const paylod = {
          managerId: managerId,
          deviceIds: [data?.deviceId]
        }
        setloading({ ...loading, loading3: true });
        const res = await fetchData(`managers/disabledDeviceRequest`, paylod);
        if (res?.status === "success") {
          show(`${res?.msg}`, "success");
          setloading({ ...loading, loading3: false });
          return;
        }
        if (res?.status === "failed") {
          show(`${res?.msg}`, "success");
          setloading({ ...loading, loading3: false });
          return;
        } else {
          show("something went wrong", "error");
        }

      } catch (error) {
        show("something went wrong", "error");
      }
      return
    }

    setDropdownVisible(null);
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownVisible !== null &&
        dropdownRefs.current[dropdownVisible] &&
        !dropdownRefs.current[dropdownVisible].contains(event.target)
      ) {
        setDropdownVisible(null);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownVisible]);

  const getCellStyle = (mode, baseColor = "#1B1D21") => {
    return mode ? { backgroundColor: baseColor, color: "#E4E7EB" } : {};
  };

  const getHeaderStyle = (mode) => {
    return mode ? { backgroundColor: "#232529", color: "#E4E7EB" } : {};
  };
  return (
    <div
      className={styles.table_container}
      style={mode ? { backgroundColor: "#3D3D3D" } : {}}
    >
      <table className={styles.custom_table}>
        <thead>
          <tr>
            <th style={getHeaderStyle(mode)}>Device ID</th>
            <th style={getHeaderStyle(mode)}>Name </th>
            <th style={getHeaderStyle(mode)}>Platform</th>
            <th style={getHeaderStyle(mode)}>OS</th>
            <th style={getHeaderStyle(mode)}>Status</th>
            <th style={getHeaderStyle(mode)}>Action</th>
          </tr>
        </thead>
        <tbody>
          {loading.loading1 ? (
            <tr style={{ border: "1px solid" }}>
              <td style={getCellStyle(mode)}> <Skeleton lines={3} thickness={4} /> </td>
              <td style={getCellStyle(mode)}> <Skeleton lines={3} thickness={4} /> </td>
              <td style={getCellStyle(mode)}> <Skeleton lines={3} thickness={4} /> </td>
              <td style={getCellStyle(mode)}> <Skeleton lines={3} thickness={4} /> </td>
              <td style={getCellStyle(mode)}> <Skeleton lines={3} thickness={4} /> </td>
              <td style={getCellStyle(mode)}> <Skeleton lines={3} thickness={4} /> </td>
            </tr>

          ) : (
            <>
              {data.length === 0 ? (
                 <tr style={{ height: "100%", border: "1px solid", width: "100%" }}>
                 <td style={getCellStyle(mode)}> </td>
                 <td style={getCellStyle(mode)}> </td>
                 <td style={getCellStyle(mode)}> </td>
                 <td style={getCellStyle(mode)} >
                   <NoDataFound icon={<TemplateNotFound />} />
                 </td>
                 <td style={getCellStyle(mode)}> </td>
                 <td style={getCellStyle(mode)}> </td>
                 <td style={getCellStyle(mode)}> </td>

               </tr>
              ) : (
                data.map((e, index) => (
                  <tr key={index}>
                    <td style={getCellStyle(mode)}>{truncateText(e?.deviceId, 16)}</td>
                    <td style={getCellStyle(mode)}>{truncateText(e?.deviceName, 16)}</td>
                    <td style={getCellStyle(mode)}>{e?.devicePlatform}</td>
                    <td style={getCellStyle(mode)}>{e?.deviceOS}</td>
                    <td
                      style={{
                        color: "#FB9600",
                        backgroundColor: mode && "#1B1D21",
                      }}
                    >
                      {e?.deviceActive}
                    </td>
                    <td style={{ ...getCellStyle(mode) }}>
                      <div
                        className={styles.threeDot}
                        onClick={(e) => { toggleDropdown(index); e.stopPropagation() }}

                      >
                        <div>
                          <ManagerDevices3Dots color={mode ? "#ffffff" : "#1C274C"} />
                        </div>
                        {dropdownVisible === index && (
                          <div
                            className={styles.optionIs}
                            style={{
                              backgroundColor: mode ? "#232529" : "#ffff",
                              color: mode ? "#fff" : "#000",
                              border: mode ? "none" : "1px solid #E4E7EB",
                            }}
                            ref={(el) => (dropdownRefs.current[index] = el)}
                          > {
                             ( e?.deviceActive === "pinsetup" || e?.deviceActive === "pending" )&&
                              <div
                                className={`${styles.option_item} ${mode ? styles.darkHover : styles.lightHover
                                  }`}
                                onClick={() => handleOptionClick("Active", e)}
                              >
                                <span>Active</span>
                              </div>
                            }
                            <div
                              className={`${styles.option_item} ${mode ? styles.darkHover : styles.lightHover
                                }`}
                              onClick={() => handleOptionClick("Disabled", e)}
                            >
                              <span>Disabled</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default SingleManagerDevicesTable

