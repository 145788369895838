// import React, { useEffect, useState } from 'react'
// import styles from './Warehouse.module.css'
// import { DeleteIcon, Edit, EyeIcon } from '../../components/icons/Icon'
// import Modal from '../../components/Modal'
// import { GreenButton, RedOutButton } from '../../components/Buttons'

// const Warehouse = () => {
//   const [show, setShow] = useState(false);
//   const [deleteModal, setDeletModal] = useState(false);
//   const [viewEdit, setViewEdit] = useState(0);
//   const [warehouseData, setWarehouseData] = useState([]);
//   const [dataWare, setDataWare] = useState({
//     warehousename: '',
//     address: '',
//     pincode: '',
//     tempmsg: '',
//     status: '',
//   })

//   const [fields, setFields] = useState([
//     { workday: "Monday", workstatus: "" },
//     { workday: "Tuesday", workstatus: "" },
//     { workday: "Wednesday", workstatus: "" },
//     { workday: "Thursday", workstatus: "" },
//     { workday: "Friday", workstatus: "" },
//     { workday: "Saturday", workstatus: "" },
//     { workday: "Sunday", workstatus: "" }
//   ]);

//   function handleChange(i, event) {
//     const values = [...fields];
//     values[i]["workstatus"] = event.target.value;
//     setFields(values);
//   }
//   const [tempClosed, setTempClosed] = useState([]);

//   const postWarehouse = () => {
//     if (dataWare.warehousename === '' || dataWare.address === '' || dataWare.pincode === '' || dataWare.status === '' || fields.workstatus === null) {
//       alert('feilds cant be empty')
//     }
//     else {
//       fetch(process.env.REACT_APP_URL + "warehouses/addWarehouse", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
//         },
//         body: JSON.stringify({
//           'warehousename': dataWare.warehousename,
//           'address': dataWare.address,
//           'pincode': dataWare.pincode,
//           'tempclosed': tempClosed,
//           'tempmsg': dataWare.tempmsg,
//           'workingdays': fields,
//           'status': dataWare.status
//         }),
//       })
//         .then(response => response.json())
//         .then(result => {
//           if (result.status === 'success') {
//             setDataWare('');
//             setTempClosed('');
//             getAllWarehouse()
//             setShow(false)
//           }
//         })
//         .catch(error => console.log(error))
//     }
//   };

//   const getAllWarehouse = async () => {
//     await fetch(process.env.REACT_APP_URL + "warehouses/getAllWarehouse", {
//       method: "POST",
//       headers: {
//         'Content-Type': 'application/json',
//         "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
//       }
//     })
//       .then(response => response.json())
//       .then(result => setWarehouseData(result.data))
//   }

//   useEffect(() => {
//     getAllWarehouse()
//   }, [])

//   const getWareHouse = (id) => {
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       "warehouseId": id
//     });

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(process.env.REACT_APP_URL + "warehouses/getWarehouse", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         if (result.status === 'success') {
//           setDataWare(result.data)
//           setTempClosed(result.data.tempclosed)
//           setFields(result.data.workingdays)
//           setShow(true)
//         }
//       })
//       .catch(error => console.log('error', error));
//   }

//   const updateWareHouse = (id) => {
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       "warehouseId": id,
//       'warehousename': dataWare.warehousename,
//       'address': dataWare.address,
//       'pincode': dataWare.pincode,
//       'tempclosed': tempClosed,
//       'tempmsg': dataWare.tempmsg,
//       'workingdays': fields,
//       'status': dataWare.status
//     });

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(process.env.REACT_APP_URL + "warehouses/updateWarehouse", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         if (result.status === 'success') {
//           getAllWarehouse()
//           setShow(false)
//           alert('updated successfully')
//           setDataWare({})
//           setTempClosed([])
//           setFields([
//             { workday: "Monday", workstatus: "" },
//             { workday: "Tuesday", workstatus: "" },
//             { workday: "Wednesday", workstatus: "" },
//             { workday: "Thursday", workstatus: "" },
//             { workday: "Friday", workstatus: "" },
//             { workday: "Saturday", workstatus: "" },
//             { workday: "Sunday", workstatus: "" }
//           ])
//           setViewEdit(0)
//         }
//       })
//       .catch(error => console.log('error', error));
//   }

//   const deleteWareHouse = (id) => {
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       "warehouseId": id
//     });

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(process.env.REACT_APP_URL + "warehouses/deleteWarehouse", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         if (result.status === 'success') {
//           getAllWarehouse()
//           alert('deleted successfully')
//           setShow(false);
//           setDeletModal(false)
//         }
//       })
//       .catch(error => console.log('error', error));
//   }

//   useEffect(() => {
//     if (show) {
//       document.body.style.overflow = 'hidden'
//     } else {
//       document.body.style.overflow = 'auto'
//     }
//   }, [show])

//   // console.log('show', show);

//   return (
//     <React.Fragment>
//       <div className={styles.main_div}>

//         <div className='d-flex justify-content-between w-100'>
//           <h1 className={styles.heading}>Warehouse</h1>
//           <div className={styles.addwarehouse_btn} onClick={() => {
//             setShow(true);
//             setViewEdit(2);
//             setDataWare({});
//             setTempClosed([]);
//             setFields([
//               { workday: "Monday", workstatus: "" },
//               { workday: "Tuesday", workstatus: "" },
//               { workday: "Wednesday", workstatus: "" },
//               { workday: "Thursday", workstatus: "" },
//               { workday: "Friday", workstatus: "" },
//               { workday: "Saturday", workstatus: "" },
//               { workday: "Sunday", workstatus: "" }
//             ]);
//           }} >+ ADD WAREHOUSE</div>
//         </div>

//         <div>
//           {warehouseData && warehouseData.map((item, index) =>
//             <div key={index} className={styles.Warehouse_card}
//               onClick={() => { setViewEdit(0); getWareHouse(item._id) }}
//             >
//               <div className='ms-4'>
//                 <span className='d-flex justify-content-start align-items-center'>
//                   <input disabled={item.status === 'disable' ? true : false} type="radio" name="" className={styles.Warehouse_card_checkbox} defaultChecked={item.status === "active" ? true : false} />&nbsp;&nbsp;Active
//                 </span>
//                 <h2 className={styles.warehouse_name}>{item.warehousename}</h2>
//               </div>
//               <div className='d-flex flex-row'>

//                 <div className={styles.ViewEdit_div} onClick={() => { setViewEdit(0); getWareHouse(item._id) }} >
//                   <EyeIcon color='#fff' />
//                   <span><u>View</u></span>
//                 </div>
//                 <div className={styles.ViewEdit_div} onClick={(e) => { e.stopPropagation(); setViewEdit(1); getWareHouse(item._id) }}>
//                   <Edit color='#fff' />
//                   <span><u>Edit</u></span>
//                 </div>
//                 {/* <div className={styles.ViewEdit_div} onClick={(e) => { e.stopPropagation(); setDeletModal(true) }}>
//                   <DeleteIcon />
//                   <span><u>Delete</u></span>
//                 </div> */}
//               </div>
//             </div>
//           )}
//         </div>

//       </div>

//       <Modal
//         show={show}
//         close={setShow}
//         heading=''
//         closeBtn=''
//         content={
//           <form>
//             <div className='d-flex justify-content-between align-items-center'>
//               <h2>{viewEdit === 1 ? 'Update Warehouse': viewEdit === 0? dataWare.warehousename:'Add Warehouse'}</h2>
//               {dataWare._id && viewEdit === 1 ?
//                 <h6 className="text-end mouse text-danger d-flex flex-column justify-content-center align-items-center border p-1 rounded" onClick={() => setDeletModal(true)}><DeleteIcon />
//                   <span className={styles.delete_btn}>Delete</span></h6> : null}
//             </div>
//             <div className={styles.modal_div}>
//               <div className='w-50'>
//                 <span>Name</span>
//                 <div>
//                   <input type='text' value={dataWare.warehousename}
//                     onChange={(e) => setDataWare({ ...dataWare, warehousename: e.target.value })} />
//                 </div>
//                 <span>Street Address</span>
//                 <div>
//                   <textarea rows="2" type='text' value={dataWare.address}
//                     onChange={(e) => setDataWare({ ...dataWare, address: e.target.value })} />
//                 </div>
//                 <span>Pin Code</span>
//                 <div>
//                   <input type='number' value={dataWare.pincode}
//                     onChange={(e) => setDataWare({ ...dataWare, pincode: e.target.value })} />
//                 </div>
//                 <span>Temporary Closed</span>
//                 <div className='d-flex justify-content-center align-items-center'>
//                   <input
//                     type='date'
//                     className={'text-light'}
//                     name="fromdate"
//                     value={tempClosed.fromdate}
//                     onChange={(e) => setTempClosed({ ...tempClosed, fromdate: e.target.value })} />&nbsp;&nbsp;To&nbsp;&nbsp;
//                   <input
//                     type='date'
//                     name="fromdate"
//                     className='text-light'
//                     value={tempClosed.tilldate}
//                     onChange={(e) => setTempClosed({ ...tempClosed, tilldate: e.target.value })} />
//                 </div>
//                 <span>Temporary Message</span>
//                 <div>
//                   <textarea rows="2" type='text' value={dataWare.tempmsg}
//                     onChange={(e) => setDataWare({ ...dataWare, tempmsg: e.target.value })} />
//                 </div>
//               </div>
//               <div className='w-50 ms-4'>
//                 <div className='mb-2'>Work Days :</div>
//                 {fields && fields.map((field, idx) =>
//                   <div key={`${field}-${idx}`} className='d-flex justify-content-between'>
//                     <div className=''>{field.workday}</div>
//                     <div className={styles.status_radio}>
//                       <div className={styles.workDays}>
//                         <input
//                           className={styles.days_radio}
//                           type='radio'
//                           label="Active"
//                           name={idx}
//                           value="active"
//                           onChange={(e) => handleChange(idx, e)}
//                           checked={field.workstatus === "active" ? true : false}
//                         />
//                         Active
//                       </div>
//                       <div className={styles.workDays}>
//                         <input
//                           className={styles.days_radio}
//                           type='radio'
//                           name={idx}
//                           label="Disable"
//                           value="disable"
//                           onChange={(e) => handleChange(idx, e)}
//                           checked={field.workstatus === "disable" ? true : false}
//                         />
//                         Disable
//                       </div>
//                     </div>
//                   </div>
//                 )}

//                 <div className='d-flex justify-content-between mt-2'>
//                   <div className='fw-600 text-danger'>Status</div>
//                   <div className={styles.status_radio}>
//                     <div className={styles.workDays}>
//                       <input
//                         className={styles.days_radio}
//                         type="radio"
//                         label="Active"
//                         name="formHorizontalRadios"
//                         value="active"
//                         onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
//                         checked={dataWare.status === "active" ? true : false}
//                       />
//                       Active
//                     </div>
//                     <div className={styles.workDays}>
//                       <input
//                         className={styles.days_radio}
//                         type="radio"
//                         label="Disable"
//                         name="formHorizontalRadios"
//                         value="disable"
//                         onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
//                         checked={dataWare.status === "disable" ? true : false}
//                       />
//                       Disable
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className={viewEdit === 1 ? 'd-flex justify-content-between w-75 mx-auto mt-4' : 'd-flex justify-content-center w-75 mx-auto mt-4'}>
//               <RedOutButton title="CANCEL" handleSubmit={() => {
//                 setShow(false);
//                 setDataWare({});
//                 setTempClosed([]);
//                 setFields([
//                   { workday: "Monday", workstatus: "" },
//                   { workday: "Tuesday", workstatus: "" },
//                   { workday: "Wednesday", workstatus: "" },
//                   { workday: "Thursday", workstatus: "" },
//                   { workday: "Friday", workstatus: "" },
//                   { workday: "Saturday", workstatus: "" },
//                   { workday: "Sunday", workstatus: "" }
//                 ]);
//                 setViewEdit(1);
//               }} />
//               {viewEdit === 0 ? "" :
//                 <GreenButton btnType='button' title={viewEdit === 1 ? "UPDATE" : "CREATE"} css="ms-5  " handleSubmit={() => viewEdit === 1 ? updateWareHouse(dataWare._id) : postWarehouse()} />
//               }
//             </div>
//           </form>
//         }
//       />

//       <Modal
//         show={deleteModal}
//         close={setDeletModal}
//         heading=''
//         closeBtn=''
//         content={
//           <form>
//             <h2>Delete</h2>
//             <div className={styles.modal_div}>
//               <h3 className='my-2'>Are you sure you want to delete this warehouse ?</h3>
//             </div>
//             <div className='d-flex justify-content-between w-75 mx-auto mt-4'>
//               <RedOutButton title="CANCEL" handleSubmit={() => { setDeletModal(false); }} />
//               <GreenButton btnType='button' title="DELETE" css="ms-5 bg-transparent mb-0 text-success" handleSubmit={() => deleteWareHouse(dataWare._id)} />
//             </div>
//           </form>
//         }
//       />
//     </React.Fragment>
//   )
// }
// export default Warehouse






// import React, { useEffect, useState } from 'react'
// import styles from './Warehouse.module.css'
// import { DeleteIcon, Edit, EyeIcon } from '../../components/icons/Icon'
// import Modal from '../../components/Modal'
// import { GreenButton, RedOutButton } from '../../components/Buttons'
// import Button from '../../components/updatedComponents/Dealers/Button'
// import { CreateTem, TemplateDone } from '../../components/icons/Icon2'
// import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper'
// import { mode } from 'crypto-js'
// import WarehouseInfoCard from '../../components/updatedComponents/Dealers/Warehousecardinfo'
// import { Warehouseedit, Warehouseview } from '../../components/icons/Icon4'

// const Warehouse = () => {
//   const [show, setShow] = useState(false);
//   const [deleteModal, setDeletModal] = useState(false);
//   const [viewEdit, setViewEdit] = useState(0);
//   const [warehouseData, setWarehouseData] = useState([]);
//   const [dataWare, setDataWare] = useState({
//     warehousename: '',
//     address: '',
//     pincode: '',
//     tempmsg: '',
//     status: '',
//   })

//   const [fields, setFields] = useState([
//     { workday: "Monday", workstatus: "" },
//     { workday: "Tuesday", workstatus: "" },
//     { workday: "Wednesday", workstatus: "" },
//     { workday: "Thursday", workstatus: "" },
//     { workday: "Friday", workstatus: "" },
//     { workday: "Saturday", workstatus: "" },
//     { workday: "Sunday", workstatus: "" }
//   ]);

//   function handleChange(i, event) {
//     const values = [...fields];
//     values[i]["workstatus"] = event.target.value;
//     setFields(values);
//   }
//   const [tempClosed, setTempClosed] = useState([]);

//   const postWarehouse = () => {
//     if (dataWare.warehousename === '' || dataWare.address === '' || dataWare.pincode === '' || dataWare.status === '' || fields.workstatus === null) {
//       alert('feilds cant be empty')
//     }
//     else {
//       fetch(process.env.REACT_APP_URL + "warehouses/addWarehouse", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
//         },
//         body: JSON.stringify({
//           'warehousename': dataWare.warehousename,
//           'address': dataWare.address,
//           'pincode': dataWare.pincode,
//           'tempclosed': tempClosed,
//           'tempmsg': dataWare.tempmsg,
//           'workingdays': fields,
//           'status': dataWare.status
//         }),
//       })
//         .then(response => response.json())
//         .then(result => {
//           if (result.status === 'success') {
//             setDataWare('');
//             setTempClosed('');
//             getAllWarehouse()
//             setShow(false)
//           }
//         })
//         .catch(error => console.log(error))
//     }
//   };

//   const getAllWarehouse = async () => {
//     await fetch(process.env.REACT_APP_URL + "warehouses/getAllWarehouse", {
//       method: "POST",
//       headers: {
//         'Content-Type': 'application/json',
//         "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
//       }
//     })
//       .then(response => response.json())
//       .then(result => setWarehouseData(result.data))
//   }

//   useEffect(() => {
//     getAllWarehouse()
//   }, [])

//   const getWareHouse = (id) => {
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       "warehouseId": id
//     });

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(process.env.REACT_APP_URL + "warehouses/getWarehouse", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         if (result.status === 'success') {
//           setDataWare(result.data)
//           setTempClosed(result.data.tempclosed)
//           setFields(result.data.workingdays)
//           setShow(true)
//         }
//       })
//       .catch(error => console.log('error', error));
//   }

//   const updateWareHouse = (id) => {
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       "warehouseId": id,
//       'warehousename': dataWare.warehousename,
//       'address': dataWare.address,
//       'pincode': dataWare.pincode,
//       'tempclosed': tempClosed,
//       'tempmsg': dataWare.tempmsg,
//       'workingdays': fields,
//       'status': dataWare.status
//     });

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(process.env.REACT_APP_URL + "warehouses/updateWarehouse", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         if (result.status === 'success') {
//           getAllWarehouse()
//           setShow(false)
//           alert('updated successfully')
//           setDataWare({})
//           setTempClosed([])
//           setFields([
//             { workday: "Monday", workstatus: "" },
//             { workday: "Tuesday", workstatus: "" },
//             { workday: "Wednesday", workstatus: "" },
//             { workday: "Thursday", workstatus: "" },
//             { workday: "Friday", workstatus: "" },
//             { workday: "Saturday", workstatus: "" },
//             { workday: "Sunday", workstatus: "" }
//           ])
//           setViewEdit(0)
//         }
//       })
//       .catch(error => console.log('error', error));
//   }

//   const deleteWareHouse = (id) => {
//     var myHeaders = new Headers();
//     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//     myHeaders.append("Content-Type", "application/json");

//     var raw = JSON.stringify({
//       "warehouseId": id
//     });

//     var requestOptions = {
//       method: 'POST',
//       headers: myHeaders,
//       body: raw,
//       redirect: 'follow'
//     };

//     fetch(process.env.REACT_APP_URL + "warehouses/deleteWarehouse", requestOptions)
//       .then(response => response.json())
//       .then(result => {
//         if (result.status === 'success') {
//           getAllWarehouse()
//           alert('deleted successfully')
//           setShow(false);
//           setDeletModal(false)
//         }
//       })
//       .catch(error => console.log('error', error));
//   }

//   useEffect(() => {
//     if (show) {
//       document.body.style.overflow = 'hidden'
//     } else {
//       document.body.style.overflow = 'auto'
//     }
//   }, [show])

//   // console.log('show', show);

//   return (
//     <React.Fragment>
//       <div className={styles.main_div}>

//         <div className='d-flex justify-content-between w-100'>
//           {/* <h1 className={styles.heading}>Warehouse</h1> */}
//           {/* <div className={styles.addwarehouse_btn}
//            onClick={() => {
//             setShow(true);
//             setViewEdit(2);
//             setDataWare({});
//             setTempClosed([]);
//             setFields([
//               { workday: "Monday", workstatus: "" },
//               { workday: "Tuesday", workstatus: "" },
//               { workday: "Wednesday", workstatus: "" },
//               { workday: "Thursday", workstatus: "" },
//               { workday: "Friday", workstatus: "" },
//               { workday: "Saturday", workstatus: "" },
//               { workday: "Sunday", workstatus: "" }
//             ]);
//           }} >+ ADD WAREHOUSE</div> */}
//         {/* <div style={{width:"100px,"}}>  <Button
//           buttonIcon={<CreateTem/>}
//           buttonBgColor={"blue"}
//           buttonText={"Add Warehouse"}
//           buttonTextColor={"white"}
//           /></div> */}
//           <div style={{ width: "100px" }}>
//   <Button
//     buttonClick={() => {
//       setShow(true);
//       setViewEdit(2);
//       setDataWare({});
//       setTempClosed([]);
//       setFields([
//         { workday: "Monday", workstatus: "" },
//         { workday: "Tuesday", workstatus: "" },
//         { workday: "Wednesday", workstatus: "" },
//         { workday: "Thursday", workstatus: "" },
//         { workday: "Friday", workstatus: "" },
//         { workday: "Saturday", workstatus: "" },
//         { workday: "Sunday", workstatus: "" }
//       ]);
//     }}
//     buttonIcon={<CreateTem />}
//     buttonBgColor={"blue"}
//     buttonText={"Add Warehouse"}
//     buttonTextColor={"white"}
//   />
// </div>


//         </div>

//         <div >
//           {/* {warehouseData && warehouseData.map((item, index) =>
//             <div style={{border:"2px solid green", backgroundColor:"white", }}  key={index}
//             >
//               <WarehouseInfoCard
//               warehouseName={item.warehousename}
//               warehouseStatus={item.status}
//               warehousestreetaddress={"ygikjnlk"}

//               />
//               <div className='ms-4'>

//               </div>
//               <div className='d-flex flex-row'>

//                 <div onClick={() => { setViewEdit(0); getWareHouse(item._id) }} >
//                   <Warehouseview color='#fff' />
//                 </div>
//                 <div onClick={(e) => { e.stopPropagation(); setViewEdit(1); getWareHouse(item._id) }}>
//                   <Warehouseedit color='#fff' />
//                 </div>

//               </div>
//             </div>
//           )} */}
//    <div 
//   style={{ 
//     display: "flex", 
//     flexWrap: "wrap", 
//     justifyContent: "flex-start",  // Align the cards to the start without spacing
//     gap: "10px",  // No gap between cards
//     // backgroundColor:"white"
//   }}
// >
//   {warehouseData && warehouseData.map((item, index) => (
//   <div
//   style={{
//     // border: warehouseData.length === 7 ? "2px solid gray" : "none", // Border only if 7 cards
//     backgroundColor: "white",
//     flex: "0 0 calc(14.10% - 10px)", // Adjust width to be smaller (12.20% is an example)
//     marginBottom: "10px", // Space between rows
//     borderRadius: "5px", // Rounded corners for each card
//     boxSizing: "border-box", // Ensure padding/borders are included in width calculation
//     height: "200px", // Set the height to match the width (you can adjust this as needed)
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//   }}
//   key={index}
// >
//   <WarehouseInfoCard
//     warehouseName={item.warehousename}
//     warehouseStatus={item.status}
//     warehousestreetaddress={"ygikjnlk"}
//   />
//   <div className="ms-4"></div>
//   <div className="d-flex flex-row">
//     <div onClick={() => { setViewEdit(0); getWareHouse(item._id); }}>
//       <Warehouseview color="#fff" />
//     </div>
//     <div onClick={(e) => { e.stopPropagation(); setViewEdit(1); getWareHouse(item._id); }}>
//       <Warehouseedit color="#fff" />
//     </div>
//   </div>
// </div>

//   ))}
// </div>


//         </div>

//       </div>

//     <div >  <ModalWrapper  style={{
//             backgroundColor: "black",
//             // color: mode ? "#fff" : "#1B1D21",
//           }}
//       isOpen={show}
//         onClose={setShow}
//         heading=''
//         closeBtn=''

//         children={
//           <form>
//             <div className='d-flex justify-content-between align-items-center'>
//               <h2>{viewEdit === 1 ? 'Update Warehouse': viewEdit === 0? dataWare.warehousename:'Add Warehouse'}</h2>
//               {dataWare._id && viewEdit === 1 ?
//                 <h6 className="text-end mouse text-danger d-flex flex-column justify-content-center align-items-center border p-1 rounded" onClick={() => setDeletModal(true)}><DeleteIcon />
//                   <span className={styles.delete_btn}>Delete</span></h6> : null}
//             </div>
//             <div className={styles.modal_div}>
//               <div className='w-50'>
//                 <span>Name</span>
//                 <div>
//                   <input type='text' value={dataWare.warehousename}
//                     onChange={(e) => setDataWare({ ...dataWare, warehousename: e.target.value })} />
//                 </div>
//                 <span>Street Address</span>
//                 <div>
//                   <textarea rows="2" type='text' value={dataWare.address}
//                     onChange={(e) => setDataWare({ ...dataWare, address: e.target.value })} />
//                 </div>
//                 <span>Pin Code</span>
//                 <div>
//                   <input type='number' value={dataWare.pincode}
//                     onChange={(e) => setDataWare({ ...dataWare, pincode: e.target.value })} />
//                 </div>
//                 <span>Temporary Closed</span>
//                 <div className='d-flex justify-content-center align-items-center'>
//                   <input
//                     type='date'
//                     className={'text-light'}
//                     name="fromdate"
//                     value={tempClosed.fromdate}
//                     onChange={(e) => setTempClosed({ ...tempClosed, fromdate: e.target.value })} />&nbsp;&nbsp;To&nbsp;&nbsp;
//                   <input
//                     type='date'
//                     name="fromdate"
//                     className='text-light'
//                     value={tempClosed.tilldate}
//                     onChange={(e) => setTempClosed({ ...tempClosed, tilldate: e.target.value })} />
//                 </div>
//                 <span>Temporary Message</span>
//                 <div>
//                   <textarea rows="2" type='text' value={dataWare.tempmsg}
//                     onChange={(e) => setDataWare({ ...dataWare, tempmsg: e.target.value })} />
//                 </div>
//               </div>
//               <div className='w-50 ms-4'>
//                 <div className='mb-2'>Work Days :</div>
//                 {fields && fields.map((field, idx) =>
//                   <div key={`${field}-${idx}`} className='d-flex justify-content-between'>
//                     <div className=''>{field.workday}</div>
//                     <div className={styles.status_radio}>
//                       <div className={styles.workDays}>
//                         <input
//                           className={styles.days_radio}
//                           type='radio'
//                           label="Active"
//                           name={idx}
//                           value="active"
//                           onChange={(e) => handleChange(idx, e)}
//                           checked={field.workstatus === "active" ? true : false}
//                         />
//                         Active
//                       </div>
//                       <div className={styles.workDays}>
//                         <input
//                           className={styles.days_radio}
//                           type='radio'
//                           name={idx}
//                           label="Disable"
//                           value="disable"
//                           onChange={(e) => handleChange(idx, e)}
//                           checked={field.workstatus === "disable" ? true : false}
//                         />
//                         Disable
//                       </div>
//                     </div>
//                   </div>
//                 )}

//                 <div className='d-flex justify-content-between mt-2'>
//                   <div className='fw-600 text-danger'>Status</div>
//                   <div className={styles.status_radio}>
//                     <div className={styles.workDays}>
//                       <input
//                         className={styles.days_radio}
//                         type="radio"
//                         label="Active"
//                         name="formHorizontalRadios"
//                         value="active"
//                         onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
//                         checked={dataWare.status === "active" ? true : false}
//                       />
//                       Active
//                     </div>
//                     <div className={styles.workDays}>
//                       <input
//                         className={styles.days_radio}
//                         type="radio"
//                         label="Disable"
//                         name="formHorizontalRadios"
//                         value="disable"
//                         onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
//                         checked={dataWare.status === "disable" ? true : false}
//                       />
//                       Disable
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className={viewEdit === 1 ? 'd-flex justify-content-between w-75 mx-auto mt-4' : 'd-flex justify-content-center w-75 mx-auto mt-4'}>
//               <RedOutButton
//                title="CANCEL" handleSubmit={() => {
//                 setShow(false);
//                 setDataWare({});
//                 setTempClosed([]);
//                 setFields([
//                   { workday: "Monday", workstatus: "" },
//                   { workday: "Tuesday", workstatus: "" },
//                   { workday: "Wednesday", workstatus: "" },
//                   { workday: "Thursday", workstatus: "" },
//                   { workday: "Friday", workstatus: "" },
//                   { workday: "Saturday", workstatus: "" },
//                   { workday: "Sunday", workstatus: "" }
//                 ]);
//                 setViewEdit(1);
//               }} />
//               {viewEdit === 0 ? "" :
//                 <GreenButton btnType='button' title={viewEdit === 1 ? "UPDATE" : "CREATE"} css="ms-5  " handleSubmit={() => viewEdit === 1 ? updateWareHouse(dataWare._id) : postWarehouse()} />
//               }
//             </div>
//           </form>
//         }
//       />
// </div>


// <div className={styles.deletemodalmaindiv} >    
//     <ModalWrapper
//      style={{
//       backgroundColor: "pink",

//     }}
//   isOpen={deleteModal}
//   onClose={setDeletModal}
//   heading=""
//   closeBtn=""
//   children={
//     <div >
//       <div  className={styles.deletemodalcontentheading} >Delete</div>

//         <div className={styles.deletemodalcontentmsg}>Are you sure you want to delete this warehouse?</div>

//       <div  className={styles.deletemodalbtndiv} >
//       <div style={{width:'110px'}}>  <Button
//           buttonBgColor={"black"}
//           buttonIcon={<TemplateDone/>}
//           buttonTextColor={"white"}
//           buttonText={"CANCEL"}
//          buttonClick={() => {
//             setDeletModal(false);
//           }}
//         /></div>
//        <div style={{width:'110px'}}> <Button
//         buttonBgColor={"blue"}
//         buttonIcon={<TemplateDone/>}
//         buttonTextColor={"white"}
//         buttonText={"DELETE"}
//           buttonClick={() => deleteWareHouse(dataWare._id)}
//         /></div>
//       </div>
//     </div>
//   }
// />


// </div>

//     </React.Fragment>
//   )
// }
// export default Warehouse












import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../components/context/Auth";

import styles from './Warehouse.module.css'
import { DeleteIcon, EditManagerIcon, ManagerCameraIcon, TestTopBackArrowIcon, TestTopDotIcon } from "../../components/icons/Icon";
import { useNavigate } from "react-router-dom";
import Button from "../../components/updatedComponents/Dealers/Button";
import { CreateTem, TemplateDone, TemplateNotFound } from "../../components/icons/Icon2";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import PaginationComponent from "../../components/updatedComponents/Pagnation/Pagenation";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import { Cancel, Editwarehouse, View12, Warehouseedit, Warehouseview } from "../../components/icons/Icon4";
import WarehouseInfoCard from "../../components/updatedComponents/Dealers/Warehousecardinfo";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import fetchData from "../../APIsControll/apiControll";
import { changeDateFormat } from "../../Utils/TextUtils";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import InputTypedate from "../../components/updatedComponents/FormComponents/InputTypedate";
import InputTypedate2 from "../../components/updatedComponents/FormComponents/InputTypedate2";
import InputRedio from "../../components/updatedComponents/FormComponents/InputRedio";
import LocationSarchFilter from "../../components/updatedComponents/Filter/LocationSarchFilter"
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";
const Warehouse = () => {
  const { mode, designation, managerId } = useAuth();
  const [keyword, setKeyword] = useState("");
  const [keyword1, setKeyword1] = useState("");
  const [pages, setPages] = useState(1);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [activewarehouse, setActivewarehouse] = useState([]);
  const [disablewarehouse, setDisablewarehouse] = useState([]);
  const [allstate, setAllstate] = useState([]);
  const [allcity, setAllcity] = useState([]);
  const [allpin, setAllpin] = useState([]);
  const [alldistrict, setAlldistrict] = useState([]);
  const [allstatus, setAllstatus] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [isimage, setIsimage] = useState("");
  const [locationData, setLocationData] = useState("");
  const [warehouseid, setWarehouseid] = useState("");
  const [warehuseName, setWarehouseName] = useState("");
  const [fileis, setfileis] = useState(null);
  const [address, setAddress] = useState(null);
  const [imagefile, setImagefile] = useState(null);
  const [maplink, setMaplink] = useState(null);
  const [description, setDescription] = useState(null);
  const [warehousePrefix, setWarehousePrefix] = useState("");
  const [status, setStatus] = useState("");


  const navigate = useNavigate();
  const [getAllWarehouseLoading, setgetAllWarehouseLoading] = useState(false)
  const warehouseStatusData = [
    { statusText: "All", statusItemCount: activewarehouse + disablewarehouse },
    { statusText: "Active", statusItemCount: activewarehouse },
    { statusText: "Disabled", statusItemCount: disablewarehouse },

  ];
  const [state, setState] = React.useState({
    totalPages: "",
    currentPage: 1,
  });
  const fileInputRef = useRef(null);

  const [state1, setState1] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: []
  })
  const [District, setDistrict] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: []
  })
  const [City, setCity] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: []
  })
  const [pin, setPin] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: []
  })


  const handlePhotoUploadClick = () => {
    fileInputRef.current.click();
  };

  const handlePhotoChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setPreviewImage(imageUrl);
      setfileis(file)
    }
  };

  const handleStateSelection = (option) => {
    setSelectedState(option?.label);
  };

  const handleStateRejection = () => {
    setSelectedState(null);
  };

  const handleCitySelection = (option) => {
    setSelectedCity(option.label);
  };

  const handleCityRejection = () => {
    setSelectedCity(null);
  };

  // const getTruncatedName = (name, city, state) => {
  //   const fullName = `${name || ""}, ${city || ""}, ${state || ""}`;
  //   const maxLength = window.innerWidth <= 350 ? 6 : window.innerWidth <= 700 ? 10 : 13;

  //   return `${fullName.slice(0, maxLength)}${fullName.length > maxLength ? "..." : ""}`;
  // };
  const getTruncatedName = (name, city, state) => {
    const fullName = `${name || ""}, ${city || ""}, ${state || ""}`;
    let maxLength;

    if (window.innerWidth <= 350) {
      maxLength = 4;
    } else if (window.innerWidth <= 500) {
      maxLength = 6;
    } else if (window.innerWidth <= 700) {
      maxLength = 8;
    } else if (window.innerWidth >= 701 && window.innerWidth <= 900) {
      maxLength = 8; // For window width between 701px and 900px, set maxLength to 6

    } else {
      maxLength = 12;
    }

    return `${fullName.slice(0, maxLength)}${fullName.length > maxLength ? "..." : ""}`;
  };

  const [show, setShow] = useState(false);
  const [deleteModal, setDeletModal] = useState(false);
  const [pincode, setPincode] = useState('');
  const [viewEdit, setViewEdit] = useState(0);
  const [warehouseData, setWarehouseData] = useState([]);
  const [previewImage, setPreviewImage] = useState(
    "/assets/updatedAssets/manager_img.png"
  );
  const [dataWare, setDataWare] = useState({

    warehousename: '',
    Keyword1: '',
    'street': '',
    'pincode': '',
    tempclosed: '',
    'description': '',
    'workingdays': '',
    'status': '',
    'latitude': '',
    'longitude': '',
    'warehousePrefix': '',
    'city': '',
    'district': '',
    'state': '',
    'file': '',
    googleMapLink: '',
  })

  const [fields, setFields] = useState([
    { workday: "Monday", workstatus: "" },
    { workday: "Tuesday", workstatus: "" },
    { workday: "Wednesday", workstatus: "" },
    { workday: "Thursday", workstatus: "" },
    { workday: "Friday", workstatus: "" },
    { workday: "Saturday", workstatus: "" },
    { workday: "Sunday", workstatus: "" }
  ]);
  const [location, seLocation] = useState([]);

  function handleChange(i, event) {
    const values = [...fields];
    values[i]["workstatus"] = event.target.value;
    setFields(values);
  }

  const [tempClosed, setTempClosed] = useState(
    {
      fromdate: '',
      tilldate: '',
    }
  );


  const { totalPages } = state;
  console.log("hhh", state1.locationList);



  const getAllWarehouse = async () => {
    try {
      const payload = {
        managerId: managerId,
        keyword: keyword1 || '', // Use empty string if no keyword is provided
        status: status.toLowerCase() || '', // Use empty string if no status is selected
        locations: {
          states: state1.selectFilter,
          districts: District.selectFilter,
          cities: City.selectFilter,
          pincodes: []
        }
      };
      setgetAllWarehouseLoading(true);

      // Call the API using the payload
      const res = await fetchData(`warehouses/getAllWarehouse?page=${page}&limit=32`, payload);
      setgetAllWarehouseLoading(false);

      if (res.status === "success") {
        setPage(res?.page);
        setPages(res?.pages);
        setWarehouseData(res.data);
        setActivewarehouse(res?.statusCounts?.active);
        setDisablewarehouse(res?.statusCounts?.disable);
        return;
      }

      if (res.status === "failed") {
        setWarehouseData([]); // Clear the warehouse data if fetch fails
        setActivewarehouse(0);
        setDisablewarehouse(0);
        setgetAllWarehouseLoading(false);
        return;
      }

      if (res.pages) {
        setState((prevState) => ({
          ...prevState,
          totalPages: res.pages || "", // Set total pages if available
        }));
      }
    } catch (error) {
      setgetAllWarehouseLoading(false); // Set loading to false if there's an error
      console.error('Error fetching warehouse data:', error);
    }
  };

  useEffect(() => {
    getAllWarehouse();
  }, [page, selectedState, keyword1, status, state1.selectFilter, District.selectFilter, City.selectFilter]);
  const getWareHouse = (warehouseId) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({

      'warehouseId': warehouseId,
      managerId: managerId,

    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + `warehouses/getWarehouse`, requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          setDataWare(result.data)
          const Correctfromdate = changeDateFormat(result?.data?.tempclosed[0]?.fromdate, "-")
          const Correcttilldate = changeDateFormat(result?.data?.tempclosed[0]?.tilldate, "-")

          setTempClosed([{
            "fromdate": Correctfromdate,
            "tilldate": Correcttilldate,
          }])
          // setTempClosed(result.data.tempclosed)
          setFields(result.data.workingdays)
          setShow(true)
          setLocationData(result?.data?.location)
          setAddress(result?.data?.address)
          setWarehouseid(result.data.warehouseid)
          setMaplink(result.data.googleMapLink)
          setWarehousePrefix(result.data.warehouseId)
          setImagefile(result.data.profileImage)
          setDescription(result.data.description)
        }
      })
      .catch(error => console.log('error', error));
  }
  const getFilterData = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      stateSearch: setSelectedState,
      districtSearch: "",
      citySearch: "",
      pincodeSearch: "",
      locations: {
      },
    });

    console.log("Request Payload:", raw);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    console.log("Request Options:", requestOptions);

    fetch(process.env.REACT_APP_URL + "warehouses/filterLocation", requestOptions)
      .then((response) => {

        return response.json();
      })
      .then((result) => {
        console.log("Parsed Response:", result);
        if (result.status === "success") {
          setDataWare(result.data);
          setDistrict((pre) => ({ ...pre, locationList: result?.data?.districts }));
          setState1((pre) => ({ ...pre, locationList: result?.data?.states }));
          setCity((pre) => ({ ...pre, locationList: result?.data?.cities }));
          setPin((pre) => ({ ...pre, locationList: result?.data?.pincodes }));


          console.log("Raw Response:", result.data.states);
          setAllstate(result?.data?.states)
          setAlldistrict(result?.data?.districts)
          setAllcity(result?.data?.cities)
          setAllpin(result?.data?.pincodes)

          // setShow(true);
        } else {
          console.error("API Response Error:", result);
          setAllstate([])
        }
      })
      .catch((error) => console.error("Fetch Error:", error));

  };
  useEffect(() => {
    getFilterData()
  }, [selectedState])

  const getLocationData = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      keyword: dataWare?.pincode,
    })
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          console.log('result', result.SubDistName[0], result.states[0], result.districts[0])
          setDataWare({
            state: result.states[0], city: result.SubDistName[0], district: result.districts[0],
          })
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (dataWare?.pincode?.length === 6) {
      getLocationData();
    }

  }, [dataWare?.pincode]);




  const validatePincode = (code) => {
    const regex = /^[1-9][0-9]{5}$/; // Validates Indian PIN Codes (6 digits, starting from 1-9)
    return regex.test(code);
  };
  const [errors, setErrors] = useState({
    warehousename: "",
    warehousePrefix: "",
    pincode: "",
    street: "",
    latitude: "",
    longitude: "",
    file: "",
  });


  const validateFields = () => {
    const validationErrors = {};

    if (!warehuseName?.trim()) validationErrors.warehousename = "Warehouse name is required.";
    if (!warehousePrefix?.trim()) validationErrors.warehousePrefix = "Warehouse ID is required.";
    if (!pincode?.trim()) {
      validationErrors.pincode = "Pincode is required.";
    } else if (!validatePincode(pincode)) {
      validationErrors.pincode = "Invalid Pincode. Please enter a valid 6-digit PIN Code.";
    }
    if (!dataWare?.street?.trim()) validationErrors.street = "Street is required.";
    if (!dataWare?.latitude) validationErrors.latitude = "Latitude is required.";
    if (!dataWare?.longitude) validationErrors.longitude = "Longitude is required.";
    if (!fileis) validationErrors.file = "File is required.";

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };


  // const postWarehouse = () => {


  //   if (!validateFields()) {
  //     return; 
  //   }


  //   console.log("dataware", dataWare);

  //   const formData = new FormData();

  //   formData.append("managerId", managerId);
  //   formData.append("warehousename", warehuseName ? warehuseName : dataWare?.warehousename);
  //   formData.append("street", dataWare.street);
  //   formData.append("pincode", pincode ? pincode : dataWare?.pincode);

  //   if (tempClosed?.fromdate || tempClosed?.tilldate) {
  //     formData.append("tempclosed", JSON.stringify([tempClosed]));
  //   } else {
  //     formData.append("tempclosed", JSON.stringify([])); 
  //   }

  //   formData.append("description", description ? description : ''); 
  //   formData.append("googleMapLink", maplink ? maplink : ''); 

  //   formData.append("workingdays", JSON.stringify(fields));
  //   formData.append("status", dataWare?.status);
  //   formData.append("latitude", dataWare?.latitude);
  //   formData.append("longitude", dataWare?.longitude);
  //   formData.append("warehousePrefix", warehousePrefix ? warehousePrefix : dataWare?.warehousePrefix);
  //   formData.append("city", dataWare?.city);
  //   formData.append("district", dataWare?.district);
  //   formData.append("state", dataWare?.state);

  //   if (fileis) {
  //     formData.append("file", fileis);
  //   }

  //   fetch(process.env.REACT_APP_URL + "warehouses/addWarehouse", {
  //     method: "POST",
  //     headers: {
  //       "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
  //     },
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((result) => {
  //       if (result.status === "success") {
  //         setDataWare(""); 
  //         setTempClosed([{ fromdate: "", tilldate: "" }]); 
  //         getAllWarehouse(); 
  //         setShow(false); 
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // }


  // const updateWareHouse = () => {
  //   const formData = new FormData();
  //   console.log(tempClosed);

  //   formData.append('managerId', managerId);
  //   formData.append('warehousename', warehuseName ? warehuseName : dataWare?.warehousename);
  //   formData.append('street', address ? address : dataWare.street);
  //   formData.append('pincode', pincode ? pincode : dataWare?.pincode);

  //   if (tempClosed?.fromdate || tempClosed?.tilldate) {
  //     formData.append('tempclosed', JSON.stringify([tempClosed]));
  //   } else {
  //     formData.append("tempclosed", JSON.stringify([])); 
  //   }

  //   formData.append('description', dataWare?.description);
  //   formData.append('workingdays', JSON.stringify(fields));
  //   formData.append('status', dataWare?.status);
  //   formData.append('latitude', locationData?.latitude);
  //   formData.append('longitude', locationData?.longitude);
  //   formData.append('warehouseId', warehousePrefix ? warehousePrefix : dataWare?.warehousePrefix);
  //   formData.append('city', dataWare?.city);
  //   formData.append('district', dataWare?.district);
  //   formData.append('state', dataWare?.state);
  //   formData.append('googleMapLink', maplink ? maplink : dataWare?.maplink);

  //   if (isimage || fileis) {
  //     formData.append('file', isimage ? isimage : fileis);
  //   }

  //   fetch(process.env.REACT_APP_URL + "warehouses/updateWarehouse", {
  //     method: "POST",
  //     headers: {
  //       "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
  //     },
  //     body: formData,
  //   })
  //     .then(response => response.json())
  //     .then(result => {
  //       if (result.status === 'success') {
  //         setDataWare(""); 
  //         setTempClosed([{ fromdate: "", tilldate: "" }]); 
  //         setDescription(""); 
  //         setMaplink(""); 
  //         setPincode(""); 
  //         setWarehousePrefix(""); 
  //         setFields([]); 
  //         setfileis(null); 
  //         getAllWarehouse(); 
  //         setShow(false); 
  //         alert('Updated successfully');
  //         setViewEdit(0); 
  //       }
  //     })
  //     .catch(error => console.log('error', error));
  // };


  const postWarehouse = () => {
    if (!validateFields()) {
      return;
    }

    console.log("dataware", dataWare);

    const formData = new FormData();

    formData.append("managerId", managerId);
    formData.append("warehousename", warehuseName ? warehuseName : dataWare?.warehousename);
    formData.append("street", dataWare.street);
    formData.append("pincode", pincode ? pincode : dataWare?.pincode);

    if (tempClosed?.fromdate || tempClosed?.tilldate) {
      formData.append("tempclosed", JSON.stringify([tempClosed]));
    } else {
      formData.append("tempclosed", JSON.stringify([]));
    }

    formData.append("description", description ? description : '');
    formData.append("googleMapLink", maplink ? maplink : '');

    formData.append("workingdays", JSON.stringify(fields));
    formData.append("status", dataWare?.status);
    formData.append("latitude", dataWare?.latitude);
    formData.append("longitude", dataWare?.longitude);
    formData.append("warehousePrefix", warehousePrefix ? warehousePrefix : dataWare?.warehousePrefix);
    formData.append("city", dataWare?.city);
    formData.append("district", dataWare?.district);
    formData.append("state", dataWare?.state);

    if (fileis) {
      formData.append("file", fileis);
    }

    fetch(process.env.REACT_APP_URL + "warehouses/addWarehouse", {
      method: "POST",
      headers: {
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          // Resetting state after success
          setDataWare("");
          setTempClosed([{ fromdate: "", tilldate: "" }]);
          setDescription("");
          setMaplink("");
          setPincode("");
          setWarehousePrefix("");
          setFields([]);
          setfileis(null);
          getAllWarehouse();
          setShow(false);
        }
      })
      .catch((error) => console.log(error));
  };

  const updateWareHouse = () => {
    const formData = new FormData();
    console.log(tempClosed);

    formData.append('managerId', managerId);
    formData.append('warehousename', warehuseName ? warehuseName : dataWare?.warehousename);
    formData.append('street', address ? address : dataWare.street);
    formData.append('pincode', pincode ? pincode : dataWare?.pincode);

    if (tempClosed?.fromdate || tempClosed?.tilldate) {
      formData.append('tempclosed', JSON.stringify([tempClosed]));
    } else {
      formData.append("tempclosed", JSON.stringify([]));
    }

    formData.append('description', dataWare?.description);
    formData.append('workingdays', JSON.stringify(fields));
    formData.append('status', dataWare?.status);
    formData.append('latitude', locationData?.latitude);
    formData.append('longitude', locationData?.longitude);
    formData.append('warehouseId', warehousePrefix ? warehousePrefix : dataWare?.warehousePrefix);
    formData.append('city', dataWare?.city);
    formData.append('district', dataWare?.district);
    formData.append('state', dataWare?.state);
    formData.append('googleMapLink', maplink ? maplink : dataWare?.maplink);

    if (isimage || fileis) {
      formData.append('file', isimage ? isimage : fileis);
    }

    fetch(process.env.REACT_APP_URL + "warehouses/updateWarehouse", {
      method: "POST",
      headers: {
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },
      body: formData,
    })
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          // Resetting state after success
          setDataWare("");
          setTempClosed([{ fromdate: "", tilldate: "" }]);
          setDescription("");
          setMaplink("");
          setPincode("");
          setWarehousePrefix("");
          setFields([]);
          setfileis(null);
          getAllWarehouse();
          setShow(false);
          alert('Updated successfully');
          setViewEdit(0);
        }
      })
      .catch(error => console.log('error', error));
  };

  // console.log(state1);



  const deleteWareHouse = (warehouseId) => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      'managerId': managerId,
      warehouseId: warehouseId,
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(process.env.REACT_APP_URL + "warehouses/deleteWarehouse", requestOptions)
      .then(response => response.json())
      .then(result => {
        if (result.status === 'success') {
          getAllWarehouse()
          alert('deleted successfully')
          setShow(false);
          setDeletModal(false)
        }
      })
      .catch(error => console.log('error', error));
  }

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
  }, [show])

  const optionsState = allstate?.map(States => ({
    label: States,
  }));

  const optionsCity = allcity?.map(Cit => ({
    label: Cit,
  }));


  const optionsPin = allpin?.map(Pincode => ({
    label: Pincode,
  }));

  const optionsDistrict = alldistrict?.map(Distr => ({
    label: Distr,
  }));
  return (
    <>
      <div className={styles.mainCon} style={{ backgroundColor: mode ? "#232529" : "#D9DCE5", }}>
        <div style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC", }} className={styles.voucher_maindiv} >
          <div
            className={styles.dispatch_top_left}
            style={{
              position: "relative",
              backgroundColor: mode ? "#2C2E33" : "#F8F9FC",

            }}
          >
            <div className={styles.mgx_top}>
              <div className={styles.mgx_top_left}>

                <div



                  className={styles.dispatch_top_left_go_back}
                  onClick={() => navigate(-1)}
                >
                  <TestTopBackArrowIcon color={mode ? "#749AFF" : "#4164E3"} />
                  <p
                    className={styles.dispatch_go_back_text}
                    style={{
                      color: mode ? "#749AFF" : "#4164E3", marginTop: "4PX",
                    }}
                  >
                    Go Back
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "7px",
                  }}
                >
                  {" "}
                  <div style={{ marginBottom: "2px", }}>
                    <TestTopDotIcon color={mode ? "#9C9797" : "#E4E7EB"} />
                  </div>
                  <div>
                    <p
                      className={styles.mgx_top_left_path_text}
                      style={{
                        color: mode ? "#9C9797" : "#646B88",
                        marginTop: "5px",
                        // fontSize:"12px",

                      }}
                    >

                      Warehouse

                    </p>
                  </div>{" "}
                </div>
              </div>
            </div>

            <div style={{ fontWeight: "500", width: "20vh" }} className={styles.warehousebtn} >

              <Button
                buttonClick={() => {
                  setShow(true);
                  setViewEdit(2);
                  setDataWare({});

                }}
                buttonIcon={<CreateTem />}
                buttonBgColor={"#4164E3"}
                buttonText={"Add Warehouse"}
                buttonTextColor={"white"}
              />
            </div>
          </div>
          <div
            style={{ paddingLeft: "10px", paddingRight: "10px" }}>       <div
              className={styles.mgx_middle}
              style={
                mode ? { backgroundColor: "#232529", borderColor: "#FFFFFF", } : {}

              }
            >
              {warehouseStatusData.map((status) => (
                <div style={{ alignContent: "left", cursor: "pointer", display: "flex" }}
                  key={status.statusText}
                  className={`${styles.order_filter} ${mode ? styles.order_filter_dark : ""
                    } ${status.status === status ? styles.order_filter_active : ""
                    }`}
                  onClick={() => {
                    setStatus(status?.statusText); // Set the selected status
                    setPage(1); // Reset the page to 1 when the status is changed
                  }}>
                  <DealerStatusCard
                    manager
                    statusText={status.statusText}
                    statusItemCount={status.statusItemCount}
                  />
                </div>
              ))}</div>
          </div>


          <div
            className={styles.search}

          >

            <div style={{ width: "37vh" }} className="search-container">
              <SearchBar
                placeholder={"Search by Order ID"}
                value={keyword1}
                onChange={(e) => setKeyword1(e.target.value)}
              />


            </div>
            <div className={styles["dropdown-container"]}
            >
              <LocationSarchFilter
                text={"State"}
                list={state1?.locationList}
                search={state1?.searchValue}
                setSearch={(value) =>
                  setState1((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={state1?.selectFilter}
                setSelectFilter={(value) =>
                  setState1((prevState) => ({ ...prevState, selectFilter: value }))
                }
              />
              <LocationSarchFilter
                text={"District"}
                list={District?.locationList}
                search={District?.searchValue}
                setSearch={(value) =>
                  setDistrict((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={District?.selectFilter}
                setSelectFilter={(value) =>
                  setDistrict((prevState) => ({ ...prevState, selectFilter: value }))
                }
              />
              <LocationSarchFilter
                text={"City"}
                list={City?.locationList}
                search={City?.searchValue}
                setSearch={(value) =>
                  setCity((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={City?.selectFilter}
                setSelectFilter={(value) =>
                  setCity((prevState) => ({ ...prevState, selectFilter: value }))
                }
              />
              {/* <LocationSarchFilter
                text={"City"}
                list={pin?.locationList}
                search={pin?.searchValue}
                setSearch={(value) =>
                  setPin((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={pin?.selectFilter}
                setSelectFilter={(value) =>
                  setPin((prevState) => ({ ...prevState, selectFilter: value }))
                }
              /> */}
              {/* <DropDown
                label={"State"}
                listLabel={"All"}
                options={optionsState}
                onSelect={handleStateSelection}
                onReject={handleStateRejection}
              />
              <DropDown
                label={"pincode"}
                listLabel={"All"}
                options={optionsPin}
                onSelect={handleStateSelection}
                onReject={handleStateRejection}
              />

              <DropDown
                label={"District"}
                listLabel={"All"}
                options={optionsDistrict}
                onSelect={handleStateSelection}
                onReject={handleStateRejection}
              />
              <DropDown
                label={"City"}
                listLabel={"All"}
                options={optionsCity}
                onSelect={handleCitySelection}
                onReject={handleCityRejection}
              /> */}
              <DropDown
                label={"Status"}
                listLabel={"All"}
                options={[{ label: "Active" }, { label: "Desble" }]}
                onSelect={handleStateSelection}
                onReject={handleStateRejection}
              />
            </div>
          </div>

          <div style={{

            marginBottom: "60px",
            overflowY: "auto", // Enable vertical scrolling
            maxHeight: "57vh",
            position: "relative",
            paddingLeft: "5px",

          }}>
            <div className={styles.container}
            >
              {getAllWarehouseLoading ? (
                <>loading</>
              ) : warehouseData.length === 0 ? (
                <div style={{ display: "flex", width: "100%", paddingTop: "30PX", }}>
                  <NoDataFound
                    icon={<TemplateNotFound />}
                    to={"/templates/template"} />
                </div>
              ) : (
                warehouseData.map((item, index) => (
                  <div className={styles.cards}
                    style={{
                      backgroundColor: mode ? "#1B1D21" : "white",
                    }}
                    key={index}
                  >

                    <div className="flex-container">
                      <WarehouseInfoCard

                        warehouseStatus={item?.status}

                        // warehouseName={`${`${item?.warehousename || ''}, ${item?.city || ''}, ${item?.state || ''}`.slice(0, 10)}${`${item?.warehousename || ''}, ${item?.city || ''}, ${item?.state || ''}`.length > 18 ? "..." : ""}`}
                        warehouseName={getTruncatedName(item?.warehousename, item?.city, item?.state)}



                      />
                      <div>
                        <div style={{ display: "flex", gap: "30PX", cursor: "pointer", }} onClick={() => {
                          setViewEdit(0);
                          getWareHouse(item?.warehouseId);
                        }}> <View12 mode={mode} />
                          <div style={{ cursor: "pointer", }} onClick={(e) => {
                            e.stopPropagation();
                            setViewEdit(1);
                            getWareHouse(item?.warehouseId);
                          }}> <Editwarehouse mode={mode} /></div>
                        </div>

                      </div>
                    </div>

                  </div>
                ))
              )}
            </div></div>





          <div>
            <ModalWrapper style={{
              backgroundColor: mode ? "#2C2E33 " : "#FFFFFF",
              width: "80vh",
              borderRadius: "20px",
            }}
              isOpen={show}
              onClose={() => setShow(!show)}
            >
              <button
                style={{
                  position: "absolute",
                  top: "15px",
                  right: "15px",
                  backgroundColor: "transparent",
                  border: "none",
                  fontSize: "25px",
                  color: mode ? "white" : "black",
                  cursor: "pointer",
                }}
                onClick={() => setShow(false)}
              >
                &times;
              </button>
              <div style={{ color: mode ? "white" : "black", fontSize: "18px", fontWeight: "500", padding: "20px 0px 0px 20px" }}
              >{viewEdit === 1 ? 'Update Warehouse' : viewEdit === 0 ? dataWare?.warehousename : 'Add Warehouse'}</div>

              <div style={{ display: "flex", width: "100%", padding: "20px", gap: "20px" }}  >
                <div className={styles.left_side} >

                  <div style={{ width: "100%" }}>
                    <InputTag
                      labelText={"Warehouse Name"}
                      star={true}
                      padding={"8px"}
                      fontSize={"12px"}
                      placeholderText={"Enter Warehouse Name"}
                      maxLength={40}
                      placeholderType={"text"}
                      value={dataWare?.warehousename}
                      onChange={(e) => {
                        setWarehouseName(e.target.value)
                        setDataWare({ ...dataWare, warehousename: e.target.value })
                      }
                      }
                    />
                    {errors.warehousename && <p style={{ color: "red", fontSize: "12px" }}>{errors.warehousename}</p>}

                  </div>


                  <div style={{ width: "100%" }}>

                    <InputTag
                      labelText={"  Warehouse ID"}
                      star={true}
                      padding={"8px"}
                      maxLength={5}

                      fontSize={"12px"}
                      placeholderText={"Enter Warehouse ID"}
                      placeholderType={"text"}
                      value={warehousePrefix ? warehousePrefix : dataWare?.warehousePrefix}
                      onChange={(e) => {
                        setWarehousePrefix(e.target.value)
                        setDataWare({ ...dataWare, warehousePrefix: e.target.value })
                      }
                      }
                    />
                    {errors.warehousePrefix && <p style={{ color: "red", fontSize: "12px" }}>{errors.warehousePrefix}</p>}
                  </div>

                  <div style={{ width: "100%" }}>
                    <InputTag
                      labelText={"PIN Code"}
                      star={true}
                      padding={"8px"}
                      maxLength={6}
                      fontSize={"12px"}
                      placeholderText={"Enter PIN Code"}
                      placeholderType={"Number"}
                      value={dataWare?.pincode}
                      onChange={(e) => {
                        setDataWare({ ...dataWare, pincode: e.target.value });
                        setPincode(e.target.value);

                        if (!e.target.value.trim()) {
                          setErrors((prev) => ({ ...prev, pincode: "Pincode is required." }));
                        } else if (!validatePincode(e.target.value)) {
                          setErrors((prev) => ({
                            ...prev,
                            pincode: "Invalid Pincode. Please enter a valid 6-digit PIN Code.",
                          }));
                        } else {
                          setErrors((prev) => {
                            const { pincode, ...rest } = prev;
                            return rest;
                          });
                        }
                      }}
                    />
                    {errors.pincode && <p style={{ color: "red", fontSize: "12px" }}>{errors.pincode}</p>}
                  </div>

                  <div style={{ width: "100%" }}>

                    <InputTag
                      labelText={"Street Address"}
                      star={true}
                      padding={"8px"}
                      maxLength={50}
                      fontSize={"12px"}
                      placeholderText={"Enter   Street Address"}
                      placeholderType={"text"}

                      value={address ? address : dataWare?.street}
                      onChange={(e) =>
                        setDataWare({ ...dataWare, street: e.target.value })
                      }
                    />
                    {errors.street && <p style={{ color: "red", fontSize: "12px" }}>{errors.street}</p>}

                  </div>

                  <div style={{ display: "flex", gap: "10px" }}>
                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"State"}
                        star={true}
                        padding={"8px"}
                        fontSize={"12px"}
                        maxLength={20}
                        placeholderText={"Enter State"}
                        placeholderType={"text"}
                        value={dataWare?.state}
                        onChange={(e) => setDataWare({ ...dataWare, state: e.target.value })}
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"District"}
                        star={true}
                        padding={"8px"}
                        fontSize={"12px"}
                        maxLength={20}
                        placeholderText={"Enter District"}
                        placeholderType={"text"}
                        value={dataWare?.district}
                        onChange={(e) => setDataWare({ ...dataWare, district: e.target.value })}
                      />
                    </div>

                    <div style={{ width: "100%" }}>
                      <InputTag
                        labelText={"City"}
                        star={true}
                        padding={"8px"}
                        fontSize={"12px"}
                        maxLength={20}
                        placeholderText={"Enter City"}
                        placeholderType={"text"}
                        value={dataWare?.city}
                        onChange={(e) => setDataWare({ ...dataWare, city: e.target.value })}
                      />
                    </div>
                  </div>


                  <div style={{ display: "flex", gap: "10px", alignItems: "flex-start", }}>
                    <div style={{ width: "100%" }}>
                      <div>
                        <InputTag
                          labelText={"Latitude"}
                          star={true}
                          padding={"8px"}
                          fontSize={"12px"}
                          maxLength={50}
                          placeholderText={"Enter Latitude"}
                          placeholderType={"text"}
                          value={locationData?.latitude}
                          onChange={(e) => setDataWare({ ...dataWare, latitude: e.target.value })}
                        />
                        {errors.latitude && <p style={{ color: "red", fontSize: "12px" }}>{errors.latitude}</p>}

                      </div>
                    </div>

                    <div style={{ width: "100%" }}>
                      <div>
                        <InputTag
                          labelText={"Longitude"}
                          star={true}
                          padding={"8px"}
                          fontSize={"12px"}
                          maxLength={50}
                          placeholderText={"Enter Longitude"}
                          placeholderType={"text"}
                          value={locationData?.longitude}
                          onChange={(e) => setDataWare({ ...dataWare, longitude: e.target.value })}
                        />
                        {errors.longitude && <p style={{ color: "red", fontSize: "12px" }}>{errors.longitude}</p>}

                      </div>
                    </div>
                  </div>

                  <div style={{ width: "100%" }}>
                    <InputTag
                      labelText={" Map link"}
                      // star={true}
                      padding={"8px"}
                      fontSize={"12px"}
                      maxLength={200}
                      placeholderText={"Enter Map Link"}
                      placeholderType={"text"}
                      value={maplink ? maplink : dataWare?.maplink} onChange={(e) => {
                        setMaplink(e.target.value)
                        setDataWare({ ...dataWare, googleMapLink: e.target.value })
                      }
                      }
                    />
                  </div>
                  <span style={{ color: mode ? "white" : "black", fontSize: "14px", }}>Temporary Closed</span>
                  <div style={{ display: "flex" }}

                  >

                    <InputTypedate2
                      text={"Form"}
                      onChange={(e) => setTempClosed({ ...tempClosed, fromdate: e.target.value })}

                    />
                    {/* <span style={{ color: mode ? "white" : "black", marginTop: "10px", marginLeft: "5px", }}>                         &nbsp;&nbsp;To&nbsp;&nbsp;
                      </span> */}
                    <InputTypedate2
                      text={"To"}
                      onChange={(e) => setTempClosed({ ...tempClosed, tilldate: e.target.value })}

                    />

                  </div>

                  <div style={{ width: "100%", }}>
                    <InputTag
                      labelText={"Description"}
                      // star={true}
                      padding={"10px"}
                      fontSize={"12px"}
                      maxLength={50}
                      placeholderText={"Enter Description"}
                      placeholderType={"text"}
                      value={dataWare?.description}
                      onChange={(e) => setDataWare({ ...dataWare, description: e.target.value })} />
                    <span className={styles.redText}>Character limit 50-160*</span>
                  </div>

                </div>


                <div className={styles.seandCon} >
                  <div className={styles.nm_photo} onClick={handlePhotoUploadClick}>
                    <img src={
                      imagefile ?
                        `${process.env.REACT_APP_S3URLA}${imagefile}` :
                        previewImage || "default-placeholder.png"} alt="Manager" />
                    <div className={styles.nm_camera_icon}>
                      <ManagerCameraIcon />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handlePhotoChange}
                      acc
                      ept="image/*"
                    />
                    {errors.file && <p style={{ color: "red" }}>{errors.file}</p>}

                  </div>
                  <div className={styles.right_side} style={{ border: mode ? "none" : "1px solid #ccc", marginTop: "40px", color: mode ? "white" : "black" }} >
                    <div>Work Days:</div>

                    {fields && fields.map((field, idx) =>

                      <div style={{}} className={styles.redioBtnConintner3}>
                        <div style={{ color: mode ? "white" : "black", }}
                          className="status-label">{field.workday}</div>

                        <div style={{ display: "flex", gap: "10px", justifyContent: "flex-end", }}>
                          <InputRedio
                            lableText="Active"
                            value="active"


                            name={idx}
                            onChange={(e) => handleChange(idx, e)}
                            checked={field?.workstatus === "active" ? true : false}
                          />
                          <InputRedio
                            lableText="Disable"
                            value="disable"

                            name={idx}
                            onChange={(e) => handleChange(idx, e)}
                            checked={field?.workstatus === "disable" ? true : false}
                          /></div>

                      </div>

                    )}</div>
                </div>
              </div>


              <div style={{ display: "flex", justifyContent: "space-between", padding: "0 20px 20px 20px ", }}>
                <div className={styles.redioBtnConintner3}>
                  <div style={{ color: "#F93E3E", marginTop: "5px" }} className="status-label">Status</div>
                  <InputRedio
                    lableText="Active"
                    value="active"

                    name="formHorizontalRadios"
                    onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
                    checked={dataWare?.status === 'active'}
                  />
                  <InputRedio
                    lableText="Disable"
                    value="disable"
                    name="formHorizontalRadios"
                    onChange={(e) => setDataWare({ ...dataWare, status: e.target.value })}
                    checked={dataWare?.status === 'disable'}
                  />
                  {dataWare?._id && viewEdit === 1 ?
                    <div onClick={() => setDeletModal(true)}><DeleteIcon /> </div>
                    : null}
                </div>


                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    justifyContent: viewEdit === 0 ? "flex-start" : "flex-end", // Align left when one button, right when both are present
                  }}
                  className={`button-container ${viewEdit === 1 ? 'edit-mode' : ''}`}
                >
                  <div style={{ width: "100px" }}>
                    <Button
                      buttonText="Cancel"
                      buttonBgColor="black"
                      buttonTextColor="white"
                      buttonClick={() => {
                        setShow(false);
                        setDataWare({});
                        setViewEdit(1);
                      }}
                    />

                  </div>
                  {viewEdit !== 0 && (
                    <div style={{ width: "100px" }}>
                      <Button
                        buttonBgColor="blue"
                        buttonTextColor="white"
                        buttonText={viewEdit === 1 ? "Update" : "Create"}
                        buttonClick={viewEdit === 1 ? () => updateWareHouse(dataWare.warehousename) : postWarehouse}
                      />
                    </div>
                  )}
                </div>

              </div>

              {/* </div> */}
            </ModalWrapper>
          </div>


          <div className={styles.deletemodalmaindiv}>
            <ModalWrapper
              style={{
                backgroundColor: mode ? "#2C2E33 " : "#FFFFFF",
                borderRadius: "20px",
                padding: "10px",
                position: "relative",
              }}
              isOpen={deleteModal}
              onClose={setDeletModal}
              heading=""
              closeBtn=""
              children={
                <div >

                  <div
                    onClick={() => setDeletModal(false)}
                    style={{
                      position: "absolute",
                      top: "20px",
                      right: "20px",
                      cursor: "pointer",
                      fontSize: "20px",
                      color: mode ? "white" : "black",
                    }}
                  >
                    ✖
                  </div>

                  <div style={{ color: mode ? "white" : "black", fontSize: "18px" }} className={styles.deletemodalcontentheading}>
                    Delete
                  </div>

                  <div style={{ color: mode ? "white" : "black", fontSize: "16px", padding: "12px" }} className={styles.deletemodalcontentmsg}>
                    Are you sure you want to delete this warehouse?
                  </div>

                  <div className={styles.deletemodalbtndiv}>
                    <div style={{ width: '100px' }}>
                      <Button
                        buttonBgColor={"black"}
                        buttonIcon={<Cancel />}
                        buttonTextColor={"white"}
                        buttonText={"CANCEL"}
                        buttonClick={() => {
                          setDeletModal(false);
                        }}
                      />
                    </div>
                    <div style={{ width: '100px' }}>
                      <Button
                        buttonBgColor={"blue"}
                        buttonIcon={<TemplateDone />}
                        buttonTextColor={"white"}
                        buttonText={"DELETE"}
                        buttonClick={() => deleteWareHouse(dataWare.warehouseId)}
                      />
                    </div>
                  </div>
                </div>
              }
            />
          </div>

        </div>
        <div className={styles.paginationdiv}
          style={{
            backgroundColor: mode ? "#1B1D21" : "#FFFFFF",
            // marginTop:"2PX",
            boxShadow: mode ? "0px -5px 10px rgba(0, 0, 0, 0.2)" : "0px -5px 10px rgba(0, 0, 0, 0.1)",

          }}    >
          <div className={styles.page1}
          >
            <GoToPage
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"1"}
            />
          </div>

          <div className={styles.page2}
          >
            <PaginationComponent
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"2"}
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default Warehouse






