import React, { useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useAuth } from "../../components/context/Auth";
import {
  BackArrowIcon,
  CancelIcon,
  CancelIcon2,
  Checked,
  CheckGreenIcon,
  CheckIcon,
  DeleteFile,
  DeleteIcon,
  DispatchDownloadIcon,
  DispatchedIcon,
  DispatchIcon,
  DownArrowCircle,
  ManagerTickIcon,
  OrderChecked,
  Printbtn,
  SideBarPackingOrderIcon,
  Spinner,
  TestTopAddDealerIcon,
  TestTopBackArrowIcon,
  TestTopDotIcon,
  TickIcon,
  UpArrowCircle,
} from "../../components/icons/Icon";
import styles from "./DishpatchManager.module.css";
import styles1 from "../../components/updatedComponents/Test/Test.module.css";

import {
  GreenButton,
  GreenOutButton,
  RedOutButton,
} from "../../components/Buttons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PackingSlip } from "./PackingSlip";
import { PrintLabel } from "./PrintLabel";
import Modal from "../../components/Modal";
import { FileHandler } from "../../components/DragAndDrop";
import Button from "../../components/updatedComponents/Dealers/Button";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import { TemplateDone } from "../../components/icons/Icon2";
import {
  Back,
  Cancel,
  Canceldeny,
  Label,
  Pack,
  Pakingslip,
  Statusicon,
  Viewdispatch,
  Viewdispatchdetails,
} from "../../components/icons/Icon4";
import { FileHandler2 } from "../../components/updatedComponents/Draganddrop2";
// import DropDown2 from "../../components/updatedComponents/FormComponents/Dropdown2";

// import modalcss from '../../components/Modal.module.css'

import DropDown2 from "../../components/updatedComponents/FormComponents/Dropdown2";
import { Dropdown } from "react-bootstrap";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import DropDown3 from "../../components/updatedComponents/FormComponents/Dropdown3";
import fetchData from "../../APIsControll/apiControll";
import Skeleton from "../../components/updatedComponents/Skeleton/Skeleton";






const PackingVoucher = () => {
  let componentRef = React.useRef();
  const navigate = useNavigate();
  const { switchDisplay, managerId, designation, mode } = useAuth();
  const params = useParams();
  const orderId = params.orderId;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showLabelModal, setShowLabelModal] = useState(false);
  const [showDeliveryModal, setShowDeliveryModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [msgShow, setMsgShow] = useState("");
  const [packer, setPacker] = useState(managerId);
  // const [couriers, setCouriers] = useState([])
  const [partners, setPartners] = useState("");
  const [data, setData] = useState({});
  const [isReloaded, setIsReloaded] = useState(false);
  const [lableBtnLoading, setLableBtnLoading] = useState(false);
  const [paymentLink, setPaymentLink] = useState("");
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [isImage, setisImage] = React.useState("");
  const [warehouses, setWarehouses] = useState([]);
  const [packingmanager, setPackingmanager] = useState([]);
  const [selectedpackingmanager, setSelectedpackingmanager] = useState("");
  const [courierPartners, setCourierPartners] = useState([]);

  const [isApiCalled, setIsApiCalled] = useState(false); // Flag to prevent multiple API calls
       



  const [delivery, setDelivery] = useState({
    courierName: "",
    partnerName: "",
  });
  const [printLabel, setPrintLabel] = useState({
    qtyPacks: "",
    delPerName: "",
    delPerMobile: "",
    delTrackingNo: "",
    delTrackingUrl: "",
    courierSlip: "",
  });


  const optionsPack = packingmanager?.map(Pack => ({
    label: Pack.name,  // Use manager's name for the label
  }));

  const optionsPart = courierPartners?.map(Part => ({
    name: Part.partnerName,  // Use manager's name for the label

  }));

  console.log(" optionsPart", optionsPart);


  const handlePartnerSelection = (optionis) => {
    setSelectedpackingmanager(optionis.value);
  };

  const handlePartnerRejection = () => {
    setSelectedpackingmanager(null);
  };



  const [courierSlip, setCourierSlip] = useState();

  const [showPackedModal, setShowPackedModal] = useState(false);
  const [check, setCheck] = useState(false);
  const [images, setImages] = useState([]);
  const [packs, setPacks] = useState();
  const [point, setPoint] = useState();
  console.log("images", images);
  const handleAddImage = (file) => {
    const updatedImages = [...images];
    // Ensure the number of images matches the number of packs
    if (images.length < parseInt(packs)) {
      updatedImages.push(file);
    } else {
      updatedImages[packs - 1] = file;
    }
    setImages(updatedImages);
  };
  
  // useEffect(() => {
  //   if (managerId) {
  //     var myHeaders = new Headers();
  //     myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //       managerId: managerId,
  //       orderId: orderId,
  //       courierName: delivery.courierName,
  //     });

  //     var requestOptions = {
  //       method: "POST",
  //       headers: myHeaders,
  //       body: raw,
  //       redirect: "follow",
  //     };

  //     fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
  //       .then((response) => response.json())
  //       .then((result) => {
  //         if (result.status === "success") {
  //           setData(result.data);
  //           setPacker(result.data.order.pmId);
  //           // setCouriers(result.data.couriers)
  //           setPartners(result.data.courierPartners);
  //           setCourierPartners(result.data.courierPartners);
  //           setPrintLabel((prevState) => ({
  //             ...prevState,
  //             qtyPacks: result.data.order.qtyPacks,
  //             delPerName: result.data.order.delPerName,
  //             delPerMobile: result.data.order.delPerMobile,
  //             delTrackingNo: result.data.order.delTrackingNo,
  //             delTrackingUrl: result.data.order.delTrackingUrl,
  //             courierSlip: result.data.order.courierSlip,

  //           }));
  //           if (!delivery.courierName) {
  //             setDelivery((prevState) => ({
  //               ...prevState,
  //               courierName: result.data.order.courierName,
  //               methodName: result.data.order.methodName,
  //               partnerName: result.data.order.partnerName,
  //               shippingType: result.data.order.shippingType,
  //             }));
  //           }
  //           // setCourierSlip(result.data.order.courierSlip)

  //           setLoading(false);
  //         }
  //       })
  //       .catch((error) => console.log("error", error));
  //   }
  // }, [orderId, managerId, delivery.courierName, isReloaded, selectedpackingmanager, setCourierPartners]);


  // new api coll

  // const getOrder = async () => {
  //   try {
  //     const payload = {
  //       managerId: managerId,
  //       orderId: orderId,
  //       courierName: delivery.courierName,
  //     }
  //     const result = await fetchData(`dispatch/order`, payload);
  //     if (result.status === "success") {
  //       setData(result.data);
  //       setPacker(result.data.order.pmId);
  //       // setCouriers(result.data.couriers)
  //       setPartners(result.data.courierPartners);
  //       setCourierPartners(result.data.courierPartners);
  //       setPrintLabel((prevState) => ({
  //         ...prevState,
  //         qtyPacks: result.data.order.qtyPacks,
  //         delPerName: result.data.order.delPerName,
  //         delPerMobile: result.data.order.delPerMobile,
  //         delTrackingNo: result.data.order.delTrackingNo,
  //         delTrackingUrl: result.data.order.delTrackingUrl,
  //         courierSlip: result.data.order.courierSlip,

  //       }));
  //       if (!delivery.courierName) {
  //         setDelivery((prevState) => ({
  //           ...prevState,
  //           courierName: result.data.order.courierName,
  //           methodName: result.data.order.methodName,
  //           partnerName: result.data.order.partnerName,
  //           shippingType: result.data.order.shippingType,
  //         }));
  //       }
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }


  // useEffect(() => {
  //   getOrder()

  // }, [orderId, managerId, delivery.courierName, isReloaded, selectedpackingmanager, setCourierPartners])




  // -----------------Courier---------
 
 
 
  useEffect(() => {
    if (managerId && orderId && !isApiCalled) { // Check if API hasn't been called already
      setIsApiCalled(true); // Set flag to prevent further API calls
      
      const myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        managerId: managerId,
        orderId: orderId,
        courierName: delivery.courierName,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData(result.data);
            setPacker(result.data.order.pmId);
            setPartners(result.data.courierPartners);
            setCourierPartners(result.data.courierPartners);

            setPrintLabel((prevState) => ({
              ...prevState,
              qtyPacks: result.data.order.qtyPacks,
              delPerName: result.data.order.delPerName,
              delPerMobile: result.data.order.delPerMobile,
              delTrackingNo: result.data.order.delTrackingNo,
              delTrackingUrl: result.data.order.delTrackingUrl,
              courierSlip: result.data.order.courierSlip,
            }));

            if (!delivery.courierName) {
              setDelivery((prevState) => ({
                ...prevState,
                courierName: result.data.order.courierName,
                methodName: result.data.order.methodName,
                partnerName: result.data.order.partnerName,
                shippingType: result.data.order.shippingType,
              }));
            }

            setLoading(false);
          }
        })
        .catch((error) => {
          console.error("Error fetching data: ", error);
        })
        .finally(() => {
          setIsApiCalled(false); // Reset the flag after the API call completes
        });
    }
  }, [managerId, orderId, delivery.courierName, isReloaded, selectedpackingmanager]); // Dependencies of the effect

  const handleSendLink = async () => {
    setPaymentLink("");
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderId: orderId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_URL + "receipt/generatePaymentLink",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setPaymentLink(result.shortUrl);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleAcceptOrder = (e) => {
    e.preventDefault();

    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      pmId: packer ? packer : managerId,
      courierName: delivery.courierName,
      partnerName: delivery.partnerName,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handleSendLink();
        }
        setMsgShow(result.msg);
        setShowAcceptModal(true);
      })
      .catch((error) => console.log("error", error));
  };

  const orderPlacedNotification = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "online_order_placed",
      headerId: "https://dealer.wallicon.in/assets/order_placed.jpg",
      buttonDataPayload: paymentLink,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.error(error));
  };

  const orderPlacedPod = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "order_placed_pod",
      headerId: "https://dealer.wallicon.in/assets/order_placed.jpg",
      buttonDataPayload: paymentLink,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (packs) {
      setPoint(
        Array.from(
          Array(
            packs === "0"
              ? 1
              : packs === "1"
                ? 1
                : packs === "2"
                  ? 2
                  : parseInt(packs - 1)
          ).keys()
        )
      );
    }
  }, [packs]);

  const addFormFields = () => {
    point.forEach((element) => {
      setImages((prevState) => [...prevState, {}]);
    });
  };

  useEffect(() => {
    if (check && images.length < parseInt(packs)) {
      point.forEach((element) => {
        setImages((prevState) => [
          ...prevState.slice(0, images.length),
          {},
          // ...images.slice(index + 1)
        ]);
      });
    }
  }, [check, images.length, packs, point]);

  const handlePrintLabel = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      qtyPacks: printLabel.qtyPacks,
      delPerName: printLabel.delPerName,
      delPerMobile: printLabel.delPerMobile,
      delTrackingNo: printLabel.delTrackingNo,
      delTrackingUrl: printLabel.delTrackingUrl,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          handlePrint();
          setShowLabelModal(false);
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };

  // console.log('images', images);

  const handlePacked = (e) => {
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("packingQty", packs);
    images && images.map((image) => formdata.append("images", image));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL +
      "packing/orderPacked/" +
      managerId +
      "/" +
      orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          window.location.reload();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const labelRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => labelRef.current,
  });

  // useEffect(() => {
  //     if (data && data.order && data.order.dispatchStatus!=='New Order') {

  const generatePaymentLink = () => {
    setPaymentLink("");
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      orderId: orderId,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "receipt/generatePaymentLink",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setPaymentLink(result.shortUrl);
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleDispatched = () => {
    var myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

    var formdata = new FormData();
    formdata.append("shippingType", delivery.shippingType);
    formdata.append("courierName", delivery.courierName);
    formdata.append("partnerName", delivery.partnerName);

    formdata.append("delPerName", printLabel.delPerName);
    formdata.append("delPerMobile", printLabel.delPerMobile);
    formdata.append("delTrackingNo", printLabel.delTrackingNo);
    formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
    // if (courierSlip && courierSlip[0]) {
    formdata.append("image", courierSlip[0]);
    // }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL +
      "dispatch/deliveryDetails/" +
      managerId +
      "/" +
      orderId,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          if (result.data.dac === null) {
            orderDispatchedNotification();
          } else {
            orderDispatchedNotificationAuth(
              result.data.shippingInfo.name,
              result.data.orderId,
              result.data.dac
            );
          }
          setShowDeliveryModal(false);
          setIsReloaded(Math.random());
        }
        alert(result.msg);
      })
      .catch((error) => console.log("error", error));
  };

  const orderDispatchedNotification = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "online_order_dispatched",
      headerId: "https://dealer.wallicon.in/assets/dispatched.jpg",
      buttonDataPayload: paymentLink,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.error(error));
  };

  const orderDispatchedNotificationAuth = (name, id, dac) => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      phoneNumber: data.order.whatsapp,
      templateName: "delivery_auths_code",
      headerId: "https://dealer.wallicon.in/assets/dispatched.jpg",
      buttonDataPayload: paymentLink,
      dealerName: name,
      orderId: id,
      deliveryCode: dac,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (isImage) {
      setImages((prevState) => [...prevState, ...isImage]);
    }
  }, [isImage]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      // if (printLabel.courierSlip||printLabel.delTrackingNo||courierSlip) {
      handleDispatched();
      // }
      // else {
      //     alert('Please Upload Packing Slip OR Tracking Number')
      // }
    }
  };

  useEffect(() => {
    if (
      data.order &&
      data.order.shippingInfo &&
      data.order.shippingInfo.pincode
    ) {
      setLableBtnLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        keyword: data.order.shippingInfo.pincode,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.status === "success") {
            setData((prevState) => ({
              ...prevState,
              SubDistName: result.SubDistName[0] ? result.SubDistName[0] : "",
              districts: result.districts[0] ? result.districts[0] : "",
            }));
          }
          setLableBtnLoading(false);
        })
        .catch((error) => console.log("error", error));
    }
  }, [data.order]);

  const handleApproveEdit = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/editOrderApproved",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowApproveModal(false);
          navigate("/dispatchdashboard");
        }
      })
      .catch((error) => console.error(error));
  };

  const handleApproveCancel = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/cancelOrderApproved",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowApproveModal(false);
          navigate("/dispatchdashboard");
        } else {
          alert(result.msg);
          setShowApproveModal(false);
        }
      })
      .catch((error) => console.error(error));
  };

  const handleDenyEdit = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/editOrderDeny", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDenyModal(false);
          window.location.reload();
        }
      })
      .catch((error) => console.error(error));
  };
  const handleDenyCancel = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_URL + "dispatch/canceleOrderDeny",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowDenyModal(false);
          window.location.reload();
        }
      })
      .catch((error) => console.error(error));
  };

  const handleCancelOrder = () => {
    const myHeaders = new Headers();
    myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
    myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      managerId: managerId,
      orderId: orderId,
      cancelReason: cancelReason,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_URL + "dispatch/cancelOrder", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "success") {
          setShowCancelOrderModal(false);
          window.location.reload();
        } else {
          alert(result.msg);
        }
      })
      .catch((error) => console.error(error));
  };

  // console.log('data.order', data.order );
  const filterItem =
    data.order &&
    data.order.items &&
    data.order.items.length > 0 &&
    data.order.items.filter((item) => item.unit || item.qty > 0 || item.desc);

  console.log("images", images);


















  const [selectedManager, setSelectedManager] = useState(null);

  // onSelect handler for when an option is selected
  const handleManagerSelect = (option) => {
    console.log("Selected manager:", option);
    setSelectedManager(option);
  };

  // onReject handler for when the selected option is cleared
  const handleManagerReject = () => {
    console.log("Manager selection cleared");
    setSelectedManager(null);
  }




  const pakingManagers = [
    {
      "_id": "675447558efba04576a7db94",
      "name": "Sufiyan",
      "managerId": 3002
    },
    {
      "_id": "66f7a41c20f9a92f7d651f7d",
      "name": "Satyam Singh",
      "managerId": 34
    },
    {
      "_id": "66f683390747af79e35acf7b",
      "name": "Tallulah Michael",
      "managerId": 27
    },
    {
      "_id": "626bd02e32f05ece67033fdf",
      "name": "Packer 1",
      "managerId": 12
    },
    {
      "_id": "626bcfae32f05ece67033c7d",
      "name": "Packer",
      "managerId": 11
    }
  ]





  return (
    <React.Fragment>
      <div>
        <ModalWrapper
          isOpen={showDeliveryModal}
          onClose={() => setShowDeliveryModal(!showDeliveryModal)}
          closeBtn={true}
          style={{
            backgroundColor: mode ? "#2C2E33" : "white",
            width: "64vh",
            borderRadius: "20px",
            padding: "20px",
          }}
          heading={
            data.order && data.order.dispatchStatus === "Dispatched"
              ? "UPDATE DELIVERY DETAILS"
              : "DELIVERY DETAILS"
          }
          children={

            <div className={styles.text_center} style={{ position: 'relative' }}>
              <div
                style={{
                  position: 'absolute',

                  right: '20px',
                  fontSize: '30px',
                  fontWeight: '300',
                  color: mode ? 'white' : '#646B88',
                  cursor: 'pointer',
                  transition: 'all 0.3s ease',
                }}
                onClick={() => setShowDeliveryModal(false)}
              >
                &times;
              </div>


              <div
                style={{
                  color: mode ? 'white' : '#646B88',
                  fontWeight: '600',
                  fontSize: '18px',
                  padding: "2px",
                }}
              >
                Delivery Details
              </div>

              <form onSubmit={handleSubmit}>
                <div className={styles.modal_div}>
                  <div
                    style={{
                      color: mode ? 'WHITE' : 'BLACK',
                    }}
                    className="w-100 me-1"
                  >
                    <label
                      style={{
                        color: mode ? 'WHITE' : 'BLACK',
                      }}
                    >
                      &nbsp;Method
                    </label>
                    <div
                      style={{
                        color: mode ? 'WHITE' : 'BLACK',
                        backgroundColor: mode ? '#1B1D21' : 'white',
                        border: mode ? 'none' : '0.5px solid #F3F3F3',
                      }}
                      className={`${styles.input} w-100`}
                    >
                      {delivery.courierName}
                    </div>
                  </div>
                  <div className="w-100 mx-1">
                    <label
                      style={{
                        color: mode ? '#FFFFFF' : 'BLACK',
                      }}
                    >
                      &nbsp;Sub-Method
                    </label>
                    <div
                      style={{
                        backgroundColor: mode ? '#1B1D21' : 'white',
                        border: mode ? 'none' : '0.5px solid #F3F3F3',
                        color: mode ? '#FFFFFF' : 'BLACK',
                      }}
                      className={`${styles.input} w-100`}
                    >
                      {delivery.methodName}
                    </div>
                  </div>

                  <div className="d-flex flex-column w-100 ms-1">
                    <label
                      style={{
                        color: mode ? 'WHITE' : 'BLACK',
                      }}
                    >
                      {' '}
                      &nbsp;Partner
                    </label>
                    <DropDown3
                      label={"Warehouse"}
                      listLabel={"All"}
                      options={optionsPart}
                      onSelect={handlePartnerSelection}
                      onReject={handlePartnerRejection}
                    />
                  </div>
                </div>

                <div className={styles.modal_div}>
                  <input
                    style={{
                      backgroundColor: mode ? '#1B1D21' : 'white',
                      border: mode ? 'none' : '0.5px solid #F3F3F3',
                      color: mode ? '#FFFFFF' : 'BLACK',
                    }}
                    name="name"
                    type="text"
                    placeholder="Name"
                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    value={printLabel.delPerName}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delPerName: e.target.value,
                      }));
                    }}
                  />
                  <input
                    style={{
                      backgroundColor: mode ? '#1B1D21' : 'white',
                      border: mode ? 'none' : '0.5px solid #F3F3F3',
                      color: mode ? '#FFFFFF' : 'BLACK',
                    }}
                    name="mobile"
                    type="number"
                    placeholder="Mobile Number"
                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    value={printLabel.delPerMobile}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delPerMobile: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className={styles.modal_div}>
                  <input
                    style={{
                      backgroundColor: mode ? '#1B1D21' : 'white',
                      border: mode ? 'none' : '0.5px solid #F3F3F3',
                      color: mode ? '#FFFFFF' : 'BLACK',
                    }}
                    name="name"
                    type="text"
                    placeholder="Tracking Number"
                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    value={printLabel.delTrackingNo}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delTrackingNo: e.target.value,
                      }));
                    }}
                  />
                  <input
                    style={{
                      backgroundColor: mode ? '#1B1D21' : 'white',
                      border: mode ? 'none' : '0.5px solid #F3F3F3',
                      color: mode ? '#FFFFFF' : 'BLACK',
                    }}
                    name="name"
                    type="text"
                    placeholder="Tracking URL"
                    className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                    value={printLabel.delTrackingUrl}
                    onChange={(e) => {
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        delTrackingUrl: e.target.value,
                      }));
                    }}
                  />
                </div>

                <div className={styles.dragImage_div}>
                  <FileHandler2 setisImage={setCourierSlip} styles={styles} />
                </div>

                <div className={styles.uploadImage_div}>
                  <div className={styles.demoImage_div}>
                    {courierSlip || printLabel.courierSlip ? (
                      <img
                        src={
                          courierSlip
                            ? URL.createObjectURL(courierSlip[0])
                            : process.env.REACT_APP_S3URL + printLabel.courierSlip
                        }
                        className={styles.modalItem_Image}
                        alt="courierSlip"
                      />
                    ) : (
                      <img
                        src="/assets/packingImage.png"
                        className={styles.modalItem_Image}
                        alt="courier"
                      />
                    )}
                  </div>
                  <label
                    style={{
                      color: mode ? '#9C9797' : 'black',
                    }}
                    className={styles.uploadImage_btn}
                    onClick={() =>
                      window.open(URL.createObjectURL(courierSlip[0]), 'mozillaWindow', 'popup')
                    }
                  >
                    {' '}
                    Preview
                  </label>
                  <div
                    className={styles.delete_div}
                    onClick={() => {
                      setCourierSlip();
                      setPrintLabel((prevState) => ({
                        ...prevState,
                        courierSlip: '',
                      }));
                    }}
                  >
                    <span>
                      <DeleteIcon />
                    </span>
                    <span>Delete</span>
                  </div>
                </div>

                <div
                  style={{
                    marginTop: '30px',
                    marginLeft: '30px',
                    justifyContent: 'center',
                  }}
                  className={styles.modalBtn_div}
                >
                  <div style={{ width: '140px' }}>
                    <Button
                      buttonIcon={<Cancel />}
                      buttonTextColor={'white'}
                      buttonBgColor={'black'}
                      buttonText={'CANCEL'}
                      buttonClick={() => {
                        setShowDeliveryModal(false);
                      }}
                    />
                  </div>
                  <div style={{ width: '140px' }}>
                    <Button
                      buttonIcon={<Viewdispatch />}
                      buttonTextColor={'white'}
                      buttonBgColor={'#4164E3'}
                      buttonText={'DISPATCHED'}
                      buttonClick={
                        data.order && data.order.dispatchStatus === 'Dispatched'
                          ? 'UPDATE DISPATCHED'
                          : 'DISPATCHED'
                      }
                    />
                  </div>
                </div>
              </form>
            </div>

          } />
        <ModalWrapper
          isOpen={showPackedModal}
          onClose={() => setShowPackedModal(!showPackedModal)}
          closeBtn={true}
          style={{
            backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
            width: "50vh",
            borderRadius: "20px",
            padding: "20px",
            position: "relative", // Ensure the cross button is positioned absolutely inside
          }}
          children={
            <form onSubmit={handlePacked} className={styles.text_center}>
              <div className={styles.main_div}>
                {/* Cross button to close the modal */}
                <div
                  style={{
                    position: "absolute", // Position the button at the top-right corner
                    top: "10px", // Adjust the distance from the top
                    right: "10px", // Adjust the distance from the right
                    cursor: "pointer", // Change the cursor to a pointer to indicate it's clickable
                    color: mode ? "white" : "#646B88", // Color of the cross based on mode
                    fontSize: "18px", // Size of the cross button
                  }}
                  onClick={() => setShowPackedModal(false)} // Close the modal when clicked
                >
                  &#10006; {/* Cross symbol */}
                </div>

                <div
                  style={{
                    color: mode ? "white" : "#646B88",
                    fontWeight: "600",
                    fontSize: "18px",
                  }}
                >
                  {"PACKING STATUS"}
                </div>
                <div
                  style={{
                    color: mode ? "white" : "black",
                    fontWeight: "400",
                    fontSize: "18px",
                    marginTop: "5px",
                  }}
                >
                  <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
                  <input
                    style={{
                      border: "1px solid #E4E7EB",
                    }}
                    type="number"
                    name="packs"
                    value={packs}
                    onInput={(e) => (e.target.value = e.target.value.slice(0, 4))}
                    onChange={(e) => {
                      setPacks(e.target.value);
                      if (check && e.target.value && images.length > parseInt(e.target.value)) {
                        const values = [...images];
                        values.splice(
                          point,
                          images.length - (parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value))
                        );
                        setImages(values);
                      }
                    }}
                    className={styles.enterPackets}
                    required
                    autoFocus={true}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        if (packs && !check) {
                          setCheck(true);
                          addFormFields();
                        }
                      }
                    }}
                  />
                </div>

                <div
                  style={{
                    color: mode ? "white" : "black",
                    fontWeight: "400",
                    fontSize: "18px",
                    marginTop: "5px",
                  }}
                  className={styles.checkBox_addImage}
                >
                  <input
                    type="checkbox"
                    name="vehicle1"
                    value="check"
                    className={styles.checkbox}
                    checked={check ? true : false}
                    onChange={(e) => {
                      if (e.target.checked && packs) {
                        addFormFields();
                        setCheck(true);
                      } else {
                        const values = [...images];
                        values.splice(point, point.length);
                        setImages(values);
                        setCheck(false);
                      }
                    }}
                  />
                  <label htmlFor="vehicle1">
                    &nbsp;&nbsp;Add image of every pack
                  </label>
                </div>

                {/* FileHandler2 and other content */}
                <div className={styles.dragImage_div} style={{ marginBottom: "5px" }}> {/* Reduced marginBottom */}
                  <FileHandler2 setisImage={setisImage} styles={styles} />
                </div>

                {/* Image preview and delete functionality */}
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    padding: "10px",
                  }}
                >
                  {images.map((item, index) => (
                    <div key={index} className={styles.flexdiv}>y
                      <span
                        style={{
                          color: mode ? "#9C9797" : "black",
                        }}
                      >
                        Pack {index + 1}
                      </span>

                      {item.name ? (
                        <img
                          src={URL.createObjectURL(item)}
                          alt=""
                          className={styles.fileimg}
                          onClick={() =>
                            window.open(URL.createObjectURL(item), "mozillaWindow", "popup")
                          }
                        />
                      ) : (
                        <img
                          src="/assets/packingImage.png"
                          alt="packing_order_image"
                          className={styles.packingImage}
                        />
                      )}

                      <div
                        style={{
                          color: mode ? "#9C9797" : "black",
                        }}
                        className={styles.view_packingImage}
                        onClick={() =>
                          window.open(URL.createObjectURL(item), "mozillaWindow", "popup")
                        }
                      >
                        Preview
                      </div>

                      <div
                        style={{
                          color: "red",
                          fontWeight: "400",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          setPacks(parseInt(packs) - 1);
                          const list = [...images];
                          list.splice(index, 1);
                          setImages(list);
                        }}
                      >
                        <DeleteFile color={"red"} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div
                style={{
                  width: "25vh",
                  marginTop: "5px", // Reduced marginTop between file handler and buttons
                }}
                className={styles.packingModal_btn}
              >
                <Button
                  buttonIcon={<Cancel />}
                  buttonBgColor={"black"}
                  buttonText={"NO"}
                  buttonTextColor={"white"}
                  buttonClick={() => setShowPackedModal(false)}
                />
                <Button
                  buttonIcon={<TemplateDone />}
                  buttonBgColor={"#4164E3"}
                  buttonText={"YES"}
                  buttonTextColor={"white"}
                />
              </div>
            </form>
          }
        />


        <ModalWrapper
          isOpen={showLabelModal}
          onClose={() => setShowLabelModal(!showLabelModal)}
          style={{
            borderRadius: "20px", // Add slight rounding to the modal
            backgroundColor: mode ? "white" : "black",
            width: "45vh",
          }}
        >
          <div
            className={styles.text_center}
            style={{
              backgroundColor: mode ? "#2C2E33" : "#fff",
              color: mode ? "#fff" : "#000",
              borderRadius: "10px", // Ensure internal content respects modal rounding
              padding: "20px", // Add padding for aesthetics
              position: "relative", // Ensure absolute elements inside stay correct
            }}
          >
            {/* Cross Icon to Close Modal */}
            <div
              style={{
                position: "absolute",
                top: "15px",
                right: "10px",
                cursor: "pointer",
                fontSize: "22px",
                color: mode ? "white" : "#646B88",
              }}
              onClick={() => setShowLabelModal(false)}
              title="Close"
            >
              &times;
            </div>

            <p
              style={{
                fontSize: "18px",
                fontWeight: "600",
                color: mode ? "white" : "#646B88",
                fontWeight: "bold", // Make the font bold
              }}
            >
              Print Label
            </p>
            <p
              style={{
                color: mode ? "white" : "black",
                fontSize: "16px",
                fontWeight: "400",
                marginBottom: "10px", // Reduce space between paragraph and inputs
              }}
              className={styles.sliptext}
            >
              Are you sure you want to Print Label & Marked as Packed?
            </p>

            {/* Input for Number of Packs */}
            <div className={styles.modal_div}>
              <p
                style={{
                  color: mode ? "white" : "black",
                  fontSize: "16px",
                  fontWeight: "400",
                  marginBottom: "5px", // Reduced margin
                }}
                className={styles.sliptext}
              >
                Enter Number Of Packs
              </p>
              <input
                name="name"
                type="number"
                className={`${switchDisplay ? styles.inputcolorpack : styles.inputpack}`}
                value={printLabel.qtyPacks}
                onChange={(e) =>
                  setPrintLabel((prevState) => ({
                    ...prevState,
                    qtyPacks: e.target.value,
                  }))
                }
                style={{
                  backgroundColor: mode ? "#232529" : "#fff",
                  color: mode ? "#9F9F9F" : "#000", // Black text for visibility
                  borderRadius: "5px", // Match modal rounding style
                  padding: "10px",
                  width: "10%", // Adjust input width
                  boxSizing: "border-box",
                  border: mode ? "#232529" : "1px solid #D9D9D9",
                  marginBottom: "15px", // Decreased bottom margin to reduce space
                }}
              />
            </div>

            {/* Other input fields */}
            <div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Name"
                  className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                  value={printLabel.delPerName}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerName: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000", // Black text for visibility
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                    marginBottom: "10px", // Reduced margin
                  }}
                />
                <input
                  name="mobile"
                  type="number"
                  placeholder="Mobile Number"
                  className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                  value={printLabel.delPerMobile}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delPerMobile: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000", // Black text for visibility
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "10px", // Reduced margin
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                  }}
                />
              </div>
              <div className={styles.modal_div}>
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking Number"
                  className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                  value={printLabel.delTrackingNo}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingNo: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000", // Black text for visibility
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "10px", // Reduced margin
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                  }}
                />
                <input
                  name="name"
                  type="text"
                  placeholder="Tracking URL"
                  className={`${switchDisplay ? styles.inputcolor : styles.input}`}
                  value={printLabel.delTrackingUrl}
                  onChange={(e) =>
                    setPrintLabel((prevState) => ({
                      ...prevState,
                      delTrackingUrl: e.target.value,
                    }))
                  }
                  style={{
                    backgroundColor: mode ? "#232529" : "#fff",
                    color: mode ? "#9F9F9F" : "#000", // Black text for visibility
                    borderRadius: "5px",
                    padding: "10px",
                    width: "100%",
                    marginBottom: "10px", // Reduced margin
                    border: mode ? "#232529" : "1px solid #D9D9D9",
                  }}
                />
              </div>
            </div>

            {/* Buttons */}
            <div
              className={styles.modalBtn_div}
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginLeft: "20px",
                marginTop: "20px", // Decreased margin-top to bring buttons closer
              }}
            >
              <div>
                <Button
                  buttonIcon={<Cancel />}
                  buttonText={"Cancel"}
                  buttonTextColor={"white"}
                  buttonBgColor={"black"}
                  buttonClick={() => setShowLabelModal(false)}
                  style={{
                    width: "5px",
                    height: "50px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                />
              </div>

              <div>
                <Button
                  buttonIcon={<Label />}
                  buttonTextColor={"white"}
                  buttonBgColor={"#4164E3"}
                  buttonText={"PRINT"}
                  disabled={!printLabel.qtyPacks}
                  buttonClick={() => handlePrintLabel()}
                  style={{
                    width: "150px",
                    height: "50px",
                    borderRadius: "5px",
                    fontSize: "16px",
                    fontWeight: "600",
                  }}
                />
              </div>
            </div>
          </div>
        </ModalWrapper>
      </div>
      <ModalWrapper
        isOpen={showApproveModal}
        onClose={() => setShowApproveModal(!showApproveModal)}
        closeBtn={true}
        style={{
          backgroundColor: mode ? "#2C2E33" : "white",
          borderRadius: "20px",
          width: "40vh",
          position: "relative", // For positioning the close button
        }}
        children={
          <div className={styles.text_center}>
            {/* Close button (cross) */}
            <button
              onClick={() => setShowApproveModal(false)}
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                background: "transparent",
                border: "none",
                fontSize: "30px",
                color: mode ? "white" : "#646B88",
                cursor: "pointer",
              }}
            >
              ×
            </button>

            <h1
              style={{
                fontSize: "18px",
                padding: "10px",
                marginTop: "10px",
                color: mode ? "white" : "#646B88",

              }}
            >
              {" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "CANCEL ORDER REQUEST"
                  : "EDIT ORDER REQUEST")}
            </h1>
            <p
              style={{
                fontSize: "16px",
                padding: " 10px 20px 10px",
                fontWeight: "500",
                color: mode ? "white" : "black",
              }}
              className={styles.message}
            >
              Are you sure you want to Approve this{" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "Cancel"
                  : "Edit")}{" "}
              Order Request?
            </p>
            <div style={{
              gap: "10px",
              display: "flex",
              // marginBottom: "20px",
              marginLeft: "120px",
              // margin:"  auto ",
              width: "90px",
              // margin:"auto",
              marginBottom: "20px",
            }} >
              <Button
                buttonIcon={<Cancel />}

                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonBgColor={"black"}
                buttonClick={() => setShowApproveModal(false)}
              />
              <Button
                buttonIcon={<TemplateDone />}
                buttonText={"YES"}
                buttonTextColor={"white"}
                buttonBgColor={"#4164E3"}
                buttonClick={() => {
                  if (
                    data.order &&
                    data.order.reqType === "Request For Cancel"
                  ) {
                    handleApproveCancel();
                  } else {
                    handleApproveEdit();
                  }
                }}
              />
            </div>
          </div>
        } />
      <ModalWrapper
        isOpen={showDenyModal}
        onClose={() => setShowDenyModal(!showDenyModal)}
        closeBtn={true}
        style={{
          backgroundColor: mode ? "#2C2E33" : "white",
          width: "40vh",
          borderRadius: "20px",
          position: "relative", // Ensures that the cross icon can be positioned correctly inside the modal
        }}
        children={
          <div className={styles.text_center}>
            {/* Cross Icon to Close Modal */}
            <span
              onClick={() => setShowDenyModal(false)}
              style={{
                position: "absolute",
                top: "18px",
                right: "20px",
                fontSize: "18px",
                color: mode ? "white" : "#646B88",
                cursor: "pointer",
              }}
            >
              &#10006;
            </span>

            <div style={{
              fontSize: "18px",
              padding: "10px",
              marginTop: "10px",
              fontWeight: "500",
              color: mode ? "white" : "#646B88",

            }}>
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "DENY CANCEL ORDER REQUEST"
                  : "DENY EDIT ORDER REQUEST")}
            </div>
            <p style={{
              fontSize: "14px",
              // padding: " 10px 20px 10px",
              fontWeight: "500",
              color: mode ? "white" : "black",
            }} className={styles.message}>
              Are you sure you want to Deny this{" "}
              {data.order &&
                (data.order.reqType === "Request For Cancel"
                  ? "Cancel"
                  : "Edit")}{" "}
              Order Request?
            </p>
            <div style={{
              gap: "10px",
              display: "flex",
              marginBottom: "20px",
              marginLeft: "120px",
              // margin:" 5px 20px ",
              // paddingBottom: "20px",
              // margin:"  auto ",
              width: "90px",
              // margin:"auto",
              // marginBottom: "20px",
            }}   >
              <Button
                buttonIcon={<Cancel />}
                buttonBgColor={"black"}
                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonClick={() => setShowDenyModal(false)}
              />
              <Button
                buttonIcon={<TemplateDone />}
                buttonBgColor={"#4164E3"}
                buttonText={"YES"}
                buttonTextColor={"white"}
                buttonClick={() => {
                  if (
                    data.order &&
                    data.order.reqType === "Request For Cancel"
                  ) {
                    handleDenyCancel();
                  } else {
                    handleDenyEdit();
                  }
                }}
              />
            </div>
          </div>
        } />
      <ModalWrapper
        isOpen={showAcceptModal}
        onClose={() => setShowAcceptModal(!showAcceptModal)}
        closeBtn={true}
        style={{
          backgroundColor: mode ? "#2C2E33" : "white",
          width: "35vh",
          padding: "15px",
          borderRadius: "20px",
          position: "relative", // To position the close icon in the top-right corner
        }}
        children={
          <div className={styles.text_center}>

            <div
              style={{
                position: "absolute",
                fontSize: "18px",

                top: "15px",
                right: "20px",  // Shift the cross icon 20px to the left

                fontSize: "21px",
                cursor: "pointer",
                color: mode ? "white" : "#9C9797",
                fontWeight: "bold", // Making the cross bold
                // marginRight:"30px",
              }}
              onClick={() => setShowAcceptModal(false)}
            >
              &times;
            </div>

            <h1
              style={{
                fontWeight: "500",
                color: mode ? "white" : "#646B88",
                fontSize: "18px",
                padding: "5px",
              }}
            >
              {"DISPATCH ORDER STATUS"}
            </h1>
            <p
              style={{
                fontWeight: "500",
                color: mode ? "white" : "black",
                fontSize: "14px",
              }}
              className={styles.message}
            >
              {/* {msgShow} */}
              Are you sure want to accept this order?
            </p>
            <div style={
              {
                display: "flex",
                marginLeft: "80px",
                gap: "10px",
              }
            } >
              <div style={
                {

                }
              }>
                 <Button

                  // buttonIcon={<Cancel />}
                  // buttonText={"NO"}
                  // buttonBgColor={"black"}
                  // buttonTextColor={"white"}
                  // buttonClick={() => {
                  //   data.order.paymentMethod === "POD"
                  //     ? orderPlacedPod()
                  //     : orderPlacedNotification();
                  //   setTimeout(() => {
                  //     setShowAcceptModal(false);
                  //     window.location.reload();
                  //   }, 1000);
                  // }}
                  buttonIcon={<Cancel />}
                buttonBgColor={"black"}
                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonClick={() => setShowAcceptModal(false)}

                />
                </div>
              <div style={
                {

                }
              }>  <Button
                  buttonIcon={<TemplateDone />}
                  buttonText={"YES"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"white"}
                  buttonClick={() => {
                    data.order.paymentMethod === "POD"
                      ? orderPlacedPod()
                      : orderPlacedNotification();
                    setTimeout(() => {
                      setShowAcceptModal(false);
                      window.location.reload();
                    }, 1000);
                  }}
                /></div>
            </div>
          </div>
        } />
      <ModalWrapper
        isOpen={showCancelOrderModal}
        onClose={() => setShowCancelOrderModal(!showCancelOrderModal)}
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          position: "relative",
          width: "40vh",
        }}
      >
        {/* Close Button */}
        <button
          onClick={() => setShowCancelOrderModal(false)}
          style={{
            position: "absolute",
            top: "10px",
            right: "20px", // Shift the cross icon 20px to the left
            fontSize: "21px",
            background: "transparent",
            border: "none",
            color: mode ? "#fff" : "#000",
            cursor: "pointer",
          }}
          aria-label="Close"
        >
          &times; {/* Unicode for "X" */}
        </button>

        <div
          style={{
            backgroundColor: mode ? "#2C2E33" : "#FFFFFF",
            color: mode ? "#fff" : "#000",
            borderRadius: "10px",
            padding: "10px",
          }}
          className={styles.text_center}
        >
          <p
            style={{
              fontWeight: "bold",
              color: mode ? "#FFFFFF" : "#646B88",
              fontWeight: "500",
              fontSize: "18px",
            }}
          >
            CANCEL ORDER
          </p>
          <p className={styles.message}>
            Are You Sure Want To Cancel This Order?
          </p>
          <input
            type="text"
            aria-rowcount={2}
            placeholder="Enter "
            className={styles.reqIssue}
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
            style={{
              backgroundColor: mode ? "#232529" : "white",
              color: mode ? "#9F9F9F" : "black",
              borderRadius: "5px",
              width: "50%",
              border: "1px solid #D9D9D9",
              textAlign: "center",
              marginBottom: "20px",
            }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "8px", // Decreased the gap between the input and buttons
              marginTop: "8px", // Reduced top margin to bring buttons closer
              marginLeft: "5px",
            }}
          >
            <div style={{ width: "100px" }}>
              <Button
                buttonIcon={<CancelIcon2 />}
                buttonText={"NO"}
                buttonTextColor={"white"}
                buttonBgColor={"black"}
                buttonClick={() => setShowCancelOrderModal(false)}
              />
            </div>
            <div style={{ width: "100px" }}>
              <Button
                buttonIcon={<TemplateDone />}
                buttonText={"Yes"}
                buttonTextColor={"white"}
                buttonBgColor={"#4164E3"}
                buttonClick={() => {
                  handleCancelOrder();
                }}
                style={{
                  backgroundColor: "blue",
                  color: "#fff",
                  border: "none",
                  borderRadius: "5px",
                }}
              />
            </div>
          </div>
        </div>
      </ModalWrapper>
      {loading ? (
        <div style={{ width: "100%", height: "100%", padding: "10px", }}>
          <div style={{ padding: "10px", backgroundColor: mode ? "" : "#fff", width: "100%", height: "100%", position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "space-between", }}>
              <div style={{ width: "100%" }}>  <div style={{ width: "200px" }}><Skeleton lines={1} thickness={4} /></div></div>
              <div style={{ width: "100%" }}>  <div style={{ width: "200px", float: "right" }}><Skeleton lines={1} thickness={4} /></div></div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
              <div style={{ width: "100%" }}>  <div style={{ width: "80%" }}><Skeleton lines={8} thickness={2} /></div></div>
              <div style={{ width: "100%" }}>  <div style={{ width: "80%", float: "right" }}><Skeleton lines={8} thickness={2} /></div></div>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between", marginTop: "30px" }}>
              <div style={{ width: "100%" }}>  <div style={{ width: "100%" }}><Skeleton lines={8} thickness={3} /></div></div>
            </div>
          
          </div>

        </div>
      ) : (
        <div className={styles.mainCon}>
          <div
            className={styles.voucher_maindiv}
            style={{
              backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
              color: mode ? "#fff" : "#1B1D21",
            }}
          >
            <div
              className={styles.packingVoucherDiv}
              style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}
            >
              <div
                className={styles1.dispatch_top_left}
                style={{
                  position: "relative",
                  top: "-8px",
                  paddingTop: "0",
                  width: "100%",
                  display: "flex",

                  justifyContent: "space-between",
                  backgroundColor: mode ? "#2C2E33" : "#F8F9FC",

                  padding: "10px",
                }}
              >
                <div className={styles.mgx_top}>
                  <div className={styles1.mgx_top_left}>
                    <div
                      className={styles.mgx_top_left_go_back}
                      onClick={() => navigate(-1)}
                    >
                      <TestTopBackArrowIcon
                        color={mode ? "#749AFF" : "#4164E3"}
                      />
                      <p
                        className={styles1.mgx_top_left_go_back_text}
                        style={{
                          color: mode ? "#749AFF" : "#4164E3",
                        }}
                      >
                        Go Back
                      </p>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "7px",
                      }}
                    >
                      {" "}
                      <div>
                        <TestTopDotIcon color={mode ? "#9C9797" : "#E4E7EB"} />
                      </div>
                      <div>
                        <p
                          className={styles1.mgx_top_left_path_text}
                          style={{
                            color: mode ? "#9C9797" : "#646B88",
                          // marginBottom:"20px",
                          }}
                        >
                          {designation === "superadmin"
                            ? "Packing Voucher"
                            : "Dashboard"}
                        </p>
                      </div>{" "}
                    </div>
                  </div>
                </div>
                <div
                  className={`${data?.order?.dispatchStatus === "New Order"
                    ? styles.status_blue
                    : data?.order?.dispatchStatus === "Packed"
                      ? styles.statuscolor3
                      : data?.order?.dispatchStatus === "Dispatched"
                        ? styles.statuscolor4
                        : data?.order?.dispatchStatus === "Not Packed"
                          ? styles.statuscolor1
                          : data?.order?.dispatchStatus === "In Progress"
                            ? styles.statuscolor9
                            : data?.order?.dispatchStatus === "Cancelled"
                              ? styles.statuscolor8
                              : data?.order?.dispatchStatus === "Pending"
                                ? styles.statuscolor12
                                : ""
                    }`}

                  style={{
                    borderRadius: "3px",
                    padding: "5px 8px",
                    backgroundColor: mode ? "#1B1D21" : "white",
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    width: "15vh",
                    justifyContent: "center",
                    height: "5vh",
                  
                  }}
                >
                  <div
                    style={{
                      borderRadius: "2px solid green",
                    }}
                  >
                    <Statusicon status={data?.order?.dispatchStatus} />
                  </div>
                  {data?.order?.dispatchStatus}
                </div>
              </div>
            </div>

            <div
              style={{
                // padding: "10px",
                paddingTop: "0",  // Remove the top padding
                paddingLeft: "10px",  // Keep left padding
                paddingRight: "10px",  // Keep right padding
                paddingBottom: "10px",  // Keep bottom padding
                // marginBottom:"80px",
                backgroundColor: mode ? "#2C2E33" : "#F8F9FC",
              }}
            >
              {" "}
              <div
                style={{
                  backgroundColor: mode ? "#232529" : "white",


                }}
                className={styles.voucher_flex}
              >
                <div className={styles.voucherFlexDirection}>
                  <h2
                    style={{
                      color: mode ? " white" : "black",
                    }}
                    className={styles.slipheading_div}
                  >
                    {data?.order && data?.order?.storeName}
                  </h2>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                    
                    }}
                    className={styles.sliptext1}
                  >
                    Ship To:&nbsp;
                    <b
                      style={{
                        color: mode ? " white" : "black",
                      }}
                      className={styles.sliptext}
                    >
                      {data?.order?.shippingInfo && data?.order?.shippingInfo?.name}
                    </b>
                  </span>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                    }}
                    className={styles.sliptext1}
                  >
                    Address:&nbsp;
                    <b
                      style={{
                        color: mode ? " white" : "black",
                      }}
                      className={styles.sliptext}
                    >
                      {data?.order?.shippingInfo &&
                        data?.order?.shippingInfo.street +
                        ", " +
                        data?.order?.shippingInfo?.city +
                        ", " +
                        data?.order?.shippingInfo?.district +
                        ", " +
                        data?.order?.shippingInfo?.state +
                        ", " +
                        data?.order?.shippingInfo?.pincode}
                    </b>
                  </span>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                    }}
                    className={styles.sliptextGustin}
                  >
                    GSTUIN:&nbsp;
                    <b
                      style={{
                        color: mode ? " white" : "black",
                      }}
                    >
                      {data?.order && data?.order?.gstuin}
                    </b>
                  </span>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                    }}
                    className={styles.sliptext1}
                  >
                    Contact:&nbsp;
                    {data?.order?.shippingInfo && data?.order?.shippingInfo?.mobile}
                  </span>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                    }}
                    className={styles.sliptext1}
                  >
                    Order Manager:&nbsp;{data.order && data.order.ordermanager}
                  </span>
                  <span
                    style={{
                      color: mode ? " white" : "black",
                    }}
                    className={styles.sliptext}
                  >
                    Packing Manager:&nbsp;
                    {data?.order && data?.order?.packermanager}
                  </span>
                </div>
                <div className={styles.packingVoucher_left}>
                  <div className={styles.voucher_flex1}>
                    <span
                      style={{
                        color: mode ? " #FFFFFF" : "black",
                        marginTop:"10px",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Voucher No:{data?.order && data?.order?.orderId}
                    </span>
                  </div>
                  <div className={styles.voucher_flex}>
                    <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Voucher Date : {data?.order && data?.order?.orderDate}
                    </span>
                  </div>
                  <div className={styles.voucher_flex}>
                    <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Delivery Type :{data?.order && data?.order?.shippingType}
                    </span>
                  </div>
                  <div className={styles.voucher_flex}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: "20px",
                      }}
                    >
                      <span
                        style={{
                          color: mode ? "#FFFFFF" : "black",
                          whiteSpace: "nowrap",
                        }}
                        className={styles.slipDetailsTxt}
                      >
                        Delivery Method:
                      </span>
                      <div
                        style={{
                          color: mode ? "#FFFFFF" : "black",
                        }}
                        className={`${styles.slipDetailsDataSelect} py-2 px-6 `}
                      >
                        {delivery.courierName
                          ? delivery.courierName
                          : data?.order?.courierName}
                      </div>
                    </div>
                  </div>


                  <div
                    className={styles.voucher_flex1}

                  >
                    <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Sub Method :{" "}
                      {delivery.partnerName
                        ? delivery.partnerName
                        : data?.order?.partnerName}
                    </span>
                    <div
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={`${styles.slipDetailsDataSelect1} py-2 px-1`}
                    ></div>
                  </div>
                  <div className={styles.voucher_flex}>
                    <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Order Manager :{data?.order && data?.order?.managercontact}
                    </span>
                  </div>
                  <div className={styles.voucher_flex}>
                    <span
                      style={{
                        color: mode ? "#FFFFFF" : "black",
                        marginBottom: "10px",
                      }}
                      className={styles.slipDetailsTxt}
                    >
                      Packing Manager :{data?.order && data?.order?.pmcontact}
                    </span>
                  </div>{" "}
                </div>{" "}
              </div>
            </div>
            

            <div className={styles.packingvouchertablediv}

            >
              <table className={styles.packingvouchertable}

              >
                <thead>
                  <tr
                    style={{
                      borderBottom: "2px solid #646B88",
                      border: "2px solid #ddd",
                    }}
                  >
                    <th className={styles.packingvoucherth}
                      style={{
                        backgroundColor: mode ? "#232529" : "#D9DCE5",
                        color: mode ? "#fff" : "#646B88",

                      }}
                    >
                      S.No.
                    </th>
                    <th className={styles.packingvoucherth}
                      style={{
                        backgroundColor: mode ? "#232529" : "#D9DCE5",
                        color: mode ? "#fff" : "#646B88",

                      }}
                    >
                      Item Number
                    </th>
                    <th className={styles.packingvoucherth}
                      style={{
                        backgroundColor: mode ? "#232529" : "#D9DCE5",
                        color: mode ? "#fff" : "#646B88",

                      }}
                    >
                      Product
                    </th>

                    <th className={styles.packingvoucherth}
                      style={{
                        backgroundColor: mode ? "#232529" : "#D9DCE5",
                        color: mode ? "#fff" : "#646B88",

                      }}
                    >
                      Warehouse
                    </th>
                    <th className={styles.packingvoucherth}
                      style={{
                        backgroundColor: mode ? "#232529" : "#D9DCE5",
                        color: mode ? "#fff" : "#646B88",

                      }}
                    >
                      Quantity
                    </th>
                    <th className={styles.packingvoucherth}
                      style={{
                        backgroundColor: mode ? "#232529" : "#D9DCE5",
                        color: mode ? "#fff" : "#646B88",

                      }}
                    >
                      Unit
                    </th>
                  </tr>
                </thead>
                <tbody className={styles.packingvouchertbody}
                  style={{
                    backgroundColor: mode ? "#1B1D21" : "#fff",
                    color: mode ? "#fff" : "#000",
                    // marginTop: "5px",
                  }}
                >
                  {filterItem &&
                    filterItem.map((val, key) => (
                      <tr className={styles.packingvouchertr}
                        key={key}
                        style={{
                          borderBottom: "1px solid #646B88",
                        }}
                      >
                        <td className={styles.packingvouchertd} >
                          {key + 1}
                        </td>
                        <td className={styles.packingvouchertd}>
                          {val.itemNo}
                        </td>
                        <td className={styles.packingvouchertd}>
                          {val.product_name}
                        </td>
                        <td className={styles.packingvouchertd}>
                          {val.warehouse}
                        </td>
                        <td className={styles.packingvouchertd}>
                          {val.qty}
                        </td>
                        <td className={styles.packingvouchertd}>
                          {val.unit || "Pcs"}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            <div  >
              <div className={styles.packingdispachstatusdiv}
              // style={{
              //   display: "flex",
              //   justifyContent: "flex-end",
              //   width: "100%",
              //   boxShadow: "0 -4px 12px rgba(0, 0, 0, 0.2)",
              //   padding: "10px",
              //   borderTopLeftRadius: "10px",
              //   borderTopRightRadius: "10px",
              //   height: "auto",
              //   position: "absolute",
              //   bottom: "0",
              // }}
              >
                {data?.order?.dispatchStatus === "New Order" && (
                  <>
                    <div className={styles.neworderbottom}
                      style={{
                        // width: "100%",
                        // display: "flex",
                        // justifyContent: "space-between",
                        // flexDirection: "row",
                        // marginBottom:"30px",
                      }}
                    >
                      <div className={styles.packerdiv}

                      >
                        <div className={styles.totalitemdiv}

                        >
                          <div style={{
                            color: mode ? "#9C9797" : "#646B88",
                            marginLeft: "10px",
                          }
                          }>Total Items: {data.order.items.length}</div>
                        </div>
                        <div
                          style={{
                            color: mode ? "#FBFBFB" : "#646B88",
                            marginLeft: "10px",

                          }}
                          className={styles.select_box}
                        >
                          Select Packer
                        </div>


                        <div className={styles.DropDown2div} >
                          <DropDown2
                            options={pakingManagers}
                            listLabel="Self"
                            onSelect={handleManagerSelect}
                            onReject={handleManagerReject}
                          />
                        </div>






                        {console.log("bdciuguigciudgc", optionsPack)
                        }
                      </div>
                    </div>
                  </>
                )}
                <div
                  className={styles.btn_div}
                  style={{
                    // display: "flex",
                    // justifyContent: "flex-start",
                    // gap: "10px",
                    // padding: "5px",
                    // position: "relative",                    // height: "auto",

                  }}
                >
                  <div
                    style={{
                      width: "100px",
                      position: "relative",
                      // marginTop:"10PX",
                      marginBottom: "15PX",
                    }}
                  >
                    <Button
                      buttonIcon={<Back />}
                      buttonBgColor={"black"}
                      buttonTextColor={"white"}
                      buttonText={"BACK"}
                      buttonClick={() => window.history.back()}
                    />
                  </div>

                  {data?.order?.reqStatus === "" &&
                    data?.order?.dispatchStatus !== "New Order" &&
                    data?.order?.dispatchStatus !== "Cancelled" && (
                      <div
                        style={{
                          position: "relative",
                          marginBottom: "15PX",

                        }}
                      >
                        <Button
                          buttonIcon={<Cancel />}
                          buttonBgColor={"black"}
                          buttonTextColor={"white"}
                          buttonText={"Cancel Order"}
                          buttonClick={() => {
                            setShowCancelOrderModal(true);
                          }}
                        />
                      </div>
                    )}

                  {data?.order?.dispatchStatus === "New Order" ? (
                    <div
                      style={{
                        width: "100px",
                        position: "relative",
                        marginBottom: "15PX",

                      }}
                    >
                      <Button
                        buttonIcon={<TemplateDone />}
                        buttonBgColor={"#4164E3"}
                        buttonTextColor={"white"}
                        buttonText={"Accept"}
                        buttonClick={handleAcceptOrder}
                      />
                    </div>
                  ) : (data?.order?.pmId === parseInt(managerId) ||
                    designation === "superadmin") &&
                    data?.order?.packingStatus !== "Packed" ? (
                    <>
                      {data?.order?.reqStatus === "Pending" ? (
                        <>
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "15PX",

                                }}
                              >
                                <Button
                                  buttonText={"APPROVE EDIT REQUEST"}
                                  buttonClick={() => {
                                    setShowApproveModal(true);
                                  }}
                                  css={styles.editRequestButton}
                                />

                                <Button
                                  buttonIcon={<Cancel />}
                                  buttonBgColor={"black"}
                                  buttonTextColor={"white"}
                                  buttonText={"Cancel"}
                                  buttonClick={() => {
                                    setShowCancelOrderModal(true);
                                  }}
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <RedOutButton
                                btnType="button"
                                title={"DENY EDIT REQUEST"}
                                handleSubmit={() => {
                                  setShowDenyModal(true);
                                }}
                                css="px-3 py-2"
                              />
                            )}
                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <Button

                                btnType="button"
                                buttonBgColor={"black"}
                                buttonTextColor={"white"}
                                buttonIcon={<Cancel />}
                                buttonText={"APPROVE CANCEL REQUEST"}
                                buttonClick={() => {
                                  setShowApproveModal(true);
                                }}
                                css={styles.editRequestButton}
                              />
                            )}
                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <Button
                                buttonIcon={<Canceldeny />}
                                buttonBgColor={"black"}
                                buttonTextColor={"white"}
                                buttonText={"DENY CANCEL REQUEST"}
                                buttonClick={() => {
                                  setShowDenyModal(true);
                                }}
                                css="px-3 py-2"
                              />
                            )}
                        </>
                      ) : (
                        <>
                          {data?.order?.dispatchStatus !== "Cancelled" && (
                            <>
                              <ReactToPrint
                                trigger={() => (
                                  <div
                                    style={{
                                      position: "relative",
                                      marginBottom: "15PX",

                                    }}
                                  >
                                    <Button
                                      buttonBgColor={"black"}
                                      buttonTextColor={"white"}
                                      buttonIcon={<Pakingslip />}
                                      buttonText={"PACKING SLIP"}
                                      buttonClick={(e) => {
                                        setPrintLabel((prevState) => ({
                                          ...prevState,
                                          qtyPacks: e.target.value,
                                        }));
                                      }}
                                      title={
                                        <div className={styles.btn}>
                                          <Printbtn />
                                          &nbsp;&nbsp;PACKING SLIP
                                        </div>
                                      }
                                    />
                                  </div>
                                )}
                                content={() => componentRef}
                              />
                              <div style={{ display: "none" }}>
                                <PackingSlip
                                  orderId={orderId}
                                  managerId={managerId}
                                  delivery={delivery}
                                  ref={(el) => (componentRef = el)}
                                />
                              </div>
                              <div
                                style={{
                                  width: "80px",
                                  position: "relative",
                                  marginBottom: "15PX",

                                }}
                              >
                                <Button
                                  buttonBgColor={"black"}
                                  buttonTextColor={"white"}
                                  buttonIcon={<Pack />}
                                  buttonText={"PACK"}
                                  buttonClick={() => {
                                    setShowPackedModal(true);
                                  }}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {data?.order?.dispatchStatus === "Dispatched" ? (

                        <div style={{
                          marginBottom: "15PX",
                        }}>   <Button
                            buttonBgColor={"#000"}
                            buttonIcon={<Viewdispatch />}
                            buttonText={"View Dispatch Details"}
                            buttonTextColor={"white"}
                            buttonClick={() =>
                              navigate("/viewdispatchdetails/" + orderId)
                            }

                          /></div>
                      ) : data?.order?.reqStatus === "Pending" ? (
                        <>
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "15PX",

                                }}
                              >
                                <Button
                                  buttonText={"APPROVE EDIT REQUEST"}
                                  buttonClick={() => {
                                    setShowApproveModal(true);
                                  }}
                                  css={styles.editRequestButton}
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Edit" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "15PX",

                                }}
                              >
                                <Button
                                  buttonText={"DENY EDIT REQUEST"}
                                  buttonClick={() => {
                                    setShowDenyModal(true);
                                  }}
                                  css="px-3 py-2"
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "15PX",

                                }}
                              >
                                <Button
                                  buttonIcon={<Cancel />}
                                  buttonBgColor={"black"}
                                  buttonTextColor={"white"}
                                  buttonText={"APPROVE CANCEL REQUEST"}
                                  buttonClick={() => {
                                    setShowApproveModal(true);
                                  }}
                                  css={styles.editRequestButton}
                                />
                              </div>
                            )}
                          {data?.order?.reqType === "Request For Cancel" &&
                            data?.order?.reqStatus !== "Approved" && (
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "15PX",

                                }}
                              >
                                <Button
                                  buttonIcon={<Canceldeny />}
                                  buttonBgColor={"#4164E3"}
                                  buttonTextColor={"white"}
                                  buttonText={"DENY CANCEL REQUEST"}
                                  buttonClick={() => {
                                    setShowDenyModal(true);
                                  }}
                                  css="px-3 py-2"
                                />
                              </div>
                            )}
                        </>
                      ) : (
                        <>
                          {data.order.dispatchStatus !== "Cancelled" && (
                            <div
                              style={{
                                position: "relative",
                                marginBottom: "15PX",

                              }}
                            >
                              <Button
                                buttonIcon={<Canceldeny />}
                                buttonText={"DELIVERY DETAILS"}
                                // buttonIcon={</>}
                                buttonBgColor={"black"}
                                buttonTextColor={"white"}
                                disabled={
                                  data.order.dispatchStatus === "Cancelled"
                                    ? true
                                    : false
                                }
                                buttonClick={() => {
                                  setPrintLabel((prevState) => ({
                                    ...prevState,
                                    delPerName:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.partnerName,
                                    delPerMobile:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.contactNumber,
                                    delTrackingNo:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.trackingNumber,
                                    delTrackingUrl:
                                      partners &&
                                      partners.find(
                                        (x) =>
                                          x.partnerName === delivery.partnerName
                                      )?.trackingUrl,
                                  }));
                                  setShowDeliveryModal(true);
                                  generatePaymentLink();
                                }}
                              />
                            </div>
                          )}
                        </>
                      )}
                      {data.order.reqStatus === "Pending" ? (
                        ""
                      ) : (
                        <div
                          style={{
                            position: "relative",
                            marginBottom: "15PX",

                          }}
                        >
                          {" "}
                          <Button
                            buttonTextColor={"white"}
                            buttonBgColor={"#4164E3"}
                            disabled={
                              data.order.dispatchStatus === "Cancelled" ||
                                loading
                                ? true
                                : false
                            }
                            buttonText={
                              <>
                                {lableBtnLoading ? (
                                  <Spinner size="15" />
                                ) : (
                                  <div>

                                    <Label />
                                    &nbsp;&nbsp;LABEL
                                  </div>
                                )}
                              </>
                            }
                            buttonClick={() => {
                              setShowLabelModal(true);
                            }}
                          />
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default PackingVoucher;

// import React, { useEffect, useRef, useState } from 'react'
// import ReactToPrint, { useReactToPrint } from 'react-to-print';
// import { useAuth } from '../../components/context/Auth';
// import { DeleteFile, DeleteIcon, DownArrowCircle, Printbtn, Spinner, UpArrowCircle } from '../../components/icons/Icon';
// import styles from './DishpatchManager.module.css'
// import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons';
// import { Link, useNavigate, useParams, } from 'react-router-dom';
// import { PackingSlip } from './PackingSlip';
// import { PrintLabel } from './PrintLabel';
// import Modal from '../../components/Modal';
// import { FileHandler } from '../../components/DragAndDrop';
// // import modalcss from '../../components/Modal.module.css'

// const PackingVoucher = () => {

//     let componentRef = React.useRef();
//     const navigate = useNavigate();
//     const { switchDisplay, managerId, designation } = useAuth();
//     const params = useParams()
//     const orderId = params.orderId
//     const [loading, setLoading] = useState(true);
//     const [show, setShow] = useState(false);
//     const [showLabelModal, setShowLabelModal] = useState(false);
//     const [showDeliveryModal, setShowDeliveryModal] = useState(false);
//     const [showAcceptModal, setShowAcceptModal] = useState(false);
//     const [msgShow, setMsgShow] = useState("");
//     const [packer, setPacker] = useState(managerId);
//     // const [couriers, setCouriers] = useState([])
//     const [partners, setPartners] = useState("")
//     const [data, setData] = useState({})
//     const [isReloaded, setIsReloaded] = useState(false)
//     const [lableBtnLoading, setLableBtnLoading] = useState(false)
//     const [paymentLink, setPaymentLink] = useState('')
//     const [showApproveModal, setShowApproveModal] = useState(false)
//     const [showDenyModal, setShowDenyModal] = useState(false)
//     const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
//     const [cancelReason, setCancelReason] = useState('')

//     const [isImage, setisImage] = React.useState("");
//     const [delivery, setDelivery] = useState({
//         courierName: "",
//         partnerName: "",
//     })
//     const [printLabel, setPrintLabel] = useState({
//         qtyPacks: "",
//         delPerName: "",
//         delPerMobile: "",
//         delTrackingNo: "",
//         delTrackingUrl: "",
//         courierSlip: "",
//     })
//     const [courierSlip, setCourierSlip] = useState()

//     const [showPackedModal, setShowPackedModal] = useState(false);
//     const [check, setCheck] = useState(false);
//     const [images, setImages] = useState([]);
//     const [packs, setPacks] = useState()
//     const [point, setPoint] = useState()

//     useEffect(() => {
//         if (managerId) {
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "managerId": managerId,
//                 "orderId": orderId,
//                 "courierName": delivery.courierName
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "dispatch/order", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         setData(result.data)
//                         setPacker(result.data.order.pmId)
//                         // setCouriers(result.data.couriers)
//                         setPartners(result.data.courierPartners)
//                         setPrintLabel(prevState => ({
//                             ...prevState,
//                             qtyPacks: result.data.order.qtyPacks,
//                             delPerName: result.data.order.delPerName,
//                             delPerMobile: result.data.order.delPerMobile,
//                             delTrackingNo: result.data.order.delTrackingNo,
//                             delTrackingUrl: result.data.order.delTrackingUrl,
//                             courierSlip: result.data.order.courierSlip,
//                         }))
//                         if (!delivery.courierName) {
//                             setDelivery(prevState => ({
//                                 ...prevState,
//                                 courierName: result.data.order.courierName,
//                                 methodName: result.data.order.methodName,
//                                 partnerName: result.data.order.partnerName,
//                                 shippingType: result.data.order.shippingType,
//                             }))
//                         }
//                         // setCourierSlip(result.data.order.courierSlip)

//                         setLoading(false)
//                     }
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }, [orderId, managerId, delivery.courierName, isReloaded])

//     // -----------------Courier---------

//     const handleSendLink = async () => {
//         setPaymentLink('')
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "orderId": orderId,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         await fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     setPaymentLink(result.shortUrl)
//                 }
//             })
//             .catch(error => console.log('error', error));
//     }

//     const handleAcceptOrder = (e) => {
//         e.preventDefault()

//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId,
//             "pmId": packer ? packer : managerId,
//             "courierName": delivery.courierName,
//             "partnerName": delivery.partnerName,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/acceptOrder", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     handleSendLink()
//                 }
//                 setMsgShow(result.msg)
//                 setShowAcceptModal(true)
//             })
//             .catch(error => console.log('error', error));
//     }

//     const orderPlacedNotification = () => {

//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "phoneNumber": data.order.whatsapp,
//             "templateName": "online_order_placed",
//             "headerId": "https://dealer.wallicon.in/assets/order_placed.jpg",
//             "buttonDataPayload": paymentLink
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//             .then((response) => response.json())
//             .then(result => {
//                 console.log(result);
//             })
//             .catch((error) => console.error(error));
//     }

//     const orderPlacedPod = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "phoneNumber": data.order.whatsapp,
//             "templateName": "order_placed_pod",
//             "headerId": "https://dealer.wallicon.in/assets/order_placed.jpg",
//             "buttonDataPayload": paymentLink
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//             .then((response) => response.json())
//             .then(result => {
//                 console.log(result);
//             })
//             .catch((error) => console.error(error));
//     }

//     useEffect(() => {
//         if (packs) {
//             setPoint(Array.from(Array(packs === "0" ? 1 : packs === "1" ? 1 : packs === "2" ? 2 : parseInt(packs - 1)).keys()))
//         }
//     }, [packs])

//     const addFormFields = () => {
//         point.forEach(element => {
//             setImages(prevState => ([...prevState, {}]))
//         });
//     }

//     useEffect(() => {
//         if (check && (images.length < parseInt(packs))) {
//             point.forEach(element => {

//                 setImages(prevState => [
//                     ...prevState.slice(0, images.length),
//                     {},
//                     // ...images.slice(index + 1)
//                 ])
//             });
//         }
//     }, [check, images.length, packs, point])

//     const handlePrintLabel = () => {
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId,
//             "qtyPacks": printLabel.qtyPacks,
//             "delPerName": printLabel.delPerName,
//             "delPerMobile": printLabel.delPerMobile,
//             "delTrackingNo": printLabel.delTrackingNo,
//             "delTrackingUrl": printLabel.delTrackingUrl,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/printLabel", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     handlePrint()
//                     setShowLabelModal(false)
//                 } else {
//                     alert(result.msg)
//                 }
//             })
//             .catch(error => console.log('error', error));
//     }

//     // console.log('images', images);

//     const handlePacked = (e) => {
//         e.preventDefault();
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         var formdata = new FormData();
//         formdata.append("packingQty", packs);
//         images && images.map((image) =>
//             formdata.append("images", image)
//         )
//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "packing/orderPacked/" + managerId + "/" + orderId, requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     window.location.reload()

//                 } else {
//                     alert(result.msg)
//                 }
//             })
//             .catch(error => console.log('error', error));
//     }

//     const labelRef = useRef();
//     const handlePrint = useReactToPrint({
//         content: () => labelRef.current
//     });

//     // useEffect(() => {
//     //     if (data && data.order && data.order.dispatchStatus!=='New Order') {

//     const generatePaymentLink = () => {
//         setPaymentLink('')
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         var raw = JSON.stringify({
//             "orderId": orderId,
//         });

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: raw,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "receipt/generatePaymentLink", requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === 'success') {
//                     setPaymentLink(result.shortUrl)
//                 }
//             })
//             .catch(error => console.log('error', error));
//     }

//     const handleDispatched = () => {
//         var myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

//         var formdata = new FormData();
//         formdata.append("shippingType", delivery.shippingType);
//         formdata.append("courierName", delivery.courierName);
//         formdata.append("partnerName", delivery.partnerName);

//         formdata.append("delPerName", printLabel.delPerName);
//         formdata.append("delPerMobile", printLabel.delPerMobile);
//         formdata.append("delTrackingNo", printLabel.delTrackingNo);
//         formdata.append("delTrackingUrl", printLabel.delTrackingUrl);
//         // if (courierSlip && courierSlip[0]) {
//         formdata.append("image", courierSlip[0]);
//         // }

//         var requestOptions = {
//             method: 'POST',
//             headers: myHeaders,
//             body: formdata,
//             redirect: 'follow'
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/deliveryDetails/" + managerId + "/" + orderId, requestOptions)
//             .then(response => response.json())
//             .then(result => {
//                 if (result.status === "success") {
//                     if (result.data.dac === null) {
//                         orderDispatchedNotification()
//                     } else {
//                         orderDispatchedNotificationAuth(result.data.shippingInfo.name, result.data.orderId, result.data.dac)
//                     }
//                     setShowDeliveryModal(false)
//                     setIsReloaded(Math.random())
//                 }
//                 alert(result.msg)
//             })
//             .catch(error => console.log('error', error));
//     }

//     const orderDispatchedNotification = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "phoneNumber": data.order.whatsapp,
//             "templateName": "online_order_dispatched",
//             "headerId": "https://dealer.wallicon.in/assets/dispatched.jpg",
//             "buttonDataPayload": paymentLink
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//             .then((response) => response.json())
//             .then(result => {
//                 console.log(result);
//             })
//             .catch((error) => console.error(error));
//     }

//     const orderDispatchedNotificationAuth = (name, id, dac) => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "phoneNumber": data.order.whatsapp,
//             "templateName": "delivery_auths_code",
//             "headerId": "https://dealer.wallicon.in/assets/dispatched.jpg",
//             "buttonDataPayload": paymentLink,
//             "dealerName": name,
//             "orderId": id,
//             "deliveryCode": dac
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "wallikonChat/sentStatusTemp", requestOptions)
//             .then((response) => response.json())
//             .then(result => {
//                 console.log(result);
//             })
//             .catch((error) => console.error(error));
//     }

//     useEffect(() => {
//         if (isImage) {
//             setImages(prevState => ([...prevState, ...isImage]))
//         }
//     }, [isImage])

//     const handleSubmit = (event) => {
//         event.preventDefault();
//         const form = event.currentTarget;
//         if (form.checkValidity() === false) {
//             event.preventDefault();
//             event.stopPropagation();
//         } else {
//             // if (printLabel.courierSlip||printLabel.delTrackingNo||courierSlip) {
//             handleDispatched()
//             // }
//             // else {
//             //     alert('Please Upload Packing Slip OR Tracking Number')
//             // }
//         }
//     };

//     useEffect(() => {
//         if (data.order && data.order.shippingInfo && data.order.shippingInfo.pincode) {
//             setLableBtnLoading(true)
//             var myHeaders = new Headers();
//             myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//             myHeaders.append("Content-Type", "application/json");

//             var raw = JSON.stringify({
//                 "keyword": data.order.shippingInfo.pincode,
//             });

//             var requestOptions = {
//                 method: 'POST',
//                 headers: myHeaders,
//                 body: raw,
//                 redirect: 'follow'
//             };

//             fetch(process.env.REACT_APP_URL + "locations/locations", requestOptions)
//                 .then(response => response.json())
//                 .then(result => {
//                     if (result.status === "success") {
//                         setData(prevState => ({
//                             ...prevState,
//                             SubDistName: result.SubDistName[0] ? result.SubDistName[0] : "",
//                             districts: result.districts[0] ? result.districts[0] : "",
//                         }))
//                     }
//                     setLableBtnLoading(false)
//                 })
//                 .catch(error => console.log('error', error));
//         }
//     }, [data.order])

//     const handleApproveEdit = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/editOrderApproved", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === "success") {
//                     setShowApproveModal(false)
//                     navigate('/dispatchdashboard')
//                 }
//             })
//             .catch((error) => console.error(error));
//     }

//     const handleApproveCancel = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/cancelOrderApproved", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === "success") {
//                     setShowApproveModal(false)
//                     navigate('/dispatchdashboard')
//                 } else {
//                     alert(result.msg)
//                     setShowApproveModal(false)
//                 }
//             })
//             .catch((error) => console.error(error));
//     }

//     const handleDenyEdit = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/editOrderDeny", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === "success") {
//                     setShowDenyModal(false)
//                     window.location.reload()
//                 }
//             })
//             .catch((error) => console.error(error));
//     }
//     const handleDenyCancel = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/canceleOrderDeny", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === "success") {
//                     setShowDenyModal(false)
//                     window.location.reload()
//                 }
//             })
//             .catch((error) => console.error(error));
//     }

//     const handleCancelOrder = () => {
//         const myHeaders = new Headers();
//         myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//         myHeaders.append("Content-Type", "application/json");

//         const raw = JSON.stringify({
//             "managerId": managerId,
//             "orderId": orderId,
//             "cancelReason": cancelReason
//         });

//         const requestOptions = {
//             method: "POST",
//             headers: myHeaders,
//             body: raw,
//             redirect: "follow"
//         };

//         fetch(process.env.REACT_APP_URL + "dispatch/cancelOrder", requestOptions)
//             .then((response) => response.json())
//             .then((result) => {
//                 if (result.status === 'success') {
//                     setShowCancelOrderModal(false)
//                     window.location.reload()
//                 } else {
//                     alert(result.msg)
//                 }
//             })
//             .catch((error) => console.error(error));
//     }

//     // console.log('data.order', data.order );
//     const filterItem = (data.order && data.order.items && data.order.items.length > 0) && data.order.items.filter(item => (item.unit || item.qty > 0 || item.desc))

//     return (
//         <React.Fragment>
//             {/* {data.order &&
//                 <PrintLabel
//                     data={data.order}
//                     qtyPacks={1}
//                 // ref={labelRef}
//                 // handlePrint={handlePrint}
//                 />} */}
//             <Modal
//                 show={showLabelModal}
//                 close={setShowLabelModal}
//                 closeBtn={true}
//                 heading="PRINT LABEL"
//                 content={
//                     <div className={styles.text_center}>
//                         <p className={styles.sliptext}>Are you sure you want to Print Label & Marked as Packed?</p>
//                         <div className={styles.modal_div}>
//                             <p className={styles.sliptext}>Enter Number Of Packs</p>
//                             <input
//                                 name="name"
//                                 type="number"
//                                 className={`${switchDisplay ? styles.inputcolorpack : styles.inputpack}`}
//                                 value={printLabel.qtyPacks}
//                                 onChange={(e) => {
//                                     setPrintLabel(prevState => ({
//                                         ...prevState,
//                                         qtyPacks: e.target.value
//                                     }))
//                                 }}
//                             />
//                         </div>
//                         {show ?
//                             <div>
//                                 <div className={styles.modal_div}>
//                                     <input
//                                         name="name"
//                                         type="text"
//                                         placeholder='Name'
//                                         className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                         value={printLabel.delPerName}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delPerName: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                     <input
//                                         name="mobile"
//                                         type="number"
//                                         placeholder='Mobile Number'
//                                         className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                         value={printLabel.delPerMobile}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delPerMobile: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                 </div>
//                                 <div className={styles.modal_div}>
//                                     <input
//                                         name="name"
//                                         type="text"
//                                         placeholder='Tracking Number'
//                                         className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                         value={printLabel.delTrackingNo}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delTrackingNo: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                     <input
//                                         name="name"
//                                         type="text"
//                                         placeholder='Tracking URL'
//                                         className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                         value={printLabel.delTrackingUrl}
//                                         onChange={(e) => {
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delTrackingUrl: e.target.value
//                                             }))
//                                         }}
//                                     />
//                                 </div>
//                             </div> : ""
//                         }
//                         <div className={styles.modalBtn_div}>
//                             <RedOutButton title={"CANCEL"} handleSubmit={() => { setShowLabelModal(false) }} />
//                             <div className={styles.printicon}>
//                                 <GreenOutButton css={!printLabel.qtyPacks ? styles.disableBtn : ''} title={"PRINT"} disabled={printLabel.qtyPacks ? false : true} handleSubmit={() => { handlePrintLabel() }} />
//                             </div>
//                             <div style={{ display: "none" }}>
//                                 <PrintLabel
//                                     data={data.order}
//                                     SubDistName={data.SubDistName}
//                                     districts={data.districts}
//                                     qtyPacks={printLabel.qtyPacks}
//                                     ref={labelRef}
//                                     handlePrint={handlePrint}
//                                 />
//                             </div>
//                         </div>
//                         <div className={styles.modal_div} onClick={() => setShow(!show)}>
//                             {show ? <p className={styles.sliptext}>Add Delivery Details</p> : <p className={styles.sliptext}>Hide Delivery Details</p>}

//                             <div>
//                                 {show ? <DownArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} /> : <UpArrowCircle color={switchDisplay ? "#000000" : "#FFFFFF"} />}
//                             </div>
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showDeliveryModal}
//                 close={setShowDeliveryModal}
//                 closeBtn={true}
//                 heading={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DELIVERY DETAILS" : "DELIVERY DETAILS"}
//                 content={
//                     <div className={styles.text_center}>
//                         <form onSubmit={handleSubmit}>
//                             <div className={styles.modal_div}>
//                                 <div className='w-100 me-1'>
//                                     <label>&nbsp;Method</label>
//                                     <div className={`${styles.input} w-100`}>{delivery.courierName}</div>
//                                 </div>
//                                 <div className='w-100 mx-1'>
//                                     <label>&nbsp;Sub-Method</label>
//                                     <div className={`${styles.input} w-100`}>{delivery.methodName}</div>
//                                 </div>

//                                 <div className='d-flex flex-column w-100 ms-1'>
//                                     <label>&nbsp;Partner</label>
//                                     <select
//                                         className={`${switchDisplay ? styles.inputcolor : styles.input} w-100`}
//                                         value={delivery.partnerName}
//                                         onChange={(e) => {
//                                             setDelivery(prevState => ({
//                                                 ...prevState,
//                                                 partnerName: e.target.value
//                                             }));
//                                             setPrintLabel(prevState => ({
//                                                 ...prevState,
//                                                 delPerName: partners && partners.find(x => x.partnerName === e.target.value)?.partnerName,
//                                                 delPerMobile: partners && partners.find(x => x.partnerName === e.target.value)?.contactNumber,
//                                                 delTrackingNo: partners && partners.find(x => x.partnerName === e.target.value)?.trackingNumber,
//                                                 delTrackingUrl: partners && partners.find(x => x.partnerName === e.target.value)?.trackingUrl,
//                                             }));
//                                         }}
//                                         required

//                                     >
//                                         <option value='' hidden>Select Partner</option>
//                                         {partners && partners.map((obj, index) =>
//                                             <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
//                                         )}

//                                     </select>
//                                 </div>
//                             </div>
//                             <div className={styles.modal_div}>
//                                 <input
//                                     name="name"
//                                     type="text"
//                                     placeholder='Name'
//                                     className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                     value={printLabel.delPerName}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delPerName: e.target.value
//                                         }))
//                                     }}
//                                 />
//                                 <input
//                                     name="mobile"
//                                     type="number"
//                                     placeholder='Mobile Number'
//                                     className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                     value={printLabel.delPerMobile}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delPerMobile: e.target.value
//                                         }))
//                                     }}
//                                 />
//                             </div>
//                             <div className={styles.modal_div}>
//                                 <input
//                                     name="name"
//                                     type="text"
//                                     placeholder='Tracking Number'
//                                     className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                     value={printLabel.delTrackingNo}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delTrackingNo: e.target.value
//                                         }))
//                                     }}
//                                 />
//                                 <input
//                                     name="name"
//                                     type="text"
//                                     placeholder='Tracking URL'
//                                     className={`${switchDisplay ? styles.inputcolor : styles.input}`}
//                                     value={printLabel.delTrackingUrl}
//                                     onChange={(e) => {
//                                         setPrintLabel(prevState => ({
//                                             ...prevState,
//                                             delTrackingUrl: e.target.value
//                                         }))
//                                     }}
//                                 />
//                             </div>

//                             <div className={styles.dragImage_div}>
//                                 <FileHandler
//                                     setisImage={setCourierSlip}
//                                     styles={styles}
//                                 />
//                             </div>

//                             <div className={styles.uploadImage_div}>

//                                 <div className={styles.demoImage_div}>

//                                     {(courierSlip || printLabel.courierSlip) ?
//                                         <img
//                                             src={courierSlip ? URL.createObjectURL(courierSlip[0]) : process.env.REACT_APP_S3URL + printLabel.courierSlip}
//                                             className={styles.modalItem_Image}
//                                             alt="courierSlip"
//                                         />
//                                         :
//                                         <img
//                                             src='/assets/packingImage.png'
//                                             className={styles.modalItem_Image}
//                                             alt="courier"
//                                         />
//                                     }

//                                 </div>
//                                 <label className={styles.uploadImage_btn}>View</label>
//                                 <div className={styles.delete_div} onClick={() => { setCourierSlip(); setPrintLabel(prevState => ({ ...prevState, courierSlip: '' })) }}>
//                                     <span><DeleteIcon /></span>
//                                     <span>Delete</span>
//                                 </div>
//                             </div>

//                             <div className={styles.modalBtn_div}>
//                                 <RedOutButton btnType="button" title={"CANCEL"} handleSubmit={() => { setShowDeliveryModal(false) }} />
//                                 <GreenOutButton btnType="submit" title={data.order && data.order.dispatchStatus === "Dispatched" ? "UPDATE DISPATCHED" : "DISPATCHED"} />
//                             </div>
//                         </form>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showAcceptModal}
//                 close={setShowAcceptModal}
//                 closeBtn={true}
//                 heading="DISPATCH ORDER STATUS"
//                 content={
//                     <div className={styles.text_center}>
//                         <p className={styles.message}>{msgShow}</p>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="CANCEL" handleSubmit={() => {
//                                 data.order.paymentMethod === 'POD' ? orderPlacedPod() : orderPlacedNotification();
//                                 setTimeout(() => {
//                                     setShowAcceptModal(false); window.location.reload()
//                                 }, 1000);
//                             }} />
//                             <GreenButton title="OK" handleSubmit={() => {
//                                 data.order.paymentMethod === 'POD' ? orderPlacedPod() : orderPlacedNotification();
//                                 setTimeout(() => {
//                                     setShowAcceptModal(false); window.location.reload()
//                                 }, 1000);
//                             }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showApproveModal}
//                 close={setShowApproveModal}
//                 closeBtn={true}
//                 heading={data.order && (data.order.reqType === 'Request For Cancel' ? "CANCEL ORDER REQUEST" : "EDIT ORDER REQUEST")}
//                 content={
//                     <div className={styles.text_center}>
//                         <p className={styles.message}>Are you sure you want to Approve this {data.order && (data.order.reqType === 'Request For Cancel' ? 'Cancel' : "Edit")} Order Request?</p>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="NO" handleSubmit={() => setShowApproveModal(false)} />
//                             <GreenButton title="YES" handleSubmit={() => { if (data.order && (data.order.reqType === 'Request For Cancel')) { handleApproveCancel() } else { handleApproveEdit() } }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showDenyModal}
//                 close={setShowDenyModal}
//                 closeBtn={true}
//                 heading={data.order && (data.order.reqType === 'Request For Cancel' ? "DENY CANCEL ORDER REQUEST" : "DENY EDIT ORDER REQUEST")}
//                 content={
//                     <div className={styles.text_center}>
//                         <p className={styles.message}>Are you sure you want to Deny this {data.order && (data.order.reqType === 'Request For Cancel' ? 'Cancel' : "Edit")} Order Request?</p>
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="NO" handleSubmit={() => setShowDenyModal(false)} />
//                             <GreenButton title="YES" handleSubmit={() => { if (data.order && (data.order.reqType === 'Request For Cancel')) { handleDenyCancel() } else { handleDenyEdit() } }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showCancelOrderModal}
//                 close={setShowCancelOrderModal}
//                 closeBtn={true}
//                 heading="CANCEL ORDER"
//                 content={
//                     <div className={styles.text_center}>
//                         <p className={styles.message}>Are You Sure Want To Cancel This Order</p>
//                         <textarea type="text" placeholder='Enter Cancel Reason' className={styles.reqIssue} value={cancelReason} onChange={(e) => setCancelReason(e.target.value)} />
//                         <div className={styles.massageModal_btn}>
//                             <RedOutButton title="NO" handleSubmit={() => setShowCancelOrderModal(false)} />
//                             <GreenButton title="YES" handleSubmit={() => { handleCancelOrder(); }} />
//                         </div>
//                     </div>
//                 }
//             />

//             <Modal
//                 show={showPackedModal}
//                 close={setShowPackedModal}
//                 closeBtn={true}
//                 heading="PACKING STATUS"
//                 content={
//                     <form onSubmit={handlePacked} className={styles.text_center}>
//                         <div className={styles.main_div}>
//                             <div>
//                                 <span>Enter Number Of Packs:</span>&nbsp;&nbsp;
//                                 <input type="number"
//                                     name="packs"
//                                     value={packs}
//                                     // setPacks
//                                     onInput={(e) => e.target.value = e.target.value.slice(0, 4)}
//                                     onChange={(e) => {
//                                         setPacks(e.target.value)

//                                         if (check && e.target.value && (images.length > parseInt(e.target.value))) {
//                                             const values = [...images];
//                                             values.splice(point, (images.length - (parseInt(e.target.value) === 0 ? 1 : parseInt(e.target.value))));
//                                             setImages(values);
//                                         }

//                                     }}
//                                     className={styles.enterPackets}
//                                     required
//                                     autoFocus={true}
//                                 />
//                             </div>

//                             <div className={styles.checkBox_addImage}>
//                                 <input
//                                     type="checkbox"
//                                     name="vehicle1"
//                                     value="check"
//                                     className={styles.checkbox}
//                                     checked={check ? true : false}
//                                     onChange={(e) => {
//                                         if (e.target.checked && packs) {
//                                             addFormFields()
//                                             setCheck(true)
//                                         } else {
//                                             const values = [...images];
//                                             values.splice(point, point.length);
//                                             setImages(values);
//                                             setCheck(false)
//                                         }
//                                     }}
//                                 />
//                                 <label htmlFor="vehicle1">&nbsp;&nbsp;Add image of every pack</label>
//                             </div>

//                             <div className={styles.dragImage_div}>
//                                 <FileHandler
//                                     setisImage={setisImage}
//                                     styles={styles}
//                                 />
//                             </div>

//                             {/* <div className={styles.dragImage_div}>
//                                 <span> <img src='/assets/packing_image.png' alt="item_image" className={styles.packing_image} /></span>
//                                 <p className={styles.drag_txt}>Drag photos and documents</p>
//                                 <hr className={styles.border_OR_div} />
//                                 <span className={styles.OR_css}>OR</span>
//                                 <button type='button' className={styles.browseFiles_btn}>Browse Photos</button>
//                             </div> */}

//                             <div>
//                                 {images.map((item, index) =>
//                                     <div key={index} className={styles.flexdiv}>
//                                         <span>Pack {index + 1}</span>
//                                         {/* <label className={styles.label}>
//                                             <input
//                                                 name=""
//                                                 type="file"
//                                                 hidden
//                                                 onChange={(e) => {
//                                                     let data = [...images];
//                                                     data[index] = e.target.files[0];
//                                                     setImages(data);
//                                                 }}
//                                             />
//                                             <UpLoadFile />&nbsp;Upload Your Photos Here
//                                         </label> */}

//                                         {item.name ? <img src={URL.createObjectURL(item)} alt="" className={styles.fileimg}

//                                             onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")}
//                                         /> :
//                                             <img
//                                                 src='/assets/packingImage.png'
//                                                 alt='packing_order_image'
//                                                 className={styles.packingImage}
//                                             />
//                                         }

//                                         <div className={styles.view_packingImage} onClick={() => window.open(URL.createObjectURL(item), "mozillaWindow", "popup")} >View</div>

//                                         <div className={styles.deletediv}
//                                             onClick={e => {
//                                                 let data = [...images];
//                                                 data[index] = {};
//                                                 setImages(data);
//                                             }}
//                                         >
//                                             <DeleteFile color={"red"} />
//                                             <p className={styles.deleteicon}>Delete</p>
//                                         </div>
//                                         {images.length !== 1 &&
//                                             <div className={styles.deletediv}
//                                                 onClick={e => {
//                                                     setPacks(parseInt(packs) - 1)
//                                                     const list = [...images];
//                                                     list.splice(index, 1);
//                                                     setImages(list);
//                                                 }}
//                                             >
//                                                 <span className={styles.crossIcon}> &#10006;</span>
//                                             </div>
//                                         }
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                         <div className={styles.packingModal_btn}>
//                             <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => setShowPackedModal(false)} />
//                             <GreenButton btnType="submit" title="OK" />
//                         </div>
//                     </form>
//                 }
//             />

//             {/* <PackingImage
//                 show={showPackingImage}
//                 close={setShowPackingImage}
//                 closeBtn={true}
//                 content={
//                     <>
//                         <div className={styles.modal_close_button_div}>
//                             <button
//                                 type="button"
//                                 className={styles.modal_close_btn}
//                                 onClick={() => { setShowPackingImage(false) }}
//                             >
//                                 <span>&times;</span>
//                             </button>
//                         </div>
//                         <div className={styles.packingStatus_main}>

//                             <img src={packingImage} alt='item_image' className={styles.packingImagefull} />
//                         </div>
//                     </>
//                 }
//             /> */}

//             {loading ? "Loading" :
//                 <form onSubmit={handleAcceptOrder} className={`${switchDisplay ? styles.bg_white : styles.bg_dark} ${styles.ff}`}>
//                     <div className={`${switchDisplay ? styles.voucher_maindiv : styles.voucher_maindiv}`}>

//                         <div className={styles.packingVoucherDiv}>
//                             <div className={styles.packingHeading}>
//                                 <div className={styles.backArrow} onClick={() => navigate(-1)}>&lt;&nbsp;Go Back</div>
//                                 <h2>Packing Voucher</h2>
//                                 <div className={`${(data.order.dispatchStatus === "New Order") ? styles.status_blue :
//                                     (data.order.dispatchStatus === "Packed" || data.order.dispatchStatus === "Dispatched") ? styles.statuscolor3 :
//                                         (data.order.dispatchStatus === "Not Packed") ? styles.statuscolor1 :
//                                             (data.order.dispatchStatus === 'In Progress' || data.order.dispatchStatus === 'Pending') ?
//                                                 styles.status : ''
//                                     }`}>
//                                     &#9679; {data.order.dispatchStatus}
//                                 </div>
//                             </div>

//                             <div className={styles.voucher_flex}>
//                                 <div className={styles.voucherFlexDirection}>
//                                     <h2 className={styles.slipheading_div}>{data.order && data.order.storeName}</h2>
//                                     <span className={styles.sliptext}>Ship To:&nbsp;<b>{data.order.shippingInfo && data.order.shippingInfo.name}</b></span>
//                                     <span className={styles.sliptext}>Address:&nbsp;<b>{data.order.shippingInfo &&
//                                         (data.order.shippingInfo.street + ", " + data.order.shippingInfo.city + ", " + data.order.shippingInfo.district + ", " +
//                                             data.order.shippingInfo.state + ", " + data.order.shippingInfo.pincode
//                                         )}</b></span>
//                                     <span className={styles.sliptextGustin}>GSTUIN:&nbsp;<b>{data.order && data.order.gstuin}</b></span>
//                                     {/* <span className={styles.sliptext}>State:&nbsp;{data.order && data.order.state}</span> */}
//                                     <span className={styles.sliptext}>Contact:&nbsp;{data.order.shippingInfo && data.order.shippingInfo.mobile}</span>
//                                     {/* <span className={styles.sliptext}>Email:&nbsp;{data.order && data.order.email}</span> */}
//                                     <span className={styles.sliptext}>Order Manager:&nbsp;{data.order && data.order.ordermanager}</span>
//                                     <span className={styles.sliptext}>Packing Manager:&nbsp;{data.order && data.order.packermanager}</span>
//                                 </div>
//                                 <div className={styles.packingVoucher_left}>
//                                     <div className={styles.voucher_flex}>
//                                         <span className={styles.slipDetailsTxt}>Voucher No.</span>
//                                         <span className={styles.slipDetailsData}><b>{data.order && data.order.orderId}</b></span>
//                                     </div>
//                                     <div className={styles.voucher_flex}>
//                                         <span className={styles.slipDetailsTxt}>Voucher Date</span>
//                                         <span className={styles.slipDetailsData}><b>{data.order && data.order.orderDate}</b></span>
//                                     </div>
//                                     <div className={styles.voucher_flex}>
//                                         <span className={styles.slipDetailsTxt}>Delivery Type</span>
//                                         <span className={styles.slipDetailsData}><b>{data.order && data.order.shippingType}</b></span>
//                                     </div>
//                                     <div className={styles.voucher_flex}>
//                                         <span className={styles.slipDetailsTxt}>Delivery Method</span>
//                                         <div className={`${styles.slipDetailsDataSelect} py-2 px-1`}>
//                                             {delivery.courierName ? delivery.courierName : data.order.courierName}
//                                         </div>
//                                         {/* <select className={styles.slipDetailsDataSelect}
//                                             value={delivery.courierName ? delivery.courierName : data.order.courierName}
//                                             onChange={(e) => setDelivery(prevState => ({
//                                                 ...prevState,
//                                                 courierName: e.target.value
//                                             }))}
//                                         required
//                                         >
//                                             <option value={""}>Select</option>
//                                             {couriers && couriers.map((obj, index) =>
//                                                 <option key={index} value={obj.courierName}>{obj.courierName}</option>
//                                             )}
//                                         </select> */}
//                                     </div>
//                                     <div className={styles.voucher_flex}>
//                                         <span className={styles.slipDetailsTxt}>Sub-Method</span>
//                                         <div className={`${styles.slipDetailsDataSelect} py-2 px-1`}>
//                                             {delivery.partnerName ? delivery.partnerName : data.order.partnerName}
//                                         </div>
//                                         {/* <select className={styles.slipDetailsDataSelect}
//                                             value={delivery.partnerName ? delivery.partnerName : data.order.partnerName}
//                                             onChange={(e) => setDelivery(prevState => ({
//                                                 ...prevState,
//                                                 partnerName: e.target.value
//                                             }))}
//                                         required
//                                         >
//                                             <option value={""}>Select</option>
//                                             {partners && partners.partners.map((obj, index) =>
//                                                 <option key={index} value={obj.partnerName}>{obj.partnerName}</option>
//                                             )}
//                                         </select> */}
//                                     </div>
//                                     <div className={styles.voucher_flex}>
//                                         <span className={styles.slipDetailsTxt}>Order Manager :</span>
//                                         <a href={'tel:+91' + data.order.managercontact} className={styles.slipDetailsData}><b>{data.order && data.order.managercontact}</b></a>
//                                     </div>
//                                     <div className={styles.voucher_flex}>
//                                         <span className={styles.slipDetailsTxt}>Packing Manager :</span>
//                                         <a href={'tel:+91' + data.order.pmcontact} className={styles.slipDetailsData}><b>{data.order && data.order.pmcontact}</b></a>
//                                     </div>
//                                 </div>
//                             </div>

//                             <div className={styles.fixTableHead_packing}>
//                                 <table>
//                                     <thead>
//                                         <tr>
//                                             <th>S.No.</th>
//                                             <th>Item Description</th>
//                                             <th>Qty</th>
//                                             <th>Per</th>
//                                         </tr>
//                                     </thead>
//                                     {filterItem && filterItem.map((val, key) => {
//                                         return (
//                                             <tbody key={key}>
//                                                 <tr className={styles.h10}>
//                                                     <td>{key + 1}</td>
//                                                     <td>
//                                                         <div className={styles.itemDescription_div}>
//                                                             <span>{val.itemNo ? val.itemNo : val.accessoriesName}</span>
//                                                             <span>{val.product_name}</span>
//                                                             <span>{val.warehouse}</span>
//                                                             <span>{val.batch}</span>
//                                                         </div>
//                                                     </td>
//                                                     <td>{val.qty}</td>
//                                                     <td>{val.unit ? val.unit : 'Pcs'}</td>
//                                                 </tr>
//                                                 {(key === val.descKey || val.desc) && <tr style={{ border: '1px solid' }}>
//                                                     <td colSpan={6} className={styles.itemDescription_div}>
//                                                         {val.desc}
//                                                     </td>
//                                                 </tr>
//                                                 }

//                                                 {/* <tr className={styles.brderTr}>
//                                                         <td></td>
//                                                         <td></td>
//                                                         <td></td>
//                                                         <td></td>
//                                                     </tr> */}
//                                             </tbody>
//                                         )
//                                     })}
//                                 </table>
//                             </div>
//                             <div className={styles.packingHeading}>
//                                 <div>
//                                     <div className={styles.selectheading}>Select Packer</div>

//                                     <select
//                                         className={`${switchDisplay ? styles.bg_white :
//                                             styles.bg_dark} ${styles.select}`}
//                                         onChange={(e) => setPacker(e.target.value)}
//                                         value={packer}
//                                         // defaultValue={managerId}
//                                         required
//                                     >
//                                         <option value={managerId} >Self</option>
//                                         {data.pakingManagers && data.pakingManagers.map((item, index) =>
//                                             <option
//                                                 key={index}
//                                                 className={styles.selecthover}
//                                                 value={item.managerId}
//                                             >
//                                                 {item.name}
//                                             </option>
//                                         )}
//                                     </select>
//                                 </div>
//                                 <div>Total Items: {data.order.items.length}</div>
//                             </div>

//                             <div className={styles.btn_div}>
//                                 <RedOutButton btnType="button" title={"BACK"} handleSubmit={() => { window.history.back() }} />
//                                 {data.order.reqStatus === '' && data.order.dispatchStatus !== 'New Order' && data.order.dispatchStatus !== 'Cancelled' &&
//                                     <RedOutButton
//                                         disabled={false}
//                                         btnType="button"
//                                         title='CANCEL ORDER'
//                                         css='px-2'
//                                         handleSubmit={() => { setShowCancelOrderModal(true) }}
//                                     />
//                                 }
//                                 {data.order.dispatchStatus === 'New Order' ?
//                                     <GreenOutButton btnType="submit" title={"ACCEPT"} />
//                                     :
//                                     (data.order.pmId === parseInt(managerId) || (designation === 'superadmin')) && data.order.packingStatus !== "Packed" ?
//                                         <>
//                                             <>
//                                                 {
//                                                     data.order.reqStatus === "Pending" ?
//                                                         <>
//                                                             {
//                                                                 data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
//                                                                 <GreenButton
//                                                                     btnType="button"
//                                                                     title={"APPROVE EDIT REQUEST"}
//                                                                     handleSubmit={() => { setShowApproveModal(true) }}
//                                                                     css={styles.editRequestButton}
//                                                                 />
//                                                             }
//                                                             {
//                                                                 data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
//                                                                 <RedOutButton
//                                                                     btnType="button"
//                                                                     title={"DENY EDIT REQUEST"}
//                                                                     handleSubmit={() => { setShowDenyModal(true) }}
//                                                                     css='px-3 py-2'
//                                                                 />
//                                                             }
//                                                             {
//                                                                 data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                                 <GreenButton
//                                                                     btnType="button"
//                                                                     title={"APPROVE CANCEL REQUEST"}
//                                                                     handleSubmit={() => { setShowApproveModal(true) }}
//                                                                     css={styles.editRequestButton}
//                                                                 />
//                                                             }
//                                                             {
//                                                                 data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                                 <RedOutButton
//                                                                     btnType="button"
//                                                                     title={"DENY CANCEL REQUEST"}
//                                                                     handleSubmit={() => { setShowDenyModal(true) }}
//                                                                     css='px-3 py-2'
//                                                                 />
//                                                             }
//                                                         </>
//                                                         :
//                                                         <>
//                                                             {data.order.dispatchStatus !== "Cancelled" &&
//                                                                 <>
//                                                                     <ReactToPrint
//                                                                         trigger={() => <div>
//                                                                             <GreenOutButton
//                                                                                 btnType="button"
//                                                                                 title={<div className={styles.btn}><Printbtn />&nbsp;&nbsp;PACKING SLIP</div>} />
//                                                                         </div>}
//                                                                         content={() => componentRef}
//                                                                     />
//                                                                     <div style={{ display: "none" }}>
//                                                                         <PackingSlip
//                                                                             orderId={orderId}
//                                                                             managerId={managerId}
//                                                                             delivery={delivery}
//                                                                             ref={(el) => (componentRef = el)}
//                                                                         />
//                                                                     </div>
//                                                                     <GreenButton
//                                                                         btnType="button"
//                                                                         title={"PACKED"}
//                                                                         handleSubmit={() => { setShowPackedModal(true) }}
//                                                                     />
//                                                                 </>
//                                                             }
//                                                         </>
//                                                 }
//                                             </>
//                                         </>
//                                         :
//                                         <>
//                                             {data.order.dispatchStatus === "Dispatched" ?
//                                                 <Link to={'/viewdispatchdetails/' + orderId} className={styles.viewDetail_btn}>View Dispatch Details</Link>
//                                                 :
//                                                 data.order.reqStatus === "Pending" ?
//                                                     <>
//                                                         {
//                                                             data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
//                                                             <GreenButton
//                                                                 btnType="button"
//                                                                 title={"APPROVE EDIT REQUEST"}
//                                                                 handleSubmit={() => { setShowApproveModal(true) }}
//                                                                 css={styles.editRequestButton}
//                                                             />
//                                                         }
//                                                         {
//                                                             data.order.reqType === 'Request For Edit' && data.order.reqStatus !== 'Approved' &&
//                                                             <RedOutButton
//                                                                 btnType="button"
//                                                                 title={"DENY EDIT REQUEST"}
//                                                                 handleSubmit={() => { setShowDenyModal(true) }}
//                                                                 css='px-3 py-2'
//                                                             />
//                                                         }
//                                                         {
//                                                             data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                             <GreenButton
//                                                                 btnType="button"
//                                                                 title={"APPROVE CANCEL REQUEST"}
//                                                                 handleSubmit={() => { setShowApproveModal(true) }}
//                                                                 css={styles.editRequestButton}
//                                                             />
//                                                         }
//                                                         {
//                                                             data.order.reqType === 'Request For Cancel' && data.order.reqStatus !== 'Approved' &&
//                                                             <RedOutButton
//                                                                 btnType="button"
//                                                                 title={"DENY CANCEL REQUEST"}
//                                                                 handleSubmit={() => { setShowDenyModal(true) }}
//                                                                 css='px-3 py-2'
//                                                             />
//                                                         }
//                                                     </>
//                                                     :
//                                                     <>
//                                                         {data.order.dispatchStatus !== "Cancelled" &&
//                                                             <GreenOutButton btnType="button" title="DELIVERY DETAILS"
//                                                                 disabled={data.order.dispatchStatus === "Cancelled" ? true : false}
//                                                                 handleSubmit={() => {
//                                                                     setPrintLabel(prevState => ({
//                                                                         ...prevState,
//                                                                         delPerName: partners && partners.find(x => x.partnerName === delivery.partnerName)?.partnerName,
//                                                                         delPerMobile: partners && partners.find(x => x.partnerName === delivery.partnerName)?.contactNumber,
//                                                                         delTrackingNo: partners && partners.find(x => x.partnerName === delivery.partnerName)?.trackingNumber,
//                                                                         delTrackingUrl: partners && partners.find(x => x.partnerName === delivery.partnerName)?.trackingUrl,
//                                                                     }));
//                                                                     setShowDeliveryModal(true);
//                                                                     generatePaymentLink()
//                                                                 }}
//                                                             />
//                                                         }
//                                                     </>
//                                             }
//                                             {data.order.reqStatus === "Pending" ? '' :
//                                                 <GreenOutButton
//                                                     disabled={((data.order.dispatchStatus === "Cancelled") || lableBtnLoading) ? true : false}
//                                                     btnType="button"
//                                                     title={<>{lableBtnLoading ? <Spinner size="15" /> : <div className={styles.btn}><Printbtn />&nbsp;&nbsp;LABEL</div>}</>}
//                                                     handleSubmit={() => { setShowLabelModal(true) }}
//                                                 />
//                                             }
//                                         </>
//                                 }

//                             </div>
//                         </div>
//                     </div>
//                 </form>
//             }
//             {/* <PackingSlip
//                 orderId={orderId}
//                 managerId={managerId}
//                 delivery={delivery}
//                 ref={(el) => (componentRef = el)}
//             /> */}

//         </React.Fragment>
//     )
// }
// export default PackingVoucher;

// export const PackingImage = ({ show, close, content, heading, closeBtn }) => {
//     useEffect(() => {
//         if (show) {
//             document.body.style.overflow = 'hidden'
//         } else {
//             document.body.style.overflow = 'auto'
//         }
//     }, [show,])
//     return show && (
//         <div className={modalcss.modal_backdrop} onClick={() => { close(); }}>
//             <div className={modalcss.packingImage_content} onClick={e => { e.stopPropagation(); }}  >
//                 {content}
//             </div>
//         </div >
//     )
// }
