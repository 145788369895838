import React, { useRef, useState } from 'react';
import styles from "../../../pages/kyc/Kyc.module.css";
import { DragNDropIcon } from '../../icons/Icon';
import { useAuth } from '../../context/Auth';

const UploadSingleFile = ({text }) => {
    const { mode } = useAuth();
    const inputRef = useRef(null);
    const [file, setFile] = useState(null); // Store a single file

    const onButtonClick = () => {
        inputRef.current.click();
    };

    const handleChange = (event) => {
        const selectedFile = event.target.files[0]; // Get the first file
        if (selectedFile) {
            setFile(selectedFile);
        }
    };

    return (
        <div className={styles.Drag_drop}>
            <span className={styles.aadherCard}> {text} </span>
            {file && <span className={styles.aadherCard1} onClick={() => setFile(null)}> X </span>}
            {file ? (
                <div className={styles.imagePreview}>
                    <img src={URL.createObjectURL(file)} alt="Preview" className={styles.uploadedImage} />
                </div>
            ) : (
                <>
                    <div>
                        <DragNDropIcon />
                    </div>
                    <div className={styles.textaline}>
                        <p>Drag & drop</p>
                        <p>any file.</p>
                    </div>
                    <div className={styles.textaline1}>
                        <p style={{ color: mode ? "" : "#000" }}>
                            or
                            <span className={styles.browseSpan} onClick={onButtonClick}>
                                browse files
                            </span>
                            on your computer.
                        </p>
                        <input
                            type="file"
                            id="fileInput"
                            accept="image/*"
                            style={{ display: 'none' }}
                            ref={inputRef}
                            onChange={handleChange}
                        />
                    </div>
                </>
            )}
        </div>
    );
};

export default UploadSingleFile;
