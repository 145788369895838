import { useState, useEffect } from 'react';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

const useDeviceInfo = () => {
  const [deviceInfo, setDeviceInfo] = useState({
    deviceId: '',
    browserName: 'Unknown',
    osName: 'Unknown',
  });

  useEffect(() => {
    const getDeviceId = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        setDeviceInfo((prev) => ({ ...prev, deviceId: result.visitorId }));
      } catch (error) {
        console.error("Error fetching device ID:", error);
      }
    };

    const getDeviceDetails = () => {
      const userAgent = navigator.userAgent;
      let browserName = 'Unknown';
      let osName = 'Unknown';

      // Detect Browser
      if (userAgent.includes("Firefox")) {
        browserName = "Firefox";
      } else if (userAgent.includes("Chrome")) {
        browserName = "Chrome";
      } else if (userAgent.includes("Safari")) {
        browserName = "Safari";
      } else if (userAgent.includes("Edge")) {
        browserName = "Edge";
      } else if (userAgent.includes("Opera") || userAgent.includes("OPR")) {
        browserName = "Opera";
      }

      // Detect OS
      if (userAgent.includes("Win")) {
        osName = "Windows";
      } else if (userAgent.includes("Mac")) {
        osName = "macOS";
      } else if (userAgent.includes("Linux")) {
        osName = "Linux";
      } else if (userAgent.includes("Android")) {
        osName = "Android";
      } else if (userAgent.includes("like Mac")) {
        osName = "iOS";
      }

      setDeviceInfo((prev) => ({
        ...prev,
        browserName,
        osName,
      }));
    };

    getDeviceId();
    getDeviceDetails();
  }, []);

  return deviceInfo;
};

export default useDeviceInfo;
