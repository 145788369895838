import { useAuth } from "../../components/context/Auth";
import {
  ManagerSortByIcon,
  ManagerWareHouseIcon,
  TestTopAddDealerIcon,
} from "../../components/icons/Icon";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./ManagerJsx.module.css";
import DealerInfoCard from "../../components/updatedComponents/Dealers/DealerInfoCard";
import DealerStatusCard from "../../components/updatedComponents/Dealers/DealerStatusCard";
import Button from "../../components/updatedComponents/Dealers/Button";
import DropDown from "../../components/updatedComponents/FormComponents/DropDown";
import SearchBar from "../../components/updatedComponents/FormComponents/SearchBar";
import fetchData from "../../APIsControll/apiControll";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import GoToPage from "../../components/updatedComponents/Pagnation/GoToPage";
import Pagenation from "../../components/updatedComponents/Pagnation/Pagenation";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ManagerDevicesTable from "../../components/updatedComponents/Manager/ManagerDevicesTable";
import { RequestForDevice, TemplateNotFound } from "../../components/icons/Icon2";
import LocationSarchFilter from "../../components/updatedComponents/Filter/LocationSarchFilter";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";
import NoDataFound from "../../components/updatedComponents/NoDataFound/NoDataFound";

const Managers = () => {
  const { mode, managerId } = useAuth();
  const containerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [managersData, setManagersData] = useState([]);
  const [managersData1, setManagersData1] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showAllRequest, setShowallRequest] = useState(false);
  const [requestLength, setRequestLength] = useState(0);
  const [allDesignations, setAllDesignations] = useState([]);
  const [AllWarehouse, setAllWarehouse] = useState([]);
  const [sarch, setSearch] = useState("");
  const [Isstatus, setIsstatus] = useState("All");
  const [AllDesignation, setAllDesignation] = useState("");
  const [Allwarehousename, setAllwarehousename] = useState("");
  const [status, setStatus] = useState("");

  // console.log("Allwarehousename",AllWarehouse);

  const [state, setState] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: []
  })
  const [District, setDistrict] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: []
  })
  const [City, setCity] = useState({
    locationList: [],
    searchValue: "",
    selectFilter: []
  });



  useEffect(() => {
    const getDealerLoction = async () => {
      try {
        const payload = {
          managerId: managerId,
          stateSearch: "",
          districtSearch: "",
          citySearch: "",
          pincodeSearch: "",
          locations: {
            states: [],
            districts: [],
            cities: [],
            pincodes: []
          }
        }
        const res = await fetchData(`warehouses/filterLocation`, payload);
        setDistrict((pre) => ({ ...pre, locationList: res?.data?.districts }));
        setState((pre) => ({ ...pre, locationList: res?.data?.states }));
        setCity((pre) => ({ ...pre, locationList: res?.data?.cities }));
      } catch (error) {

      }
    }
    getDealerLoction()
  }, [managerId])



  useEffect(() => {
    const getAllDesignations = async () => {
      try {
        const payload = { managerId }
        const AllDesignations = await fetchData(`managers/getAllDesignations`);
        const AllWarehouse = await fetchData(`warehouses/getAllWarehouse`, payload);
        setAllDesignations(AllDesignations.data);
        setAllWarehouse(AllWarehouse.data);
      } catch (error) {

      }
    }
    getAllDesignations()
  }, [managerId])




  useEffect(() => {
    const fetchAllManagers = async () => {

      try {
        setLoading(true);

        const apiData = {
          managerId,
          keyword: sarch,
          warehousename: Allwarehousename,
          designation: AllDesignation,
          status: Isstatus.toLocaleLowerCase() === "all" ? "" : Isstatus.toLocaleLowerCase(),
          locations: {
            states: state.searchValue,
            districts: District.selectFilter,
            cities: City.selectFilter,
            pincodes: []
          }

        };
        const endpoint = `managers/allManagersList?page=${page}&limit=${32}`;
        const result = await fetchData(endpoint, apiData);
        if (result.status === "success") {
          setManagersData((prevDealerData) => {
            const newData = result?.data;
            if (
              state.selectFilter ||
              District.selectFilter ||
              City.selectFilter
            ) {
              return newData;
            }

            const mergedData = [...prevDealerData, ...newData];
            // const uniqueData = mergedData.filter(
            //   (item, index, self) => index === self.findIndex((t) => t.dealerId === item.dealerId)
            // );
            return mergedData;
          })
          setManagersData1(result)
          setPages(result.pages);
          if (page >= result.pages) {
            setHasMore(false);
          }
        }
        if (result.status === "failed") {
          setManagersData([]);
          setPage(1)
        }
      } catch (error) {
        console.error("Error while fetching managers:", error);
      } finally {
        setLoading(false);
      }
    };

    if (managerId) {
      fetchAllManagers();
    }
  }, [managerId, page, state.selectFilter, District.selectFilter, City.selectFilter, sarch, Isstatus, AllDesignation, Allwarehousename, status]);

  useEffect(() => {
    const handleScroll = () => {
      if (loading || !hasMore) return;
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;

      if (scrollTop + clientHeight >= scrollHeight - 50) {
        setPage((prevPage) => prevPage + 1);
        if (!hasMore) {
          console.log("No more data to load.");
        }
      }
    };
    const container = containerRef.current;
    if (container) {
      // container.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (container) {
        // container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading, hasMore]);

  const formatText = (t) => {
    if (typeof t !== "string") {
      return t;
    }

    if (t === "superadmin") {
      return "Super Admin";
    }

    const baseName = t.replace(/manager$/, "");

    const capitalizedBaseName = baseName
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return capitalizedBaseName.trim();
  };


  const mangerStatusData = [
    { statusText: "All", statusItemCount: managersData1.total || 0 },
    { statusText: "Active", statusItemCount: managersData1.statusCounts?.active || 0 },
    { statusText: "Disabled", statusItemCount: managersData1.statusCounts?.disable || 0 },
  ];


  return (
    <>
      <ModalWrapper isOpen={showAllRequest} onClose={() => setShowallRequest(!showAllRequest)}  >
        <ManagerDevicesTable setRequestLength={setRequestLength} modelTogele={showAllRequest} />
      </ModalWrapper>



      <div
        style={
          mode ? { backgroundColor: "#2C2E33", borderColor: "#2C2E33" } : {}
        }
        className={styles.mgx}
      >
        <div className={styles.mgx_top}  >
          <TopTabBar text={location.pathname.slice(1, location.pathname.length)} />
          <div className={styles.mgx_top_right}>
            <div className={styles.mgx_add_manager_btn}>
              <Button
                buttonIcon={<RequestForDevice />}
                buttonText={"Request"}
                buttonBgColor={"#000"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => setShowallRequest(!showAllRequest)}
                notify={requestLength === 0 ? false : true}
                count={requestLength}
              />
            </div>
            <div className={styles.mgx_add_manager_btn}>
              <Button
                buttonIcon={<TestTopAddDealerIcon />}
                buttonText={"Add Manager"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/newmanager");
                }}
              />
            </div>
          </div>
        </div>

        <div className={styles.mgx_mob_filters}  >
          <div className={styles.mgx_mob_filters_top}>
            <div style={{ width: "40%" }}>
              <SearchBar />
            </div>
            <ManagerWareHouseIcon />
            <ManagerSortByIcon />
            <div>
              <Button
                buttonIcon={<TestTopAddDealerIcon />}
                buttonText={"Add Manager"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#ffffff"}
                buttonBorder={"none"}
                buttonClick={() => {
                  navigate("/newmanager");
                }}
              />
            </div>
          </div>
          <div className={styles.mgx_mob_filters_top}>
            <DropDown label={"State"} />
            <DropDown label={"District"} />
            <DropDown label={"City"} />
          </div>
        </div>

        <div
          className={styles.mgx_middle}
          style={
            mode ? { backgroundColor: "#232529",
               borderColor: "#232529" }
              : {}
          }
        >
          {mangerStatusData.map((status) => (
            <div key={status.statusText}
              className={`${styles.receipts_filter} ${mode ? styles.receipts_filter_dark : ""
                } ${status.statusText === Isstatus
                  ? styles.receipts_filter_active
                  : ""
                }`} onClick={() => setIsstatus(status.statusText)} >
              <DealerStatusCard
                manager
                statusText={status.statusText}
                statusItemCount={status.statusItemCount}
              />
            </div>
          ))}
        </div>

        <div
          className={styles.mgx_bottom}
          style={
            mode ? { backgroundColor: "#2C2E33", } : {}
          }
        >
          <div
            className={styles.mgx_top}
            // style={{ padding: "10px 10px 5px 10px" }}
            style={{ marginBottom: "10px" }}
          >
            <div>
              <SearchBar placeholder={"Search Managers"} onChange={(event) => setSearch(event.target.value)} />
            </div>

            <div className={styles.mgx_top_right}>
              <DropDown label={"Designation"}
                options={allDesignations?.map((e) => ({
                  label: e.designation.charAt(0).toUpperCase() + e.designation.slice(1).toLowerCase(),
                  value: e.designation
                }))}
                onSelect={(value) => setAllDesignation(value.value)}
                onReject={() => setAllDesignation("")}
              />
              <LocationSarchFilter
                text={"State"}
                list={state.locationList}
                search={state.searchValue}
                setSearch={(value) =>
                  setState((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={state.selectFilter}
                setSelectFilter={(value) =>
                  setState((prevState) => ({ ...prevState, selectFilter: value }))
                }
              />
              <LocationSarchFilter
                text={"District"}
                list={District.locationList}
                search={District.searchValue}
                setSearch={(value) =>
                  setDistrict((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={District.selectFilter}
                setSelectFilter={(value) =>
                  setDistrict((prevState) => ({ ...prevState, selectFilter: value }))
                }
              />
              <LocationSarchFilter
                text={"City"}
                list={City.locationList}
                search={City.searchValue}
                setSearch={(value) =>
                  setCity((prevState) => ({ ...prevState, searchValue: value }))
                }
                selectFilter={City.selectFilter}
                setSelectFilter={(value) =>
                  setCity((prevState) => ({ ...prevState, selectFilter: value }))
                }
              />
              <DropDown label={"Warehouse"} options={AllWarehouse?.map((item) => ({
                label: item?.warehousename,
                value: item?.warehousename
              }))}
                onSelect={(value) => setAllwarehousename(value.value)}
                onReject={() => setAllwarehousename("")}
              />
              {/* <DropDown label={"Status"} options={[
                { label: "Active", value: "active" },
                { label: "Disable", value: "disable" },
              ]}
                onSelect={(val) => setStatus(val.value)}
                onReject={() => setStatus("")}
              /> */}
            </div>
          </div>

          <div className={styles.maindivscroll}>
            <div
              className={styles.mgx_data}
              style={
                mode ? { backgroundColor: "#2C2E33", } : {}
              }
              ref={containerRef}
            >
              {loading ? <>  </> :
                managersData.length === 0 ? <>
                  <div> </div>
                  <div> </div>
                  <div> </div>
                  <div> </div>
                  <div style={{ display: "flex", width: "100%", border: "1px solid" }}>
                    <NoDataFound
                      icon={<TemplateNotFound />}
                      to={"/templates/template"} />
                  </div>
                  <div> </div>
                  <div> </div>
                  <div> </div>
                  <div> </div>


                </>
                  :
                  managersData.map((item, i) => (
                    <div
                      className={styles.mgx_manager_card}
                      key={i}
                      onClick={() => {
                        navigate("/manager?manager_Id=" + item._id);
                      }}
                    >
                      <DealerInfoCard
                        dealerName={item.name}
                        dealerStatus={item.status}
                        dealerDesignation={item.designation
                          .map((d) => formatText(d))
                          .join(", ")}
                        type={"manager"}
                      />
                    </div>
                  ))}
              <div style={{ margin: "auto" }}>
                {loading && (
                  <Spinner
                    height={80}
                    width={80}
                    borderWidth={"5px"}
                    color={mode ? "#000000" : "#4164E3"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.mgx_pagination} style={{ backgroundColor: mode ? "#232529" : "#fff" }} >
          <div style={{ width: "50%" }}>
            <div
              style={{
                width: "50%",
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "14px",
              }}
            >
              <GoToPage
                currentPage={page}
                setCurrentPage={setPage}
                totalPages={pages}
                key={"1"}
              />
            </div>
          </div>
          <div
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "flex-end",
              fontSize: "12px",
            }}
          >
            <Pagenation
              currentPage={page}
              setCurrentPage={setPage}
              totalPages={pages}
              key={"1"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Managers;
// import React, { useEffect, useState } from "react";
// import { useNavigate, useSearchParams } from "react-router-dom";
// import AddDealerModal from "../../components/AddDealerModal";
// import { GreenOutButton } from "../../components/Buttons";
// import { useAuth } from "../../components/context/Auth";
// import {
//   AddDealerIcon,
//   Ban,
//   Call,
//   Checked,
//   Messanger,
//   Search,
//   WhatsApp,
// } from "../../components/icons/Icon";
// import Pagination from "../../components/Paginantion";

// // import { AddDealerModal } from '../ordermanager/SaleOrders'
// import styles from "../kyc/Dealer.module.css";

// const Managers = () => {
//   const navigate = useNavigate();
//   const [searchparams] = useSearchParams();
//   const page = searchparams.get("page")
//     ? parseInt(searchparams.get("page"))
//     : 1;

//   const [showAddDealer, setShowAddDealer] = useState(false);
//   const [dealerData, setDealerData] = useState(false);
//   const { managerId } = useAuth();
//   const [managersData, setManagersData] = useState([]);
//   const [loading, setLoading] = useState(false);
//   const [width, setWidth] = useState(window.innerWidth);
//   useEffect(() => {
//     window.addEventListener("resize", () => {
//       return setWidth(window.innerWidth);
//     });
//     window.removeEventListener("resize", () => {
//       return setWidth(window.innerWidth);
//     });
//   }, []);
//   const [searchDealerData, setSearchDealerData] = useState({
//     searchDealerName: "",
//     sortByStatus: "",
//   });
//   const [state, setState] = React.useState({
//     totalPages: "",
//     currentPage: 1,
//   });

//   const { totalPages } = state;

//   useEffect(() => {
//     if (managerId) {
//       setLoading(true);
//       var myHeaders = new Headers();
//       myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
//       myHeaders.append("Content-Type", "application/json");

//       var raw = JSON.stringify({
//         managerId: managerId,
//         keyword: searchDealerData.searchDealerName,
//         sortKey: searchDealerData.sortByStatus,
//       });

//       var requestOptions = {
//         method: "POST",
//         headers: myHeaders,
//         body: raw,
//         redirect: "follow",
//       };

//       fetch(
//         process.env.REACT_APP_URL +
//           `managers/allManagersList?page=${page}&limit=${
//             width > 1600 ? "18" : "15"
//           }`,
//         requestOptions
//       )
//         .then((response) => response.json())
//         .then((result) => {
//           if (result.status === "success") {
//             if (result.managers) {
//               setLoading(false);
//               setManagersData(result.managers);
//             }
//             if (result.pages) {
//               setState((prevState) => ({
//                 ...prevState,
//                 totalPages: result.pages ? result.pages : "",
//               }));
//             }
//           }
//         })
//         .catch((error) => console.log("error", error));
//     }
//   }, [
//     managerId,
//     searchDealerData.sortByStatus,
//     searchDealerData.searchDealerName,
//     page,
//     width,
//   ]);

//   useEffect(() => {
//     if (dealerData.dealerId) {
//       navigate("/dealers/" + dealerData.dealerId);
//     }
//   }, [dealerData.dealerId, navigate]);

//   return (
//     <React.Fragment>
//       <AddDealerModal
//         show={showAddDealer}
//         close={setShowAddDealer}
//         setData={setDealerData}
//       />

//       <div className={styles.right_main}>
//         <div className={styles.addDealers}>
//           <h1 className={styles.dealers}>Managers</h1>
//           <GreenOutButton
//             btnType="button"
//             title={
//               <>
//                 <AddDealerIcon />
//                 &nbsp;&nbsp;Add Manager
//               </>
//             }
//             css={styles.addDealerButton}
//             handleSubmit={() => {
//               navigate("/manager");
//             }}
//             //   handleSubmit={() => { setShowAddDealer(!showAddDealer) }}
//           />
//         </div>
//         {loading ? (
//           <div className={"loadingMain"}>
//             <img src="/wallicon.gif" alt="walliconGIF" />
//           </div>
//         ) : (
//           <div className={styles.Dealer_main_container}>
//             <div className={styles.dealer_header}>
//               <div className={styles.dealer_search_div}>
//                 <input
//                   type="search"
//                   placeholder="Search"
//                   className={styles.dealer_search}
//                   value={searchDealerData.searchDealerName}
//                   onChange={(e) =>
//                     setSearchDealerData({
//                       ...searchDealerData,
//                       searchDealerName: e.target.value,
//                     })
//                   }
//                 />
//                 <Search color="#ffffff" css={styles.dealer_search_icon} />
//               </div>
//               <div className={styles.dealer_header_right}>
//                 <div className="">
//                   <label className={styles.dealer_sortBy_txt}>Sort By </label>
//                   <select
//                     className={styles.dealer_sortBy_select}
//                     value={searchDealerData.sortByStatus}
//                     onChange={(e) =>
//                       setSearchDealerData({
//                         ...searchDealerData,
//                         sortByStatus: e.target.value,
//                       })
//                     }
//                   >
//                     <option className={styles.dealer_sortBy_color} value="">
//                       View All
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Referral"
//                     >
//                       Referral
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Widthout OTP"
//                     >
//                       Widthout OTP
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Managers Created"
//                     >
//                       Managers Created
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Self Created"
//                     >
//                       Self Created
//                     </option>
//                     <option className={styles.dealer_sortBy_color} value="New">
//                       New
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="newestfirst"
//                     >
//                       Newest First
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Pending"
//                     >
//                       Not Verified
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Verified"
//                     >
//                       Verified
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Ascending"
//                     >
//                       Ascending
//                     </option>
//                     <option
//                       className={styles.dealer_sortBy_color}
//                       value="Descending"
//                     >
//                       Descending
//                     </option>
//                   </select>
//                 </div>
//                 {/* <div className={styles.dealer_header_select_days}>
//                             <div className={`${styles.dealer_days_select} ${styles.days_active}`}>All</div>
//                             <div className={styles.dealer_days_select}>15 Days</div>
//                             <div className={styles.dealer_days_select}>15-30 Days</div>
//                             <div className={styles.dealer_days_select}>30 Days</div>
//                          </div> */}
//               </div>
//             </div>
//             <div className={styles.dealer_main_div}>
//               {managersData &&
//                 managersData.map((item, index) => (
//                   <div className={styles.dealer_card} key={index}>
//                     <div className={styles.dealer_card_div}>
//                       <div
//                         onClick={() => {
//                           navigate("/manager?manager_Id=" + item._id);
//                         }}
//                         className={styles.cursor_pointer}
//                       >
//                         <div className={styles.dealer_status}>
//                           {item.status === "active" ? (
//                             <Checked
//                               color="#1FFC33"
//                               css={styles.dealer_status_icon}
//                             />
//                           ) : (
//                             <Ban
//                               color="#F93E3E"
//                               css={styles.dealer_status_icon}
//                             />
//                           )}
//                         </div>

//                         {!item.profileImage ? (
//                           <div className={styles.name_char}>
//                             <span className={styles.name_char_mb}>
//                               {item && item.name.charAt(0).toUpperCase()}
//                             </span>
//                           </div>
//                         ) : (
//                           <img
//                             src={
//                               process.env.REACT_APP_S3URL + item.profileImage
//                             }
//                             className={styles.dealer_image}
//                             alt="dealer_image"
//                           />
//                         )}

//                         <h4 className={styles.managerName}>{item.name}</h4>
//                         <div className={styles.dealeStore_owner}>
//                           {item.designation}
//                         </div>
//                       </div>
//                       <div className={styles.dealer_btn_div}>
//                         <button className={styles.dealer_Btn}>
//                           <Messanger color="#ffffff" width={16} />
//                         </button>
//                         <a
//                           href={"tel:+91" + item.mobile}
//                           className={styles.dealer_Btn}
//                         >
//                           <Call color="#ffffff" width={16} />
//                         </a>
//                         <a
//                           target="_blank"
//                           rel="noreferrer"
//                           href={"https://wa.me/+91" + item.whatsapp}
//                           className={styles.dealer_Btn}
//                         >
//                           <WhatsApp width={16} />
//                         </a>
//                       </div>
//                     </div>
//                   </div>
//                 ))}
//             </div>
//             <Pagination total={totalPages} current={page} />
//           </div>
//         )}
//       </div>
//     </React.Fragment>
//   );
// };

// export default Managers;
