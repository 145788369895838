import React, { createContext, useContext, useState, useEffect, useRef } from "react";
const InactivityContext = createContext();

export const InactivityProvider = ({ children }) => {
  const [isActive, setIsActive] = useState(true);
  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const inactivityTimeoutRef = useRef(null);

  const resetInactivityTimeout = () => {
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
    }
    inactivityTimeoutRef.current = setTimeout(() => {
      const loginId = sessionStorage.getItem("loginID");
      if (loginId) {
        setIsActive(false);
        setShowModal(true); // Show modal on inactivity
      }
    }, 60 * 60 * 1000); // 20 min timeout
    // }, 5 *  1000); // 20 min timeout
  };

  const handleActivity = () => {
    setIsActive(true);
    if (showModal) {
      setShowModal(false); // Close modal on user activity
    }
    resetInactivityTimeout(); // Reset inactivity timer
  };

  useEffect(() => {
    // Add event listeners for user activity
    window.addEventListener("mousemove", handleActivity);
    window.addEventListener("keypress", handleActivity);
    window.addEventListener("click", handleActivity);

    resetInactivityTimeout(); // Start the inactivity timeout

    return () => {
      // Cleanup event listeners and timeout
      window.removeEventListener("mousemove", handleActivity);
      window.removeEventListener("keypress", handleActivity);
      window.removeEventListener("click", handleActivity);

      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
    };
  }, []);

  return (
    <InactivityContext.Provider value={{ isActive, showModal, setShowModal }}>
      {children}
    </InactivityContext.Provider>
  );
};

export const useInactivity = () => useContext(InactivityContext);
