export const handleFileData = async (endpoint, apiData, timeout = 300000) => {
  const controller = new AbortController();
  const signal = controller.signal;
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  try {
    const response = await fetch(`${process.env.REACT_APP_URL}${endpoint}`, {
      method: "POST",
      headers: {
        "wa-platform": process.env.REACT_APP_ADMIN_SECRET,
      },
      body: apiData,
      signal: signal,
    });

    clearTimeout(timeoutId);
    const result = await response.json();
    return result;
  } catch (error) {
    clearTimeout(timeoutId);
    return error;
  }
};
