import { useState } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { CancelIcon2, RoundedTickIcon } from "../../icons/Icon";
import Button from "../Dealers/Button";
import { useToast } from "../Toaster/Toaster";

const RequestForCancelModal = ({ handleCloseModal, mode, handleSubmit }) => {
  const { show } = useToast();
  const [reason, setReason] = useState("");
  const handleClick = () => {
    if (reason === "") {
      show("The reason must be greater than 20 characters!", "warning");
    } else {
      handleSubmit(reason);
      handleCloseModal();
    }
  };
  return (
    <div
      className={styles.req_for_cancel}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <p style={mode ? { color: "#FFFFFF" } : {}}>Request for Cancel</p>
      <p style={mode ? { color: "#FFFFFF" } : {}}>
        Enter the reason why you need to cancel this order.
      </p>
      <textarea
        name=""
        id=""
        placeholder="*minimun 20 characters"
        required
        rows="4"
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        className={`${styles.textarea} ${mode ? styles.textarea_dark : ""}`}
      ></textarea>
      <div>
        <Button
          buttonIcon={<CancelIcon2 />}
          buttonText={"Cancel"}
          buttonBgColor={"#000000"}
          buttonTextColor={"#FFFFFF"}
          buttonClick={handleCloseModal}
        />
        <Button
          buttonIcon={<RoundedTickIcon />}
          buttonText={"Submit"}
          buttonBgColor={"#4164E3"}
          buttonTextColor={"#FFFFFF"}
          buttonClick={handleClick}
        />
      </div>
    </div>
  );
};

export default RequestForCancelModal;
