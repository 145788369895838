import styles from "../../../pages/ordermanager/Orders.module.css";

const ItemList = ({ index, d, mode }) => {
  const style = mode ? { backgroundColor: "#232529" } : {};
  return (
    <>
      <li
        className={`${styles.p_table_row} ${styles.hide_760px} ${
          mode ? styles.p_table_row_dark : ""
        }`}
      >
        <div className={styles.entry_2}> {index + 1} </div>
        <div className={styles.entry_2}>{d.itemNo}</div>
        <div className={styles.entry_2}> {d.product_name} </div>
        <div className={styles.entry_2}>{d.warehouse}</div>
        <div className={styles.entry_2}>{d.qty}</div>
        <div className={styles.entry_2}> {d.unit}</div>
        <div className={styles.entry_2}> {d.price}</div>
        <div className={styles.entry_2}> {d.price * d.qty}</div>
      </li>
      {d.desc !== "" ? (
        <div style={mode ? { color: "#ffffff" } : {}} className={styles.desc}>
          {d.desc}
        </div>
      ) : null}

      <div
        className={`${styles.show_760px} ${mode ? styles.show_760px_dark : ""}`}
        style={{ marginTop: "0.25rem" }}
      >
        <div className={styles.show_760px_1}>
          <p style={mode ? { color: "#FFFFFF" } : {}}>
            <span>{index + 1}.</span> Item Information{" "}
          </p>
        </div>
        <div className={`${styles.show_760px_2}`} style={style}>
          <div>
            <p>{d.itemNo}</p>
          </div>
          <div>
            <p>{d.product_name}</p>
          </div>
          <div>
            <p>{d.warehouse}</p>
          </div>
        </div>
        <div className={styles.show_760px_3}>
          <div className={styles.element}>
            <p>Qty</p>
            <div style={style}>
              <p>{d.qty}</p>
            </div>
          </div>
          <div className={styles.element}>
            <p>Unit</p>
            <div style={style}>
              <p>{d.unit}</p>
            </div>
          </div>
          <div className={styles.element}>
            <p>Rate</p>
            <div style={style}>
              <p>{d.price}</p>
            </div>
          </div>
          <div className={styles.element}>
            <p>Amount</p>
            <div style={style}>
              <p>{d.price * d.qty}</p>
            </div>
          </div>
        </div>
        {d.desc !== "" ? (
          <div style={style} className={styles.show_760px_4}>
            {d.desc}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default ItemList;
