import { useState } from "react";
import styles from "../../../pages/superadmin/Receipts.module.css";
import { CancelIcon2, RevertIcon, RoundedTickIcon } from "../../icons/Icon";
import Button from "../Dealers/Button";
import { useToast } from "../Toaster/Toaster";
import fetchData from "../../../APIsControll/apiControll";

const CancelReceiptModal = ({
  mode,
  managerId,
  handleCloseModal,
  receiptId,
  orderId,
  revertCase = "false",
  onActionCompleted,
}) => {
  const [reason, setReason] = useState("");
  const { show } = useToast();

  const handleCancelReceipt = async () => {
    const apiData = {
      managerId: managerId,
      receiptId: receiptId,
      orderId: orderId,
      reason: reason,
    };
    try {
      const result = await fetchData("receipt/cancelreceipt", apiData);
      if (result.status === "success") {
        show(result.msg, "success");
        onActionCompleted();
      }
      if (result.status === "failed") {
        show(result.msg, "error");
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const hanldeRevertReceipt = async () => {
    const apiData = {
      managerId: managerId,
      receiptId: receiptId,
      orderId: orderId,
      description: reason,
    };
    try {
      const result = await fetchData("receipt/revertReceipt", apiData);
      if (result.status === "success") {
        show(result.msg, "success");
        onActionCompleted();
      }
      if (result.status === "failed") {
        show(result.msg, "error");
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  const handleClick = () => {
    if (reason.length < 20) {
      show("Enter a reason with minimum 20 characters!", "warning");
    } else {
      if (revertCase === "true") {
        hanldeRevertReceipt();
      } else {
        handleCancelReceipt();
      }
      handleCloseModal();
    }
  };

  return (
    <>
      <div
        className={styles.cancel_receipt_modal}
        style={mode ? { backgroundColor: "#232529" } : {}}
      >
        <>
          <p style={mode ? { color: "#FFFFFF" } : {}}>
            {revertCase === "true" ? "Revert Receipt" : "Request for Cancel"}
          </p>
          <p style={mode ? { color: "#FFFFFF" } : {}}>
            Enter the reason why you need to{" "}
            {revertCase === "true" ? "revert" : "cancel"} this receipt.
          </p>
          <textarea
            name=""
            id=""
            placeholder="*enter reason here (not less than 20 Characters)"
            required
            rows="4"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            className={`${styles.textarea} ${mode ? styles.textarea_dark : ""}`}
          ></textarea>
          <div>
            <Button
              buttonIcon={<CancelIcon2 />}
              buttonText={"Cancel"}
              buttonBgColor={"#000000"}
              buttonTextColor={"#FFFFFF"}
              buttonClick={handleCloseModal}
            />
            {revertCase === "true" ? (
              <Button
                buttonIcon={<RevertIcon color={"#FFFFFF"} />}
                buttonText={"Revert Back"}
                buttonBgColor={"#F93E3E"}
                buttonTextColor={"#FFFFFF"}
                buttonClick={handleClick}
              />
            ) : (
              <Button
                buttonIcon={<RoundedTickIcon />}
                buttonText={"Submit"}
                buttonBgColor={"#4164E3"}
                buttonTextColor={"#FFFFFF"}
                buttonClick={handleClick}
              />
            )}
          </div>
        </>
      </div>
    </>
  );
};

export default CancelReceiptModal;
