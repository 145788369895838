import React from 'react'
import styles from "./Style.module.css"
import { useAuth } from '../../context/Auth'
import DealerStatusCard from '../Dealers/DealerStatusCard';
const CommonDashboard = () => {
    const { mode, designations } = useAuth();
    const RemainingRoles = ["inventorymanager", "ordermanager", "dispatchmanager",
        "packingmanager", "invoicemanager", "contentmanager", "superadmin", "paymentmanager",
    ]

    return (
        <div className={styles.compoContiner}>
            <div className={styles.scandMainCon} style={{
                backgroundColor: mode ? "#2C2E33" : "#fff"
            }}>
                {/* Order */}
                {(designations.includes("superadmin") || designations.includes("ordermanager")) &&
                    <div className={styles.mainCompo} style={{
                        color: mode ? "#fff" : "#000"
                    }}>
                        <div className={styles.mainText}>
                            <h1>Order</h1>
                        </div>
                        <div className={styles.cardDash}>
                            {Array.from({ length: 9 }).map((_, i) => (
                                <div className={styles.cardContainer} key={i}>
                                    <DealerStatusCard
                                        dealerStatus={"hee"}
                                        statusText={"jjjj"}
                                        statusItemCount={20}
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                }

                {(designations.includes("superadmin") || designations.includes("ordermanager")) &&
                    <div className={styles.mainCompo} style={{
                        color: mode ? "#fff" : "#000"
                    }}>
                        <div className={styles.mainText}>
                            <h1>Recipt </h1>
                        </div>
                        <div className={styles.cardDash}>
                            {Array.from({ length: 9 }).map((_, i) => (
                                <div className={styles.cardContainer} key={i}>
                                    <DealerStatusCard
                                        dealerStatus={"hee"}
                                        statusText={"jjjj"}
                                        statusItemCount={20}
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                }
                {/* Recipt */}
                {(designations.includes("superadmin") || designations.includes("dispatchmanager")) &&
                    <div className={styles.mainCompo} style={{
                        color: mode ? "#fff" : "#000"
                    }}>
                        <div className={styles.mainText}>
                            <h1>Order</h1>
                        </div>
                        <div className={styles.cardDash}>
                            {Array.from({ length: 9 }).map((_, i) => (
                                <div className={styles.cardContainer} key={i}>
                                    <DealerStatusCard
                                        dealerStatus={"hee"}
                                        statusText={"jjjj"}
                                        statusItemCount={20}
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                }

                {/* Product */}
                {(designations.includes("superadmin") || designations.includes("contentmanager")) &&
                    <div className={styles.mainCompo} style={{
                        color: mode ? "#fff" : "#000"
                    }}>
                        <div className={styles.mainText}>
                            <h1>Product </h1>
                        </div>
                        <div className={styles.cardDash}>
                            {Array.from({ length: 9 }).map((_, i) => (
                                <div className={styles.cardContainer} key={i}>
                                    <DealerStatusCard
                                        dealerStatus={"hee"}
                                        statusText={"jjjj"}
                                        statusItemCount={20}
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                }
                {/* inventory  */}
                {(designations.includes("superadmin") || designations.includes("invoicemanager") || designations.includes("contentmanager")) &&
                    <div className={styles.mainCompo} style={{
                        color: mode ? "#fff" : "#000"
                    }}>
                        <div className={styles.mainText}>
                            <h1>inventory</h1>
                        </div>
                        <div className={styles.cardDash}>
                            {Array.from({ length: 9 }).map((_, i) => (
                                <div className={styles.cardContainer} key={i}>
                                    <DealerStatusCard
                                        dealerStatus={"hee"}
                                        statusText={"jjjj"}
                                        statusItemCount={20}
                                    />
                                </div>
                            ))}

                        </div>
                    </div>
                }

            </div>
        </div>

    )
}

export default CommonDashboard