import styles from "../../../pages/superadmin/Receipts.module.css";
import { Close } from "../../icons/Icon";
import Button from "../Dealers/Button";

const Info = ({ mode, handleCloseModal, status }) => {
  const getStatusColor = (status) => {
    if (status === "Received") {
      return "#31974D";
    } else if (status === "Cancelled") {
      return "red";
    } else if (status.includes("Pending")) {
      return "#FF9B04";
    } else if (status === "Refund") {
      return "#F56221";
    } else {
      return "";
    }
  };

  return (
    <div
      className={styles.cancelled_receipt_modal}
      style={mode ? { backgroundColor: "#232529" } : {}}
    >
      <div className={styles.cancelled_receipt}>
        <p style={mode ? { color: "#FFFFFF" } : {}}>Information</p>
        <img
          src="/assets/updatedAssets/cancelledReceipt.png"
          alt="Cancelled Receipt"
        />
        <p style={mode ? { color: "#FFFFFF" } : {}}>
          You cannot perform this action as the status of this receipt is{" "}
          <span style={{ color: getStatusColor(status) }}>{status}</span> !
        </p>
        <div>
          <Button
            buttonIcon={<Close />}
            buttonText={"Close"}
            buttonBgColor={"#000000"}
            buttonTextColor={"#FFFFFF"}
            buttonClick={handleCloseModal}
          />
        </div>
      </div>
    </div>
  );
};

export default Info;
