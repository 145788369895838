// import React from "react";
// import styles from "./DealerStyles.module.css";
// import { useAuth } from "../../context/Auth";

// const SlidingFilter = ({
//   itemList,
//   bgcolor,
//   setFilter,
//   selectedItem,
//   setSelectedItem,
// }) => {
//   const { mode } = useAuth();

//   const handleItemClick = (item) => {
//     setSelectedItem(item); 
//     if (item === "All") {
//       setFilter("");
//     } else {
//       setFilter(item);
//     }
//   };

//   return (
//     <div className={mode ? styles.sliding_filter_dark : styles.sliding_filter}>
//       <ul
//         className={styles.sliding_filter_list}
//         style={{
//           backgroundColor: mode ? "#1B1D21" : "#fff",
//           color: mode ? "#fff" : "#000",
//           ...(bgcolor ? { border: `1px solid ${bgcolor}` } : {}),
//         }}
//       >
//         {itemList.map((item, index) => (
//           <li
//             key={index}
//             className={`${styles.sliding_filter_item} ${
//               item === selectedItem ? styles.sliding_selected_item : ""
//             }`}
//             onClick={() => handleItemClick(item)}
//             style={
//               item === selectedItem
//                 ? bgcolor
//                   ? { backgroundColor: bgcolor }
//                   : mode
//                   ? { backgroundColor: "#000", color: "#fff" }
//                   : { backgroundColor: "#000", color: "#fff" }
//                 : {
//                     backgroundColor: "transparent",
//                     color: mode ? "#fff" : "#000",
//                   }
//             }
//           >
//             {item}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default SlidingFilter;

import React, { useState } from "react";
import styles from "./DealerStyles.module.css";
import { useAuth } from "../../context/Auth";

const SlidingFilter = ({ itemList, bgcolor, setFilter }) => {
  const { mode } = useAuth();
  const [selectedItem, setSelectedItem] = useState(itemList[0]);

  const handleItemClick = (item) => {
    setSelectedItem(item);
    if (item === "All") {
      setFilter("");
    } else {
      setFilter(item);
    }
  };

  return (
    <div className={mode ? styles.sliding_filter_dark : styles.sliding_filter}>
      <ul
        className={styles.sliding_filter_list}
        style={{
          backgroundColor: mode ? "#1B1D21" : "#fff",
          color: mode ? "#fff" : "#000",
          ...(bgcolor ? { border: `1px solid ${bgcolor}` } : {}),
        }}
      >
        {itemList.map((item, index) => (
          <li
            key={index}
            className={`${styles.sliding_filter_item} ${
              item === selectedItem ? styles.sliding_selected_item : ""
            }`}
            onClick={() => handleItemClick(item)}
            style={
              item === selectedItem
                ? bgcolor
                  ? { backgroundColor: bgcolor }
                  : mode
                  ? { backgroundColor: "#000", color: "#fff" }
                  : { backgroundColor: "#000", color: "#fff" }
                : {
                    backgroundColor: "transparent",
                    color: mode ? "#fff" : "#000",
                  }
            }
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SlidingFilter;
