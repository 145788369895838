import styles from "../../../pages/ordermanager/Orders.module.css";

const IconActionText = ({ icon, text, textColor, onClick }) => {
  return (
    <div className={styles.icon_action_text} onClick={onClick}>
      {icon}
      <p style={{ color: textColor && textColor }}>{text}</p>
    </div>
  );
};

export default IconActionText;
