import React from 'react'
import styles from '../../../pages/Home.module.css'
import { RequestPandingIcon } from '../../icons/Icon2';
import { useAuth } from '../../context/Auth';
import { LogInBtnIcon } from '../../icons/Icon';
const RequestPending = ({ loading , handleClick}) => {

    const { mode } = useAuth();
    return (
        <div
            className={styles.login_container}
            style={mode ? { backgroundColor: "#2C2E33" } : {}}
        >
            <div
                className={styles.login1}
                style={mode ? { backgroundColor: "#232529" } : {}}
            >
                <div className={styles.sub_Login}>
                    <div className={styles.login_Header}>
                        <div className={styles.login_Header_Text}>
                            <h2 style={mode ? { color: "#ffffff" } : {}}> Device Registration
                                Request Pending</h2>
                            <p style={mode ? { color: "#9C9797" } : {}}>
                                Your device registration is still pending. you have to wait for the approval.
                            </p>
                        </div>
                        <div>
                            <RequestPandingIcon />
                        </div>
                    </div>
                    <form
                        className={styles.login_Form}
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleClick();
                        }}
                    >

                        <button
                            type="submit"
                            disabled={loading}
                            className={styles.login_button}
                        >
                            {loading ? (
                                <>
                                    <span className={styles.spinner}></span>
                                    <p>Loading...</p>
                                </>
                            ) : (
                                <>
                                    <LogInBtnIcon />
                                    <p>Log Out</p>
                                </>
                            )}
                        </button>
                    </form>
                    <div style={{ marginTop: "40px" }}>   </div>
                    {/* {msg && <div className={styles.err}>{msg}</div>}
        {error && (
          <div className={styles.err}>
            Oops! Something went wrong. Please try again later.
          </div>
        )} */}
                </div>
            </div>
        </div>
    )
}

export default RequestPending




