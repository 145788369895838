import { useAuth } from "../../context/Auth";
import { NavbarArrowDownIcon } from "../../icons/Icon";
import styles from "./FromComponent.module.css";

const TextElement = ({
  label,
  value,
  labelColor,
  valueColor,
  fontSize,
  fontWeight,
  type,
}) => {
  const { mode } = useAuth();
  const formatTabText = (d) => {
    if (typeof d !== "string") {
      return d;
    }

    if (d === "superadmin") {
      return "Super Admin";
    }

    const isManager = d.endsWith("manager");
    let formatted = d;

    if (isManager) {
      const baseName = d.slice(0, -7);
      const capitalizedBaseName = baseName
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
      formatted = `${capitalizedBaseName} Manager`;
    }
    return formatted;
  };

  return (
    <div className={styles.text_element}>
      <p
        style={{
          color: labelColor,
          fontWeight: fontWeight ? fontWeight : "600",
          fontSize: fontSize && fontSize,
        }}
      >
        {label}
      </p>
      {Array.isArray(value) && value.length !== 0 ? (
        <div className={styles.designation_wrapper}>
          <p
            style={{
              color: valueColor,
            }}
          >
            <>
              {value.length}
              <NavbarArrowDownIcon />
            </>
          </p>
          <ul
            className={styles.hidden_designations}
            style={
              mode
                ? {
                    color: "#fff",
                    backgroundColor: "#2C2E33",
                    borderColor: "#2C2E33",
                  }
                : {}
            }
          >
            {value.map((v, i) => (
              <li
                className={`${styles.hidden_designations_item} ${
                  mode ? styles.hidden_designations_item_dark : ""
                }`}
                key={i}
              >
                {formatTabText(v)}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p
          style={{
            color: valueColor,
            fontSize: type === "order" && fontSize,
            textTransform: "capitalize",
          }}
        >
          {value}
        </p>
      )}
    </div>
  );
};

export default TextElement;
