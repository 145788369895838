import React, { useEffect, useRef, useState } from 'react'
import styles from './Camp.module.css'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import { useAuth } from '../../components/context/Auth'
import SearchBar from '../../components/updatedComponents/FormComponents/SearchBar'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import Button from '../../components/updatedComponents/Dealers/Button'
import { Pluse } from '../../components/icons/Icon3'
import { useNavigate } from 'react-router-dom'
import TimeOutFetch from '../../APIsControll/TimeOutFechControll'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import Skeleton from '../../components/updatedComponents/Skeleton/Skeleton'
import NoDataFound from '../../components/updatedComponents/NoDataFound/NoDataFound'
import { TemplateNotFound, ThreeDot } from '../../components/icons/Icon2'
import { changeDateFormat, TextColorCode, truncateText } from '../../Utils/TextUtils'
// import useDebounce from '../../Utils/Debounce'
const Groups = () => {
    const { mode, managerId } = useAuth();
    const { show } = useToast();
    const [subBox, setSubBox] = useState(null);
    const navigation = useNavigate();
    const [groupData, setGroupDsts] = useState([]);
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const boxRef = useRef(null);
    const [sarchKyewords, setsarchKeywords] = useState("")

    const [loading, setloading] = useState({
        loading1: false
    })
    const handeltogle = (index) => {
        setSubBox(subBox === index ? null : index)
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (boxRef.current && !boxRef.current.contains(event.target)) {
                setSubBox(null);  // Reset the subBox state when clicking outside
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const getGroups = async () => {
        setloading(prve => ({
            ...prve, loading1: true
        }))
        try {
            const payload = {
                managerId: managerId,
                keyword: sarchKyewords
            }

            const res = await TimeOutFetch(`wallikonCampaign/groupList?page=${page}`, payload);
            setloading(prve => ({
                ...prve, loading1: false
            }))
            if (res.status === "success") {
                // show(`${res.msg}`, "success");
                setGroupDsts(res?.data);
                setPages(res?.pages)
                setPage(res?.page)
                return
            }
            if (res.status === "failed") {
                setGroupDsts(res?.data);
                setPages(1)
                setPage(1)
                if (res.msg === "Group list not found!") {
                    return
                } else {
                    show(`${res.msg}`, "error")
                }
            } else {
                return show("Something went wrong", "error");

            };

        } catch (error) {
            return show("Something went wrong", "error");
        }
    }


    const stauseChane = async (item) => {
        setSubBox(null)
        try {
            const payload = {
                managerId: managerId,
                groupName: item
            }
            const res = await TimeOutFetch(`wallikonCampaign/changeGroupStatus`, payload);
            if (res.status === "success") {
                getGroups()
                return show(`${res.msg}`, "success");

            }
            if (res.status === "failed") {
                return show(`${res.msg}`, "error");

            } else {
                return show("Something went wrong", "error");

            };

        } catch (error) {
            return show("Something went wrong", "error");
        }
    }

    useEffect(() => {
        getGroups();
    }, [managerId, sarchKyewords, page]);

    return (
        <div className={styles.mainCintner}>
            <div className={styles.Continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC", border: mode ? "none" : "1px solid #e4e7eb" }}>
                <div className={styles.goBackCon}  >
                    <div className={styles.gobacFristChlid1}>
                        <div>
                            <TopTabBar text={"Groups"} />
                        </div>
                        <div style={{ width: "160px" }}>
                            <Button
                                buttonBgColor={"#4164E3"}
                                buttonText={"Create Group"}
                                buttonIcon={<Pluse />}
                                buttonTextColor={"#fff"}
                                buttonClick={() => navigation("/newgroup")}
                            // buttonClick={toggleDrawer}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.sarchBarConinnter}>
                    <div className={styles.sarchBarConinnterFristChild}>
                        <div className={styles.sarchconGrop}>
                            <div className={styles.sacrchGroup} >
                                <SearchBar placeholder={"Search Groups...."}
                                    onChange={(event) => setsarchKeywords(event.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.tableContiner}>
                    <div className={styles.container} style={{ height: "100%" }}>
                        <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                            <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                : { backgroundColor: "#D9DCE5" }} >
                                <div className={`${styles.col} `} style={{ width: "800px" }} >
                                    <div className={styles.TableHeadChackBox} >
                                        {/* <label className={styles.cl_checkbox} >
                                            <input type="checkbox" />
                                            <span></span>
                                        </label> */}
                                    </div>
                                    Group Name </div>
                                <div className={`${styles.col}`} > <span>Description </span>  </div>
                                <div className={styles.col} >Member</div>
                                <div className={styles.col} >Creation Date</div>
                                <div className={styles.col} >Status</div>
                                <div className={styles.col} ></div>
                            </li>
                            <div style={{ overflowY: "scroll", height: "100%" }}>

                                {
                                    loading?.loading1 ? <>  <Skeleton lines={8} width="100%" thickness={8} height='400px' /> </>
                                        : <>
                                            {
                                                groupData.length === 0 ? <>
                                                    <div style={{ height: "90%" }}>
                                                        <NoDataFound
                                                            icon={<TemplateNotFound />}
                                                            linkname={"add a template."}
                                                            textTitle={`No Dealers found. To run a WhatsApp campaign`}
                                                            to={"/newgroup"} /> </div>
                                                </> : <>
                                                    {
                                                        groupData?.map((e, i) =>
                                                            <li className={styles.tablerow}
                                                                style={mode ? { backgroundColor: "#1B1D21", color: "#fafafa", height: "60px" } : { backgroundColor: "#ffff", height: "60px" }} key={i}
                                                                onClick={() => navigation(`/newgroup/${e?.groupName}`)}
                                                            >
                                                                <div className={`${styles.col}`} >
                                                                    <div className={styles.TableHeadChackBox} >
                                                                        {/* <label className={styles.cl_checkbox} >
                                                                        <input type="checkbox" />
                                                                        <span></span>
                                                                    </label> */}
                                                                    </div>
                                                                    {truncateText(`${e?.groupName}`, 15)}
                                                                </div>
                                                                <div className={`${styles.col} `}>{truncateText(`${e?.description}`, 25)}</div>
                                                                <div className={styles.col}>{e?.member}</div>
                                                                <div className={styles.col}> {changeDateFormat(e?.createdAt, "-")}</div>
                                                                <div className={styles.col} style={{ color: TextColorCode(e?.status) }} >{e?.status === "Disable" ? "Disabled" : e?.status} </div>
                                                                <div className={styles.col} style={{ position: "relative" }} ref={boxRef}  >
                                                                    <span onClick={(e) => { e.stopPropagation(); handeltogle(i); }} style={{ width: "25px" }} >
                                                                        <ThreeDot mode={mode} /> </span>
                                                                    {
                                                                        subBox === null ? null :
                                                                            <div className={`${styles.subBox1} ${subBox === i ? styles.active : ''}`}
                                                                                style={{
                                                                                    backgroundColor: mode ? "#2C2E33" : "#fff",
                                                                                    // border: "1px solid #000",
                                                                                    top: "100%",
                                                                                    position: "absolute",
                                                                                    right: "100%",
                                                                                    borderRadius: "10px",
                                                                                    width: "180px"
                                                                                }}
                                                                                onClick={(e) => e.stopPropagation()}
                                                                            >
                                                                                {e?.status === "Active" &&
                                                                                    <div className={mode ? styles.sub_box_tab1 : styles.sub_box_tab1_light} onClick={() => stauseChane(e?.groupName)}  >
                                                                                        <span>Disable</span>
                                                                                    </div>
                                                                                }
                                                                                {e?.status === "Disable" &&
                                                                                    <div className={mode ? styles.sub_box_tab1 : styles.sub_box_tab1_light} onClick={() => stauseChane(e?.groupName)}  >
                                                                                        <span>Active</span>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                    }
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                </>
                                            }
                                        </>}
                            </div>
                            <li style={{ height: "20px" }}></li>
                        </ul>
                    </div>
                </div>
                <div>
                </div>

                <div className={styles.pagnationConintner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                    <div className={styles.gotToPage1} >
                        <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                    </div>
                    <div className={styles.gotToPage2}  >
                        <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Groups