



import { useAuth } from "../../context/Auth";
import { NavbarArrowDownDarkIcon, NavbarArrowDownIcon } from "../../icons/Icon";
import styles from "./FromComponent1.module.css";
import { useState, useEffect, useRef } from "react";

const DropDown2 = ({ label, options, listLabel, onSelect, onReject }) => {
  const [isOpen, setIsOpen] = useState(false); // To control dropdown open/close
  const [selectedOption, setSelectedOption] = useState(null); // Store selected option
  const [dropdownPosition, setDropdownPosition] = useState("bottom"); // For dropdown position (top/bottom)
  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);
  const { mode } = useAuth(); // Dark mode setting

  // Handle option selection
  const handleOptionSelect = (option) => {
    setSelectedOption(option); // Set the selected option
    onSelect(option); // Call the parent onSelect function
    setIsOpen(false); // Close the dropdown after selecting
  };

  // Handle click outside to close the dropdown
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current && !dropdownRef.current.contains(event.target) &&
        triggerRef.current && !triggerRef.current.contains(event.target)
      ) {
        setIsOpen(false); // Close dropdown if clicked outside
      }
    };

    const updateDropdownPosition = () => {
      if (triggerRef.current) {
        const rect = triggerRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        // Adjust dropdown position based on available space
        if (windowHeight - rect.bottom < 200) { // If not enough space below
          setDropdownPosition("top");
        } else {
          setDropdownPosition("bottom");
        }
      }
    };

    if (isOpen) {
      updateDropdownPosition();
      document.addEventListener("mousedown", handleClickOutside);
      window.addEventListener("resize", updateDropdownPosition); // Recalculate on window resize
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", updateDropdownPosition);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      window.removeEventListener("resize", updateDropdownPosition);
    };
  }, [isOpen]);

  return (
    <div className={styles.drop_down_container1} ref={dropdownRef} style={{ position: 'relative' }}>
      {/* <p className={styles.drop_down_label} style={mode ? { color: "#fff" } : {}}>
        {label} 
      </p> */}

      <div className={styles.drop_down_select}>
        <div
          ref={triggerRef}
          className={styles.drop_down_trigger}
          style={mode ? { backgroundColor: "#1B1D21", borderColor: "#1B1D21" } : {}}
          onClick={() => setIsOpen(!isOpen)} // Toggle dropdown visibility on click
        >
          <div
            className={styles.drop_down_placeholder}
            style={{
              color: mode ? "#fff" : "#646b88",
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {/* Show the full name of the selected option */}
            {selectedOption ? (
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }} >
                <p>{selectedOption.name}</p>
                {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
              </div>
            ) : (
              <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                <p>{listLabel || "Select"}</p>
                {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />}
              </div>
            )}
          </div>
        </div>

        {/* Show dropdown options when open */}
        {isOpen && (
          <ul
            className={styles.drop_down_list}
            style={{
              backgroundColor: mode ? "#232529" : "white",
              border: mode ? "none" : "1px solid #e4e7eb",
              position: "absolute",
              top: dropdownPosition === "bottom" ? "100%" : "auto",
              bottom: dropdownPosition === "top" ? "100%" : "auto",
              left: 0,
              zIndex: 1,
              width: "auto",
              minWidth: "300px", // Consistent width
            }}
          >
            {options.map((option, index) => (
              <li
                key={index}
                className={`${styles.drop_down_item} ${mode ? styles.drop_down_item_dark : ""}`}
                onClick={() => handleOptionSelect(option)} // Select the option
              >
                {option.name} {/* Display the name of each option */}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropDown2;

