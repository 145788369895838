import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GreenButton, GreenOutButton, RedOutButton } from '../../components/Buttons'
import { Ban, Checked, Clock, DeleteFile, DeleteIcon, Edit, View } from '../../components/icons/Icon'
import AddFaq from './AddFaq'
import AddCollection from './AddCollection'
import AddStyle from './AddStyle'
import AddType from './AddType'
import styles from './Product.module.css'
import Modal from '../../components/Modal'
import { useAuth } from '../../components/context/Auth'

const ViewProduct = ({ setProduct }) => {
    const { managerId } = useAuth();
    const product_name = window.location.pathname.split("/")[window.location.pathname.split("/")?.length - 1]
    const url = window.location.href.split("/")[window.location.href.split("/")?.length - 2]
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const getId = searchParams.get("product_id");
    const product_id = (!getId || getId === null) ? "" : getId
    const [styleData, setStyleData] = useState([])
    const [typeData, setTypeData] = useState([])
    const [collectionData, setCollectionData] = useState([])
    const [loading, setLoading] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [deleteStyleId, setDeleteStyleId] = useState('')
    const [deleteTrue, setDeleteTrue] = useState(false)
    const [deleteStyleName, setDeleteStyleName] = useState('')
    const [showDeleteTypeModal, setShowDeleteTypeModal] = useState(false)
    const [deleteTypeId, setDeleteTypeId] = useState('')
    const [deleteTypeTrue, setDeleteTypeTrue] = useState(false)
    const [deleteTrueName, setDeleteTrueName] = useState('')
    const [showDeleteCollectionModal, setShowDeleteCollectionModal] = useState(false)
    const [deleteCollectionId, setDeleteCollectionId] = useState('')
    const [deleteCollectionTrue, setDeleteCollectionTrue] = useState(false)
    const [deleteCollectionName, setDeleteCollectionName] = useState('')

    useEffect(() => {
        setLoading(true)
        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "product_id": product_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "content/productInfo", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setLoading(false)
                    setStyleData(result.styles)
                    setTypeData(result.types)
                    setCollectionData(result.collections)
                }
            })
            .catch(error => console.log('error', error));
    }, [product_id, deleteTrue, deleteTypeTrue, deleteCollectionTrue])

    const handleDeleteItem = (deleteStyleId) => {
        if (managerId) {
            setDeleteTrue(false)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "_id": deleteStyleId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "content/deleteStyle", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // alert(result.msg)
                    if (result.status === 'success') {
                        setDeleteTrue(true)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    const handleDeleteTypeItem = (deleteTypeId) => {
        if (managerId) {
            setDeleteTypeTrue(false)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "_id": deleteTypeId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "content/deleteType", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // alert(result.msg)
                    if (result.status === 'success') {
                        setDeleteTypeTrue(true)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }
    const handleDeleteCollectionItem = (deleteCollectionId) => {
        if (managerId) {
            setDeleteCollectionTrue(false)
            var myHeaders = new Headers();
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify({
                "managerId": managerId,
                "_id": deleteCollectionId
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "content/deleteCollection", requestOptions)
                .then(response => response.json())
                .then(result => {
                    // alert(result.msg)
                    if (result.status === 'success') {
                        setDeleteCollectionTrue(true)
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    // console.log('deleteStyleId', deleteStyleId)

    return (
        <React.Fragment>

            <Modal
                show={showDeleteModal}
                close={setShowDeleteModal}
                closeBtn={true}
                heading={deleteStyleName&&deleteStyleName}
                content={
                    <div className={styles.text_center}>
                        <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteStyleName&&deleteStyleName}</p></div>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteModal(false)} />
                            <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteItem(deleteStyleId); setShowDeleteModal(false) }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showDeleteTypeModal}
                close={setShowDeleteTypeModal}
                closeBtn={true}
                heading={deleteTrueName&&deleteTrueName}
                content={
                    <div className={styles.text_center}>
                        <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteTrueName&&deleteTrueName}</p></div>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteTypeModal(false)} />
                            <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteTypeItem(deleteTypeId); setShowDeleteTypeModal(false) }} />
                        </div>
                    </div>
                }
            />

            <Modal
                show={showDeleteCollectionModal}
                close={setShowDeleteCollectionModal}
                closeBtn={true}
                heading={deleteCollectionName&&deleteCollectionName}
                content={
                    <div className={styles.text_center}>
                        <div className={styles.deleteModal_text}>Are You Sure Want to Delete<p>{deleteCollectionName&&deleteCollectionName}</p></div>
                        <div className={styles.massageModal_btn}>
                            <RedOutButton title="CANCEL" handleSubmit={() => setShowDeleteCollectionModal(false)} />
                            <GreenButton title={<div className={styles.deleteButton}><DeleteIcon />&nbsp;DELETE</div>} handleSubmit={() => { handleDeleteCollectionItem(deleteCollectionId); setShowDeleteCollectionModal(false) }} />
                        </div>
                    </div>
                }
            />

            <div className={styles.product_main_div}>
                <div className={styles.product_header}>
                    <u onClick={() => navigate(-1)}>
                        &lt; Go Back
                    </u>
                    <div>
                        <GreenOutButton type="button" title="+ ADD / VIEW FAQ" css="successBtn mouse me_20" handleSubmit={() => navigate('/addfaq/' + product_name + '?product_id=' + product_id)} />
                        <GreenOutButton type="button" title="+ ADD STYLE" css="successBtn mouse me_20" handleSubmit={() => navigate('/addstyle/' + product_name + '?product_id=' + product_id)} />
                        <GreenOutButton type="button" title="+ ADD TYPE" css="successBtn mouse me_20" handleSubmit={() => navigate('/addtype/' + product_name + '?product_id=' + product_id)} />
                        <GreenOutButton type="button" title="+ ADD COLLECTION" css="successBtn mouse" handleSubmit={() => navigate('/addcollection/' + product_name + '?product_id=' + product_id)} />
                    </div>
                </div>

                {
                    loading ? <div className={'loadingMain'}>
                        <img src='/wallicon.gif' alt='walliconGIF' />
                    </div> :
                        <>
                            <h1 className={styles.heading}>
                                {product_name}
                            </h1>

                            {url === "addfaq" ?
                                <AddFaq />
                                : url === "addstyle" ?
                                    <AddStyle />
                                    : url === "addtype" ?
                                        <AddType />
                                        : url === "addcollection" ?
                                            <AddCollection />
                                            : url === "product" ?

                                                <div className={styles.product_scroll_div}>

                                                    <div className={styles.product_st_div}>
                                                        <h2>Style</h2>
                                                        <div className={styles.product_st_row}>

                                                            {styleData && styleData.map((item, index) =>
                                                                <div className={styles.product_st_col} key={index}>
                                                                    <div onClick={() => { navigate("/addstyle/" + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>

                                                                        <div className={styles.product_st_status}>
                                                                            {item.status === "Disable" || item.status === "Delete" ? <Ban color={item.status === "Delete" ? "#F93E3E" : "#FFFFFF"} css={styles.product_st_status_icon} /> : item.status === "Active" ? <Checked color="#1FFC33" css={styles.product_st_status_icon} /> : <Clock color="yellow" css={styles.product_st_status_icon} />}
                                                                        </div>
                                                                        <img src={item.logo ? process.env.REACT_APP_S3URL + item.logo : "/assets/img.png"} alt="style_Logo" width={72} height={66} />
                                                                        <h6>
                                                                            {item.style_name}
                                                                        </h6>
                                                                    </div>
                                                                    <div className={styles.product_st_btn_div}>
                                                                        <button className={styles.product_st_btn}>
                                                                            <Edit color="#1FFC33" width={16}
                                                                            //  onClick={(e) => { navigate('/editstyle'); setIds(item._id); localStorage.setItem("viewEdit", 1) }} 
                                                                            />
                                                                        </button>
                                                                        <button className={styles.product_st_btn} onClick={() => { setShowDeleteModal(true); setDeleteStyleId(item._id); setDeleteStyleName(item.style_name) }}>
                                                                            <DeleteFile color="#F93E3E" width={16}
                                                                            // onClick={selectModal}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className={styles.product_st_div}>
                                                        <h2>Type</h2>
                                                        <div className={styles.product_st_row}>

                                                            {typeData && typeData.map((item, index) =>
                                                                <div className={styles.product_st_col} key={index}>
                                                                    <div onClick={() => { navigate("/addtype/" + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>

                                                                        <div className={styles.product_st_status}>
                                                                            {item.status === "Disable" || item.status === "Delete" ? <Ban color={item.status === "Delete" ? "#F93E3E" : "#FFFFFF"} css={styles.product_st_status_icon} /> : item.status === "Active" ? <Checked color="#1FFC33" css={styles.product_st_status_icon} /> : <Clock color="yellow" css={styles.product_st_status_icon} />}
                                                                        </div>
                                                                        <img src={item.logo ? process.env.REACT_APP_S3URL + item.logo : "/assets/img.png"} alt="style_Logo" width={72} height={66} />
                                                                        <h6>
                                                                            {item.type_name}
                                                                        </h6>
                                                                    </div>
                                                                    <div className={styles.product_st_btn_div}>
                                                                        <button className={styles.product_st_btn}>
                                                                            <Edit color="#1FFC33" width={16}
                                                                            //  onClick={(e) => { navigate('/editstyle'); setIds(item._id); localStorage.setItem("viewEdit", 1) }} 
                                                                            />
                                                                        </button>
                                                                        <button className={styles.product_st_btn} onClick={() => { setShowDeleteTypeModal(true); setDeleteTypeId(item._id); setDeleteTrueName(item.type_name) }}>
                                                                            <DeleteFile color="#F93E3E" width={16}
                                                                            // onClick={selectModal}
                                                                            />
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>

                                                    <h1 className={`${styles.text_center} ${styles.heading}`}>Collections</h1>
                                                    {collectionData && collectionData.map((item, index) =>
                                                        <div className={styles.productRow} key={index}>
                                                            <div className={styles.w_100} onClick={() => navigate('/items/' + product_name + "/" + item.collection_name.replace(/\s/g, "-") + '?product_id=' + product_id + "&id=" + item._id)} >
                                                                <input type="radio" defaultChecked={item.status === "Active" ? true : false} /> <span className={styles.muted_clr}>Active</span>
                                                                <h1>
                                                                    {item.collection_name}
                                                                </h1>
                                                            </div>
                                                            <div className={styles.productBtns}>
                                                                <div className={styles.productsEVS} onClick={() => { setShowDeleteCollectionModal(true); setDeleteCollectionId(item._id); setDeleteCollectionName(item.collection_name) }}>
                                                                    <div><DeleteIcon /></div>
                                                                    <span>Delete</span>
                                                                </div>
                                                                <div className={styles.productsEVS} onClick={() => { navigate('/addcollection/' + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>
                                                                    <div><Edit color="#ffffff" /></div>
                                                                    Edit
                                                                </div>
                                                                <div className={styles.productsEVS} onClick={() => { navigate('/addcollection/' + product_name + '?product_id=' + product_id + "&id=" + item._id) }}>
                                                                    <div><View /></div>
                                                                    View
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                : ""
                            }
                        </>
                }
            </div>
        </React.Fragment>
    )
}

export default ViewProduct
