// import React, { useState } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
// import {
//   HamBurgerIcon,
//   // SideBarDashBoardIcon,
//   // SideBarDealersIcon,
//   // SideBarDispatchIcon,
//   // SideBarInventoryIcon,
//   // SideBarOrdersIcon,
//   // SideBarOutsideInventoryIcon,
//   // SideBarPackingOrderIcon,
//   // SideBarProductIcon,
//   // SideBarReceiptIcon,
//   SideBarWalliconLogo,
// } from "../components/icons/Icon";
// import styles from "./Menus.module.css";
// import { useAuth } from "../components/context/Auth";
// import useWindowWidth from "../components/updatedComponents/Hooks/WidthSize";

// const AdminMenu = ({ onClick, isClosed, menuItems }) => {
//   const navigate = useNavigate();

//   const { designation, mode } = useAuth();
//   const { pathname } = useLocation();
//   const [hoveredPath, setHoveredPath] = useState(null);
//   const [hoveredIcon, setHovereIcon] = useState(null);
//   const width = useWindowWidth();

//   // const marketingMenuItems = [
//   //   { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
//   //   { label: "CONTACTS", path: "/contacts", Icon: SideBarDealersIcon },
//   //   { label: "LEADS", path: "/leads", Icon: SideBarOrdersIcon },
//   // ];

//   // const contentManagerMenuItems = [
//   //   { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
//   //   { label: "PRODUCTS", path: "/products", Icon: SideBarProductIcon },
//   //   { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon },
//   // ];

//   // const orderManagerMenuItems = [
//   //   { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
//   //   { label: "ORDER", path: "/orders", Icon: SideBarInventoryIcon },
//   //   // { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon },
//   //   { label: "DEALERS", path: "/orders/dealers", Icon: SideBarDealersIcon },
//   // ];

//   // const inventoryManagerMenuItems = [
//   //   { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
//   //   { label: "INVENTORY", path: "/inventory", Icon: SideBarDashBoardIcon },
//   //   {
//   //     label: "OUTSIDE INVENTORY",
//   //     path: "/outsideinventory",
//   //     Icon: SideBarOutsideInventoryIcon,
//   //   },
//   //   { label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon },
//   // ];

//   // const dispatchManagerMenuItems = [
//   //   { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
//   //   { label: "DISPATCH", path: "/dispatchorders", Icon: SideBarInventoryIcon },
//   //   { label: "PACKERS", path: "/packers", Icon: SideBarInventoryIcon },
//   // ];

//   // const packingManagerMenuItems = [
//   //   { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon },
//   //   { label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon },
//   // ];

//   // const paymentManagerMenuItems = [
//   //   { label: "RECEIPTS", path: "/receipts", Icon: SideBarReceiptIcon },
//   // ];

//   // let filteredMenuItems = menuItems;
//   // if (designation === "marketing") {
//   //   filteredMenuItems = marketingMenuItems;
//   // } else if (designation === "contentmanager") {
//   //   filteredMenuItems = contentManagerMenuItems;
//   // } else if (designation === "ordermanager") {
//   //   filteredMenuItems = orderManagerMenuItems;
//   // } else if (designation === "inventorymanager") {
//   //   filteredMenuItems = inventoryManagerMenuItems;
//   // } else if (designation === "dispatchmanager") {
//   //   filteredMenuItems = dispatchManagerMenuItems;
//   // } else if (designation === "packingmanager") {
//   //   filteredMenuItems = packingManagerMenuItems;
//   // } else if (designation === "paymentmanager") {
//   //   filteredMenuItems = paymentManagerMenuItems;
//   // } else if (designation === "superadmin") {
//   //   filteredMenuItems = menuItems;
//   // }

//   return (
//     <React.Fragment>
//       <div
//         className={`${styles.logo_Blue_Bg_Div} ${mode ? styles.logo_Blue_Bg_Div_Dark : ""
//           }`}
//         onClick={onClick}
//       >
//         {isClosed ? (
//           <HamBurgerIcon color={mode ? "#fff" : "#646B88"} />
//         ) : (
//           <SideBarWalliconLogo
//             backgroundColor={mode ? "#232529" : "#F5F6FA"}
//             textColor={mode ? "#FFFFFF" : "#231F20"}
//           />
//         )}
//       </div>
//       {menuItems.map(({ label, path, Icon }) => (
//         <div
//           key={path}
//           className={`${styles.menuItem_div} ${pathname.startsWith(path)
//               ? mode
//                 ? `${styles.active_div_Dark}`
//                 : `${styles.active_div}`
//               : ""
//             } ${isClosed ? styles.menuItem_div_toggled : ""}`}
//           onClick={() => navigate(path)}
//           onMouseEnter={() => {
//             setHoveredPath(path);
//             setHovereIcon(path);
//           }}
//           onMouseLeave={() => {
//             setHoveredPath(null);
//             setHovereIcon(null);
//           }}
//           style={{
//             backgroundColor: pathname.startsWith(path)
//               ? mode
//                 ? "#232529"
//                 : "#f5f6fa"
//               : mode
//                 ? hoveredPath === path
//                   ? "#232529"
//                   : "#2C2E33"
//                 : hoveredPath === path
//                   ? "#f5f6fa"
//                   : "#ffffff",
//           }}
//         >
//           <div>
//             {width < 1250 ? (
//               hoveredIcon === path && (
//                 <div
//                   className={styles.hoverPathname}
//                   style={{
//                     backgroundColor: mode ? "#000" : "#bebebe",
//                     color: mode ? "#fff" : "#000",
//                   }}
//                 >
//                   <div> {label}</div>
//                 </div>
//               )
//             ) : null}
//             <Icon css={styles.iconImage} color={mode ? "#ffffff" : "#646B88"} />
//           </div>
//           <span
//             className={isClosed ? styles.hide_span : ""}
//             style={mode ? { color: "#fff" } : { color: "#646B88" }}
//           >
//             {label}
//           </span>
//         </div>
//       ))}

//     </React.Fragment>
//   );
// };

// export default AdminMenu;

























import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HamBurgerIcon,
  SideBarWalliconLogo,
} from "../components/icons/Icon";
import styles from "./Menus.module.css";
import { useAuth } from "../components/context/Auth";
import useWindowWidth from "../components/updatedComponents/Hooks/WidthSize";

const AdminMenu = ({ onClick, isClosed, menuItems }) => {
  const navigate = useNavigate();
  const { designation, mode } = useAuth();
  const { pathname } = useLocation();
  const [hoveredPath, setHoveredPath] = useState(null);
  const [hoveredIcon, setHovereIcon] = useState(null);
  const width = useWindowWidth();
  const [updatedMenuItems, setUpdatedMenuItems] = useState([]);

  useEffect(() => {
    // Make a shallow copy of menuItems to avoid mutating the original array
    const updatedItems = [...menuItems];
    if (designation && designation.length >= 1) {
      // Change the label of the first item to "DASHBOARD"
      updatedItems[0] = { ...updatedItems[0], label: "DASHBOARD" };
    }
    setUpdatedMenuItems(updatedItems);
  }, [designation, menuItems]);

  return (
    <React.Fragment>
      <div
        className={`${styles.logo_Blue_Bg_Div} ${
          mode ? styles.logo_Blue_Bg_Div_Dark : ""
        }`}
        onClick={onClick}
      >
        {isClosed ? (
          <HamBurgerIcon color={mode ? "#fff" : "#646B88"} />
        ) : (
          <SideBarWalliconLogo
            backgroundColor={mode ? "#232529" : "#F5F6FA"}
            textColor={mode ? "#FFFFFF" : "#231F20"}
          />
        )}
      </div>
      {updatedMenuItems.map(({ label, path, Icon }) => (
        <div
          key={path}
          className={`${styles.menuItem_div} ${
            pathname.startsWith(path)
              ? mode
                ? `${styles.active_div_Dark}`
                : `${styles.active_div}`
              : ""
          } ${isClosed ? styles.menuItem_div_toggled : ""}`}
          onClick={() => navigate(path)}
          onMouseEnter={() => {
            setHoveredPath(path);
            setHovereIcon(path);
          }}
          onMouseLeave={() => {
            setHoveredPath(null);
            setHovereIcon(null);
          }}
          style={{
            backgroundColor: pathname.startsWith(path)
              ? mode
                ? "#232529"
                : "#f5f6fa"
              : mode
              ? hoveredPath === path
                ? "#232529"
                : "#2C2E33"
              : hoveredPath === path
              ? "#f5f6fa"
              : "#ffffff",
          }}
        >
          <div>
            {width < 1250 ? (
              hoveredIcon === path && (
                <div
                  className={styles.hoverPathname}
                  style={{
                    backgroundColor: mode ? "#000" : "#bebebe",
                    color: mode ? "#fff" : "#000",
                  }}
                >
                  <div>{label}</div>
                </div>
              )
            ) : null}
            <Icon css={styles.iconImage} color={mode ? "#ffffff" : "#646B88"} />
          </div>
          <span
            className={isClosed ? styles.hide_span : ""}
            style={mode ? { color: "#fff" } : { color: "#646B88" }}
          >
            {label}
          </span>
        </div>
      ))}
    </React.Fragment>
  );
};

export default AdminMenu;

