import React, { useState } from 'react'
import styles from '../../../pages/Home.module.css'
import { useAuth } from '../../context/Auth'
import { LogInBtnIcon, } from '../../icons/Icon';
import { RegistrationIcon } from '../../icons/Icon2';
import ModalWrapper from '../layoutWrapper/ModalWrapper';
import InputTag from '../FormComponents/InputTag';
import Button from '../Dealers/Button';
const Request = ({ loading, handleClick, setDeviceName }) => {
    console.log(loading);
    
    const [modelOpen, setmodelOpen] = useState();
    const { mode } = useAuth();
    return (
        <div
            className={styles.login_container}
            style={mode ? { backgroundColor: "#2C2E33" } : {}}
        >
            <ModalWrapper isOpen={modelOpen} onClose={() => setmodelOpen(!modelOpen)}>
                <form onSubmit={(e)=>{
                    e.preventDefault();
                    handleClick();
                }}>
                    <div className={styles.Device_Continer} style={mode ? { backgroundColor: "#2C2E33", color:"#fff" } : { backgroundColor: "#fff", color:"#000" }}>
                        <p className={styles.Device_Continer_fristp}>Write Your <span style={{ color: "green" }}> device name </span> </p>
                        <p className={styles.Device_Continer_SandP}>You can give your device a name by which it will be recognized. </p>
                        <div>
                            <InputTag
                                name="loginID"
                                labelText={"Device Name"}
                                placeholderText={"Type device name"}
                                placeholderType={"text"}
                                padding={"8px"}
                                fontSize={"12px"}
                                required={true}
                                star
                                maxLength={50}
                                onChange={(e) => setDeviceName(e.target.value)}
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            <Button
                                buttonText={"Submit"}
                                buttonBgColor={"#4164E3"}
                                buttonTextColor={"#ffffff"}
                                buttonBorder={"none"}
                                loading={loading}
                               
                            />
                        </div>
                    </div>
                </form>
            </ModalWrapper>
            <div
                className={styles.login1}
                style={mode ? { backgroundColor: "#232529" } : {}}
            >
                <div className={styles.sub_Login}>
                    <div className={styles.login_Header}>
                        <div className={styles.login_Header_Text}>
                            <h2 style={mode ? { color: "#ffffff" } : {}}>Registration Request</h2>
                            <p style={mode ? { color: "#9C9797" } : {}}>
                                Your device is no registered please send the request to registered the device
                            </p>
                        </div>
                        <div>
                            <RegistrationIcon />
                        </div>
                    </div>
                    <form
                        className={styles.login_Form}
                        onSubmit={(e) => {
                            e.preventDefault();
                            setmodelOpen(!modelOpen);
                        }}
                    >

                        <button
                            type="submit"
                            disabled={loading}
                            className={styles.login_button}
                        >
                            {loading ? (
                                <>
                                    <span className={styles.spinner}></span>
                                    <p>Loading...</p>
                                </>
                            ) : (
                                <>
                                    <LogInBtnIcon />
                                    <p>Register Now</p>
                                </>
                            )}
                        </button>
                    </form>
                    <div style={{ marginTop: "40px" }}>   </div>
                </div>
            </div>
        </div>
    )
}

export default Request