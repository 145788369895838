import { useEffect, useRef } from "react";
import styles from "../../../pages/superadmin/Receipts.module.css";

const OptionsMenu = ({
  mode,
  index,
  closeMenu,
  openCancelReceiptModal,
  receiptData,
  openUpdateModal,
  openInfoModal,
  openRefundModal,
}) => {
  const menuRef = useRef(null);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        closeMenu();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [closeMenu]);

  // console.log("___>>> ", receiptData);

  const handleClick = (e, arg) => {
    e.stopPropagation();
    if (arg === "cancel") {
      if (
        receiptData.status === "Cancelled" ||
        receiptData.status === "Refund"
      ) {
        openInfoModal(receiptData.status);
      } else {
        openCancelReceiptModal(receiptData);
      }
    }
    if (arg === "update") {
      if (
        receiptData.status === "Cancelled" ||
        receiptData.status === "Refund" ||
        receiptData.status === "Received"
      ) {
        openInfoModal(receiptData.status);
      } else {
        openUpdateModal(receiptData);
      }
    }
    if (arg === "refund") {
      if (
        receiptData.status === "Cancelled" ||
        receiptData.status === "Refund" ||
        receiptData.status.includes("Pending")
      ) {
        openInfoModal(receiptData.status);
      } else {
        if (receiptData.status === "Received") {
          openRefundModal(receiptData);
        }
      }
    }
    closeMenu();
  };

  return (
    <ul
      ref={menuRef}
      key={index}
      className={`${styles.options_menu} ${
        mode ? styles.options_menu_dark : ""
      }`}
      style={
        index === 0
          ? { right: "1rem", top: "0px" }
          : index === 14
          ? { right: "1rem", bottom: "0%" }
          : { right: "1rem" }
      }
    >
      <li
        className={`${styles.options_menu_li} ${
          mode ? styles.options_menu_li_dark : ""
        }`}
        onClick={(e) => handleClick(e, "cancel")}
      >
        Cancel
      </li>
      <li
        className={`${styles.options_menu_li} ${
          mode ? styles.options_menu_li_dark : ""
        }`}
        onClick={(e) => handleClick(e, "update")}
      >
        Update
      </li>
      <li
        className={`${styles.options_menu_li} ${
          mode ? styles.options_menu_li_dark : ""
        }`}
        onClick={(e) => handleClick(e, "refund")}
      >
        Refund
      </li>
    </ul>
  );
};

export default OptionsMenu;
