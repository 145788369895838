// import React, { useState } from 'react';
// import Navbar from './Navbar';
// import { Outlet } from 'react-router-dom';
// import AdminMenu from '../../../leftMeuns/AdminMenu';
// import styles from "./Navbar.module.css";
// import { useAuth } from '../../context/Auth';
// import { SideBarArrowLeft, SideBarArrowRight } from '../../icons/Icon';
// import useWindowWidth from '../Hooks/WidthSize';

// const NavLayout = () => {
//     const [isClosed, setIsClosed] = useState(false);
//     const { mode } = useAuth();
//     const width =  useWindowWidth();

//     console.log(width);
//     if (width < 1366) {
//         setIsClosed(true)
//     }else{
//         setIsClosed(false)
//     }

//     const contentWidth = isClosed ? "calc(100% - 100px)" : "calc(100% - 230px)";

//     return (
//         <div style={{ display: "flex", maxWidth: "1920px", width: "100%" }}>
//             <div  className={ isClosed ?  styles.con : styles.con1 }>
//                 <div
//                     className={
//                         isClosed
//                             ? `${styles.leftMenu} ${styles.leftMenuToggled}`
//                             : styles.leftMenu
//                     }
//                     style={mode ? { backgroundColor: "#2C2E33" } : {}}
//                 >
//                     <button
//                         className={styles.toggle_Btn}
//                         onClick={() => setIsClosed(!isClosed)}
//                     >
//                         {isClosed ? <SideBarArrowLeft /> : <SideBarArrowRight />}
//                     </button>

//                     <AdminMenu isClosed={isClosed} />
//                 </div>
//             </div>
//             <div
//                 style={{
//                     width: contentWidth,
//                     transition: 'width 0.3s ease',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     flex: 1
//                 }}
//             >
//                 <Navbar isTogle={isClosed} />
//                 <main>
//                     <Outlet />
//                 </main>
//             </div>
//         </div>
//     );
// };

// export default NavLayout;

// import React, { useState, useEffect } from 'react';
// import Navbar from './Navbar';
// import { Outlet } from 'react-router-dom';
// import AdminMenu from '../../../leftMeuns/AdminMenu';
// import styles from "./Navbar.module.css";
// import { useAuth } from '../../context/Auth';
// import { SideBarArrowLeft, SideBarArrowRight } from '../../icons/Icon';
// import useWindowWidth from '../Hooks/WidthSize';

// const NavLayout = () => {
//     const [isClosed, setIsClosed] = useState(false);
//     const { mode } = useAuth();
//     const width = useWindowWidth();

//     useEffect(() => {
//         if (width < 1366) {
//             setIsClosed(true);
//         } else {
//             setIsClosed(false);
//         }
//     }, [width]); // Dependency array includes `width` so effect runs when `width` changes

//     const contentWidth = isClosed ? "calc(100% - 100px)" : "calc(100% - 230px)";

//     // console.log("hiii   =>" + contentWidth);

//     return (
//         <div style={{ display: "flex", maxWidth: "1920px", width: "100%" }}>
//             <div className={isClosed ? styles.con : styles.con1}  >
//                 <div
//                     className={
//                         isClosed
//                             ? `${styles.leftMenu} ${styles.leftMenuToggled}`
//                             : styles.leftMenu
//                     }
//                     style={mode ? { backgroundColor: "#2C2E33" } : {}}
//                 >
//                     <button
//                         className={styles.toggle_Btn}
//                         onClick={() => setIsClosed(!isClosed)}
//                     >
//                         {isClosed ? <SideBarArrowLeft /> : <SideBarArrowRight />}
//                     </button>

//                     <AdminMenu isClosed={isClosed} />
//                 </div>
//             </div>
//             <div
//                 style={{
//                     width: contentWidth,
//                     transition: 'width 0.3s ease',
//                     display: 'flex',
//                     flexDirection: 'column',
//                     flex: 1

//                 }}
//             >
//                 <Navbar isTogle={isClosed} />
//                 <main>
//                     <Outlet />
//                 </main>
//             </div>
//         </div>
//     );
// };

// export default NavLayout;

import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import { Outlet } from "react-router-dom";
import AdminMenu from "../../../leftMeuns/AdminMenu";
import styles from "./Navbar.module.css";
import { useAuth } from "../../context/Auth";
import {
  SideBarAccountIcon,
  SideBarApplicationIcon,
  SideBarComplaintsIcon,
  SideBarDashBoardIcon,
  SideBarDealersIcon,
  SideBarDeliveryIcon,
  SideBarDispatchIcon,
  SideBarEditDeleteIcon,
  SideBarGreetingIcon,
  SideBarInventoryIcon,
  SideBarInvoiceIcon,
  SideBarJobsIcon,
  SideBarManagerIcon,
  SideBarMarketingIcon,
  SideBarNotificationIcon,
  SideBarOrdersIcon,
  SideBarPackingOrderIcon,
  SideBarProductIcon,
  SideBarReceiptIcon,
  SideBarTemplateIcon,
  SideBarWareHousetIcon,
  WhatChat
} from "../../icons/Icon";
import useWindowWidth from "../Hooks/WidthSize";

const NavLayout = () => {
  const [isClosed, setIsClosed] = useState(true);
  // const [RemainingRoles, setRemainingRoles] = useState([]);
  const { mode, designations, } = useAuth();
  const width = useWindowWidth();
  const RemainingRoles = ["inventorymanager", "ordermanager", "dispatchmanager",
    "packingmanager", "invoicemanager", "contentmanager", "superadmin", "paymentmanager",
  ];
  // const designationPriority = {
  //   superadmin: 1,
  //   ordermanager: 2,
  //   dispatchmanager: 3,
  //   inventorymanager: 4,
  //   paymentmanager: 5,
  //   packingmanager: 6,
  //   invoicemanager: 7,
  //   contentmanager: 8,
  // };

  // const defaultDashboardRoutes = {
  //   superadmin: "/dashboard",
  //   ordermanager: "/orders",
  //   dispatchmanager: "/dispatchdashboard",
  //   inventorymanager: "/inventory",
  //   paymentmanager: "/recipts",
  //   packingmanager: "/recipts",
  //   invoicemanager: "/recipts",
  //   contentmanager: "/recipts",
  // };

  // const getDefaultDashboard = (designations) => {
  //   if (!designations || designations.length === 0) {
  //     return "/porfile"; // Default fallback
  //   }

  //   // Sort designations based on priority
  //   const sortedDesignations = [...designations].sort((a, b) =>
  //     (designationPriority[a] || Infinity) - (designationPriority[b] || Infinity)
  //   );

  //   // Get the dashboard route for the highest priority designation
  //   const topDesignation = sortedDesignations[0];
  //   return defaultDashboardRoutes[topDesignation] || "/dashboard"; // Fallback if no match
  // };
  // const defaultDashboard = getDefaultDashboard(designations);

  // // Example: Use defaultDashboard for redirection or navigation
  // useEffect(() => {
  //   // Redirect to default dashboard if the user lands on root
  //   if (window.location.pathname === "/") {
  //     window.location.replace(defaultDashboard);
  //   }
  // }, [defaultDashboard]);

  useEffect(() => {
    if (width < 1366) {
      setIsClosed(true);
    } else {
      setIsClosed(false);
    }
  }, [width]); // Dependency array includes `width` so effect runs when `width` c hanges

  // Calculate the width of the sidebar dynamically
  const sidebarWidth = isClosed ? "80px" : "190px";
  const contentWidth = `calc(100% - ${sidebarWidth})`;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const chacktoggleDrawer = () => {
    if (isDrawerOpen) {
      toggleDrawer();
    }
  };
  const handelClose = () => {
    if (width < 1250) {
      return;
    } else {
      setIsClosed(!isClosed);
    }
  };
  // console.log("jhkhjvhkj", designations);


  const menuItems = [
    { label: "DASHBOARD", path: "/dashboard", Icon: SideBarDashBoardIcon, allowedDesignations: ["superadmin"] },
    { label: "DEALERS", path: "/dealers", Icon: SideBarDealersIcon, allowedDesignations:
       ["superadmin", "contentmanager", /* "ordermanager" */] },
    {
      label: "ORDERS", path: "/orders", Icon: SideBarOrdersIcon, allowedDesignations:
        ["superadmin", "ordermanager", "dispatchmanager", "inventorymanager"]
    },
    { label: "RECEIPT", path: "/recipts", Icon: SideBarReceiptIcon, allowedDesignations: ["superadmin", "paymentmanager"] },
    { label: "DISPATCH", path: "/dispatchdashboard", Icon: SideBarDispatchIcon, allowedDesignations: ["superadmin", "ordermanager", "dispatchmanager"] },
    { label: "INVENTORY", path: "/inventory", Icon: SideBarInventoryIcon, allowedDesignations: ["superadmin", "inventorymanager"] },
    { label: "CAMPAIGN", path: "/campaign", Icon: SideBarTemplateIcon, allowedDesignations: ["superadmin",] },
    { label: "CHAT", path: "/whatsappchats", Icon: WhatChat, allowedDesignations: ["superadmin", "ordermanager"] },
    { label: "PACKING ORDER", path: "/packingorder", Icon: SideBarPackingOrderIcon, allowedDesignations: ["superadmin", "packingmanager"] },
    { label: "INVOICE", path: "/invoices", Icon: SideBarInvoiceIcon, allowedDesignations: ["superadmin", "invoicemanager"] },
    { label: "COMPLAINTS", path: "/complaints", Icon: SideBarComplaintsIcon, allowedDesignations: ["superadmin",] },
    { label: "PRODUCTS", path: "/products", Icon: SideBarProductIcon, allowedDesignations: ["superadmin", "contentmanager"] },
    { label: "MANAGER", path: "/managers", Icon: SideBarManagerIcon, allowedDesignations: ["superadmin",] },
    { label: "MARKETING", path: "/marketing", Icon: SideBarMarketingIcon, allowedDesignations: ["superadmin",] },
    { label: "WAREHOUSE", path: "/warehouses", Icon: SideBarWareHousetIcon, allowedDesignations: ["superadmin",] },
    { label: "JOBS", path: "/jobs", Icon: SideBarJobsIcon, allowedDesignations: ["superadmin",] },
    { label: "APPLICATION", path: "/applicants", Icon: SideBarApplicationIcon, allowedDesignations: ["superadmin",] },
    { label: "DELIVERY", path: "/delivery", Icon: SideBarDeliveryIcon, allowedDesignations: ["superadmin",] },
    { label: "GREETING", path: "/greetings", Icon: SideBarGreetingIcon, allowedDesignations: ["superadmin",] },
    { label: "NOTIFICATION", path: "/createnotification", Icon: SideBarNotificationIcon, allowedDesignations: ["superadmin",] },
    { label: "DELETE / EDIT", path: "/deleteoredit", Icon: SideBarEditDeleteIcon, allowedDesignations: ["superadmin",] },
    { label: "ACCOUNT", path: "/accounts", Icon: SideBarAccountIcon, allowedDesignations: ["superadmin",] },
  ];

  const filteredMenuItems = menuItems.filter((item) =>
    item.allowedDesignations.some((designation) =>
      designations.includes(designation)
    )
  );

  return (
    <div
      style={{
        display: "flex",
        maxWidth: "1920px",
        width: "100%",
        height: "100vh",
      }}
    >
      {width < 700 ? null : (
        <div
          className={isClosed ? styles.con : styles.con1}
          style={{ width: sidebarWidth, transition: "width 0.3s ease" }}
        >
          <div
            className={
              isClosed
                ? `${styles.leftMenu} ${styles.leftMenuToggled}`
                : styles.leftMenu
            }
            style={mode ? { backgroundColor: "#2C2E33" } : {}}
          >
            {/* {
              width < 1250 ? null :
                <button
                  className={styles.toggle_Btn}
                  onClick={handelClose}
                  style={{zIndex:"999"}}
                >
                  {isClosed ? <SideBarArrowLeft /> : <SideBarArrowRight />}
                </button>
            } */}

            <AdminMenu
              isClosed={isClosed}
              // menuItems={menuItems}
              menuItems={filteredMenuItems}
              onClick={handelClose}
            />
          </div>
        </div>
      )}
      <div
        style={{
          width: contentWidth,
          transition: "width 0.3s ease",
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          flex: 1,
        }}
      >
        {/* <Navbar
          isTogle={isClosed}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          // menuItems={menuItems}
          menuItems={filteredMenuItems}
        /> */}
        <Navbar
          isTogle={isClosed}
          isDrawerOpen={isDrawerOpen}
          toggleDrawer={toggleDrawer}
          menuItems={filteredMenuItems}
          // defaultDashboard={defaultDashboard}
        />

        {
          width < 700 ?
            <main onClick={chacktoggleDrawer} className={styles.mdSccreen}>
              <Outlet />
            </main> :

            <main className={styles.lg_screen}>
              <Outlet />
            </main>
        }
      </div>
    </div>
  );
};

export default NavLayout;
