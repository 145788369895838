import React from "react";

export const Download = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <path d="M17 9.50391C19.175 9.51602 20.3529 9.61247 21.1213 10.3809C22 11.2596 22 12.6738 22 15.5022V16.5022C22 19.3306 22 20.7449 21.1213 21.6235C20.2426 22.5022 18.8284 22.5022 16 22.5022H8C5.17157 22.5022 3.75736 22.5022 2.87868 21.6235C2 20.7449 2 19.3306 2 16.5022V15.5022C2 12.6738 2 11.2596 2.87868 10.3808C3.64706 9.61247 4.82497 9.51602 7 9.50391" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                <path d="M12 2.5V15.5M12 15.5L9 12M12 15.5L15 12" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </React.Fragment>
    );
};
export const Cancel = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" stroke="white" stroke-width="2" />
                <path d="M18 18L6 6" stroke="white" stroke-width="2" />
            </svg>
        </React.Fragment>
    );
};
export const Back = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M14 7L9 12L14 17" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </React.Fragment>
    );
};
export const Label = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 17.25C22.5 18.078 21.828 18.75 21 18.75H19.5C19.1655 17.4578 17.8965 16.5 16.5 16.5H7.5C6.1035 16.5 4.8345 17.4578 4.5 18.75H3C2.172 18.75 1.5 18.078 1.5 17.25V15.75C1.5 14.922 2.172 14.25 3 14.25H21C21.828 14.25 22.5 14.922 22.5 15.75V17.25ZM21 12.75H3C1.34325 12.75 0 14.0933 0 15.75V17.25C0 18.9067 1.34325 20.25 3 20.25H4.5C4.8345 21.5422 6.1035 22.5 7.5 22.5H16.5C17.8965 22.5 19.1655 21.5422 19.5 20.25H21C22.6567 20.25 24 18.9067 24 17.25V15.75C24 14.0933 22.6567 12.75 21 12.75ZM19.5 4.5C19.5 2.84325 18.1567 1.5 16.5 1.5H7.5C5.84325 1.5 4.5 2.84325 4.5 4.5V11.25H19.5V4.5Z" fill="white" />
            </svg>
        </React.Fragment>
    );
}; export const Viewdispatch = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="red">
                <path d="M9.56443 8.73049L10.0789 10.5926C10.5639 12.3481 10.8064 13.2259 11.5194 13.6252C12.2323 14.0244 13.1374 13.7892 14.9474 13.3188L16.8673 12.8199C18.6774 12.3495 19.5824 12.1143 19.9941 11.4227C20.4057 10.7312 20.1632 9.85344 19.6782 8.09788L19.1638 6.2358C18.6788 4.48023 18.4363 3.60244 17.7233 3.20319C17.0103 2.80394 16.1052 3.03915 14.2952 3.50955L12.3753 4.00849C10.5652 4.47889 9.66021 4.71409 9.24856 5.40562C8.83692 6.09714 9.07943 6.97493 9.56443 8.73049Z" fill="white" />
                <path d="M2.27749 5.24889C2.38823 4.84976 2.80157 4.61597 3.2007 4.72671L4.9044 5.19939C5.82129 5.45378 6.5469 6.16061 6.80003 7.07684L8.95106 14.8628L9.10935 15.4094C9.74249 15.6458 10.2863 16.0885 10.6314 16.6766L10.9414 16.5809L19.8115 14.2758C20.2124 14.1716 20.6219 14.4121 20.7261 14.813C20.8303 15.2139 20.5897 15.6233 20.1888 15.7275L11.3515 18.0243L11.0228 18.1257C11.0161 19.3966 10.1392 20.5574 8.81236 20.9022C7.22189 21.3155 5.58709 20.4001 5.16092 18.8575C4.73476 17.3149 5.67861 15.7293 7.26908 15.316C7.3479 15.2955 7.42682 15.2783 7.5057 15.2642L5.35419 7.47628C5.24592 7.08437 4.92897 6.76287 4.50338 6.64479L2.79968 6.17211C2.40054 6.06137 2.16675 5.64803 2.27749 5.24889Z" fill="white" />
            </svg>
        </React.Fragment>
    );
};

export const Pakingslip = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M22.5 17.25C22.5 18.078 21.828 18.75 21 18.75H19.5C19.1655 17.4578 17.8965 16.5 16.5 16.5H7.5C6.1035 16.5 4.8345 17.4578 4.5 18.75H3C2.172 18.75 1.5 18.078 1.5 17.25V15.75C1.5 14.922 2.172 14.25 3 14.25H21C21.828 14.25 22.5 14.922 22.5 15.75V17.25ZM21 12.75H3C1.34325 12.75 0 14.0933 0 15.75V17.25C0 18.9067 1.34325 20.25 3 20.25H4.5C4.8345 21.5422 6.1035 22.5 7.5 22.5H16.5C17.8965 22.5 19.1655 21.5422 19.5 20.25H21C22.6567 20.25 24 18.9067 24 17.25V15.75C24 14.0933 22.6567 12.75 21 12.75ZM19.5 4.5C19.5 2.84325 18.1567 1.5 16.5 1.5H7.5C5.84325 1.5 4.5 2.84325 4.5 4.5V11.25H19.5V4.5Z" fill="white" />
            </svg>
        </React.Fragment>
    );
};
export const Pack = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M20.2242 12.5257C19.639 9.40452 19.3464 7.84393 18.2355 6.92196C17.1246 6 15.5368 6 12.3612 6H11.64C8.46447 6 6.87669 6 5.76579 6.92196C4.65489 7.84393 4.36228 9.40452 3.77706 12.5257C2.95414 16.9146 2.54268 19.1091 3.7423 20.5545C4.94192 22 7.17463 22 11.64 22H12.3612C16.8266 22 19.0593 22 20.259 20.5545C20.9549 19.7159 21.1086 18.6252 20.9543 17" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                <path d="M9 6V5C9 3.34315 10.3431 2 12 2C13.6569 2 15 3.34315 15 5V6" stroke="white" stroke-width="1.5" stroke-linecap="round" />
            </svg>
        </React.Fragment>
    );
};
export const Labelpic = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path d="M25.7801 21.6008C26.4248 21.6008 26.9478 21.0634 26.9478 20.4008V5.60078C26.9478 4.9382 26.4248 4.40078 25.7801 4.40078H4.75998C4.11519 4.40078 3.5922 4.9382 3.5922 5.60078V20.4008C3.5922 21.0634 4.11519 21.6008 4.75998 21.6008H25.7801ZM25.7801 23.2008H4.75998C3.25528 23.2008 2.03516 21.947 2.03516 20.4008V5.60078C2.03516 4.05457 3.25528 2.80078 4.75998 2.80078H25.7801C27.2847 2.80078 28.5049 4.05457 28.5049 5.60078V20.4008C28.5049 21.947 27.2847 23.2008 25.7801 23.2008Z" fill="#F93E3E" />
                <path opacity="0.5" d="M30.8696 18.3889L32.0977 18.6105C33.1576 18.8016 33.8652 19.8402 33.6775 20.929L31.3416 34.4867C31.2301 35.1336 30.6193 35.573 29.9899 35.4594L9.3516 31.7354C8.71563 31.6207 8.29094 30.9974 8.40354 30.344L9.21437 25.638C9.28933 25.2029 9.00689 24.7878 8.58349 24.7108C8.1601 24.6337 7.75612 24.924 7.68116 25.3591L6.87033 30.0651C6.60765 31.5894 7.59848 33.0436 9.08235 33.3113L29.7205 37.0353C31.1961 37.3016 32.6134 36.2818 32.8748 34.7657L35.2107 21.208C35.5486 19.2481 34.2748 17.3786 32.3669 17.0346L31.1389 16.813C30.7154 16.7366 30.3118 17.0274 30.2375 17.4625C30.1631 17.8977 30.4461 18.3124 30.8696 18.3888L30.8696 18.3889Z" fill="#646B88" />
            </svg>
        </React.Fragment>
    );
};
export const Canceldeny = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 24C8.8174 24 5.76516 22.7357 3.51472 20.4853C1.26428 18.2348 0 15.1826 0 12C0 8.8174 1.26428 5.76516 3.51472 3.51472C5.76516 1.26428 8.8174 0 12 0C15.1826 0 18.2348 1.26428 20.4853 3.51472C22.7357 5.76516 24 8.8174 24 12C24 15.1826 22.7357 18.2348 20.4853 20.4853C18.2348 22.7357 15.1826 24 12 24ZM12 22.5C14.7848 22.5 17.4555 21.3938 19.4246 19.4246C21.3938 17.4555 22.5 14.7848 22.5 12C22.5 9.21523 21.3938 6.54451 19.4246 4.57538C17.4555 2.60625 14.7848 1.5 12 1.5C9.21523 1.5 6.54451 2.60625 4.57538 4.57538C2.60625 6.54451 1.5 9.21523 1.5 12C1.5 14.7848 2.60625 17.4555 4.57538 19.4246C6.54451 21.3938 9.21523 22.5 12 22.5Z" fill="white" />
                <path d="M12.0002 5.625C11.4212 5.625 10.9652 5.811 10.6292 6.1845C10.2991 6.54241 10.1187 7.01318 10.1252 7.5C10.1252 8.004 10.2932 8.442 10.6292 8.8155C10.9652 9.189 11.4212 9.375 12.0002 9.375C12.5792 9.375 13.0352 9.189 13.3712 8.8155C13.7072 8.442 13.8752 8.004 13.8752 7.5C13.8752 6.996 13.7072 6.558 13.3712 6.1845C13.0352 5.811 12.5792 5.625 12.0002 5.625ZM10.5002 10.5V18H13.5002V10.5H10.5002Z" fill="white" />
            </svg>
        </React.Fragment>
    );
};
// export const Statusicon = ({ status }) => {
//     return (
//         <React.Fragment>
//             <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" >
//                 <g clip-path="url(#clip0_6090_67207)">
//                     <path d="M12 5C13.3845 5 14.7378 5.41054 15.889 6.17971C17.0401 6.94888 17.9373 8.04213 18.4672 9.32121C18.997 10.6003 19.1356 12.0078 18.8655 13.3656C18.5954 14.7235 17.9287 15.9708 16.9497 16.9497C15.9708 17.9287 14.7235 18.5954 13.3656 18.8655C12.0078 19.1356 10.6003 18.997 9.32121 18.4672C8.04213 17.9373 6.94888 17.0401 6.17971 15.889C5.41054 14.7378 5 13.3845 5 12C5 10.1435 5.7375 8.36301 7.05025 7.05025C8.36301 5.7375 10.1435 5 12 5Z" fill={status === "New Order" ? "purple" : "red"} />
//                     <path d="M12.0007 22.6693C9.89099 22.6693 7.8287 22.0437 6.07457 20.8716C4.32045 19.6995 2.95328 18.0336 2.14594 16.0846C1.33861 14.1355 1.12737 11.9908 1.53895 9.92165C1.95052 7.85252 2.96642 5.9519 4.45818 4.46014C5.94994 2.96838 7.85056 1.95247 9.91969 1.5409C11.9888 1.12932 14.1335 1.34056 16.0826 2.14789C18.0317 2.95523 19.6976 4.3224 20.8697 6.07653C22.0417 7.83065 22.6673 9.89294 22.6673 12.0026C22.6673 14.8316 21.5435 17.5447 19.5431 19.5451C17.5427 21.5455 14.8296 22.6693 12.0007 22.6693ZM12.0007 2.66928C10.1547 2.66928 8.35019 3.21667 6.81533 4.24223C5.28047 5.26779 4.0842 6.72545 3.37778 8.4309C2.67136 10.1363 2.48653 12.013 2.84666 13.8235C3.20679 15.6339 4.0957 17.297 5.40099 18.6023C6.70628 19.9076 8.36932 20.7965 10.1798 21.1566C11.9903 21.5167 13.8669 21.3319 15.5724 20.6255C17.2778 19.9191 18.7355 18.7228 19.761 17.1879C20.7866 15.6531 21.334 13.8486 21.334 12.0026C21.334 9.52726 20.3507 7.15328 18.6003 5.40295C16.85 3.65261 14.476 2.66928 12.0007 2.66928Z" fill={status === "New Order" ? "pink" : "red"} />
//                 </g>
//                 <defs>
//                     <clipPath id="clip0_6090_67207">
//                         <rect width="24" height="24" fill="white" />
//                     </clipPath>
//                 </defs>
//             </svg>
//         </React.Fragment>
//     );
// };



export const Statusicon = ({ status }) => {
    // Define colors based on status
    const getStatusColor = (status) => {
        switch (status) {
            case "New Order":
                return { fill1: "#1E3DAA", fill2: "#1E3DAA" };
            case "Packed":
                return { fill1: "#28699C", fill2: "#28699C" };
            case "Dispatched":
                return { fill1: "#00A052", fill2: "#00A052" };
            case "Not Packed":
                return { fill1: "red", fill2: "orange" };
            case "In Progress":
                return { fill1: "#FF9B04", fill2: "#FF9B04" };
            case "Pending":
                return { fill1: "#F93E3E", fill2: "#F93E3E" };
            default:
                return { fill1: "red", fill2: "red" };
        }
    };

    const colors = getStatusColor(status);

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_6090_67207)">
                <path
                    d="M12 5C13.3845 5 14.7378 5.41054 15.889 6.17971C17.0401 6.94888 17.9373 8.04213 18.4672 9.32121C18.997 10.6003 19.1356 12.0078 18.8655 13.3656C18.5954 14.7235 17.9287 15.9708 16.9497 16.9497C15.9708 17.9287 14.7235 18.5954 13.3656 18.8655C12.0078 19.1356 10.6003 18.997 9.32121 18.4672C8.04213 17.9373 6.94888 17.0401 6.17971 15.889C5.41054 14.7378 5 13.3845 5 12C5 10.1435 5.7375 8.36301 7.05025 7.05025C8.36301 5.7375 10.1435 5 12 5Z"
                    fill={colors.fill1}
                />
                <path
                    d="M12.0007 22.6693C9.89099 22.6693 7.8287 22.0437 6.07457 20.8716C4.32045 19.6995 2.95328 18.0336 2.14594 16.0846C1.33861 14.1355 1.12737 11.9908 1.53895 9.92165C1.95052 7.85252 2.96642 5.9519 4.45818 4.46014C5.94994 2.96838 7.85056 1.95247 9.91969 1.5409C11.9888 1.12932 14.1335 1.34056 16.0826 2.14789C18.0317 2.95523 19.6976 4.3224 20.8697 6.07653C22.0417 7.83065 22.6673 9.89294 22.6673 12.0026C22.6673 14.8316 21.5435 17.5447 19.5431 19.5451C17.5427 21.5455 14.8296 22.6693 12.0007 22.6693ZM12.0007 2.66928C10.1547 2.66928 8.35019 3.21667 6.81533 4.24223C5.28047 5.26779 4.0842 6.72545 3.37778 8.4309C2.67136 10.1363 2.48653 12.013 2.84666 13.8235C3.20679 15.6339 4.0957 17.297 5.40099 18.6023C6.70628 19.9076 8.36932 20.7965 10.1798 21.1566C11.9903 21.5167 13.8669 21.3319 15.5724 20.6255C17.2778 19.9191 18.7355 18.7228 19.761 17.1879C20.7866 15.6531 21.334 13.8486 21.334 12.0026C21.334 9.52726 20.3507 7.15328 18.6003 5.40295C16.85 3.65261 14.476 2.66928 12.0007 2.66928Z"
                    fill={colors.fill2}
                />
            </g>
            <defs>
                <clipPath id="clip0_6090_67207">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};



export const Edit = () => {
    return (
        <React.Fragment>
            <path xmlns="http://www.w3.org/2000/svg" opacity="0.4" d="M13.75 2.5H11.25C5 2.5 2.5 5 2.5 11.25V18.75C2.5 25 5 27.5 11.25 27.5H18.75C25 27.5 27.5 25 27.5 18.75V16.25" stroke="#34A853" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
        </React.Fragment>
    );
};
export const Warehouseview = () => {
    return (
        <React.Fragment>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M25.2369 13.6679C25.7221 14.257 25.9648 14.5515 25.9648 15C25.9648 15.4485 25.7221 15.743 25.2369 16.3321C23.4618 18.4874 19.5465 22.5 15.0019 22.5C10.4572 22.5 6.54198 18.4874 4.76686 16.3321C4.28166 15.743 4.03906 15.4485 4.03906 15C4.03906 14.5515 4.28166 14.257 4.76686 13.6679C6.54198 11.5127 10.4572 7.5 15.0019 7.5C19.5465 7.5 23.4618 11.5127 25.2369 13.6679Z" fill="#4164E3" fill-opacity="0.2" />
                <path d="M15 18.75C17.0711 18.75 18.75 17.0711 18.75 15C18.75 12.9289 17.0711 11.25 15 11.25C12.9289 11.25 11.25 12.9289 11.25 15C11.25 17.0711 12.9289 18.75 15 18.75Z" fill="#4164E3" />
            </svg>        </React.Fragment>
    );
};

export const Warehouseedit = () => {
    return (
        <React.Fragment>
            <path xmlns="http://www.w3.org/2000/svg" opacity="0.4" d="M13.75 2.5H11.25C5 2.5 2.5 5 2.5 11.25V18.75C2.5 25 5 27.5 11.25 27.5H18.75C25 27.5 27.5 25 27.5 18.75V16.25" stroke="#34A853" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round" />
        </React.Fragment>
    );
};
export const Editwarehouse = ({mode}) => {
    return (
        <React.Fragment>
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<rect width="40" height="40" rx="10" fill={mode ?  '#212327' : "#F8F9FC"  }/>
<path opacity="0.4" d="M18.75 7.5H16.25C10 7.5 7.5 10 7.5 16.25V23.75C7.5 30 10 32.5 16.25 32.5H23.75C30 32.5 32.5 30 32.5 23.75V21.25" stroke="#34A853" stroke-width="1.875" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M25.0513 8.77654L15.2013 18.6266C14.8263 19.0016 14.4513 19.7391 14.3763 20.2766L13.8388 24.0391C13.6388 25.4016 14.6013 26.3516 15.9638 26.1641L19.7263 25.6266C20.2513 25.5516 20.9888 25.1766 21.3763 24.8016L31.2263 14.9515C32.9263 13.2515 33.7263 11.2765 31.2263 8.77654C28.7263 6.27654 26.7513 7.07654 25.0513 8.77654Z" stroke="#34A853" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path opacity="0.4" d="M23.6367 10.1875C24.4742 13.175 26.8117 15.5125 29.8117 16.3625" stroke="#34A853" stroke-width="1.875" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
</svg>     
 </React.Fragment>
    );
};


export const View12 = ({mode}) => {
    return (
        <React.Fragment>
<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
<rect width="40" height="40" rx="10" fill={mode ?  '#212327' : "#F8F9FC"  }/>
<path d="M30.2369 18.6679C30.7221 19.257 30.9648 19.5515 30.9648 20C30.9648 20.4485 30.7221 20.743 30.2369 21.3321C28.4618 23.4874 24.5465 27.5 20.0019 27.5C15.4572 27.5 11.542 23.4874 9.76686 21.3321C9.28166 20.743 9.03906 20.4485 9.03906 20C9.03906 19.5515 9.28166 19.257 9.76686 18.6679C11.542 16.5127 15.4572 12.5 20.0019 12.5C24.5465 12.5 28.4618 16.5127 30.2369 18.6679Z" fill="#4164E3" fill-opacity="0.2"/>
<path d="M20 23.75C22.0711 23.75 23.75 22.0711 23.75 20C23.75 17.9289 22.0711 16.25 20 16.25C17.9289 16.25 16.25 17.9289 16.25 20C16.25 22.0711 17.9289 23.75 20 23.75Z" fill="#4164E3"/>
</svg>   
   </React.Fragment>
    );
};