import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './components/context/Auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import { InactivityProvider } from './components/context/Inactivity';
// import ErrorBoundary from "./components/updatedComponents/Hooks/ErrorHandeler"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<>

  {/* <React.StrictMode> */}
  {/* <ErrorBoundary> */}
  <InactivityProvider>
    <AuthProvider>
      <App />
    </AuthProvider>
  </InactivityProvider>
  {/* </ErrorBoundary> */}
  {/* </React.StrictMode> */}
</>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
