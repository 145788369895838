import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "./Orders.module.css";
import {
  DividerIcon,
  TestTopAddDealerIcon,
  LeftArrowIcon,
  ShareIcon,
  SaveIcon,
  CancelIcon2,
  NewEyeIcon,
  RemoveIcon,
} from "../../components/icons/Icon";
import { useAuth } from "../../components/context/Auth";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputTag from "../../components/updatedComponents/FormComponents/InputTag";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";
import Button from "../../components/updatedComponents/Dealers/Button";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import ModalWrapper from "../../components/updatedComponents/layoutWrapper/ModalWrapper";
import ShareLinkModal from "../../components/updatedComponents/OrderManager/ShareLinkModal";
import AddReceiptModal from "../../components/updatedComponents/OrderManager/AddReceiptModal";
import SearchDealer from "../../components/updatedComponents/OrderManager/SearchDealer";
import ItemList from "../../components/updatedComponents/OrderManager/ItemList";
import NewItemList from "../../components/updatedComponents/OrderManager/NewItemList";
import ShippingChargesModal from "../../components/updatedComponents/OrderManager/ShippingChargesModal";
import ManagerModal from "../../components/updatedComponents/Manager/ManagerModal";
import DealerAddressModal from "../../components/updatedComponents/OrderManager/DealerAddressModal";
import fetchData from "../../APIsControll/apiControll";
import OrderSuccessModal from "../../components/updatedComponents/OrderManager/OrderSuccessModal";
import VerticalDropDown2 from "../../components/updatedComponents/FormComponents/VerticalDropDown2";
import AdduserModel from "../../components/updatedComponents/models/AdduserModel";
import AddNewItemModal from "../../components/updatedComponents/OrderManager/AddNewItemModal";
import { handleFileData } from "../../APIsControll/apiControl2";
import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import Spinner from "../../components/updatedComponents/FormComponents/Spinner";
import RequestForCancelModal from "../../components/updatedComponents/OrderManager/RequestForCancelModal";
import IconActionText from "../../components/updatedComponents/OrderManager/IconActionText";
import useWindowWidth from "../../components/updatedComponents/Hooks/WidthSize";

const SaleOrder = ({ onOrderData }) => {
  const { mode, managerId } = useAuth();
  const { show } = useToast();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const width = useWindowWidth();
  const orderId = searchParams.get("orderId")
    ? searchParams.get("orderId")
    : "";
  const [loading, setLoading] = useState(false);
  const [shippingInfo, setShippingInfo] = useState("");
  const [generalInfo, setGeneralInfo] = useState({});
  const [reciptsInfo, setReciptsInfo] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [dispatchManagers, setDispatchManagers] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [methodPartners, setMethodPartners] = useState([]);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [nextBtn, setNextBtn] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // dealer search
  const [showDealerSearch, setShowDealerSearch] = useState(
    !orderId ? true : false
  );
  const [searchDealer, setSearchDealer] = useState("");
  const [dealerResults, setDealerResults] = useState([]);
  const [dealerLoading, setDealerLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [shouldFocus, setShouldFocus] = useState(true);
  const [updatedAddress, setUpdatedAddress] = useState(null);
  const [useFallback, setUseFallback] = useState(true);
  // item search
  const [showItemSearch, setShowItemSearch] = useState(false);
  const [focusedItemIndex, setFocusedItemIndex] = useState(null);
  const [skipFocus, setSkipFocus] = useState(false);
  const [showDescriptionInput, setShowDescriptionInput] = useState(false);
  const [itemsdetails, setItemsdetails] = useState([
    {
      amount: 0,
      collection_name: "",
      desc: "",
      descKey: "",
      dp: 0,
      itemNo: "",
      price: 0,
      product_name: "",
      qty: 0,
      thumbnail: "",
      totalQty: "",
      unit: "",
      vm: 0,
      warehouse: "",
      weight: 0,
    },
  ]);
  const [error_state] = useState({
    dp: "",
    index: "",
  });
  const [selectedWarehouse, setSelectedWarehouse] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const [stateUpdated, setStateUpdated] = useState(false); //for skip case
  const [dropDownOpen, setDropDownOpen] = useState(false); //for no case
  const [receiptCreated, setReceiptCreated] = useState(false);
  const [receiptData, setReceiptData] = useState({
    paymentMethod: "Cash",
    transactionScreenshot: "",
    paymentAmount: "",
    transactionId: "",
    accountNumber: "",
    totalAmount: "",
    receiptId: "",
    paymentType: "Cash",
  });

  const dealerInpRef = useRef(null);
  const locationRef = useRef(null);
  const itemInpRefs = useRef([]);
  const itemInpRefsMob = useRef([]);
  const quantityRefs = useRef([]);
  const quantityRefsMob = useRef([]);
  const checkboxRef = useRef(null);
  const formRef = useRef(null);

  //?this resets the variables on page change
  useEffect(() => {
    if (!orderId) {
      setGeneralInfo((prevState) =>
        Object.keys(prevState).reduce((acc, key) => {
          acc[key] = "";
          return acc;
        }, {})
      );
      setShippingInfo((prevState) =>
        Object.keys(prevState).reduce((acc, key) => {
          acc[key] = "";
          return acc;
        }, {})
      );
      setDispatchManagers([]);
      setItemsdetails([
        {
          itemNo: "",
          product_name: "",
          collection_name: "",
          warehouse: "",
          qty: "",
          dp: "",
          unit: "",
          amount: "",
          totalQty: "",
          desc: "",
          descKey: "",
        },
      ]);
      setShowDescriptionInput(false);
    }
  }, [orderId]);

  //?this keeps the dealer input focused on page load
  useEffect(() => {
    if (shouldFocus) {
      dealerInpRef?.current?.focus();
    } else {
      dealerInpRef?.current?.blur();
    }
  }, [shouldFocus]);

  //?this gets the data in the view order scenario
  useEffect(() => {
    const fetchOrder = async () => {
      setLoading(true);
      try {
        const apiData = {
          managerId: managerId,
          orderId: orderId,
          warehousename: "",
        };
        const result = await fetchData("order/order", apiData);
        if (result.status === "success") {
          setLoading(false);
          if (result.data.order) {
            setGeneralInfo((prevState) => ({
              ...prevState,
              dealerId: result.data.order.dealerId,
              storeName: result.data.order.storeName,
              dealerName: result.data.order.name,
              createdBy: result.data.order.createdBy,
              billingAddress: result.data.order.billingAddress,

              shippingType: result.data.order.shippingInfo.shippingType,
              shippingCharge: result.data.order.shippingInfo.shippingCharge,
              courierName: result.data.order.shippingInfo.courierName,
              partnerName: result.data.order.shippingInfo.methodName
                ? result.data.order.shippingInfo.methodName
                : result.data.order.shippingInfo.partnerName,
              deliveryPartner: result.data.order.shippingInfo.methodName
                ? result.data.order.shippingInfo.methodName
                : result.data.order.shippingInfo.partnerName,
              mobile: result.data.order.shippingInfo.mobile
                ? result.data.order.shippingInfo.mobile
                : result.data.order.DealerInfo.whatsapp,
              whatsapp: result.data.order.DealerInfo.whatsapp
                ? result.data.order.DealerInfo.whatsapp
                : result.data.order.shippingInfo.mobile,
              email: result.data.order.DealerInfo.email
                ? result.data.order.DealerInfo.email
                : "",
              warehousename: result.data.order.warehousename
                ? result.data.order.warehousename
                : "",
              dmId: result.data.order.dmId,
              dmName: result.data.order.dmName,
              cartType:
                result.data.order.cartType === "Cart" ? "ocart" : "pcart",
              orderDate: result.data.order.orderDate,
              orderId: result.data.order.orderId,

              transactionScreenshot: result.data.order.transactionScreenshot,
              paymentMethod: result.data.order.paymentMethod,
              paymentType: result.data.order.paymentType,
              paymentStatus: result.data.order.paymentStatus,
              paymentAmount: result.data.order.paymentAmount,
              transferTransId: result.data.order.transferTransId,

              status: result.data.order.status,
              manageName: result.data.order.manageName,
              manageValue: result.data.order.manageValue,
              orderType: result.data.order.orderType,
              toPay: result.data.order.toPay,
              dispatchStatus: result.data.order.dispatchStatus,
              reqStatus: result.data.order.reqStatus,
              reqType: result.data.order.reqType,
            }));
            setShippingInfo(result.data.order.shippingInfo);
            setItemsdetails(result.data.order.items);
            setReciptsInfo(result.data.receipts);
          }
        }
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    if (managerId && orderId) {
      fetchOrder();
    }
  }, [orderId, managerId, receiptCreated]);

  //?this gets dispatch managers
  const fetchDMs = useCallback(
    async (warehouse, oID) => {
      try {
        const apiData = {
          managerId: managerId,
          orderId: orderId || oID,
          warehousename: warehouse || generalInfo.warehousename,
        };
        const result = await fetchData("order/order", apiData);
        if (result.status === "success") {
          if (result.data.warehouses) {
            setWarehouses(result.data.warehouses);
          }
          if (result.data.dispatchmanagers.length > 0) {
            setDispatchManagers(result.data.dispatchmanagers);
            setGeneralInfo((prevState) => ({
              ...prevState,
              dmId: result.data.dispatchmanagers[0].managerId,
            }));
          }
          const accounts = result.data.accounts;
          setAccounts(accounts);
          return accounts;
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    [generalInfo.warehousename, managerId, orderId]
  );

  //?gets dispatch managers in view scenario
  useEffect(() => {
    if (managerId && orderId) {
      fetchDMs();
    }
  }, [fetchDMs, orderId, managerId]);

  useEffect(() => {
    if (generalInfo.warehousename) {
      fetchDMs(generalInfo.warehousename);
    }
  }, [generalInfo.warehousename, fetchDMs]);

  const fetchDealer = useCallback(async () => {
    try {
      setDealerLoading(true);
      const apiData = {
        managerId: 1,
        keyword: searchDealer,
      };

      const result = await fetchData("order/finddealer", apiData);

      if (result.status === "success") {
        setDealerResults(result.data);
      }
      setDealerLoading(false);
    } catch (error) {
      console.log("error", error);
      setDealerLoading(false);
    }
  }, [searchDealer]);

  //?this triggers fetchDealer
  useEffect(() => {
    if (searchDealer) {
      const delayDebounceFn = setTimeout(() => {
        fetchDealer();
      }, 700);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchDealer, fetchDealer]);

  //?this method sets up the delivery method details
  const orderDeliveryMethod = async () => {
    if (generalInfo.warehousename.trim() === "") {
      alert("Please select a warehouse before next button.");
      return;
    }
    setLoadingBtn(true);
    try {
      const validSaleItems = itemsdetails.filter((item) =>
        Object.values(item).some(
          (value) => value !== "" && value !== null && value !== undefined
        )
      );
      if (shippingInfo.pincode) {
        setMethodPartners([]);
        const apiData = {
          courierId: "",
          courierName: "",
          shipPincode: shippingInfo.pincode,
          warehousename: generalInfo.warehousename,
          methodName: generalInfo.deliveryPartner,
          streetAddress:
            shippingInfo.street +
            ", " +
            shippingInfo.city +
            ", " +
            shippingInfo.district +
            ", " +
            shippingInfo.state,
          selectedItems: validSaleItems,
        };
        const result = await fetchData(
          "deliverymethod/orderDeliveryMethod",
          apiData
        );
        if (result.status === "success") {
          if (result.methodSelection === "Active") {
            setMethodPartners(result.deliveryMethod);
            setGeneralInfo((prevState) => ({
              ...prevState,
              methodSelection: result.methodSelection
                ? result.methodSelection
                : "",
              courierName: result.courierName ? result.courierName : "",
              deliveryPartner: result.data.methodName
                ? result.data.methodName
                : "",
              deliveryCharge: result.data.deliveryCharge,
            }));
          } else if (result.methodSelection === "Disable") {
            setGeneralInfo((prevState) => ({
              ...prevState,
              shippingType: "Standard",
              cartType: "ocart",
              courierName: result.data.courierName,
              partnerName: result.data.methodName,
              deliveryCharge: result.data.deliveryCharge,
              deliveryPartner: result.data.methodName
                ? result.data.methodName
                : "Self",
              methodSelection: result.methodSelection
                ? result.methodSelection
                : "",
            }));
          }
          setNextBtn(true);
          setLoadingBtn(false);
        } else {
          alert(result.msg);
          setNextBtn(false);
          setLoadingBtn(false);
        }
      }
    } catch (error) {
      console.log("error", error);
      setNextBtn(false);
      setLoadingBtn(false);
    }
  };

  //?updates shipping charges for particular sub method i.e. erickshaw, tata ace etc
  const orderSubMethod = async (selectedValue) => {
    if (shippingInfo.pincode && selectedValue && selectedValue !== "Self") {
      setMethodPartners([]);
      try {
        const validSaleItems = itemsdetails.filter((item) =>
          Object.values(item).some(
            (value) => value !== "" && value !== null && value !== undefined
          )
        );
        const apiData = {
          courierId: "",
          courierName: "",
          shipPincode: shippingInfo.pincode,
          warehousename: generalInfo.warehousename,
          methodName: selectedValue,
          streetAddress:
            shippingInfo.street +
            ", " +
            shippingInfo.city +
            ", " +
            shippingInfo.district +
            ", " +
            shippingInfo.state,
          selectedItems: validSaleItems,
        };
        setLoadingBtn(true);
        const result = await fetchData(
          "deliverymethod/orderDeliveryMethod",
          apiData
        );
        if (result.status === "success") {
          if (result.methodSelection === "Active") {
            setMethodPartners(result.deliveryMethod);
            setGeneralInfo((prevState) => ({
              ...prevState,
              methodSelection: result.methodSelection
                ? result.methodSelection
                : "",
              courierName: result.courierName ? result.courierName : "",
              deliveryPartner: result.data.methodName
                ? result.data.methodName
                : "",
              deliveryCharge: result.data.deliveryCharge,
            }));
          } else if (result.methodSelection === "Disable") {
            setGeneralInfo((prevState) => ({
              ...prevState,
              shippingType: "Standard",
              cartType: "ocart",
              courierName: result.data.courierName,
              partnerName: result.data.methodName,
              deliveryCharge: result.data.deliveryCharge,
              deliveryPartner: result.data.methodName
                ? result.data.methodName
                : "Self",
              methodSelection: result.methodSelection
                ? result.methodSelection
                : "",
            }));
          }
          setNextBtn(true);
          setLoadingBtn(false);
        } else {
          setMethodPartners(result.deliveryMethod);
          setGeneralInfo((prevState) => ({
            ...prevState,
            methodSelection: result.methodSelection
              ? result.methodSelection
              : "",
            courierName: result.courierName ? result.courierName : "",
            deliveryPartner: "",
            deliveryCharge: "",
          }));
          alert(result.msg);
          setLoadingBtn(false);
        }
      } catch (error) {
        console.log("error", error);
        setLoadingBtn(false);
      }
    }
  };

  //?this method calculates the shipping charges according to selected method
  const calculateCharges = async () => {
    if (generalInfo.warehousename.trim() === "") {
      alert("Please select a warehouse before next button.");
      return;
    }
    try {
      if (shippingInfo.pincode) {
        setMethodPartners([]);
        const apiData = {
          courierId: "",
          courierName: "",
          shipPincode: shippingInfo.pincode,
          warehousename: generalInfo.warehousename,
          methodName: generalInfo.deliveryPartner,
          streetAddress:
            shippingInfo.street +
            ", " +
            shippingInfo.city +
            ", " +
            shippingInfo.district +
            ", " +
            shippingInfo.state,
          selectedItems: itemsdetails,
        };
        const result = await fetchData(
          "deliverymethod/orderDeliveryMethod",
          apiData
        );
        if (result.status === "success") {
          if (result.methodSelection === "Active") {
            setGeneralInfo((prevState) => ({
              ...prevState,
              deliveryCharge: result.data.deliveryCharge,
            }));
          } else if (result.methodSelection === "Disable") {
            setGeneralInfo((prevState) => ({
              ...prevState,
              deliveryCharge: result.data.deliveryCharge,
            }));
          }
        } else {
          alert(result.msg);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //?updates the receipt data coming from modal
  const updateReceiptData = (updatedData, newOrderId) => {
    setReceiptData(updatedData);
    setTimeout(async () => {
      handleAddReceipt(updatedData, newOrderId);
    }, 0);
  };

  //?this places the order
  const handleSaleOrder = async (status) => {
    if (error_state.dp) {
      alert(error_state.dp);
      return;
    }

    if (totalAmount < 0) {
      alert("Total amount must be greater than zero");
      return;
    }

    try {
      setLoading(true);

      const validSaleItems = itemsdetails.filter((item) =>
        Object.values(item).some(
          (value) => value !== "" && value !== null && value !== undefined
        )
      );

      const shippingCharge =
        generalInfo.deliveryPartner === "Self"
          ? 0
          : generalInfo.deliveryCharge || generalInfo.shippingCharge;

      const apiData = {
        managerId: managerId,
        orderId: orderId,
        gstuin: generalInfo.gstuin,
        warehousename: generalInfo.warehousename,
        dmId: generalInfo.dmId,
        cartType: generalInfo.cartType,
        dealerId: generalInfo.dealerId,
        name: shippingInfo.name,
        street: shippingInfo.street,
        mobile: shippingInfo.mobile,
        pincode: shippingInfo.pincode,
        city: shippingInfo.city,
        district: shippingInfo.district,
        state: shippingInfo.state,
        shippingType: generalInfo.shippingType,
        shippingCharge: shippingCharge,
        toPay: generalInfo.toPay || false,
        courierName:
          generalInfo.deliveryPartner === "Self"
            ? "Pick Up"
            : generalInfo.courierName,
        methodName:
          generalInfo.courierName === "Pick Up"
            ? "Self"
            : generalInfo.methodName || generalInfo.deliveryPartner,
        partnerName: generalInfo.deliveryPartner || generalInfo.partnerName,
        manageName: generalInfo.manageName,
        manageValue: generalInfo.manageValue,
        saleItems: validSaleItems,
        status: status,
      };

      const result = await fetchData("order/saleOrder", apiData);

      if (result.status === "success") {
        setLoading(false);
        setLoadingBtn(false);

        if (status === "success") {
          if (!orderId) {
            navigate(`/orders/saleorder?orderId=${result.data.orderId}`);
          }
          const oID = result.data.orderId;
          const link = await generatePaymentLink(oID);
          // setOrderPlaced(true);
          const acc = await fetchDMs("", oID);
          handleOpenModal(
            <OrderSuccessModal
              mode={mode}
              handleCloseModal={handleCloseModal}
              orderSuccessKeyPress={(action) =>
                orderSuccessKeyPress(action, link, oID, acc)
              }
            />
          );
        } else if (status === "draft") {
          navigate("/orders");
        }
      } else {
        show(result.msg, "warning");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in handleSaleOrder:", error);
      setLoading(false);
      setLoadingBtn(false);
    }
  };

  const handleCancelOrder = async () => {
    const apiData = {
      managerId: managerId,
      orderId: orderId,
    };
    try {
      const result = await fetchData("order/cancelOrder", apiData);
      if (result.status === "success") {
        show("Order cancelled successfully", "success");
        navigate("/orders");
      }
    } catch (error) {
      show(error, "error");
      console.log("error", error);
    }
  };

  const handleRequestForCancel = async (reason) => {
    const apiData = {
      managerId: managerId,
      orderId: orderId,
      reqType: "Request For Cancel",
      reqIssue: reason,
    };
    try {
      const result = await fetchData("order/orderhelp", apiData);
      if (result.status === "success") {
        show(result.msg, "success");
        navigate("/orders");
      } else {
        show(result.msg, "warning");
      }
    } catch (error) {
      console.log("error ", error);
      show(error, "error");
    }
  };

  const generatePaymentLink = useCallback(
    async (oId) => {
      setPaymentLink("");
      const currentOrderId = oId || orderId;
      if (!currentOrderId) {
        console.error("Order ID is not available for generating payment link");
        return;
      }
      try {
        const apiData = { orderId: currentOrderId };
        const result = await fetchData("receipt/generatePaymentLink", apiData);
        if (result.status === "success" && result.shortUrl) {
          const link = `https://dealer.wallicon.in/${result.shortUrl}`;
          setPaymentLink(link);
          return link;
        } else {
          console.error("Failed to generate payment link:", result.msg);
        }
      } catch (error) {
        console.error("Error generating payment link:", error);
      }
    },
    [orderId]
  );

  useEffect(() => {
    if (orderId) {
      generatePaymentLink(orderId);
    }
  }, [generatePaymentLink, orderId]);

  // ?this triggers orderDeliveryMethod and changes the next button to create order button
  const handleNextButtonPress = () => {
    if (!generalInfo.warehousename) {
      if (formRef.current) {
        if (formRef.current.checkValidity()) {
          if (
            generalInfo.deliveryPartner !== "Self" &&
            generalInfo.partnerName !== "Self"
          ) {
            console.log("Ordering delivery method1");
            setGeneralInfo((prevState) => ({
              ...prevState,
              warehousename: selectedWarehouse,
            }));
            checkboxRef.current.blur();
            handleSubmit();
          } else {
            console.log("Setting nextBtn to true");
            setNextBtn(true);
          }
        } else {
          console.log("Form is invalid");
          show("Form is invalid", "warning");
          formRef.current.reportValidity();
        }
      } else {
        console.log("formRef.current is null");
        show("Form is empty!", "warning");
      }
    } else {
      if (
        generalInfo.deliveryPartner !== "Self" &&
        generalInfo.partnerName !== "Self"
      ) {
        console.log("Ordering delivery method2");
        checkboxRef.current.blur();
        handleSubmit();
      } else {
        console.log("Setting nextBtn to true ?????");
        setNextBtn(true);
        handleSubmit();
      }
    }
  };

  //?same as handleNextButtonPress but for button click
  const handleNextButtonClick = () => {
    console.log("handleNextButtonClick called");
    console.log("generalInfo.warehousename", generalInfo.warehousename);
    console.log("warehouseName", generalInfo.warehousename);

    if (!generalInfo.warehousename) {
      if (formRef.current) {
        if (formRef.current.checkValidity()) {
          if (
            generalInfo.deliveryPartner !== "Self" &&
            generalInfo.partnerName !== "Self"
          ) {
            console.log("Ordering delivery method1");
            setGeneralInfo((prevState) => ({
              ...prevState,
              warehousename: selectedWarehouse,
            }));
            orderDeliveryMethod();
          } else {
            console.log("Setting nextBtn to true");
            setNextBtn(true);
          }
        } else {
          console.log("Form is invalid");
          formRef.current.reportValidity();
        }
      } else {
        console.log("formRef.current is null");
      }
    } else {
      if (
        generalInfo.deliveryPartner !== "Self" &&
        generalInfo.partnerName !== "Self"
      ) {
        console.log("Ordering delivery method2");
        orderDeliveryMethod();
      } else {
        console.log("Setting nextBtn to true");
        setNextBtn(true);
      }
    }
  };

  //?creates reeceipt
  const handleAddReceipt = async (updatedData, newOrderId) => {
    const formData = new FormData();
    formData.append(
      "transactionScreenshot",
      updatedData.transactionScreenshot ? updatedData.transactionScreenshot : ""
    );
    formData.append(
      "paymentMethod",
      updatedData.paymentMethod ? updatedData.paymentMethod : ""
    );
    formData.append(
      "paymentType",
      updatedData.paymentType ? updatedData.paymentType : ""
    );
    formData.append(
      "paymentAmount",
      updatedData.paymentAmount
        ? parseFloat(updatedData.paymentAmount).toFixed(2)
        : ""
    );
    formData.append(
      "transactionId",
      updatedData.transactionId ? updatedData.transactionId : ""
    );
    formData.append(
      "accountNumber",
      updatedData.accountNumber ? updatedData.accountNumber : ""
    );
    formData.append("totalAmount", totalAmount ? totalAmount.toFixed(2) : "");

    const orderIdentifier = newOrderId || orderId;

    try {
      const result = await handleFileData(
        `receipt/offlinePay/${managerId}/${orderIdentifier}?receiptId=${receiptData.receiptId}`,
        formData
      );
      if (result.status === "success") {
        show(result.msg, "success");
        setReceiptCreated(true);
        handleCloseModal();
      }
      if (result.status === "warn" || result.status === "warning") {
        show(result.msg, "warning");
      }
      if (result.status === "failed") {
        show(result.msg, "error");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  //?for skip shipping charges scenario
  const handleSkipShippingCharges = () => {
    setGeneralInfo((prevState) => ({
      ...prevState,
      deliveryPartner: "Self",
      courierName: "Pick Up",
      toPay: false,
    }));
    setStateUpdated(true);
  };
  if (stateUpdated) {
    setTimeout(() => {
      handleNextButtonPress();
    }, 700);
    setStateUpdated(false);
  }

  //?adds up new item in the list
  const handleAddClick = () => {
    const errors = [];
    if (itemsdetails)
      itemsdetails.forEach((item) => {
        if (item.qty > item.totalQty) {
          errors.push(
            `Quantity exceeds available stock! Total available: ${item.totalQty}`
          );
        }
        if (item.itemNo === "") {
          errors.push(`Item Number cannot be empty`);
        }
        if (item.qty === "") {
          errors.push(`Item Quantity cannot be empty`);
        }
      });

    if (error_state.dp) {
      alert(error_state.dp);
    } else if (errors.length > 0) {
      show(errors.join(" "), "warning");
    } else {
      setItemsdetails([
        ...itemsdetails,
        {
          itemNo: "",
          product_name: "",
          collection_name: "",
          warehouse: "",
          qty: "",
          dp: "",
          unit: "",
          amount: "",
          totalQty: "",
          desc: "",
          descKey: "",
        },
      ]);
    }
  };

  //?removes item from the list with the help of index
  const handleRemoveClick = (index) => {
    if (itemsdetails.length > 1) {
      const list = [...itemsdetails];
      list.splice(index, 1);
      setItemsdetails(list);
    }
  };

  //?After selecting dealer this updates the dealer info
  const updateGeneralInfo = (d) => {
    if (d.name === "Cash") {
      setGeneralInfo((prevState) => ({
        ...prevState,
        dealerId: d.dealerId,
        dealerName: d.name,
        storeName: d.storeName,
        mobile: d.mobile,
        whatsapp: d.whatsapp,
        email: d.email,
        gstuin: d.gstuin ? d.gstuin : "",
        shippingType: "Standard",
        courierName: "Pick Up",
        partnerName: "Self",
      }));
    } else {
      setGeneralInfo((prevState) => ({
        ...prevState,
        dealerId: d.dealerId,
        dealerName: d.name,
        storeName: d.storeName,
        mobile: d.mobile,
        whatsapp: d.whatsapp,
        email: d.email,
        gstuin: d.gstuin,
        billingAddress:
          (d.street || d.city || d.district || d.state || d.pincode) &&
          [d.street, d.city, d.district, d.state, d.pincode]
            .filter(Boolean)
            .join(" "),
      }));
    }
    itemInpRefs.current[0]?.focus();
    itemInpRefsMob.current[0]?.focus();
  };

  //?After selecting the dealer or selecting dealer with an updated shipping address it sets up the shipping address
  const updateShippingInfo = (dealer, address) => {
    setShippingInfo((prevState) => ({
      ...prevState,
      mobile: dealer.mobile,
      name: dealer.storeName,
      pincode: address?.pinCode || dealer.pincode,
      street: address?.streetAddress || dealer.street,
      city: address?.city || dealer.city,
      district: address?.district || dealer.district,
      state: address?.state || dealer.state,
    }));
  };

  console.log("search dealer ", searchDealer);
  //?handles selection of dealer after dealer search
  const handleSelectDealer = (d) => {
    setSearchDealer("");
    dealerInpRef.current = null;
    locationRef.current?.focus();
    console.log("dealer Info ", d);
    if ("refcode" in d) {
      updateGeneralInfo(d);
      updateShippingInfo(d);
    } else {
      handleOpenModal(
        <DealerAddressModal
          name={"dealer_address_modal"}
          shippingAddress={
            d.street || d.city || d.district || d.state || d.pincode
              ? [d.street, d.city, d.district, d.state, d.pincode]
                  .filter(Boolean)
                  .join(" ")
              : undefined
          }
          ref={locationRef}
          handleCloseModal={() => handleCloseModal()}
          onAddressUpdate={(data) => {
            setUpdatedAddress(data);
            updateGeneralInfo(d);
            updateShippingInfo(d, data);
          }}
          mode={mode}
          onKeyPress={(e) => {
            e.preventDefault();
            e.stopPropagation();
            updateGeneralInfo(d);
            updateShippingInfo(d, updatedAddress);
          }}
        />
      );
    }
  };

  //?this focuses the item search of every new item list
  const handleFocusNextItem = () => {
    setTimeout(() => {
      const lastItemIndex = itemsdetails.length - 1;

      if (itemInpRefs.current.length) {
        const nextItemIndex = lastItemIndex + 1;

        if (nextItemIndex < itemInpRefs.current.length) {
          const nextItemRef = itemInpRefs.current[nextItemIndex];

          if (nextItemRef && !skipFocus) {
            nextItemRef.focus();
            setFocusedItemIndex(nextItemIndex);
            setShowItemSearch(true);
          }
        }
      }
    }, 0);
    setTimeout(() => {
      const lastItemIndex = itemsdetails.length - 1;

      if (itemInpRefsMob.current.length) {
        const nextItemIndex = lastItemIndex + 1;

        if (nextItemIndex < itemInpRefsMob.current.length) {
          const nextItemRef = itemInpRefsMob.current[nextItemIndex];

          if (nextItemRef && !skipFocus) {
            nextItemRef.focus();
            setFocusedItemIndex(nextItemIndex);
            setShowItemSearch(true);
          }
        }
      }
    }, 0);
  };

  //?handles selection of item after item search
  const handleSelectItem = (item, index) => {
    setGeneralInfo((prevState) => ({
      ...prevState,
      cartType: item.cartType,
      shippingType: "Standard",
      warehousename: item.warehouse,
    }));
    fetchDMs(item.warehouse);
    setTimeout(() => {
      setItemsdetails((prevState) => {
        const updatedItems = prevState.map((currentItem, idx) => {
          return idx === index
            ? {
                ...currentItem,
                ...item,
                desc: item.desc ?? currentItem.desc,
              }
            : currentItem;
        });
        return updatedItems;
      });
    }, 0);
    handleFocusNextItem();
  };

  const handleItemInputFocus = (index) => {
    setFocusedItemIndex(index);
    setShowItemSearch(true);
  };

  const handleItemInputBlur = () => {
    setTimeout(() => {
      setShowItemSearch(false);
      setFocusedItemIndex(null);
    }, 200);
  };

  const handleDealerKeyDown = (e) => {
    if (showDealerSearch) {
      if (e.key === "ArrowDown") {
        setCurrentIndex((prevIndex) =>
          prevIndex < dealerResults.length - 1 ? prevIndex + 1 : prevIndex
        );
        e.preventDefault();
      }

      if (e.key === "ArrowUp") {
        setCurrentIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : prevIndex
        );
        e.preventDefault();
      }

      if (e.key === "Escape") {
        setShowDealerSearch(false);
      }

      if ((e.altKey || e.metaKey) && e.key.toLowerCase() === "c") {
        setSearchDealer("");
        setTimeout(() => {
          openAddDealerModal();
        }, 100);
      }

      if (e.key === "Enter") {
        e.preventDefault();
        handleSelectDealer(dealerResults && dealerResults[currentIndex]);
      }
    }
  };

  //?this sends order data (ONLINE/OFFLINE and STATUS) to the header of the parent page (Order.jsx)
  useEffect(() => {
    if (!loading) {
      onOrderData({
        type: generalInfo.orderType,
        status: generalInfo.status,
        orderId: orderId,
        createdBy: generalInfo.createdBy,
        orderDate: generalInfo.orderDate,
      });
    }
  }, [
    generalInfo.createdBy,
    generalInfo.orderDate,
    generalInfo.orderType,
    generalInfo.status,
    loading,
    onOrderData,
    orderId,
  ]);

  const handleOpenModal = (content) => {
    setShouldFocus(false);
    setIsModalOpen(true);
    setModalContent(content);
  };

  const handleCloseModal = () => {
    setShouldFocus(true);
    setIsModalOpen(false);
    setModalContent(null);
  };

  const openShippingModal = () => {
    setSkipFocus(true);
    handleOpenModal(
      <ShippingChargesModal
        mode={mode}
        close={() => handleCloseModal()}
        checkboxRef={checkboxRef}
        calculateCharges={() => {
          calculateCharges();
        }}
        orderDeliveryMethod={() => {
          orderDeliveryMethod();
        }}
        skipShippingCharges={handleSkipShippingCharges}
        noShippingCharges={() => setDropDownOpen(true)}
      />
    );
  };

  //?when order is placed this gets executed for sharing payment link or creating receipt
  const orderSuccessKeyPress = (action, link, oID, acc) => {
    const whatsappNo = generalInfo?.whatsapp || "Not Provided";
    const mobileNo = generalInfo?.mobile || "Not Provided";
    const email = generalInfo?.email || "Not Provided";
    const generatedLink = link || paymentLink || "No Link Available";
    const newOrderId = orderId || oID || "No Order Id Available";
    const accounts2 = acc || accounts;

    if (action === "shareLink") {
      setTimeout(() => {
        handleOpenModal(
          <ShareLinkModal
            mode={mode}
            managerId={managerId}
            handleCloseModal={handleCloseModal}
            generatedLink={generatedLink}
            whatsappNo={whatsappNo}
            mobileNo={mobileNo}
            email={email}
            isModalOpen={true}
          />
        );
      }, 0);
    } else if (action === "addReceipt") {
      setTimeout(() => {
        handleOpenModal(
          <AddReceiptModal
            handleCloseModal={handleCloseModal}
            mode={mode}
            totalAmount={totalAmount}
            accounts={accounts2}
            receiptData={receiptData && receiptData}
            updateReceiptData={async (updatedData) =>
              updateReceiptData(updatedData, newOrderId)
            }
            type={"create"}
          />
        );
      }, 0);
    }
  };

  //?this opens up an confirmation modal then calls handleSaleOrder accordingly
  const handleSubmit = () => {
    handleOpenModal(
      <ManagerModal
        heading={"Confirm Order"}
        centerHeading={"Are you sure want to place this Order?"}
        targetWord={"order"}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={() => handleSaleOrder("success")}
      />
    );
  };

  const openAddDealerModal = () => {
    handleOpenModal(
      <AdduserModel
        isModalOpen={isModalOpen}
        mode={mode}
        focus={true}
        close={() => handleCloseModal()}
        onDealerAdd={(newDealer) => handleSelectDealer(newDealer)}
      />
    );
  };

  const openAddItemModal = () => {
    handleOpenModal(
      <AddNewItemModal
        handleCloseModal={() => setIsModalOpen(false)}
        managerId={managerId}
        mode={mode}
      />
    );
  };

  const openCancelOrderModal = () => {
    handleOpenModal(
      <ManagerModal
        heading={"Cancel Order"}
        centerHeading={"Are you sure want to cancel this Order?"}
        subHeading={"To confirm! type cancel in the text field."}
        targetWord={"cancel"}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={() => handleCancelOrder()}
      />
    );
  };

  const openRequestForCancelModal = () => {
    handleOpenModal(
      <RequestForCancelModal
        mode={mode}
        handleCloseModal={() => handleCloseModal()}
        handleSubmit={handleRequestForCancel}
      />
    );
  };

  const amount = itemsdetails.reduce((sum, item) => {
    if (item.amount && !isNaN(item.amount)) {
      return sum + parseFloat(item.amount);
    }
    return sum;
  }, 0);

  let cartPriceSum =
    itemsdetails &&
    itemsdetails.reduce((sum, currentItem) => {
      return (
        sum +
        parseFloat(currentItem.price ? currentItem.price : 0) *
          parseFloat(currentItem.qty ? currentItem.qty : 0)
      );
    }, 0);

  let cartTotal =
    itemsdetails &&
    itemsdetails
      .map((x) =>
        !x.accessories
          ? 0
          : x.accessories
              .map(
                (y) =>
                  (y.accessoriesQty ? y.accessoriesQty : 0) * y.accessoriesPrice
              )
              .reduce((a, b) => a + b, 0)
      )
      .reduce((a, b) => a + b, 0);

  let subtotal =
    (cartPriceSum ? cartPriceSum : 0) + (cartTotal ? cartTotal : 0);

  let gettotalAmount =
    subtotal +
    parseFloat(
      generalInfo.toPay
        ? 0
        : generalInfo.deliveryPartner === "Self"
        ? 0
        : generalInfo.deliveryCharge
        ? generalInfo.deliveryCharge
        : generalInfo.shippingCharge
        ? generalInfo.shippingCharge
        : 0
    );

  let totalAmount = Math.round(gettotalAmount);

  const totalReceiptAmount = reciptsInfo.reduce(
    (sum, item) => sum + item.paymentAmount,
    0
  );

  const headerData = [
    "S.No",
    "Item No",
    "Product",
    "Warehouse",
    "Quantity",
    "Unit",
    "Rate",
    "Amount",
  ];

  return (
    <>
      <ModalWrapper
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        noBtn
        style={
          modalContent?.props.name === "dealer_address_modal" && width < 890
            ? { alignSelf: "flex-start", marginTop: "10px" }
            : {}
        }
      >
        {modalContent}
      </ModalWrapper>

      {loading && orderId ? (
        <div className={styles.loader_con}>
          <Spinner color={mode ? "#000000" : "#4164E3"} />
        </div>
      ) : (
        <>
          <div className={styles.so_scroll_wrapper}>
            <form
              className={styles.so_form}
              ref={formRef}
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className={styles.so_form_1}>
                <div style={{ position: "relative" }}>
                  <InputTag
                    ref={dealerInpRef}
                    labelText={"Company or Dealer's Name / ID"}
                    placeholderText={"Search Dealer"}
                    padding={"8px"}
                    fontSize={"12px"}
                    value={
                      useFallback && !searchDealer
                        ? generalInfo.storeName && generalInfo.dealerName
                          ? `${generalInfo.storeName} / ${generalInfo.dealerName}`
                          : ""
                        : searchDealer
                    }
                    onChange={(e) => {
                      setSearchDealer(e.target.value);
                      setUseFallback(false);
                    }}
                    onFocus={() => {
                      if (!orderId) {
                        setShowDealerSearch(true);
                      }
                    }}
                    onBlur={() => {
                      setShowDealerSearch(false);
                      if (searchDealer === "") {
                        setUseFallback(true);
                      }
                    }}
                    onKeyDown={(e) => handleDealerKeyDown(e)}
                    maxLength={100}
                    required
                    readOnly={orderId}
                    enterKeyHint={"enter"}
                  />

                  {showDealerSearch && (
                    <SearchDealer
                      mode={mode}
                      results={dealerResults}
                      loading={dealerLoading}
                      currentIndex={currentIndex}
                      onDealerSelect={handleSelectDealer}
                      openAddDealerModal={openAddDealerModal}
                    />
                  )}
                </div>
                <div>
                  <InputTag
                    labelText={"Shipping Address"}
                    placeholderText={"Enter Dealer Address"}
                    value={
                      (shippingInfo.street ||
                        shippingInfo.city ||
                        shippingInfo.district ||
                        shippingInfo.state ||
                        shippingInfo.pincode) &&
                      [
                        shippingInfo.street,
                        shippingInfo.city,
                        shippingInfo.district,
                        shippingInfo.state,
                        shippingInfo.pincode,
                      ]
                        .filter(Boolean)
                        .join(" ")
                    }
                    padding={"8px"}
                    fontSize={"12px"}
                    required
                    readOnly={orderId}
                  />
                </div>
              </div>
              <div className={styles.so_form_1}>
                <div>
                  <div style={{ width: "50%" }}>
                    <VerticalDropDown
                      height={"37px"}
                      fontSize={"12px"}
                      dropDownText={generalInfo.warehousename || "Select"}
                      label={"Warehouse"}
                      value={
                        generalInfo.warehousename
                          ? {
                              label: generalInfo.warehousename,
                              value: generalInfo.warehousename,
                            }
                          : null
                      }
                      options={
                        warehouses?.map((w) => ({
                          label: w.warehousename,
                          value: w.warehousename,
                        })) || []
                      }
                      onchange={(option) => {
                        const selectedValue = option.value;
                        setSelectedWarehouse(selectedValue);
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          warehousename: selectedValue,
                        }));
                      }}
                      disableOpen={orderId}
                    />
                  </div>
                  <div style={{ width: "50%" }}>
                    <VerticalDropDown
                      height={"37px"}
                      fontSize={"12px"}
                      dropDownText={
                        dispatchManagers?.find(
                          (manager) => manager.managerId === generalInfo.dmId
                        )?.name || "Select"
                      }
                      label={"Dispatch Manager"}
                      value={
                        generalInfo.dmId
                          ? {
                              label: dispatchManagers.find(
                                (manager) =>
                                  manager.managerId === generalInfo.dmId
                              )?.name,
                              value: generalInfo.dmId,
                            }
                          : null
                      }
                      options={
                        dispatchManagers?.map((d) => ({
                          label: d.name,
                          value: d.managerId,
                        })) || []
                      }
                      onchange={(option) => {
                        const selectedValue = option.value;
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          dmId: selectedValue,
                        }));
                      }}
                      disableOpen={orderId}
                    />
                  </div>
                </div>
                <div>
                  <div style={{ width: "100%", position: "relative" }}>
                    <div className={styles.voucher_det}>
                      <TextElement
                        labelColor={mode ? "#fff" : "#646B88"}
                        value={
                          generalInfo.deliveryPartner === "Self"
                            ? "Pick Up"
                            : generalInfo.courierName || "--"
                        }
                        valueColor={mode ? "#fff" : "#646B88"}
                        fontWeight={"400"}
                      />
                    </div>
                    <VerticalDropDown2
                      height={"37px"}
                      fontSize={"12px"}
                      label={"Delivery Method"}
                      dropDownOpen={dropDownOpen}
                      dropDownText={
                        generalInfo.deliveryPartner
                          ? generalInfo.deliveryPartner
                          : "Select"
                      }
                      value={
                        generalInfo.deliveryPartner
                          ? {
                              label: generalInfo.deliveryPartner,
                              value: generalInfo.deliveryPartner,
                            }
                          : null
                      }
                      options={[
                        { label: "Self", value: "Self" },
                        ...methodPartners.map((m) => ({
                          label: m.methodName || m.partnerName,
                          value: m.methodName || m.partnerName,
                        })),
                      ]}
                      onchange={(option) => {
                        const selectedValue = option.value;
                        setGeneralInfo((prevState) => ({
                          ...prevState,
                          deliveryPartner: selectedValue,
                        }));
                        setDropDownOpen(false);
                        if (selectedValue !== "Self") {
                          orderSubMethod(selectedValue);
                        } else {
                          handleSkipShippingCharges();
                        }
                        checkboxRef.current?.focus();
                      }}
                      disableOpen={orderId}
                    />
                  </div>
                </div>
              </div>
            </form>

            <ul className={styles.p_table}>
              <div className={styles.p_table_header_con}>
                <li
                  className={`${styles.p_table_header} ${
                    mode ? styles.p_table_header_dark : ""
                  }`}
                >
                  {headerData.map((h, index) => (
                    <div
                      className={`${styles.entry_2} ${
                        h === "Item No" && !orderId ? styles.item_no : ""
                      }`}
                      key={`${h}-${index}`}
                    >
                      {h}
                    </div>
                  ))}
                </li>
                {!orderId && itemsdetails.length > 1 && (
                  <div
                    className={styles.hide_760px}
                    style={{ visibility: "hidden" }}
                  >
                    <RemoveIcon />
                  </div>
                )}
              </div>
              <div className={styles.p_table_list}>
                <>
                  {itemsdetails.map((item, index) => (
                    <React.Fragment key={index}>
                      {orderId ? (
                        <ItemList d={item} index={index} mode={mode} />
                      ) : (
                        <NewItemList
                          itemInpRefs={(el) =>
                            (itemInpRefs.current[index] = el)
                          }
                          itemInpRefsMob={(el) =>
                            (itemInpRefsMob.current[index] = el)
                          }
                          key={index}
                          item={item}
                          index={index}
                          mode={mode}
                          managerId={managerId}
                          orderType={generalInfo.orderType}
                          onSelectItem={(item) => {
                            handleSelectItem(item, index);
                          }}
                          itemsdetailslength={itemsdetails.length}
                          removeItem={() => handleRemoveClick(index)}
                          addItem={() => handleAddClick()}
                          onShowDesc={(value) => setShowDescriptionInput(value)}
                          onCompletion={() => openShippingModal()}
                          quantityRefs={quantityRefs.current}
                          quantityRefsMob={quantityRefsMob.current}
                          openAddItemModal={openAddItemModal}
                          showItemSearch={
                            showItemSearch && focusedItemIndex === index
                          }
                          setShowItemSearch={setShowItemSearch}
                          focusedIndex={focusedItemIndex}
                          onFocus={() => handleItemInputFocus(index)}
                          onBlur={handleItemInputBlur}
                        />
                      )}
                    </React.Fragment>
                  ))}
                </>
              </div>
              {!orderId && (
                <div
                  className={styles.cricle_btn}
                  onClick={() => {
                    handleAddClick();
                  }}
                >
                  <TestTopAddDealerIcon color="#4164E3" />
                </div>
              )}
              {showDescriptionInput && !orderId && (
                <div className={styles.so_instc}>
                  <p>Press Enter to Add Item</p>
                  <DividerIcon />
                  <p>Double Enter for Next Option</p>
                  <DividerIcon />
                  <p>Ctrl + Backspace for Remove Item</p>
                </div>
              )}
            </ul>
          </div>
        </>
      )}

      <div
        className={`${styles.so_footer} ${mode ? styles.so_footer_dark : ""}`}
      >
        {loading ? (
          <div style={{ margin: "auto 0" }}>
            <Spinner
              borderWidth={"1px"}
              height={20}
              width={20}
              color={mode ? "#000000" : "#4164E3"}
            />
          </div>
        ) : (
          orderId && (
            <div className={styles.so_footer_left}>
              <div
                className={`${styles.so_receipt_data} ${
                  mode ? styles.so_receipt_data_dark : ""
                }`}
              >
                <div
                  className={`${styles.so_receipt_data_header} ${
                    mode ? styles.so_receipt_data_header_dark : ""
                  }`}
                >
                  <div className="col">Receipt ID</div>
                  <div className="col">Method</div>
                  <div className="col">Amount</div>
                  <div className="col">Status</div>
                </div>
                <div className={styles.scroll_con}>
                  {reciptsInfo &&
                    reciptsInfo.map((r, index) => (
                      <div
                        className={`${styles.so_receipt_data_entry} ${
                          mode ? styles.so_receipt_data_entry_dark : ""
                        }`}
                        key={index}
                      >
                        <div className="col" key={index + r.receiptId}>
                          {r.receiptId}
                        </div>
                        <div className="col" key={index + r.paymentMethod}>
                          {r.paymentMethod}
                        </div>
                        <div className="col" key={index + r.paymentAmount}>
                          {r.paymentAmount}
                        </div>
                        <div
                          className="col"
                          key={index + r.status}
                          style={{ color: "#FB9600" }}
                        >
                          {r.status}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              {totalReceiptAmount < totalAmount && (
                <div
                  className={styles.add_receipt_btn}
                  onClick={() => orderSuccessKeyPress("addReceipt")}
                >
                  <TestTopAddDealerIcon />
                </div>
              )}
            </div>
          )
        )}

        <div className={styles.so_footer_right}>
          <div className={styles.right_btns}>
            {loading ? (
              <>
                <Spinner
                  borderWidth={"1px"}
                  height={10}
                  width={10}
                  color={mode ? "#000000" : "#4164E3"}
                />
              </>
            ) : !orderId ? null : generalInfo.reqType !==
                "Request For Cancel" &&
              generalInfo.status !== "Cancelled" &&
              generalInfo.dispatchStatus !== "New Order" &&
              generalInfo.dispatchStatus !== null ? (
              <>
                <div className={styles.hide_1150px}>
                  <Button
                    buttonIcon={<CancelIcon2 />}
                    buttonText={"Request for Cancel"}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() => openRequestForCancelModal()}
                  />
                </div>
                <div className={styles.show_1150px}>
                  <IconActionText
                    icon={<CancelIcon2 />}
                    text={"Request for Cancel"}
                    textColor={mode ? "#FFFFFF" : "#000000"}
                    onClick={() => openRequestForCancelModal()}
                  />
                </div>
              </>
            ) : (
              generalInfo.status !== "Cancelled" &&
              generalInfo.reqType !== "Request For Cancel" &&
              generalInfo.status !== "Dispatched" && (
                <>
                  <div className={styles.hide_1150px}>
                    <Button
                      buttonIcon={<CancelIcon2 />}
                      buttonText={"Cancel Order"}
                      buttonBgColor={"#000000"}
                      buttonTextColor={"#ffffff"}
                      buttonBorder={"none"}
                      buttonClick={() => openCancelOrderModal()}
                    />
                  </div>
                  <div className={styles.show_1150px}>
                    <IconActionText
                      icon={
                        <CancelIcon2 color={mode ? "#FFFFFF" : "#000000"} />
                      }
                      text={"Cancel Order"}
                      textColor={mode ? "#FFFFFF" : "#000000"}
                      onClick={() => openCancelOrderModal()}
                    />
                  </div>
                </>
              )
            )}

            {orderId && (
              <>
                <div className={styles.hide_1150px}>
                  <Button
                    buttonIcon={<NewEyeIcon />}
                    buttonText={"View Invoice"}
                    buttonBgColor={"#000000"}
                    buttonTextColor={"#ffffff"}
                    buttonBorder={"none"}
                    buttonClick={() =>
                      navigate("/orders/saleinvoice?orderId=" + orderId)
                    }
                  />
                </div>
                <div className={styles.show_1150px}>
                  <IconActionText
                    icon={<NewEyeIcon color={mode ? "#FFFFFF" : "#000000"} />}
                    text={"View Invoice"}
                    textColor={mode ? "#FFFFFF" : "#000000"}
                    onClick={() =>
                      navigate("/orders/saleinvoice?orderId=" + orderId)
                    }
                  />
                </div>
              </>
            )}
            {orderId &&
              generalInfo.status !== "Cancelled" &&
              generalInfo.reqType !== "Request For Cancel" && (
                <>
                  <div className={styles.hide_1150px}>
                    <Button
                      buttonIcon={<ShareIcon />}
                      buttonText={"Share"}
                      buttonBgColor={"#4164E3"}
                      buttonTextColor={"#ffffff"}
                      buttonBorder={"none"}
                      buttonClick={() => orderSuccessKeyPress("shareLink")}
                    />
                  </div>
                  <div className={styles.show_1150px}>
                    <IconActionText
                      icon={<ShareIcon color={mode ? "#FFFFFF" : "#000000"} />}
                      text={"Share"}
                      textColor={mode ? "#FFFFFF" : "#000000"}
                      onClick={() => orderSuccessKeyPress("shareLink")}
                    />
                  </div>
                </>
              )}
          </div>

          <div
            className={`${styles.so_amount_info} ${
              mode ? styles.so_amount_info_dark : ""
            }`}
          >
            <div>
              <p>Sub Total:</p>
              <p>
                ₹{" "}
                {!orderId
                  ? amount === 0
                    ? 0
                    : amount
                  : itemsdetails.reduce(
                      (total, item) => total + item.qty * item.price,
                      0
                    )}
              </p>
            </div>
            <div className={styles.so_shipping}>
              <p>Shipping Charges:</p>
              <div className={styles.so_check}>
                <p>To Pay</p>
                <input
                  type="checkbox"
                  value={generalInfo.toPay}
                  onChange={(e) =>
                    setGeneralInfo((prevState) => ({
                      ...prevState,
                      toPay: e.target.checked,
                    }))
                  }
                  checked={generalInfo.toPay}
                  ref={checkboxRef}
                  disabled={
                    generalInfo.deliveryPartner === "Self"
                      ? true
                      : false || orderId
                  }
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleNextButtonPress();
                    }
                  }}
                />
              </div>
              <p>
                {loadingBtn ? (
                  <Spinner
                    borderWidth={"1px"}
                    height={10}
                    width={10}
                    color={mode ? "#000000" : "#4164E3"}
                  />
                ) : (
                  <>
                    ₹{" "}
                    {generalInfo.deliveryPartner === "Self"
                      ? 0
                      : generalInfo.deliveryCharge ||
                        generalInfo.shippingCharge ||
                        0}
                  </>
                )}
              </p>
            </div>
            <div>
              <p>Total Amount(INR):</p>
              <p>
                {loadingBtn ? (
                  <Spinner
                    borderWidth={"1px"}
                    height={10}
                    width={10}
                    color={mode ? "#000000" : "#4164E3"}
                  />
                ) : (
                  <>₹ {totalAmount === 0 ? 0 : totalAmount}</>
                )}
              </p>
            </div>
          </div>

          {!orderId && (
            <div className={styles.next_btn}>
              {!orderId && nextBtn ? (
                <Button
                  buttonIcon={<SaveIcon />}
                  buttonText={"Save"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#FFFFFF"}
                  buttonBorder={"none"}
                  buttonClick={() => handleSubmit()}
                />
              ) : (
                <Button
                  buttonIcon={<LeftArrowIcon />}
                  buttonText={"Next"}
                  buttonBgColor={"#4164E3"}
                  buttonTextColor={"#ffffff"}
                  buttonBorder={"none"}
                  buttonClick={() => handleNextButtonClick()}
                  loading={loadingBtn}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SaleOrder;
