import { useEffect, useRef } from "react";
import styles from "../../../pages/ordermanager/PurchaseOrder.module.css";
import Spinner from "../FormComponents/Spinner";

const SearchSupplier = ({
  mode,
  results = [],
  loading,
  currentIndex,
  onDealerSelect,
  openSupplierModal,
}) => {
  const rowRefs = useRef([]);
  useEffect(() => {
    if (rowRefs.current[currentIndex]) {
      rowRefs.current[currentIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [currentIndex]);
  return (
    <>
      <div
        className={`${styles.search_supplier} ${
          mode ? styles.search_supplier_dark : ""
        }`}
        onMouseDown={(e) => e.preventDefault()}
      >
        <div>
          <p
            style={{ color: mode ? "#749aff" : "#0100E4" }}
            onClick={() => openSupplierModal()}
          >
            Create New Supplier
          </p>
          <p onClick={() => openSupplierModal()}>ALT + C</p>
        </div>
        <div className={styles.search_list}>
          {loading ? (
            <Spinner
              height={60}
              width={60}
              color={mode ? "#000000" : "#4164E3"}
            />
          ) : (
            <>
              {results &&
                results.map((r, index) => (
                  <div
                    className={`${
                      currentIndex === index
                        ? mode
                          ? styles.focused_row_dark
                          : styles.focused_row
                        : mode
                        ? styles.selected_row_dark
                        : styles.selected_row
                    } ${styles.search_list_item}`}
                    key={index}
                    ref={(el) => (rowRefs.current[index] = el)}
                    onClick={() => {
                      onDealerSelect(r);
                    }}
                  >
                    {r.supplierName}
                  </div>
                ))}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SearchSupplier;
