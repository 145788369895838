import { CancelIcon2, TestTopAddDealerIcon } from "../../icons/Icon";
import { CloseIcon } from "../../icons/Icon2";
import Button from "../Dealers/Button";
import InputTag from "../FormComponents/InputTag";
import FileUpload from "./FileUpload";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { useEffect, useState } from "react";
import VerticalDropDown from "../FormComponents/VerticalDropDown";
import fetchData from "../../../APIsControll/apiControll";
import VerticalSearchDropDown from "../kyc/VerticalSearchDropDown";
import { handleFileData } from "../../../APIsControll/apiControl2";
import { useToast } from "../Toaster/Toaster";

const AddNewItemModal = ({ mode, handleCloseModal, managerId }) => {
  const { show } = useToast();
  const [image, setImage] = useState(null);
  // const [loading, setLoading] = useState(false);
  const [newItemData, setNewItemData] = useState({
    product_name: "",
    product_Id: "",
    collection_name: "",
    collection_id: "",
    itemNo: "",
    unit: "",
    weight: "",
    vm: "",
    description: "",
    image: "",
    products: [],
    collections: [],
  });

  const handleAddItem = async () => {
    const formdata = new FormData();
    formdata.append("image", image ? image : "");
    formdata.append("product_Id", newItemData.product_Id);
    formdata.append("collection_id", newItemData.collection_id);
    formdata.append("product_name", newItemData.product_name);
    formdata.append("collection_name", newItemData.collection_name);
    formdata.append("unit", newItemData.unit);
    formdata.append("price", newItemData.price);
    formdata.append("weight", newItemData.weight);
    formdata.append("vm", newItemData.vm);
    formdata.append("description", newItemData.description);
    try {
      // setLoading(true);
      const result = await handleFileData(
        `inventory/addUpdateOutsideItem/${managerId}/${newItemData.itemNo}`,
        formdata
      );
      if (result.status === "success") {
        show(result.msg, "success");
        // setLoading(false);
      }
      if (result.status === "falied") {
        show(result.msg, "error");
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      console.log("Error", error);
    }
  };

  useEffect(() => {
    const fetchItemData = async () => {
      try {
        const apiData = {
          managerId: managerId,
          product_name: newItemData.product_name.toUpperCase(),
        };
        const result = await fetchData("order/productsColl", apiData);
        if (result.status === "success") {
          setNewItemData((prevState) => ({
            ...prevState,
            products: result.data.products ? result.data.products : [],
            collections: result.data.collections ? result.data.collections : [],
          }));
        }
      } catch (error) {
        console.log("Error ", error);
      }
    };
    if (managerId) {
      fetchItemData();
    }
  }, [managerId, newItemData.product_name]);

  const submitForm = (e) => {
    e.preventDefault();
    if (!newItemData.unit) {
      alert("Please select a unit.");
      return;
    }
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      return;
    }
    handleAddItem();
    setNewItemData({
      product_name: "",
      product_Id: "",
      collection_name: "",
      collection_id: "",
      itemNo: "",
      unit: "",
      weight: "",
      vm: "",
      description: "",
      image: "",
      products: [],
      collections: [],
    });
    handleCloseModal();
  };

  const handleProductSelect = (selectedOption) => {
    console.log(selectedOption);
    setNewItemData({
      ...newItemData,
      product_name: selectedOption.label,
      product_Id: selectedOption.value,
    });
  };

  const handleCollectionSelect = (selectedOption) => {
    console.log(selectedOption);
    setNewItemData({
      ...newItemData,
      collection_name: selectedOption.label,
      collection_id: selectedOption.value,
    });
  };

  const handleFileChange = (file) => {
    setImage(file);
    setNewItemData({
      ...newItemData,
      image: file,
    });
  };

  const units = [
    { label: "Roll", value: "Roll" },
    { label: "Pcs", value: "Pcs" },
    { label: "Box", value: "Box" },
    { label: "Sq Meter", value: "Sq Meter" },
    { label: "Meter", value: "Meter" },
    { label: "Sq Feet", value: "Sq Feet" },
    { label: "Feet", value: "Feet" },
    { label: "Kg", value: "Kg" },
  ];

  return (
    <div
      className={styles.add_new_item_modal}
      style={{ backgroundColor: mode && "#232529" }}
    >
      <div>
        <p style={{ color: mode && "#FFFFFF" }}> Add New Item</p>
        <div style={{ cursor: "pointer" }} onClick={handleCloseModal}>
          <CloseIcon mode={mode} />
        </div>
      </div>
      <div>
        {/* <InputTag
          labelText={"Product Name"}
          placeholderText={"Enter Product name"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"text"}
          maxLength={10}
          value={newItemData.product_name}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              product_name: e.target.value.toUpperCase(),
            })
          }
        />
        <datalist id="prodcuts">
          {newItemData.products &&
            newItemData.products.map((item, index) => (
              <option key={index}>{item.product_name}</option>
            ))}
        </datalist> */}
        <div className={styles.element_2}>
          <p style={mode ? { color: "#FFFFFF" } : {}}>Product Name</p>
          <VerticalSearchDropDown
            options={newItemData.products.map((v) => ({
              label: v.product_name,
              value: v._id,
            }))}
            onOptionSelect={handleProductSelect}
          />
        </div>
        <div className={styles.element_2}>
          <p style={mode ? { color: "#FFFFFF" } : {}}>Collection Name</p>
          <VerticalSearchDropDown
            options={newItemData.collections.map((v) => ({
              label: v.collection_name,
              value: v._id,
            }))}
            onOptionSelect={handleCollectionSelect}
          />
        </div>

        {/* <InputTag
          labelText={"Collection Name"}
          placeholderText={"Enter Collection"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"text"}
          maxLength={20}
          value={newItemData.collection_name}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              collection_name: e.target.value.toUpperCase(),
            })
          }
        /> */}
      </div>
      <div>
        <InputTag
          labelText={"Item Number"}
          placeholderText={"Enter Item Number"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"number"}
          maxLength={10}
          value={newItemData.itemNo}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              itemNo: e.target.value,
            })
          }
        />
        <VerticalDropDown
          height={"37px"}
          fontSize={"12px"}
          dropDownText={"-Select-"}
          label={"Unit"}
          options={units}
          value={{
            label: newItemData.unit || "-Select-",
            value: newItemData.unit,
          }}
          onchange={(option) => {
            setNewItemData((prevState) => ({
              ...prevState,
              unit: option.value,
            }));
          }}
        />
      </div>
      <div>
        <InputTag
          labelText={"Price"}
          placeholderText={"Enter Price"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"number"}
          maxLength={10}
          value={newItemData.price}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              price: e.target.value,
            })
          }
        />
        <InputTag
          labelText={"Description"}
          placeholderText={"Enter Description"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"text"}
          maxLength={1000}
          value={newItemData.description}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              description: e.target.value,
            })
          }
        />
      </div>
      <div>
        <InputTag
          labelText={"Weight"}
          placeholderText={"Enter Weight"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"number"}
          maxLength={10}
          value={newItemData.weight}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              weight: e.target.value,
            })
          }
        />
        <InputTag
          labelText={"Volume (CBM)"}
          placeholderText={"Enter Volume"}
          padding={"8px"}
          fontSize={"12px"}
          required={true}
          placeholderType={"number"}
          maxLength={10}
          value={newItemData.vm}
          onChange={(e) =>
            setNewItemData({
              ...newItemData,
              vm: e.target.value,
            })
          }
        />
      </div>
      <FileUpload
        mode={mode}
        image={image}
        setImage={setImage}
        onFileChange={handleFileChange}
      />
      <div style={{ justifyContent: "center" }}>
        <div>
          <Button
            buttonBgColor={"#000"}
            buttonText={"CANCEL"}
            buttonTextColor={"#fff"}
            buttonIcon={<CancelIcon2 />}
            buttonClick={handleCloseModal}
          />
        </div>
        <div>
          <Button
            buttonBgColor={"#4164E3"}
            buttonText={"ADD ITEM"}
            buttonTextColor={"#fff"}
            buttonIcon={<TestTopAddDealerIcon />}
            buttonClick={(e) => submitForm(e)}
          />
        </div>
      </div>
    </div>
  );
};

export default AddNewItemModal;
