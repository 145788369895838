import React, { useState, useRef } from 'react';
import AvatarEditor from 'react-avatar-editor';

const ImageCroper2 = ({ image , setImage}) => {
//   const [image, setImage] = useState(null);
  const [scale, setScale] = useState(1);
  const editorRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleScaleChange = (e) => {
    setScale(e.target.value);
  };

  const handleSave = () => {
    if (editorRef.current) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      canvas.toBlob((blob) => {
        if (blob) {
          console.log(blob); // The cropped image as a Blob
          // Example: Upload the Blob
          // const formData = new FormData();
          // formData.append('file', blob, 'cropped-image.png');
          // fetch('your-upload-endpoint', { method: 'POST', body: formData });
        }
      }, 'image/png');
    }
  };

  return (
    <div>
      <h2>Image Cropper</h2>
      {image && (
        <>
          <div style={{ margin: '20px 0' }}>
            <AvatarEditor
              ref={editorRef}
              image={image}
              width={250}
              height={250}
              border={50}
              scale={scale}
              borderRadius={0} // This will create a circular crop
              rotate={0}
            />
          </div>
          <div>
            <label>Zoom:</label>
            <input
              type="range"
              min="1"
              max="10"
              step="0.01"
              value={scale}
              onChange={handleScaleChange}
            />
          </div>
          <button onClick={handleSave}>Save Cropped Image</button>
        </>
      )}
    </div>
  );
};

export default ImageCroper2;
