import { useEffect } from "react";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { CrossIcon2, SkipIcon, TickIcon } from "../../icons/Icon";
import Button from "../Dealers/Button";

const ShippingChargesModal = ({
  close,
  calculateCharges,
  orderDeliveryMethod,
  checkboxRef,
  mode,
  skipShippingCharges,
  noShippingCharges,
}) => {
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.key.toLowerCase() === "y") {
        calculateCharges && calculateCharges();
        orderDeliveryMethod && orderDeliveryMethod();
        checkboxRef.current?.focus();
        close();
      }
      if (e.key.toLowerCase() === "n") {
        orderDeliveryMethod && orderDeliveryMethod();
        noShippingCharges();
        close();
      }
      if (e.key.toLowerCase() === "s") {
        skipShippingCharges && skipShippingCharges();
        close();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    checkboxRef,
    close,
    calculateCharges,
    orderDeliveryMethod,
    skipShippingCharges,
    noShippingCharges,
  ]);

  return (
    <div
      className={`${styles.shipping_charges_modal} ${
        mode ? styles.dealer_address_modal_dark : ""
      }`}
    >
      <p>Shipping charges detail</p>
      <img
        src="/assets/updatedAssets/shippingCharge.png"
        alt="Shipping charges"
      />
      <p>Do you want the system to calculate shipping charges automatically?</p>
      <div>
        <div>
          <Button
            buttonText={"YES"}
            buttonIcon={<TickIcon />}
            buttonBgColor={"#4164E3"}
            buttonTextColor={"#FFFFFF"}
            buttonBorder={"none"}
            buttonClick={() => {
              calculateCharges && calculateCharges();
              orderDeliveryMethod && orderDeliveryMethod();
              checkboxRef.current?.focus();
              close();
            }}
          />
          <p className={styles.btn_text}>Press - Y</p>
        </div>
        <div>
          <Button
            buttonText={"NO"}
            buttonIcon={<CrossIcon2 />}
            buttonBgColor={"#F93E3E"}
            buttonTextColor={"#FFFFFF"}
            buttonBorder={"none"}
            buttonClick={() => {
              orderDeliveryMethod && orderDeliveryMethod();
              noShippingCharges();
              close();
            }}
          />
          <p className={styles.btn_text}>Press - N</p>
        </div>
        <div>
          <Button
            buttonText={"SKIP"}
            buttonIcon={<SkipIcon />}
            buttonBgColor={"#000000"}
            buttonTextColor={"#FFFFFF"}
            buttonBorder={"none"}
            buttonClick={() => {
              skipShippingCharges && skipShippingCharges();
              close();
            }}
          />
          <p className={styles.btn_text}>Press - S</p>
        </div>
      </div>
    </div>
  );
};

export default ShippingChargesModal;

// import { useEffect } from "react";
// import styles from "../../../pages/ordermanager/Orders.module.css";
// import { CrossIcon2, SkipIcon, TickIcon } from "../../icons/Icon";
// import Button from "../Dealers/Button";

// const ShippingChargesModal = ({
//   close,
//   calculateCharges,
//   orderDeliveryMethod,
//   shippingRef,
//   checkboxRef,
//   mode,
//   skipShippingCharges,
//   noShippingCharges,
// }) => {
//   useEffect(() => {
//     const handleKeyPress = (e) => {
//       e.preventDefault();

//       if (e.key.toLowerCase() === "y") {
//         handleAction(() => {
//           calculateCharges && calculateCharges();
//           orderDeliveryMethod && orderDeliveryMethod();
//         });
//       } else if (e.key.toLowerCase() === "n") {
//         handleAction(() => {
//           orderDeliveryMethod && orderDeliveryMethod();
//           noShippingCharges && noShippingCharges();
//         });
//       } else if (e.key.toLowerCase() === "s") {
//         handleAction(() => {
//           skipShippingCharges && skipShippingCharges();
//         });
//       }
//     };

//     const handleAction = (action) => {
//       action();
//       blurActiveElement();
//       close();
//     };

//     const blurActiveElement = () => {
//       if (
//         document.activeElement &&
//         typeof document.activeElement.blur === "function"
//       ) {
//         document.activeElement.blur();
//       }
//     };

//     window.addEventListener("keydown", handleKeyPress);
//     return () => {
//       window.removeEventListener("keydown", handleKeyPress);
//     };
//   }, [
//     checkboxRef,
//     close,
//     calculateCharges,
//     orderDeliveryMethod,
//     skipShippingCharges,
//     noShippingCharges,
//   ]);

//   return (
//     <div
//       className={`${styles.shipping_charges_modal} ${
//         mode ? styles.dealer_address_modal_dark : ""
//       }`}
//       tabIndex={-1} // Ensures modal can capture focus if needed
//     >
//       <p>Shipping charges detail</p>
//       <img
//         src="/assets/updatedAssets/shippingCharge.png"
//         alt="Shipping charges"
//       />
//       <p>Do you want the system to calculate shipping charges automatically?</p>
//       <div>
//         <div>
//           <Button
//             buttonText={"YES"}
//             buttonIcon={<TickIcon />}
//             buttonBgColor={"#4164E3"}
//             buttonTextColor={"#FFFFFF"}
//             buttonBorder={"none"}
//             buttonClick={() => {
//               calculateCharges();
//               close();
//             }}
//           />
//           <p className={styles.btn_text}>Press - Y</p>
//         </div>
//         <div>
//           <Button
//             buttonText={"NO"}
//             buttonIcon={<CrossIcon2 />}
//             buttonBgColor={"#F93E3E"}
//             buttonTextColor={"#FFFFFF"}
//             buttonBorder={"none"}
//             buttonClick={() => {
//               noShippingCharges && noShippingCharges();
//               close();
//             }}
//           />
//           <p className={styles.btn_text}>Press - N</p>
//         </div>
//         <div>
//           <Button
//             buttonText={"SKIP"}
//             buttonIcon={<SkipIcon />}
//             buttonBgColor={"#000000"}
//             buttonTextColor={"#FFFFFF"}
//             buttonBorder={"none"}
//             buttonClick={() => {
//               skipShippingCharges && skipShippingCharges();
//               close();
//             }}
//           />
//           <p className={styles.btn_text}>Press - S</p>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ShippingChargesModal;
