import { Spinner } from "../../icons/Icon";
import styles from "./DealerStyles.module.css";

const Button = ({
  buttonIcon,
  buttonText,
  buttonBgColor,
  disabledButtonBgColor,
  buttonTextColor,
  disabledButtonTextColor,
  buttonClick,
  buttonBorder,
  // type,
  buttonFontWeight,
  loading,
  disabled,
  tabIndex,
  ref,
  buttonType,
  count,
  notify
}) => {
  return (
    <button
      // className={disabled ? styles.button1 : styles.button}
      className={styles.button}
      style={{
        backgroundColor: disabled ? disabledButtonBgColor : buttonBgColor,
        border: buttonBorder,
        cursor: disabled ? "no-drop" : "pointer",
        // padding: padding && padding,
        // borderRadius: type === "manager" && "10px",
      }}
      onClick={buttonClick}
      disabled={disabled}
      tabIndex={tabIndex && tabIndex}
      type={buttonType}
    >
  

      {loading ? null : buttonIcon}
      {buttonText && (
        <p
          style={{
            color: disabled ? disabledButtonTextColor : buttonTextColor,
            fontWeight: buttonFontWeight,
          }}
          className={styles.text}
        >
          {loading ? <Spinner size="20" /> : buttonText}
        </p>
      )}
          {
        notify && <div className={styles.notifyicon}>{count}</div>
      }
    </button>
  );
};

export default Button;
