import React, { useCallback, useEffect, useRef, useState } from 'react'
import styles from "./Kyc.module.css"
import { useAuth } from '../../components/context/Auth'
import TopTabBar from '../../components/updatedComponents/Tanolet/TopTabBar'
import Button from '../../components/updatedComponents/Dealers/Button'
import { AddBtnicon, Transfer } from '../../components/icons/Icon2'
import DropDown from '../../components/updatedComponents/FormComponents/DropDown'
import GoToPage from '../../components/updatedComponents/Pagnation/GoToPage'
import Pagenation from '../../components/updatedComponents/Pagnation/Pagenation'
import DrawerWrapper from '../../components/updatedComponents/layoutWrapper/DrawerWrapper'
import fetchData from '../../APIsControll/apiControll'
import { truncateText } from '../../Utils/TextUtils'
import LocationSarchFilter from '../../components/updatedComponents/Filter/LocationSarchFilter'
import { useToast } from '../../components/updatedComponents/Toaster/Toaster'
import ModalWrapper from '../../components/updatedComponents/layoutWrapper/ModalWrapper'
const TransferDealer = () => {
    const { mode, managerId } = useAuth();
    const { show } = useToast()
    const [isOpen, setIsOpen] = useState({
        Darwer: false,
        Form: false,
        To: false,
    });
    const [loading, setLoading] = useState({
        loading1: false,
        loading2: false,
        loading3: false,
        loading4: false,
    });
    const [selectManager, stSelectManager] = useState({
        formManger: "",
        formMangerId: "",
        toManger: "",
        toMangerId: "",
    });
    const [allSelect, setAllSelect] = useState();
    const [page, setPage] = useState(1)
    const [pages, setPages] = useState(1)
    const [formpage, setFormPage] = useState(1);
    const [formpages, setFormPages] = useState(1);
    const [Formdata, setFormdata] = useState([]);
    const [groupDealer, setgroupDealer] = useState([]);
    const [unCheckedDealers, setUnCheckedDealers] = useState([]);

    const [Topage, setToPage] = useState(1);
    const [Topages, setToPages] = useState(1);
    const [Todata, setTodata] = useState([]);
    const [tosarch, setSarch] = useState("");
    const [formsarch, setFormarch] = useState("");
    const [state, setState] = useState({ locationList: [], searchValue: "", selectFilter: [] });
    const [District, setDistrict] = useState({ locationList: [], searchValue: "", selectFilter: [] });
    const [City, setCity] = useState({ locationList: [], searchValue: "", selectFilter: [] });
    const [DealerByMangerData, setDealerByMangerData] = useState([])

    const forRef = useRef();
    const toRef = useRef();

    //  form collback 
    const formData = useCallback(async () => {
        if (loading.loading1) return
        try {
            const payload = {
                managerId: managerId,
                keyword: formsarch,
                warehousename: "",
                designation: "",
                status: "",
                locations: {
                    states: [],
                    districts: [],
                    cities: [],
                    pincodes: []
                }
            }
            setLoading({ ...loading, loading1: true })
            const res = await fetchData(`managers/allManagersList?page=${formpage}&limit=24`, payload);
            if (res.status === "success") {
                setFormPages(res.pages)
                setFormdata((prev) => {
                    if (formsarch) {
                        return res.data;
                    } else {
                        return [...prev, ...res.data];
                    }
                });

            }
            if (res.status === "failed") {
                setFormdata([]);
                setFormPage(1);
                show(res.msg, "error");
                return
            }
        } catch (error) {
            show(error.massage, "error");
        } finally {
            setLoading({ ...loading, loading1: false })
        }

    }, [managerId, formsarch, formpage, show, loading])

    //  to collbak 
    const getToData = useCallback(async () => {
        if (loading.loading2) return
        try {
            const payload = {
                managerId: managerId,
                keyword: tosarch,
                warehousename: "",
                designation: "",
                status: "",
                locations: {
                    states: [],
                    districts: [],
                    cities: [],
                    pincodes: []
                }
            }
            const res = await fetchData(`managers/allManagersList?page=${Topage}&limit=24`, payload);
            if (res.status === "success") {
                setToPages(res.pages)
                if (formsarch.trim()) {
                    setTodata(res.data);
                } else {
                    setTodata((prev) => [...prev, ...res.data]);
                }
                return;
            }
            if (res.status === "failed") {
                setTodata([]);
                setToPage(1)
                show(res.msg, "error");
                return
            }
        } catch (error) {
            // show(error.massage, "error");
        }

    }, [loading.loading2, managerId, tosarch, Topage, formsarch, show]);

    // 
    useEffect(() => {
        formData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managerId, formsarch, formpage]);

    useEffect(() => {
        getToData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [managerId, formsarch, Topage]);

    //  handelScroll 
    const handleScroll = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollHeight - scrollTop <= clientHeight + 50) {
            if (!loading.loading1 && formpage < formpages) {
                setFormPage(formpage + 1);
                return
            }
        }
    };

    //  handelScroll
    const handleScroll2 = (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;
        if (scrollHeight - scrollTop <= clientHeight + 50) {
            if (!loading.loading2 && Topage < Topages) {
                setToPage(Topage + 1);
                return
            }
        }
    };

    // HandeSelectInputBox 
    const HandeSelectInputBox = async (event, item) => {
        const { checked } = event.target;
        const { dealerId } = item;

        if (checked) {
            // Add to `groupDealer` and remove from `unCheckedDealers`
            setgroupDealer((prev) => [...prev, dealerId]);
            setUnCheckedDealers((prev) => prev.filter((id) => id !== dealerId));
        } else {
            // Add to `unCheckedDealers` and remove from `groupDealer`
            setUnCheckedDealers((prev) => [...prev, dealerId]);
            setgroupDealer((prev) => prev.filter((id) => id !== dealerId));
        }
    }

    // getDealerIds 
    const getDealerByManagerId = useCallback(async () => {
        try {
            setLoading({ ...loading, loading3: true });
            const payload = {
                managerId,
                currentManagerId: selectManager.formMangerId,
                keyword: "",
                status: "",
                locations: {
                    states: state.selectFilter,
                    districts: District.selectFilter,
                    cities: City.selectFilter,
                    pincodes: [],
                }
            }
            const res = await fetchData(`kyc/getDealersByManager?page=${page}`, payload);
            if (res.status === "success") {
                setDealerByMangerData(res?.data);
                setPages(res?.pages);
                setPage(res?.page);
                return
            } if (res.status === "failed") {
                setDealerByMangerData([]);
                setPages(1);
                setPage(1);
                return
            }
            // console.log(res);

        } catch (error) {
            show(error.message, "error");

        } finally {
            setLoading({ ...loading, loading3: false });

        }
    }, [City.selectFilter,
    District.selectFilter,
        loading, managerId,
        page, selectManager.formMangerId,
        show, state.selectFilter]);

    useEffect(() => {
        if (selectManager.formManger !== "") {
            getDealerByManagerId();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectManager.formMangerId])

    console.log("chack dealer", groupDealer);
    console.log("unchack dealer", unCheckedDealers);


    //  transferDealersInBulk 
    const getDealerIdInbulk = async () => {
        setLoading({ ...loading, loading4: true })
        try {
            const payload = {
                managerId,
                currentManagerId: selectManager.formMangerId,
                newManagerId: selectManager.toMangerId,
                keyword: "",
                status: "",
                locations: {
                    states: state.selectFilter,
                    districts: District.selectFilter,
                    cities: City.selectFilter,
                    pincodes: [],
                }
            }
            const res = await fetchData(`kyc/transferDealersInBulk`, payload);

            console.log(res);
        } catch (error) {
            show(error.message, "error");
        } finally {
            setLoading({ ...loading, loading4: true });
        }
    }

    const handelallSelect = (event) => {

    }


    return (
        <div className={styles.main2}>
            <div className={styles.continer} style={{ backgroundColor: mode ? "#2C2E33" : "#F8F9FC" }}>
                <DrawerWrapper isOpen={isOpen.Darwer} onClose={() => setIsOpen((pre) => ({
                    ...pre, Darwer: !isOpen.Darwer
                }))}>
                    <div className={mode ? styles.DrawerWrapperContinetr : styles.DrawerWrapperContinetr_light} >
                        <div className={styles.btn}>
                            <div>
                                <Button
                                    buttonBgColor={"#000"}
                                    buttonText={"Back"}
                                    buttonTextColor={"#fff"}
                                    buttonIcon={<Transfer />}
                                />
                            </div>

                        </div>
                        <div>
                            <div>
                                <Button
                                    buttonBgColor={"#4164E3"}
                                    buttonText={"Transfer"}
                                    buttonTextColor={"#fff"}
                                    buttonIcon={<Transfer />}
                                />
                            </div>
                        </div>
                    </div>
                </DrawerWrapper>
                <ModalWrapper isOpen={loading.loading4}>
                    <h5 style={{ color: mode ? "#fff" : "#000" }}>Loading..</h5>
                </ModalWrapper>

                <div className={styles.go_back}>
                    <div>
                        <TopTabBar text={"Transfer Dealer"} />
                    </div>
                    <div>
                        <div style={{ width: "120px" }}>
                            <Button
                                buttonBgColor={"#4164E3"}
                                buttonText={"Transfer"}
                                buttonTextColor={"#fff"}
                                buttonIcon={<Transfer />}
                                buttonClick={() => setIsOpen((pre) => ({
                                    ...pre, Darwer: !isOpen.Darwer
                                }))}
                            />
                        </div>
                    </div>
                </div>


                <div className={styles.filter_cantiner} style={{ color: mode ? "#fff" : "#000" }}>
                    <div className={styles.select_dealer}>
                        <div className={styles.select_dealerCont}>
                            <div> From</div>
                            <div>
                                <div className={styles.select_main_Manager} style={{
                                    border: mode ? "none" : "1px solid #ccc",
                                    backgroundColor: mode ? "#1D1F23" : "#fff"
                                }} ref={forRef} >
                                    <div className={styles.select_manager} onClick={() => setIsOpen((pre) => ({
                                        ...pre, Form: !isOpen.Form
                                    }))} >
                                        <div> {truncateText(selectManager.formManger, 19) || "Select"} </div>
                                        <div><AddBtnicon mode={mode} rotate={isOpen.Form ? 180 : 0} /> </div>
                                    </div>
                                    {
                                        isOpen.Form &&
                                        <div className={styles.select_abslut_manager1} style={{
                                            background: mode ? "#2C2E33" : "#fff",
                                            color: mode ? "#fff" : "#000",
                                            border: mode ? "none" : "1px solid #ccc"
                                        }}>
                                            <div>
                                                <input type="text"
                                                    name="Form" className={styles.select_box_input}
                                                    placeholder='Sarching...'
                                                    style={{
                                                        border: mode ? "none" : "1px solid #ccc",
                                                        backgroundColor: mode ? "#000" : "#fff",
                                                        color: mode ? "#fff" : "#000"
                                                    }}
                                                    onChange={(e) => setFormarch(e.target.value)}
                                                />
                                            </div>
                                            <div className={styles.Select_scroll_con} onScroll={handleScroll} >
                                                <>
                                                    {Formdata?.map((item, index) => (
                                                        <div
                                                            className={mode ? styles.select_manger_dark : styles.select_manger_light}
                                                            key={index}
                                                            onClick={() => {
                                                                stSelectManager({
                                                                    ...selectManager,
                                                                    formManger: item.name,
                                                                    formMangerId: item.managerId
                                                                });
                                                                // stSelectManager({ ...selectManager, formMangerId: item.managerId });
                                                                setIsOpen({ ...selectManager, Form: false })
                                                            }}
                                                        >
                                                            <span>{truncateText(item.name, 19)}</span>
                                                        </div>
                                                    ))}
                                                </>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={styles.select_dealerCont}>
                            <div> To </div>
                            <div>
                                <div className={styles.select_main_Manager} style={{
                                    border: mode ? "none" : "1px solid #ccc",
                                    backgroundColor: mode ? "#1D1F23" : "#fff"
                                }} ref={toRef} >
                                    <div className={styles.select_manager} onClick={() => setIsOpen((pre) => ({
                                        ...pre, To: !isOpen.To
                                    }))} >
                                        <div> {truncateText(selectManager.toManger, 19) || "Select"}  </div>
                                        <div><AddBtnicon mode={mode} rotate={isOpen.To ? 180 : 0} /> </div>
                                    </div>
                                    {
                                        isOpen.To &&
                                        <div className={styles.select_abslut_manager1} style={{
                                            background: mode ? "#2C2E33" : "#fff",
                                            color: mode ? "#fff" : "#000",
                                            border: mode ? "none" : "1px solid #ccc"
                                        }}>
                                            <div>
                                                <input type="text"
                                                    name="To" className={styles.select_box_input}
                                                    placeholder='Sarching...'
                                                    style={{
                                                        border: mode ? "none" : "1px solid #ccc",
                                                        backgroundColor: mode ? "#000" : "#fff",
                                                        color: mode ? "#fff" : "#000",
                                                    }}
                                                    onChange={(event) => setSarch(event.target.value)}
                                                />
                                            </div>
                                            <div className={styles.Select_scroll_con} onScroll={handleScroll2} >
                                                <>
                                                    {Todata?.map((item, index) => (
                                                        <div
                                                            className={mode ? styles.select_manger_dark : styles.select_manger_light}
                                                            key={index}
                                                            onClick={() => {
                                                                stSelectManager({
                                                                    ...selectManager,
                                                                    toManger: item.name,
                                                                    toMangerId: item.managerId
                                                                });
                                                                setIsOpen({ ...selectManager, To: false })
                                                            }}
                                                        >
                                                            <span>{truncateText(item.name, 19)}</span>
                                                        </div>
                                                    ))}
                                                </>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className={styles.Transfer_loction}>
                        <LocationSarchFilter
                            text={"State"}
                            list={state.locationList}
                            search={state.searchValue}
                            setSearch={(value) =>
                                setState((prevState) => ({ ...prevState, searchValue: value }))
                            }
                            selectFilter={state.selectFilter}
                            setSelectFilter={(value) =>
                                setState((prevState) => ({ ...prevState, selectFilter: value }))
                            }
                        />
                        <LocationSarchFilter
                            text={"District"}
                            list={District.locationList}
                            search={District.searchValue}
                            setSearch={(value) =>
                                setDistrict((prevState) => ({ ...prevState, searchValue: value }))
                            }
                            selectFilter={District.selectFilter}
                            setSelectFilter={(value) =>
                                setDistrict((prevState) => ({ ...prevState, selectFilter: value }))
                            }
                        />
                        <LocationSarchFilter
                            text={"City"}
                            list={City.locationList}
                            search={City.searchValue}
                            setSearch={(value) =>
                                setCity((prevState) => ({ ...prevState, searchValue: value }))
                            }
                            selectFilter={City.selectFilter}
                            setSelectFilter={(value) =>
                                setCity((prevState) => ({ ...prevState, selectFilter: value }))
                            }
                        />
                        <div style={{ fontSize: "12px" }}>
                            <DropDown label={"Status"} options={[{ label: "name" }, { label: "Age" }]} />
                        </div>
                    </div>
                </div>

                <div className={styles.tableContiner} >
                    <div className={styles.container} style={{ height: "100%" }}>
                        <ul className={styles.responsiveTable} style={{ height: "100%" }}>
                            <li className={styles.tableHeader} style={mode ? { backgroundColor: "#232529", color: "#fafafa" }
                                : { backgroundColor: "#D9DCE5" }} >
                                <div className={`${styles.col} `}>
                                    <label className={styles.cl_checkbox} >
                                        <input type="checkbox"
                                            onChange={handelallSelect}

                                        />
                                        <span></span>
                                    </label>

                                    Dealer ID</div>
                                <div className={`${styles.col}`}> Dealer Name  </div>
                                <div className={styles.col} >Store Name</div>
                                <div className={styles.col} >City</div>
                                <div className={styles.col} >State</div>
                                <div className={styles.col} >Designation</div>
                                <div className={styles.col} >GSTUIN</div>
                            </li>
                            <div className={styles.scrolllist}>

                                {loading.loading3 ? <> loading.. </> : DealerByMangerData.length === 0 ? <> No Data Found</> :
                                    DealerByMangerData.map((item, i) => {
                                        return (
                                            <li className={styles.tablerow} style={mode ?
                                                { backgroundColor: "#1B1D21", color: "#fafafa" } : { backgroundColor: "#ffff" }} key={i} >
                                                <div className={`${styles.col} ${styles.coljustyfy}   `} data-label="Dealer Name" >
                                                    <div className={styles.TableHeadChackBox} >
                                                        <label className={styles.cl_checkbox} >
                                                            <input type="checkbox"
                                                                onChange={(event) => HandeSelectInputBox(event, item)}
                                                                checked={groupDealer.includes(item.dealerId)}
                                                            />
                                                            <span></span>
                                                        </label>
                                                    </div>
                                                    <div>{truncateText(item?.dealerId, 19)}</div>
                                                </div>
                                                <div className={`${styles.col}`} data-label="Address" > {truncateText(item?.name, 19)}</div>
                                                <div className={styles.col} data-label="State" >  {truncateText(item?.storeName, 19)}   </div>
                                                <div className={styles.col} data-label="District" >  {truncateText(item?.city, 19)}  </div>
                                                <div className={styles.col} data-label="City" > {truncateText(item?.state, 19)}   </div>
                                                <div className={styles.col} data-label="PIN Code" >  {truncateText(item?.designation, 19)}  </div>
                                                <div className={styles.col} data-label="Status" >   {truncateText(item?.gstuin, 19)} </div>
                                            </li>
                                        )
                                    })
                                }
                            </div>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={styles.pageContiner} style={{ backgroundColor: mode ? "#232529" : "#fff" }}>
                <div style={{ width: "50%", display: "flex", justifyContent: "flex-start", }} >
                    <GoToPage currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} />
                </div>
                <div style={{ display: "flex", justifyContent: "flex-end", width: "50%", }} >
                    <Pagenation currentPage={page} setCurrentPage={setPage} totalPages={pages} key={"1"} /></div>
            </div>

        </div>
    )
}

export default TransferDealer   