import { DragNDropIcon } from "../../icons/Icon";
import styles from "../../../pages/ordermanager/Orders.module.css";
import { useRef, useState } from "react";

const FileUpload = ({
  mode,
  image,
  setImage,
  fileType = "image/*",
  onFileChange,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const validateFileType = (file) => {
    if (fileType === "image/*") {
      return file.type.startsWith("image/");
    }
    return file.type === fileType;
  };

  const handleChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file && validateFileType(file)) {
      setImage(file);
      onFileChange && onFileChange(file);
    } else {
      alert(
        `Please upload a valid ${
          fileType === "image/*" ? "image" : fileType
        } file.`
      );
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    const file = e.dataTransfer.files[0];
    if (file && validateFileType(file)) {
      setImage(file);
      onFileChange && onFileChange(file);
    } else {
      alert(
        `Please upload a valid ${
          fileType === "image/*" ? "image" : fileType
        } file.`
      );
    }
  };

  const onButtonClick = () => {
    inputRef.current.click();
  };

  const handleViewImage = () => {
    if (image) {
      const imageUrl =
        image instanceof File || image instanceof Blob
          ? URL.createObjectURL(image) 
          : process.env.REACT_APP_S3URL + image;

      window.open(
        imageUrl,
        "ImageViewer",
        "width=800,height=600,scrollbars=yes,resizable=yes"
      );
    }
  };

  const handleDelete = () => {
    setImage(null);
    onFileChange && onFileChange(null);
  };

  console.log("image in file upload ", image);

  return (
    <>
      <div
        className={styles.file_upload}
        style={
          mode ? { backgroundColor: "#1B1D21", borderColor: "#646B88" } : {}
        }
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        {image ? (
          <img
            src={image instanceof File ? URL.createObjectURL(image) : ""}
            // src={URL.createObjectURL(image)}
            alt="Preview"
            className={styles.file_preview}
          />
        ) : (
          <div className={styles.file_upload_text}>
            <DragNDropIcon />
            <p>
              Drag & Drop <br /> any file
            </p>
            <p style={mode ? { color: "#fff" } : {}}>
              or <span onClick={onButtonClick}>browse files</span> on your
              computer
            </p>
            <input
              type="file"
              id="fileInput"
              accept={fileType}
              style={{ display: "none" }}
              ref={inputRef}
              onChange={handleChange}
            />
            {dragActive && (
              <div
                className={styles.drag_overlay}
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </div>
        )}
      </div>

      <div className={styles.file_upload_footer}>
        <div>
          <span>Max Size - 1920 x 680</span>
        </div>
        {image && (
          <div>
            <span
              style={{ cursor: "pointer", marginRight: "1rem" }}
              onClick={handleViewImage}
            >
              Preview
            </span>
            <span
              style={{ color: "#F93E3E", cursor: "pointer" }}
              onClick={handleDelete}
            >
              Delete
            </span>
          </div>
        )}
      </div>
    </>
  );
};

export default FileUpload;
