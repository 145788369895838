import React, { useEffect, useState } from "react";
import styles from "../../../pages/Home.module.css";
import Button from "../Dealers/Button";
import { useAuth } from "../../context/Auth";
import { LogInBtnIcon, ManagerLogOffIcon } from "../../icons/Icon";

const TimerLogout = ({ setShowModal }) => {
    const [timeLeft, setTimeLeft] = useState(100); // 300 seconds = 5 minutes
    const { mode } = useAuth()
    useEffect(() => {
        // Countdown timer
        const timer = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    sessionStorage.clear();
                    window.location.href = "/"; // Redirect to home page
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer); // Cleanup on component unmount
    }, []);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, "0")}:${secs
            .toString()
            .padStart(2, "0")}`;
    };

    const handleSignOut = () => {
        sessionStorage.clear();
        window.location.href = "/";
    };
    return (
        <div className={styles.timer} style={{
            background: mode ? "#2C2E33" : "#fff",
            color: mode ? "#fff" : "#000",
        }}>
            <div className={styles.logoutText1} >
                <p>Your Session <span style={{ color: "red" }}>  Expies IN  </span></p>
            </div>
            <div>
                <img src="/assets/updatedAssets/logout_img.png" alt="" />
            </div>
            <div className={styles.logoutText} >
                <p>Time remaining before <span style={{ color: "red" }}> logout: </span>  </p>
            </div>
            <div> {formatTime(timeLeft)}  </div>
            <div style={{ marginTop: "10px", display: "flex", gap: "10px" }}>
                <div style={{width:"100%"}}>

                    <Button
                        buttonBgColor={"#4164E3"}
                        buttonText={"Stay Logged In"}
                        buttonTextColor={"#ccc"}
                        buttonClick={() => setShowModal(false)}
                        buttonIcon={<LogInBtnIcon />}
                    />
                </div>
                <div style={{width:"100%"}}>
                    <Button
                        buttonBgColor={"#000"}
                        buttonText={"Logout"}
                        buttonTextColor={"#ccc"}
                        buttonClick={handleSignOut}
                        buttonIcon={<ManagerLogOffIcon />}
                    />
                </div>
            </div>
        </div>
    );
};

export default TimerLogout;
