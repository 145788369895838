import React, { useCallback, useEffect, useState } from "react";
import { ChatIcon } from "../../components/icons/Icon";
import Button from "../../components/updatedComponents/Dealers/Button";
import SlidingFilter2 from "../../components/updatedComponents/Dealers/SlidingFilter2";
import styles from "./Orders.module.css";
import { useAuth } from "../../components/context/Auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SaleOrder from "./SaleOrder";
import PurchaseOrder from "./PurchaseOrder";
import TextElement from "../../components/updatedComponents/FormComponents/TextElement";
import AdjustmentOrder from "./AdjustmentOrder";
// import { useToast } from "../../components/updatedComponents/Toaster/Toaster";
import VerticalDropDown from "../../components/updatedComponents/FormComponents/VerticalDropDown";
import TopTabBar from "../../components/updatedComponents/Tanolet/TopTabBar";

const Order = () => {
  const { mode } = useAuth();
  // const { show } = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const { route } = useParams();
  let isNew = false;
  isNew = location.search === "" ? true : false;

  const [orderType, setOrderType] = useState(isNew && "Sales");
  //same but for mobile
  const [selectedOrderType, setSelectedOrderType] = useState(
    isNew && {
      label: "Sales",
      value: "Sales",
    }
  );
  const [orderData, setOrderData] = useState({});

  useEffect(() => {
    if (route === "saleorder" || location.pathname.includes("saleorder")) {
      setOrderType("Sales");
    }
    if (route === "purchase" || location.pathname.includes("purchase")) {
      setOrderType("Purchase");
    }
    if (route === "adjustment" || location.pathname.includes("adjustment")) {
      setOrderType("Adjustment");
    }
  }, [location.pathname, route]);

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isNew) {
        if (
          e.key.startsWith("F") &&
          !isNaN(e.key.slice(1)) &&
          e.key.length > 1
        ) {
          e.preventDefault();
        }
        if (e.key === "F1") {
          setOrderType("Sales");
          setSelectedOrderType({ label: "Sales", value: "Sales" });
        }
        if (e.key === "F2") {
          setOrderType("Purchase");
          setSelectedOrderType({ label: "Purchase", value: "Purchase" });
        }
        if (e.key === "F3") {
          setOrderType("Adjustment");
          setSelectedOrderType({ label: "Adjustment", value: "Adjustment" });
        }
        if (e.key === "F4") {
          setOrderType("Transfer");
          setSelectedOrderType({ label: "Transfer", value: "Transfer" });
        }
        if (e.key === "F5") {
          setOrderType("Production");
          setSelectedOrderType({ label: "Production", value: "Production" });
        }
        if (e.key === "F6") {
          setOrderType("Production & Sale");
          setSelectedOrderType({
            label: "Production & Sale",
            value: "Production & Sale",
          });
        }
        if (e.key === "F7") {
          setOrderType("Production & Transfer");
          setSelectedOrderType({
            label: "Production & Transfer",
            value: "Production & Transfer",
          });
        }
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [isNew]);

  const orderTypeChange = (label) => {
    const selectedFilter = orderTypeData.find((item) => item === label);
    setOrderType(selectedFilter || "Sales");
  };

  const handleOrderData = useCallback((data) => {
    setOrderData(data);
  }, []);

  const orderTypeData = [
    "Sales",
    "Purchase",
    "Adjustment",
    "Transfer",
    "Production",
    "Production & Sale",
    "Production & Transfer",
  ];

  // console.log("order type ", orderType);
  const topText1 = orderType === "Sales" ? "Sale Order" : orderType || "";
  const topText2 = orderData?.orderId ? ` / ${orderData.orderId}` : "";
  const topText = isNew ? topText1 : `${topText1}${topText2}`;
  return (
    <div
      className={`${styles.order}`}
      style={mode ? { backgroundColor: "#2C2E33", border: "none" } : {}}
    >
      <div className={styles.order_top}>
        <div className={styles.order_top_left}>
          <TopTabBar text={topText} />
        </div>
        <div className={styles.order_right}>
          {isNew ? (
            <div>
              <div className={styles.order_sf}>
                <SlidingFilter2
                  itemList={orderTypeData.map((item) => item)}
                  setFilter={orderTypeChange}
                  selectedItem={orderTypeData.find(
                    (item) => item === orderType
                  )}
                />
              </div>
              <div className={styles.order_dd_mob}>
                <VerticalDropDown
                  height={"37px"}
                  fontSize={"12px"}
                  onchange={(option) => {
                    setSelectedOrderType(option);
                    setOrderType(option.value);
                  }}
                  // dropDownText={"Sales"}
                  value={selectedOrderType}
                  options={orderTypeData.map((o) => ({ label: o, value: o }))}
                />
              </div>
            </div>
          ) : (
            <div className={styles.right} style={{ gap: "0.5rem" }}>
              {/* {orderType === "Sales" && ( */}
              <TextElement
                label={"Voucher No. & Date:"}
                labelColor={mode ? "#fff" : "#646B88"}
                value={orderData.orderId + " / " + orderData.orderDate}
                valueColor={mode ? "#fff" : "#646B88"}
                fontSize={"12px"}
                type={"order"}
              />
              {/* )} */}
              {orderType === "Sales" && (
                <TextElement
                  label={"Created By:"}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={orderData.createdBy}
                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
              )}
              {orderType === "Sales" && (
                <TextElement
                  label={"Order Manager:"}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={orderData.orderManager || "In Dev."}
                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
              )}
              {orderType === "Sales" && (
                <TextElement
                  label={"Source:"}
                  labelColor={mode ? "#fff" : "#646B88"}
                  value={orderData.type}
                  valueColor={mode ? "#fff" : "#646B88"}
                  fontSize={"12px"}
                  type={"order"}
                />
              )}
              <TextElement
                label={"Status:"}
                labelColor={mode ? "#fff" : "#646B88"}
                value={orderData.status}
                valueColor={mode ? "#fff" : "#646B88"}
                fontSize={"12px"}
                type={"order"}
              />
            </div>
          )}

          <div>
            <Button
              buttonIcon={<ChatIcon />}
              buttonText={"Chat"}
              buttonBgColor={"#4164E3"}
              buttonTextColor={"#ffffff"}
              buttonBorder={"none"}
              buttonClick={() => {
                navigate("/whatsappchats");
              }}
            />
          </div>
        </div>
      </div>

      {orderType === "Sales" && <SaleOrder onOrderData={handleOrderData} />}
      {orderType === "Purchase" && (
        <PurchaseOrder onOrderData={handleOrderData} />
      )}
      {orderType === "Adjustment" && (
        <AdjustmentOrder onOrderData={handleOrderData} />
      )}
      {orderType === "Transfer" && <h1>Yaha par Transfer ayega</h1>}
      {orderType === "Production" && <h1>Yaha par Production ayega</h1>}
      {orderType === "Production & Sale" && (
        <h1>Yaha par Production aur Sale dono hi ayenge</h1>
      )}
      {orderType === "Production & Transfer" && (
        <h1>Yaha par Production aur Transfer dono hi ayenge</h1>
      )}
    </div>
  );
};

export default Order;
