import { truncateText } from "../../../Utils/TextUtils";
import { useAuth } from "../../context/Auth";
import { AddBtnicon } from "../../icons/Icon2";
import styles from "./FromComponent.module.css";
import { useState, useEffect, useRef } from "react";

const DropDown = ({ label, options, listLabel, onSelect, onReject }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);
  const { mode } = useAuth();

  const handleOptionSelect = (option) => {
    if (selectedOption && selectedOption.value === option.value) {
      setSelectedOption(null);
      onReject();
    } else {
      setSelectedOption(option);
      onSelect(option);
    }
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className={styles.drop_down_container} ref={dropdownRef}>
      <p
        className={styles.drop_down_label}
        style={mode ? { color: "#fff" } : {}}
      >
        {label}
      </p>
      <div className={styles.drop_down_select1}>
        <div
          className={styles.drop_down_trigger1}
          style={
            mode ? { backgroundColor: "#1B1D21", borderColor: "#1B1D21" } : {}
          }
          onClick={() => setIsOpen(!isOpen)}
        >
          <div
            className={styles.drop_down_placeholder}
            style={{ color: mode ? "#fff" : "#646b88" }}
          >
            {selectedOption ? (
              <>
                <p>{selectedOption.label}</p>
                {/* {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon /> } */}
                {<AddBtnicon mode={mode} rotate={isOpen ? 180 : 0} />}
              </>
            ) : (
              <>
                <p> {listLabel ? listLabel : "Select"} </p>
                {/* {mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />} */}
                {<AddBtnicon mode={mode} rotate={isOpen ? 180 : 0} />}
              </>
            )}
          </div>
        </div>
        {isOpen && (
          <ul
            className={styles.drop_down_list}
            style={mode ? { backgroundColor: "#232529", border: "none" } : {}}
          >
            {options.map((option, index) => (
              <li
                key={index}
                className={`${styles.drop_down_item} ${mode ? styles.drop_down_item_dark : ""
                  }`}
                onClick={() => handleOptionSelect(option)}
              >
                {truncateText(option.label,11)}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default DropDown;