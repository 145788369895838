// import React from "react";
// import { Labelpic } from "../icons/Icon4";
// import { useAuth } from "../../components/context/Auth";
import React from "react";

import { useAuth } from "../context/Auth";
import { Labelpic } from "../icons/Icon4";


// export const FileHandler2 = ({ isImage, setisImage, error }) => {
//   const { mode } = useAuth();
//   const [dragActive, setDragActive] = React.useState(false);
//   const [isInputClicked, setIsInputClicked] = React.useState(false); // Track if input was clicked
//   const inputRef = React.useRef(null);

//   // handle drag events
//   const handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//   };

//   // triggers when file is dropped
//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragActive(false);
//     if (e.dataTransfer.files && e.dataTransfer.files[0]) {
//       setisImage(e.dataTransfer.files);
//     }
//   };

//   // triggers when file is selected with click
//   const handleChange = (e) => {
//     e.preventDefault();
//     if (e.target.files && e.target.files[0]) {
//       setisImage(e.target.files);
//     }
//   };

//   // triggers the input when the button is clicked
//   const onButtonClick = () => {
//     setIsInputClicked(true); // Set clicked state to true when Browse Photos is clicked
//     inputRef.current.click();
//   };

//   // Reset the input state after file dialog is closed
//   const handleFileInputChange = (e) => {
//     setIsInputClicked(false); // Reset after file dialog is closed
//     handleChange(e); // Handle file change logic
//   };

//   return (
//     <form
//       id="form-file-upload"
//       onDragEnter={handleDrag}
//       onSubmit={(e) => e.preventDefault()}
//     >
//       <input
//         ref={inputRef}
//         type="file"
//         id="input-file-upload"
//         multiple={true}
//         onChange={handleFileInputChange} // Handle file input change
//         style={{ display: "none" }}
//       />
//       <label
//         id="label-file-upload"
//         htmlFor="input-file-upload"
//         className={dragActive ? "drag-active" : ""}
//       >
//         {isImage ? (
//           <img
//             src={URL.createObjectURL(isImage[0])} // Preview the first image in the array
//             alt="item"
//             className="packingImage"
//           />
//         ) : (
//           <>
//             <div
//               style={{
//                 backgroundColor: mode ? "#1B1D21" : "#D9D9D9",
//                 borderRadius: "15px",
//               }}
//               className="drag_div"
//             >
//               <div style={{ width: "50%" }}>
//                 <Labelpic />
//               </div>
//               <p
//                 style={{
//                   color: mode ? "#9C9797" : "#646B88",
//                   fontWeight: "400",
//                   fontSize: "18px",
//                 }}
//                 className={
//                   error && error.receiptImage ? "alert_drag_txt" : "drag_txt1"
//                 }
//               >
//                 Drag and drop <br />any file.
//               </p>
//               <div>
//                 <p
//                   style={{
//                     color: mode ? "white" : "black",
//                   }}
//                 >
//                   or
//                   <span
//                     style={{
//                       color: mode ? "#4164E3" : "#4164E3",
//                       marginLeft: "4px",
//                       textDecoration: "underline",
//                     }}
//                     onClick={onButtonClick}
//                   >
//                     Browse Photos
//                   </span>{" "}
//                   on your computer.
//                 </p>
//               </div>
//             </div>
//           </>
//         )}
//       </label>
//       {dragActive && (
//         <div
//           id="drag-file-element"
//           onDragEnter={handleDrag}
//           onDragLeave={handleDrag}
//           onDragOver={handleDrag}
//           onDrop={handleDrop}
//         ></div>
//       )}
//       {error && error.receiptImage && (
//         <div className="text-danger d-flex">{error && error.receiptImage}</div>
//       )}
//     </form>
//   );
// };



// import React from "react";
// import { Labelpic } from "../icons/Icon4";
// import { useAuth } from "../../components/context/Auth";

// export const FileHandler2 = ({ isImage, setisImage, error }) => {
//   const { mode } = useAuth();
//   const [dragActive, setDragActive] = React.useState(false);
//   const inputRef = React.useRef(null);

//   // handle drag events
//   const handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//   };

//   // triggers when file is dropped
//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragActive(false);
//     if (e.dataTransfer.files && e.dataTransfer.files[0]) {
//       setisImage(e.dataTransfer.files);
//     }
//   };

//   // triggers when file is selected with click
//   const handleChange = (e) => {
//     e.preventDefault();
//     if (e.target.files && e.target.files[0]) {
//       setisImage(e.target.files);
//     }
//   };

//   // triggers the input when the button is clicked
//   const onButtonClick = () => {
//     inputRef.current.click();
//   };

//   // Handle file input change, only reset state when a file is selected
//   const handleFileInputChange = (e) => {
//     handleChange(e); // Handle the selected file
//   };

//   return (
//     <form
//       id="form-file-upload"
//       onDragEnter={handleDrag}
//       onSubmit={(e) => e.preventDefault()}
//     >
//       <input
//         ref={inputRef}
//         type="file"
//         id="input-file-upload"
//         multiple={true}
//         onChange={handleFileInputChange} // Handle file input change
//         style={{ display: "none" }} // Hide the file input field
//       />
//       <label
//         id="label-file-upload"
//         htmlFor="input-file-upload"
//         className={dragActive ? "drag-active" : ""}
//       >
//         {isImage && isImage.length > 0 ? (
//           // If images are selected, display the preview of the first image
//           <img
//             src={URL.createObjectURL(isImage[0])} // Preview the first image in the array
//             alt="item"
//             className="packingImage"
//           />
//         ) : (
//           <>
//             <div
//               style={{
//                 backgroundColor: mode ? "#1B1D21" : "#D9D9D9",
//                 borderRadius: "15px",
//               }}
//               className="drag_div"
//             >
//               <div style={{ width: "50%" }}>
//                 <Labelpic />
//               </div>
//               <p
//                 style={{
//                   color: mode ? "#9C9797" : "#646B88",
//                   fontWeight: "400",
//                   fontSize: "18px",
//                 }}
//                 className={
//                   error && error.receiptImage ? "alert_drag_txt" : "drag_txt1"
//                 }
//               >
//                 Drag and drop <br />any file.
//               </p>
//               <div>
//                 <p
//                   style={{
//                     color: mode ? "white" : "black",
//                   }}
//                 >
//                   or
//                   <span
//                     style={{
//                       color: mode ? "#4164E3" : "#4164E3",
//                       marginLeft: "4px",
//                       textDecoration: "underline",
//                     }}
//                     onClick={onButtonClick} // Open file dialog when clicked
//                   >
//                     Browse Photos
//                   </span>{" "}
//                   on your computer.
//                 </p>
//               </div>
//             </div>
//           </>
//         )}
//       </label>
//       {dragActive && (
//         <div
//           id="drag-file-element"
//           onDragEnter={handleDrag}
//           onDragLeave={handleDrag}
//           onDragOver={handleDrag}
//           onDrop={handleDrop}
//         ></div>
//       )}
//       {error && error.receiptImage && (
//         <div className="text-danger d-flex">{error && error.receiptImage}</div>
//       )}
//     </form>
//   );
// };




// import React from "react";
// import { Labelpic } from "../icons/Icon4";
// import { useAuth } from "../../components/context/Auth";

// export const FileHandler2 = ({ isImage, setisImage, error }) => {
//   const { mode } = useAuth();
//   const [dragActive, setDragActive] = React.useState(false);
//   const inputRef = React.useRef(null);

//   // handle drag events
//   const handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//   };

//   // triggers when file is dropped
//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragActive(false);
//     if (e.dataTransfer.files && e.dataTransfer.files[0]) {
//       setisImage(e.dataTransfer.files);
//     }
//   };

//   // triggers when file is selected with click
//   const handleChange = (e) => {
//     e.preventDefault();
//     if (e.target.files && e.target.files[0]) {
//       setisImage(e.target.files);
//     }
//   };

//   // triggers the input when the button is clicked
//   const onButtonClick = (e) => {
//     e.stopPropagation(); // Prevent event from bubbling up to the label
//     inputRef.current.click(); // Open file dialog
//   };

//   // Handle file input change, only reset state when a file is selected
//   const handleFileInputChange = (e) => {
//     handleChange(e); // Handle the selected file
//   };

//   return (
//     <form
//       id="form-file-upload"
//       onDragEnter={handleDrag}
//       onSubmit={(e) => e.preventDefault()}
//     >
//       <input
//         ref={inputRef}
//         type="file"
//         id="input-file-upload"
//         multiple={true}
//         onChange={handleFileInputChange} // Handle file input change
//         style={{ display: "none" }} // Hide the file input field
//       />
//       <label
//         id="label-file-upload"
//         htmlFor="input-file-upload"
//         className={dragActive ? "drag-active" : ""}
//       >
//         {isImage && isImage.length > 0 ? (
//           // If images are selected, display the preview of the first image
//           <img
//             src={URL.createObjectURL(isImage[0])} // Preview the first image in the array
//             alt="item"
//             className="packingImage"
//           />
//         ) : (
//           <>
//             <div
//               style={{
//                 backgroundColor: mode ? "#1B1D21" : "#D9D9D9",
//                 borderRadius: "15px",
//               }}
//               className="drag_div"
//             >
//               <div style={{ width: "50%" }}>
//                 <Labelpic />
//               </div>
//               <p
//                 style={{
//                   color: mode ? "#9C9797" : "#646B88",
//                   fontWeight: "400",
//                   fontSize: "18px",
//                 }}
//                 className={
//                   error && error.receiptImage ? "alert_drag_txt" : "drag_txt1"
//                 }
//               >
//                 Drag and drop <br />any file.
//               </p>
//               <div>
//                 <p
//                   style={{
//                     color: mode ? "white" : "black",
//                   }}
//                 >
//                   or
//                   <span
//                     style={{
//                       color: mode ? "#4164E3" : "#4164E3",
//                       marginLeft: "4px",
//                       textDecoration: "underline",
//                     }}
//                     onClick={onButtonClick} // Open file dialog when clicked
//                   >
//                     Browse Photos
//                   </span>{" "}
//                   on your computer.
//                 </p>
//               </div>
//             </div>
//           </>
//         )}
//       </label>
//       {dragActive && (
//         <div
//           id="drag-file-element"
//           onDragEnter={handleDrag}
//           onDragLeave={handleDrag}
//           onDragOver={handleDrag}
//           onDrop={handleDrop}
//         ></div>
//       )}
//       {error && error.receiptImage && (
//         <div className="text-danger d-flex">{error && error.receiptImage}</div>
//       )}
//     </form>
//   );
// };



// import React from "react";
// import { Labelpic } from "../icons/Icon4";
// import { useAuth } from "../../components/context/Auth";

export const FileHandler2 = ({ isImage, setisImage, error }) => {
  const { mode } = useAuth();
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef(null);

  // Handle drag events
  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // Triggered when a file is dropped
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);

    const files = e.dataTransfer.files;
    if (files && files[0]) {
      setisImage([files[0]]);
    }
  };
  // console.log("setisImage")

  // Triggered when file is selected via file input
  const handleChange = (e) => {
    e.preventDefault();

    const files = e.target.files;
    if (files && files[0]) {
      // Set the state to contain only the first selected file
      setisImage([files[0]]);
    }
  };

  // Trigger the file input dialog when the button is clicked
  const onButtonClick = (e) => {
    e.stopPropagation(); // Prevent event from bubbling up to the label
    inputRef.current.click(); // Open the file input dialog
  };

  // Handle file input change
  const handleFileInputChange = (e) => {
    handleChange(e); // Handle the selected file
  };

  return (
    <form
      id="form-file-upload"
      onDragEnter={handleDrag}
      onSubmit={(e) => e.preventDefault()}
    >
      <input
        ref={inputRef}
        type="file"
        id="input-file-upload"
        onChange={handleFileInputChange} // Handle file input change
        style={{ display: "none" }} // Hide the file input field
        accept="image/*" // Only allow image files
      />
      <label
        id="label-file-upload"
        htmlFor="input-file-upload"
        className={dragActive ? "drag-active" : ""}
      >
        {isImage && isImage.length > 0 ? (
          // If an image is selected, display the preview of the first image
          <img
            src={URL.createObjectURL(isImage[0])} // Preview the first image
            alt="item"
            className="packingImage"
          />
        ) : (
          <>
            <div
              style={{
                backgroundColor: mode ? "#1B1D21" : "#F8F9FC",
                borderRadius: "15px",
              }}
              className="drag_div"
            >
              <div style={{ width: "50%" }}>
                <Labelpic />
              </div>
              <p
                style={{
                  color: mode ? "#9C9797" : "#646B88",
                  fontWeight: "400",
                  fontSize: "18px",
                }}
                className={
                  error && error.receiptImage ? "alert_drag_txt" : "drag_txt1"
                }
              >
                Drag and drop <br />any file.
              </p>

              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>


                <p>  <p style={{
                  margin: 0,
                  color: mode ? "white" : "black",
                }}>or</p>
                  <button
                    type="button"
                    className={error && error.receiptImage ? 'alert_browseFiles_btn' : 'browseFiles_btn'}
                    onClick={onButtonClick}
                    style={{
                      background: 'none',             // No background
                      border: 'none',                 // No border
                      // color: 'pink',                  // Custom text color
                      color: mode ? "#4164E3" : "#4164E3",

                      textDecoration: 'none',         // Remove any underline
                      padding: 0,                     // Remove default padding
                      font: 'inherit',                // Use the same font as the parent
                      cursor: 'pointer',             // Pointer cursor to indicate it's clickable
                      outline: 'none',                // Remove the default focus outline
                      marginBottom: "0px",            // Remove bottom margin
                      marginLeft: "10px",             // Optional: add space between the button and text
                      marginRight: "10px",            // Optional: add space between the button and text
                    }}
                  >
                    Browse Photos
                  </button>  <p style={{
                    margin: 0,
                    color: mode ? "white" : "black",
                  }}>On your computer</p>
                </p>

                {/* Content after button */}
              </div>
            </div>
          </>
        )}
      </label>
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
      {error && error.receiptImage && (
        <div className="text-danger d-flex">{error && error.receiptImage}</div>
      )}
    </form>
  );
};





// export const FileHandler2 = ({ setisImage, addImageToPack, styles }) => {
//   const { mode } = useAuth();
//   const [dragActive, setDragActive] = React.useState(false);
//   const inputRef = React.useRef(null);

//   // Handle drag events
//   const handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//   };

//   // // Triggered when a file is dropped
//   // const handleDrop = (e) => {
//   //   e.preventDefault();
//   //   e.stopPropagation();
//   //   setDragActive(false);

//   //   const files = Array.from(e.dataTransfer.files);
//   //   if (files && files[0]) {
//   //     setisImage(files); // Update local state
//   //     addImageToPack(files[0]); // Notify parent to handle images
//   //   }
//   // };
//   // Triggered when a file is dropped
// const handleDrop = (e) => {
//   e.preventDefault();
//   e.stopPropagation();
//   setDragActive(false);

//   const files = Array.from(e.dataTransfer.files);
//   if (files && files[0]) {
//     const selectedFile = files[0]; // Select only the first file
//     setisImage([selectedFile]); // Update local state with a single file
//     addImageToPack(selectedFile); // Notify parent to handle the single image
//   }
// };

//   // // Triggered when a file is selected
//   // const handleChange = (e) => {
//   //   e.preventDefault();
//   //   const files = Array.from(e.target.files);
//   //   if (files && files[0]) {
//   //     setisImage(files); // Update local state
//   //     addImageToPack(files[0]); // Notify parent to handle images
//   //   }
//   // };
//   // Triggered when a file is selected
// const handleChange = (e) => {
//   e.preventDefault();
//   const files = Array.from(e.target.files);
//   if (files && files[0]) {
//     const selectedFile = files[0]; // Select only the first file
//     setisImage([selectedFile]); // Update local state with a single file
//     addImageToPack(selectedFile); // Notify parent to handle the single image
//   }
// };

//   return (
//     <form
//       id="form-file-upload"
//       onDragEnter={handleDrag}
//       onSubmit={(e) => e.preventDefault()}
//     >
//       <input
//         ref={inputRef}
//         type="file"
//         id="input-file-upload"
//         onChange={handleChange}
//         style={{ display: "none" }}
//         accept="image/*"
//       />
//       <label
//         id="label-file-upload"
//         htmlFor="input-file-upload"
//         className={dragActive ? "drag-active" : ""}
//       >
//         <div
//           style={{
//             backgroundColor: mode ? "#1B1D21" : "#F8F9FC",
//             borderRadius: "15px",
//           }}
//           className={styles.drag_div}
//         >
//           <div style={{ width: "50%" }}>
//             <Labelpic />
//           </div>
//           <p
//             style={{
//               color: mode ? "#9C9797" : "#646B88",
//               fontWeight: "400",
//               fontSize: "18px",
//             }}
//           >
//             Drag and drop <br /> any file, or{" "}
//             <button
//               type="button"
//               onClick={() => inputRef.current.click()}
//               style={{
//                 background: "none",
//                 border: "none",
//                 color: mode ? "#4164E3" : "#4164E3",
//                 cursor: "pointer",
//                 padding: 0,
//               }}
//             >
//               Browse Photos
//             </button>
//             .
//           </p>
//         </div>
//       </label>
//       {dragActive && (
//         <div
//           id="drag-file-element"
//           onDragEnter={handleDrag}
//           onDragLeave={handleDrag}
//           onDragOver={handleDrag}
//           onDrop={handleDrop}
//         ></div>
//       )}
//     </form>
//   );
// };




// export const FileHandler2 = ({ setisImage, addImageToPack, styles }) => {
//   const { mode } = useAuth();
//   const [dragActive, setDragActive] = React.useState(false);
//   const inputRef = React.useRef(null);

//   // Handle drag events
//   const handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//   };

//   // Triggered when a file is dropped
//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragActive(false);

//     const files = Array.from(e.dataTransfer.files);
//     if (files && files.length === 1) { // Ensure only one file is processed
//       setisImage(files); // Update local state
//       addImageToPack(files[0]); // Notify parent to handle the image
//     } else if (files.length > 1) {
//       alert("Please upload only one image at a time.");
//     }
//   };

//   // Triggered when a file is selected
//   const handleChange = (e) => {
//     e.preventDefault();
//     const files = Array.from(e.target.files);
//     if (files && files.length === 1) { // Ensure only one file is processed
//       setisImage(files); // Update local state
//       addImageToPack(files[0]); // Notify parent to handle the image
//     } else if (files.length > 1) {
//       alert("Please select only one image at a time.");
//     }
//   };

//   return (
//     <form
//       id="form-file-upload"
//       onDragEnter={handleDrag}
//       onSubmit={(e) => e.preventDefault()}
//     >
//       <input
//         ref={inputRef}
//         type="file"
//         id="input-file-upload"
//         onChange={handleChange}
//         style={{ display: "none" }}
//         accept="image/*"
//       />
//       <label
//         id="label-file-upload"
//         htmlFor="input-file-upload"
//         className={dragActive ? "drag-active" : ""}
//       >
//         <div
//           style={{
//             backgroundColor: mode ? "#1B1D21" : "#F8F9FC",
//             borderRadius: "15px",
//           }}
//           // className={styles.drag_div}
//         >
//           <div style={{ width: "50%" }}>
//             <Labelpic />
//           </div>
//           <p
//             style={{
//               color: mode ? "#9C9797" : "#646B88",
//               fontWeight: "400",
//               fontSize: "18px",
//             }}
//           >
//             Drag and drop <br /> any file, or{" "}
//             <button
//               type="button"
//               onClick={() => inputRef.current.click()}
//               style={{
//                 background: "none",
//                 border: "none",
//                 color: mode ? "#4164E3" : "#4164E3",
//                 cursor: "pointer",
//                 padding: 0,
//               }}
//             >
//               Browse Photos
//             </button>
//             .
//           </p>
//         </div>
//       </label>
//       {dragActive && (
//         <div
//           id="drag-file-element"
//           onDragEnter={handleDrag}
//           onDragLeave={handleDrag}
//           onDragOver={handleDrag}
//           onDrop={handleDrop}
//         ></div>
//       )}
//     </form>
//   );
// };





// export const FileHandler2 = ({ setisImage, addImageToPack, styles }) => {
//   const { mode } = useAuth();
//   const [dragActive, setDragActive] = React.useState(false);
//   const inputRef = React.useRef(null);

//   // Handle drag events
//   const handleDrag = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     if (e.type === "dragenter" || e.type === "dragover") {
//       setDragActive(true);
//     } else if (e.type === "dragleave") {
//       setDragActive(false);
//     }
//   };

//   // Triggered when a file is dropped
//   const handleDrop = (e) => {
//     e.preventDefault();
//     e.stopPropagation();
//     setDragActive(false);

//     const files = Array.from(e.dataTransfer.files);
//     if (files && files.length === 1) {
//       setisImage(files); // Update local state
//       addImageToPack(files[0], "Pack 1"); // Notify parent to add the image to Pack 1
//     } else if (files.length > 1) {
//       alert("Please upload only one image at a time.");
//     }
//   };

//   // Triggered when a file is selected
//   const handleChange = (e) => {
//     e.preventDefault();
//     const files = Array.from(e.target.files);
//     if (files && files.length === 1) {
//       setisImage(files); // Update local state
//       addImageToPack(files[0], "Pack 1"); // Notify parent to add the image to Pack 1
//     } else if (files.length > 0) {
//       alert("Please select only one image at a time.");
//     }
//   };

//   return (
//     <form
//       id="form-file-upload"
//       onDragEnter={handleDrag}
//       onSubmit={(e) => e.preventDefault()}
//     >
//       <input
//         ref={inputRef}
//         type="file"
//         id="input-file-upload"
//         onChange={handleChange}
//         style={{ display: "none" }}
//         accept="image/*"
//       />
//       <label
//         id="label-file-upload"
//         htmlFor="input-file-upload"
//         className={dragActive ? "drag-active" : ""}
//       >
//         <div
//           style={{
//             backgroundColor: mode ? "#1B1D21" : "#F8F9FC",
//             borderRadius: "15px",
//           }}
//           className={styles.drag_div}
//         >
//           <div style={{ width: "50%" }}>
//             <Labelpic />
//           </div>
//           <p
//             style={{
//               color: mode ? "#9C9797" : "#646B88",
//               fontWeight: "400",
//               fontSize: "18px",
//             }}
//           >
//             Drag and drop <br /> any file, or
//             <button
//               type="button"
//               onClick={() => inputRef.current.click()}
//               style={{
//                 background: "none",
//                 border: "none",
//                 color: mode ? "#4164E3" : "#4164E3",
//                 cursor: "pointer",
//                 padding: 0,
//               }}
//             >
//               Browse Photos
//             </button>
//             .
//           </p>
//         </div>
//       </label>
//       {dragActive && (
//         <div
//           id="drag-file-element"
//           onDragEnter={handleDrag}
//           onDragLeave={handleDrag}
//           onDragOver={handleDrag}
//           onDrop={handleDrop}
//         ></div>
//       )}
//     </form>
//   );
// };
