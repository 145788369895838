import { useAuth } from "../../context/Auth";
import {
  ManagerLogOutIcon,
  NavbarArrowDownDarkIcon,
  NavbarArrowDownIcon,
  NavbarFullScreenIcon,
  NavbarNotificationDarkIcon,
  NavbarNotificationIcon,
  NavbarSaleOrderDarkIcon,
  NavbarSaleOrderIcon,
  NavbarSearchDarkIcon,
  NavbarSearchIcon,
  NavbarSunDarkIcon,
  NavbarSunIcon,
  // NavbarWhatsAppIcon,
  UserProfileIcon,
} from "../../icons/Icon";
import styles from "./Navbar.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useEffect, useState } from "react";
import { MenuIconLight } from "../../icons/Icon2";
import Drawer from "./Drawer";
import { formatPath } from "../Hooks/GetUrlPath";
import ModalWrapper from "../layoutWrapper/ModalWrapper";
import ManagerModal from "../Manager/ManagerModal";
// import Button from "../Dealers/Button";

const Navbar = ({
  isTogle,
  isDrawerOpen,
  toggleDrawer,
  menuItems,
  defaultDashboard,
}) => {
  const { mode, setMode } = useAuth();
  const navigate = useNavigate();
  // const { designations } = useAuth();
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();

  // const defaultDashboardRoutes = {
  //   superadmin: "/dashboard",
  //   ordermanager: "/orders",
  //   dispatchmanager: "/dispatchdashboard",
  //   inventorymanager: "/inventory",
  //   paymentmanager: "/recipts",
  //   packingmanager: "/recipts",
  //   invoicemanager: "/recipts",
  //   contentmanager: "/recipts",
  // };
  const handleSignOut = () => {
    sessionStorage.clear();
    window.location.href = "/";
    setDropDownOpen(false);
  };
  useEffect(() => {
    // Example: Navigate to the defaultDashboard when needed
    if (window.location.pathname === "/") {
      navigate(defaultDashboard);
    }
  }, [defaultDashboard, navigate]);

  const handleClickOutside = (event) => {
    if (!event.target.closest(`.${styles.navbar_Profile_Section}`)) {
      setDropDownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const encryptedName = sessionStorage.getItem("name")
    ? sessionStorage.getItem("name")
    : "";
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedName, "secret key");
  const name = decryptedBytes.toString(CryptoJS.enc.Utf8);

  const dAarray = [];

  // console.log("dAarray",dAarray);
  
  console.log("dAarray", dAarray);

  const encryptedDesignations = sessionStorage.getItem("designations")
    ? sessionStorage.getItem("designations")
    : "";
  const decryptedDesignations = CryptoJS.AES.decrypt(
    encryptedDesignations,
    "secret key"
  );
  const designationss = decryptedDesignations.toString(CryptoJS.enc.Utf8);
  designationss.split(",").forEach((element) => {
    dAarray.push({ designation: element });
  });

  function toggle_full_screen() {
    if (
      (document.fullScreenElement && document.fullScreenElement !== null) ||
      (!document.mozFullScreen && !document.webkitIsFullScreen)
    ) {
      if (document.documentElement.requestFullScreen) {
        document.documentElement.requestFullScreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        /* Firefox */
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        /* Chrome, Safari & Opera */
        document.documentElement.webkitRequestFullScreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
        // console.log("hi");
      } else if (document.msRequestFullscreen) {
        /* IE/Edge */
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        /* Chrome, Safari and Opera */
        document.webkitCancelFullScreen();
      } else if (document.msExitFullscreen) {
        /* IE/Edge */
        document.msExitFullscreen();
      }
    }
  }

  // const navigateToPage = (url) => {
  //   window.location.href = url;
  // };

  const icons = {
    search: mode ? <NavbarSearchDarkIcon /> : <NavbarSearchIcon />,
    sun: mode ? <NavbarSunDarkIcon /> : <NavbarSunIcon />,
    notification: mode ? (
      <NavbarNotificationDarkIcon />
    ) : (
      <NavbarNotificationIcon />
    ),
    saleOrder: mode ? <NavbarSaleOrderDarkIcon /> : <NavbarSaleOrderIcon />,
    fullScreen: <NavbarFullScreenIcon color={mode ? "#ffffff" : "#646B88"} />,
    arrowDown: mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />,
    LogOutIcon: <ManagerLogOutIcon color={mode ? "#ffffff" : "#646B87"} />,
  };

  const darkModeStyles = {
    navbar: mode ? { backgroundColor: "#2C2E33" } : {},
    searchBar: mode ? { backgroundColor: "#1B1D21" } : {},
    profileSection: mode ? { backgroundColor: "#232529" } : {},
    text: mode ? { color: "#fff" } : {},
  };

  return (
    <div
      className={isTogle ? styles.navbar1 : styles.navbar}
      style={darkModeStyles.navbar}
    >
      <div className={styles.navbar_Search}>
        <h2 style={darkModeStyles.text}>WALLICON</h2>
      </div>

      <div className={styles.navbar_Right}>
        <div className={styles.navbar_Links}>
          {/* <div
            onClick={() => navigate("/whatsappchats")}
            style={{ cursor: "pointer", position: "relative" }}
          >
            {totalUnreadChat !== 0 && (
              <div className={styles.total_Unread_Chat}>{totalUnreadChat}</div>
            )}
            <NavbarWhatsAppIcon />
          </div> */}
          <div onClick={() => setMode(!mode)}>{icons.sun}</div>
          <div>{icons.notification}</div>
          <div onClick={() => navigate("/orders/order/saleorder")}>
            {icons.saleOrder}
          </div>
          <div
            onClick={() => {
              toggle_full_screen();
            }}
          >
            {icons.fullScreen}
          </div>
        </div>

        <div
          className={styles.navbar_Profile_Section}
          style={darkModeStyles.profileSection}
          onClick={() => setDropDownOpen(!dropDownOpen)}
        >
          <img src="/assets/updatedAssets/pfp.png" alt="img" />
          <p style={darkModeStyles.text}>{name}</p>
          {icons.arrowDown}

          {dropDownOpen && (
            <ul
              className={`${styles.hidden_designations} ${
                dropDownOpen ? styles.open : ""
              }`}
              style={
                mode
                  ? {
                      color: "#fff",
                      backgroundColor: "#232529",
                      borderColor: "#232529",
                    }
                  : {}
              }
            >
              <li
                onClick={() => {
                  setDropDownOpen(false);
                  setTimeout(() => {
                    navigate("/profile");
                  }, 300);
                }}
                className={`${styles.hidden_designations_list} ${
                  mode ? styles.hidden_designations_list_dark : ""
                }`}
              >
                <UserProfileIcon color={mode ? "#fff" : "#000"} /> Profile
              </li>
              {/* <li
                style={{ fontWeight: "600" }}
                onClick={() => setIsModalOpen(true)}
                className={`${styles.hidden_designations_list} ${
                  mode ? styles.hidden_designations_list_dark : ""
                }`}
              >
                <ManagerLogOutIcon color={mode ? "#fff" : "#000"} /> Logout
              </li> */}
            </ul>
          )}
        </div>
        <div
          onClick={handleSignOut}
          style={{ marginLeft: "20px", cursor: "pointer" }}
        >
          {icons.LogOutIcon}
        </div>
        <ModalWrapper
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
          }}
          noBtn
        >
          <ManagerModal
            heading={"Logout Device"}
            centerHeading={"Are you sure want to Logout?"}
            subHeading={"To confirm! type logout in the text field."}
            targetWord={"logout"}
            handleCloseModal={() => setIsModalOpen(false)}
            handleSubmit={() => {
              handleSignOut();
            }}
          />
        </ModalWrapper>
      </div>

      <div className={styles.md_continer}>
        <div onClick={toggleDrawer}>
          <MenuIconLight mode={mode} />
        </div>
        <div className={mode ? styles.md_menu_text_dark : styles.md_menu_text}>
          <span>{formatPath(location.pathname)}</span>
        </div>
        <div className={styles.md_image} onClick={() => setMode(!mode)}>
          <img
            src="./assets/updatedAssets/pfp.png"
            alt=""
            className={styles.main_Image}
          />
        </div>
        <Drawer
          isOpen={isDrawerOpen}
          onClose={toggleDrawer}
          menuItems={menuItems}
        />
      </div>
    </div>
  );
};

export default Navbar;

// import { useAuth } from "../../context/Auth";
// import {
//   NavbarArrowDownDarkIcon,
//   NavbarArrowDownIcon,
//   NavbarFullScreenDarkIcon,
//   NavbarFullScreenIcon,
//   NavbarNotificationDarkIcon,
//   NavbarNotificationIcon,
//   NavbarSaleOrderDarkIcon,
//   NavbarSaleOrderIcon,
//   NavbarSearchDarkIcon,
//   NavbarSearchIcon,
//   NavbarSunDarkIcon,
//   NavbarSunIcon,
//   NavbarWhatsAppIcon,
// } from "../../icons/Icon";
// import styles from "./Navbar.module.css";
// import { useLocation, useNavigate } from "react-router-dom";
// import CryptoJS from "crypto-js";
// import { useState } from "react";
// import { MenuIconLight } from "../../icons/Icon2";
// import Drawer from "./Drawer";
// import { formatPath } from "../Hooks/GetUrlPath";

// const Navbar = ({ isTogle, isDrawerOpen, toggleDrawer, menuItems }) => {
//   const { mode, setMode } = useAuth();
//   const navigate = useNavigate();
//   const { totalUnreadChat } = useAuth();
//   const [signOut, setSignOut] = useState(false);
//   const location = useLocation();

//   const handleSignOut = () => {
//     setSignOut(false);
//     sessionStorage.clear();
//     window.location.href = "/";
//   };

//   const encryptedName = sessionStorage.getItem("name") || "";
//   const decryptedBytes = CryptoJS.AES.decrypt(encryptedName, "secret key");
//   const name = decryptedBytes.toString(CryptoJS.enc.Utf8);

//   const dAarray = [];
//   const encryptedDesignations = sessionStorage.getItem("designations") || "";
//   const decryptedDesignations = CryptoJS.AES.decrypt(encryptedDesignations, "secret key");
//   const designations = decryptedDesignations.toString(CryptoJS.enc.Utf8);
//   designations.split(",").forEach((element) => {
//     dAarray.push({ designation: element });
//   });

//   const toggle_full_screen = () => {
//     if (document.fullscreenElement) {
//       document.exitFullscreen();
//     } else {
//       document.documentElement.requestFullscreen();
//     }
//   };

//   const navigateToPage = (url) => {
//     window.location.href = url;
//   };

//   const icons = {
//     search: mode ? <NavbarSearchDarkIcon /> : <NavbarSearchIcon />,
//     sun: mode ? <NavbarSunDarkIcon /> : <NavbarSunIcon />,
//     notification: mode ? <NavbarNotificationDarkIcon /> : <NavbarNotificationIcon />,
//     saleOrder: mode ? <NavbarSaleOrderDarkIcon /> : <NavbarSaleOrderIcon />,
//     fullScreen: mode ? <NavbarFullScreenDarkIcon /> : <NavbarFullScreenIcon />,
//     arrowDown: mode ? <NavbarArrowDownDarkIcon /> : <NavbarArrowDownIcon />,
//   };

//   const darkModeStyles = {
//     navbar: mode ? { backgroundColor: "#2C2E33" } : {},
//     searchBar: mode ? { backgroundColor: "#1B1D21" } : {},
//     profileSection: mode ? { backgroundColor: "#232529" } : {},
//     text: mode ? { color: "#fff" } : {},
//   };

//   return (
//     <div
//       className={isTogle ? styles.navbar1 : styles.navbar}
//       style={darkModeStyles.navbar}
//     >
//       <div className={styles.navbar_Search}>
//         <h2 style={darkModeStyles.text}>DASHBOARD</h2>
//       </div>

//       <div className={styles.navbar_Right}>
//         <div className={styles.navbar_Links}>
//           <div
//             onClick={() => navigate("/whatsappchats")}
//             style={{ cursor: "pointer", position: "relative" }}
//           >
//             {totalUnreadChat !== 0 && (
//               <div className={styles.total_Unread_Chat}>{totalUnreadChat}</div>
//             )}
//             <NavbarWhatsAppIcon />
//           </div>
//           <div onClick={() => setMode(!mode)} style={{ cursor: "pointer" }}>
//             {icons.sun}
//           </div>
//           <div style={{ cursor: "pointer" }}>{icons.notification}</div>
//           <div
//             onClick={() => navigateToPage("/orders/saleorder")}
//             style={{ cursor: "pointer" }}
//           >
//             {icons.saleOrder}
//           </div>
//           <div
//             onClick={() => {
//               toggle_full_screen();
//             }}
//             style={{ cursor: "pointer" }}
//           >
//             {icons.fullScreen}
//           </div>
//         </div>
//         <div
//           className={styles.navbar_Profile_Section}
//           style={darkModeStyles.profileSection}
//           onClick={() => handleSignOut()}
//         >
//           <img src="/assets/updatedAssets/pfp.png" alt="img" />
//           <p style={darkModeStyles.text}>{name}</p>
//           {icons.arrowDown}
//         </div>
//       </div>
//       <div className={styles.md_continer}>
//         <div onClick={toggleDrawer}>
//           <MenuIconLight mode={mode} />
//         </div>
//         <div className={mode ? styles.md_menu_text_dark : styles.md_menu_text}>
//           <span>{formatPath(location.pathname)}</span>
//         </div>
//         <div className={styles.md_image} onClick={() => setMode(!mode)}>
//           <img
//             src="./assets/updatedAssets/pfp.png"
//             alt=""
//             className={styles.main_Image}
//           />
//         </div>
//         <Drawer
//           isOpen={isDrawerOpen}
//           onClose={toggleDrawer}
//           menuItems={menuItems}
//         />
//       </div>
//     </div>
//   );
// };

// export default Navbar;
