import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { GreenButton, RedOutButton } from '../../components/Buttons'
import { DeleteFile, UpLoadFile } from '../../components/icons/Icon'
import styles from './Product.module.css'

const AddStyle = ({ setProduct }) => {
    const [searchParams] = useSearchParams();
    // const step = searchParams.get("step");
    const getId = searchParams.get("id");
    const pId = searchParams.get("product_id");
    const id = (!getId || getId === null) ? "" : getId
    const product_id = (!pId || pId === null) ? "" : pId

    const navigate = useNavigate()
    const product_name = window.location.pathname.split("/")[window.location.pathname.split("/")?.length - 1]

    const [loading, setLoading] = useState(false)

    const [addData, setAddData] = useState({
        style_name: "",
        uploadLogo: "",
        metaTitle: "",
        url: "",
        metaKeywords: "",
        focusKeyword: "",
        metaDescription: "",
        description: "",
        title: "",
        status: "",
        cartType: "",
        dealerOnly: "",
        accessories: "",
        partneredCompanyProduct: ""
    })

    // ---------------------------Desktop Banner------------------------------------
    const [banner, setBanner] = useState([{
        bannerImg: "",
        itemUrl: "",
        itemUrlType: ""
    }])

    const [bannerUpdate, setBannerUpdate] = useState([])

    const addFields = () => {
        let newfield = { bannerImg: '', itemUrl: '', itemUrlType: '' }
        setBanner([...banner, newfield])
    }
    const handleFileChange = (index, event) => {
        let data = [...banner];
        data[index][event.target.name] = event.target.files[0];
        setBanner(data);
    }
    const handleFormChange = (index, event) => {
        let data = [...banner];
        data[index][event.target.name] = event.target.value;
        setBanner(data);
    }
    const removeFormFields = (i) => {
        const values = [...banner];
        values.splice(i, 1);
        setBanner(values);
    }

    // ---------------------------Mobile Banner------------------------------------
    const [mobBanner, setMobBanner] = useState([{
        bannerImg: "",
        itemUrl: "",
        itemUrlType: ""
    }])
    const [mobBannerUpdate, setMobBannerUpdate] = useState([])

    const addMobBanner = () => {
        let newfield = { bannerImg: '', itemUrl: '', itemUrlType: '' }
        setMobBanner([...mobBanner, newfield])
    }
    const handleMobFileChange = (index, event) => {
        let data = [...mobBanner];
        data[index][event.target.name] = event.target.files[0];
        setMobBanner(data);
    }
    const handleMobFormChange = (index, event) => {
        let data = [...mobBanner];
        data[index][event.target.name] = event.target.value;
        setMobBanner(data);
    }
    const removeMobFormFields = (i) => {
        const values = [...mobBanner];
        values.splice(i, 1);
        setMobBanner(values);
    }
 // ---------------------------Meta Keywords----------------------
 const [input, setInput] = useState('');
 const [tags, setTags] = useState([]);
 const [isKeyReleased, setIsKeyReleased] = useState(false);

 const onKeyDown = (e) => {
     const { key } = e;
     const trimmedInput = input.trim();

     if ((key === ',' || key === "Enter") && trimmedInput?.length && !tags.includes(trimmedInput)) {
         e.preventDefault();
         setTags(prevState => [...prevState, trimmedInput]);
         setInput('');
     }

     if (key === "Backspace" && !input?.length && tags?.length && isKeyReleased) {
         const tagsCopy = [...tags];
         const poppedTag = tagsCopy.pop();
         e.preventDefault();
         setTags(tagsCopy);
         setInput(poppedTag);
     }

     setIsKeyReleased(false);
 };

 const onKeyUp = () => {
     setIsKeyReleased(true);
 }

 const deleteTag = (index) => {
     setTags(prevState => prevState.filter((tag, i) => i !== index))
 }

 const onChange = (e) => {
     const { value } = e.target;
     setInput(value);
 };

     // Ravi Changes
     const [tages2, setTages2] = useState([])
     useEffect(() => {
         const trimmedInput = input.trim();
         if (input.includes(",")) {
             setTages2(trimmedInput.split(','))
         }
     }, [input])
 
     useEffect(() => {
         if (tages2) {
             setTags(tages2)
             setInput("")
         }
     }, [tages2])
     // Ravi Changes
    // ---------------------------Get Style By Id----------------------
    useEffect(() => {
        if (id) {
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

            var raw = JSON.stringify({
                "_id": id
            });

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(process.env.REACT_APP_URL + "content/style", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status === 'success') {
                        if (result.data) {
                            setAddData(prevState => ({
                                ...prevState,
                                style_name: result.data.style_name ? result.data.style_name : "",
                                uploadLogo: result.data.logo ? result.data.logo : "",
                                title: result.data.title ? result.data.title : "",
                                description: result.data.description ? result.data.description : "",
                                url: result.data.url ? result.data.url : "",
                                metaTitle: result.data.metaTitle ? result.data.metaTitle : "",
                                metaKeywords: result.data.metaKeywords ? result.data.metaKeywords : "",
                                focusKeyword: result.data.focusKeyword ? result.data.focusKeyword : "",
                                metaDescription: result.data.metaDescription ? result.data.metaDescription : "",
                                status: result.data.status ? result.data.status : "",
                            }))
                            if (result.data.metaKeywords?.length === 1) {
                                setTags(result.data.metaKeywords[0].split(','))
                            } else {
                                setTags(result.data.metaKeywords)
                            }
                            setBanner(result.data.banner)
                            setMobBanner(result.data.mobBanner)
                        }
                    }
                })
                .catch(error => console.log('error', error));
        }
    }, [id])

    // ----------------------------Add Update Product-------------------------
    const handleAddUpdate = (e) => {
        e.preventDefault()
        setLoading(true)

        var myHeaders = new Headers();
        myHeaders.append("wa-platform", process.env.REACT_APP_ADMIN_SECRET);

        var formdata = new FormData();
        formdata.append("product_name", product_name);
        product_id && formdata.append("product_id", product_id);
        formdata.append("style_name", addData.style_name);
        formdata.append("logo", addData.uploadLogo);
        formdata.append("metaTitle", addData.metaTitle);
        formdata.append("metaKeywords", JSON.stringify(tags));
        formdata.append("focusKeyword", addData.focusKeyword);
        formdata.append("url", addData.url);
        formdata.append("title", addData.title);
        formdata.append("description", addData.description);
        formdata.append("metaDescription", addData.metaDescription);
        formdata.append("status", addData.status);
        banner.map(item =>
            <>
                {(formdata.append("bannerImg", item.bannerImg),
                    formdata.append("itemUrl", item.itemUrl),
                    formdata.append("itemUrlType", item.itemUrlType))}
            </>
        )
        mobBanner.map(item =>
            <>
                {(formdata.append("mobBannerImg", item.bannerImg),
                    formdata.append("mobItemUrl", item.itemUrl),
                    formdata.append("mobItemUrlType", item.itemUrlType))}
            </>
        )
        formdata.append("bannerUpdate", bannerUpdate);
        formdata.append("mobBannerUpdate", mobBannerUpdate);
        id && formdata.append("_id", id);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(process.env.REACT_APP_URL + "content/addUpdateStyle", requestOptions)
            .then(response => response.json())
            .then(result => {
                if (result.status === "success") {
                    setLoading(false)
                    navigate(-1)
                } else {
                    setLoading(false)
                }
            })
            .catch(error => console.log('error', error));
    }
    return (
        <React.Fragment>
            <h1 className={styles.text_center}>{id ? "Update" : "Add"} Style</h1>
            <form onSubmit={handleAddUpdate} className={styles.addSTC}>
                <div className={styles.metaRow}>
                    <div className={styles.meta}>Meta URL</div>
                    <div className={styles.url}>
                        {process.env.REACT_APP_GLOBALURL}
                        <input type={"text"}
                            name='url'
                            value={addData.url}
                            onChange={(e) => setAddData({ ...addData, url: e.target.value })}
                            placeholder="Enter Url"
                            className={styles.add_url_input} />
                            {process.env.REACT_APP_GLOBALURL?.length+addData.url?.length}
                    </div>
                </div>

                <div className={styles.addProductRow}>
                    <div className={styles.addProductCol}>
                        <div className={styles.addProductColP}>
                            <div className={styles.addProductRow}>
                                <div className={styles.addProductCol3}>Product Name</div>
                                <div className={styles.addProductCol9}>
                                    <input
                                        type="text"
                                        name='style_name'
                                        value={addData.style_name}
                                        onChange={(e) => setAddData({ ...addData, style_name: e.target.value })}
                                        placeholder="Enter Style Name"
                                        className={styles.add_product_input}
                                        required
                                    />
                                </div>
                            </div>

                            {banner && banner.map((item, index) =>
                            (
                                <div className={styles.addBannerRow} key={index}>
                                    <div className={styles.addProductCol3}>{index + 1}. Desktop Banner :</div>
                                    <div className={styles.addBannerCol9}>
                                        <img src={
                                            item.bannerImg && item.bannerImg.name ?
                                                URL.createObjectURL(item.bannerImg)
                                                : item.bannerImg ? process.env.REACT_APP_S3URL + item.bannerImg
                                                    : "/assets/img.png"
                                        }
                                            alt="img" height={39} width={66} className={styles.bannerImg} />

                                        <label className={styles.bannerAddBtn}>
                                            <UpLoadFile />
                                            Upload Image
                                            <input
                                                name="bannerImg"
                                                type="file"
                                                className={styles.bannerImgInput}
                                                id="banner"
                                                onChange={(e) => {
                                                    handleFileChange(index, e);
                                                    if (item.bannerImg.name && !bannerUpdate.includes(item._id) && item._id) {
                                                        setBannerUpdate(prevState => [...prevState, item._id])
                                                    }
                                                }}
                                                accept="image/*"
                                            />
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter URL"
                                            className={styles.bannerUrlInput}
                                            name="itemUrl"
                                            value={item.itemUrl}
                                            onChange={(e) => handleFormChange(index, e)}
                                        />

                                        <select
                                            className={styles.bannerSelect}
                                            name="itemUrlType"
                                            value={item.itemUrlType}
                                            onChange={(e) => handleFormChange(index, e)}
                                        >
                                            <option className={styles.bg_dark}>Select</option>
                                            <option className={styles.bg_dark} value="item">Item</option>
                                            <option className={styles.bg_dark} value="url">Url</option>
                                            <option className={styles.bg_dark} value="N/A">N/A</option>
                                        </select>

                                        <div className={styles.bannerDeleteBtn}
                                            onClick={() => {
                                                removeFormFields(index);
                                                if (!bannerUpdate.includes(item._id) && item._id) {
                                                    setBannerUpdate(prevState => [...prevState, item._id])
                                                }
                                            }} >
                                            <DeleteFile color="red" /><br />
                                            Delete
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className={styles.addMoreBtn} onClick={() => { addFields() }} >
                                + Add Desktop Banner
                            </div>
                            <div className={styles.addBannerRow}>
                                <div className={styles.addProductCol3}>Product Title</div>
                                <div className={styles.addProductCol9}>
                                    <textarea
                                        name='title'
                                        value={addData.title}
                                        onChange={(e) => setAddData({ ...addData, title: e.target.value })}
                                        placeholder="Enter Title"
                                        className={styles.add_product_input}
                                        rows={3} />
                                    <div>
                                        *use 60 letters only
                                    </div>
                                </div>
                            </div>
                            <div className={styles.addBannerRow}>
                                <div className={styles.addProductCol3}>Product Description <br /> (optional)</div>
                                <div className={styles.addProductCol9}>
                                    <textarea
                                        name="description"
                                        value={addData.description}
                                        onChange={(e) => setAddData({ ...addData, description: e.target.value })}
                                        placeholder="Enter Description"
                                        className={styles.add_product_input}
                                        rows={5} />

                                </div>
                            </div>
                            <div className={styles.addBannerRow}>
                                <div className={styles.addProductCol3}>Status</div>
                                <div className={styles.addProductCol9}>
                                    <label>
                                        <input
                                            type="radio"
                                            name="status"
                                            value="Active"
                                            onChange={(e) => setAddData({ ...addData, status: e.target.value })}
                                            checked={addData.status === "Active" ? true : false}
                                        />Active
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            name="status"
                                            value="Disable"
                                            onChange={(e) => setAddData({ ...addData, status: e.target.value })}
                                            checked={addData.status === "Disable" ? true : false}
                                        />Disable
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={styles.addProductCol}>
                        <div className={styles.addProductColP}>
                            <div className={styles.addProductRow}>
                                <div className={styles.addProductCol3}>Upload Logo :</div>
                                <div className={styles.addBannerCol9}>
                                    <img src={
                                        addData.uploadLogo && addData.uploadLogo.name ?
                                            URL.createObjectURL(addData.uploadLogo)
                                            : addData.uploadLogo ? process.env.REACT_APP_S3URL + addData.uploadLogo
                                                : "/assets/img.png"
                                    }
                                        alt="img" height={39} width={66} className={styles.bannerImg}
                                    />

                                    <label className={styles.bannerAddBtn}>

                                        <UpLoadFile />
                                        Upload Image
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className={styles.d_none}
                                            name="uploadLogo"
                                            onChange={(e) => setAddData({ ...addData, uploadLogo: e.target.files[0] })}
                                        />
                                    </label>

                                    <div className={styles.bannerDeleteBtn}
                                        onClick={() => { setAddData({ ...addData, uploadLogo: "" }) }}
                                    >
                                        <DeleteFile color="red" /><br />
                                        Delete
                                    </div>
                                </div>

                            </div>

                            {mobBanner && mobBanner.map((item, index) =>
                            (
                                <div className={styles.addBannerRow} key={index}>
                                    <div className={styles.addProductCol3}>{index + 1}. Mobile Banner :</div>
                                    <div className={styles.addBannerCol9}>
                                        <img src={
                                            item.bannerImg && item.bannerImg.name ?
                                                URL.createObjectURL(item.bannerImg)
                                                : item.bannerImg ? process.env.REACT_APP_S3URL + item.bannerImg
                                                    : "/assets/img.png"
                                        }
                                            alt="img" height={39} width={66} className={styles.bannerImg} />

                                        <label className={styles.bannerAddBtn}>
                                            <UpLoadFile />
                                            Upload Image
                                            <input
                                                name="bannerImg"
                                                type="file"
                                                className={styles.bannerImgInput}
                                                id="banner"
                                                onChange={(e) => {
                                                    handleMobFileChange(index, e);
                                                    if (item.bannerImg.name && item._id) {
                                                        if (!mobBannerUpdate.includes(item._id)) {
                                                            setMobBannerUpdate(prevState => [...prevState, item._id])
                                                        }
                                                    }
                                                }}
                                                accept="image/*"
                                            />
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter URL"
                                            className={styles.bannerUrlInput}
                                            name="itemUrl"
                                            value={item.itemUrl}
                                            onChange={(e) => handleMobFormChange(index, e)}
                                        />

                                        <select
                                            className={styles.bannerSelect}
                                            name="itemUrlType"
                                            value={item.itemUrlType}
                                            onChange={(e) => handleMobFormChange(index, e)}
                                        >
                                            <option className={styles.bg_dark}>Select</option>
                                            <option className={styles.bg_dark} value="item">Item</option>
                                            <option className={styles.bg_dark} value="url">Url</option>
                                            <option className={styles.bg_dark} value="N/A">N/A</option>
                                        </select>

                                        <div className={styles.bannerDeleteBtn}
                                            onClick={() => {
                                                removeMobFormFields(index);
                                                if (!mobBannerUpdate.includes(item._id) && item._id) {
                                                    setMobBannerUpdate(prevState => [...prevState, item._id])
                                                }
                                            }} >
                                            <DeleteFile color="red" /><br />
                                            Delete
                                        </div>
                                    </div>
                                </div>
                            ))}

                            <div className={styles.addMoreBtn} onClick={() => { addMobBanner() }} >
                                + Add Mobile Banner
                            </div>

                            <div className={styles.addBannerRow}>
                                <div className={styles.addProductCol3}>Meta Title</div>
                                <div className={styles.addProductCol9}>
                                    <textarea type="text"
                                        name='metaTitle'
                                        value={addData.metaTitle}
                                        onChange={(e) => {
                                            setAddData({
                                                ...addData, metaTitle: e.target.value,
                                                url: e.target.value.toLocaleLowerCase().replace(/[\s,-=;]+/g, "-")
                                            })
                                        }}
                                        placeholder="Enter Title"
                                        className={styles.add_product_input}
                                        rows={3} />
                                    <div>
                                        *use 60 letters only<span className={styles.counter}>{addData.metaTitle?.length}</span>

                                    </div>
                                    
                                </div>
                            </div>
                            <div className={styles.addBannerRow}>
                                <div className={styles.addProductCol3}>Meta Description <br /> (optional)</div>
                                <div className={styles.addProductCol9}>
                                    <textarea
                                        name="metaDescription"
                                        value={addData.metaDescription}
                                        onChange={(e) => setAddData({ ...addData, metaDescription: e.target.value })}
                                        placeholder="Enter Description"
                                        className={styles.add_product_input}
                                        rows={5} />
                                    <div>
                                        *use 50-1200 letters only<span className={styles.counter}>{addData.metaDescription?.length}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.addBannerRow}>
                                    <div className={styles.addProductCol3}>Keywords (optional)</div>
                                    <div className={styles.addtag}>
                                        <div className={styles.tag_main_div}>
                                            {tags.map((tag, index) =>
                                                <div key={index} className={styles.tag}>{tag}
                                                    <button type='button' className={styles.tag_button} onClick={() => deleteTag(index)}>x</button>
                                                </div>
                                            )}
                                            <textarea
                                                rows={1}
                                                value={input}
                                                placeholder="Enter a tag"
                                                onKeyDown={onKeyDown}
                                                onKeyUp={onKeyUp}
                                                onChange={onChange}
                                                className={styles.add_inputtag}
                                            />
                                        </div>
                                    </div>
                                </div>
                            <div className={styles.addBannerRow}>
                                <div className={styles.addProductCol3}>Focus Keywords (optional)</div>
                                <div className={styles.addProductCol9}>
                                    <input type="text"
                                        name='focusKeyword'
                                        value={addData.focusKeyword}
                                        onChange={(e) => setAddData({ ...addData, focusKeyword: e.target.value })}
                                        placeholder="Enter Focus Keyword"
                                        className={styles.add_product_input} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.addProductBtmBtn}>
                    <RedOutButton btnType="button" title="CANCEL" handleSubmit={() => navigate(-1)} />
                    <GreenButton disabled={loading && true} title={id ? "UPDATE" : "CREATE"} btnType="submit" />
                </div>
            </form>
        </React.Fragment>
    )
}

export default AddStyle
